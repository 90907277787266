import { Component, OnInit } from '@angular/core';
import {Title} from '@angular/platform-browser';
import {TagService} from '../../services/tag-service.service';
import {TopicService} from '../../services/topic.service';
import {Tag} from '../tag/model/tag';
import {Topic} from '../topic/model/topic';
import {Observable, throwError} from 'rxjs';
import {HostListener} from '@angular/core';
import {ActivatedRoute, Router } from '@angular/router';
import {UserService} from '../../services/user.service';
import {Login} from '../../services/user.service';
import { config } from '../../config';
import {RouterModule} from '@angular/router';
import { AdminHeaderComponent } from '../adminHeader/admin-header.component';
import { DocFooterComponent } from '../docFooter/doc-footer.component';
import { FormsModule } from '@angular/forms';

declare function unescape(s:string): string;
declare function escape(s:string): string;

@Component({
	host: {
		//'(document:keyup)': 'keyDown($event)',
    //		'(document:keydown)': 'keyDown($event)',
	},
	selector: 'app-review',
	templateUrl: './review.component.html',
	styleUrls: ['./review.component.css'],
  imports: [FormsModule, RouterModule, DocFooterComponent, AdminHeaderComponent],
  standalone: true
})
export class ReviewComponent implements OnInit {
@HostListener('window:keydown', ['$event'])

	tags: Tag[];
	topics: Topic[];
	ltopics: Topic[];
	rtopics: Topic[];
  public topic = new Topic('', '', '');
  utags: any;
	public model = new Tag('', '', '', '', '');
	public umodel = new Tag('', '', '', '', '');
	public selectedTag;
	selectedIndex = 0;
	message = "";
	searchText = "";
	currentPage = 0;
	pageSize = 10;
	pageDirection = -1;
	totalCount = 0;
	pageCount = 0;
	sortKey = "insertdate";
	selectAll = false;
	public theUser = "";
 public theGoldiUser = "";
  public selectedLeft = new Topic('', '', '');
  public selectedRight = new Topic('', '', '');
  public anytoany = false;

	constructor(private titleService: Title, private tagService: TagService, private topicService: TopicService, private activatedRoute: ActivatedRoute, public userSVC: UserService, private router: Router) { }

	ngOnInit() {
  		this.theUser = this.userSVC.loggedInUser.email;
  	if (!this.theUser) {
  		this.router.navigate(['']);
  	}

		this.activatedRoute.queryParams.subscribe(params => {
      this.searchText == ""

			this.model.hashTag = params['h'] || "";
			this.topic.hashTag = params['h'] || "";
      //router.events.subscribe((event)=>{ //fires on every URL change
      //   title.setTitle(getTitleFor(router.url));
      //});
			if(this.model.hashTag != "") {
      //				this.setFirstPage();
      this.titleService.setTitle(this.topic.hashTag);
      this.open(()=>{});
			}
		});
	}

  selectedText: string = '';

  showSelectedText(oField: any) {
    var text = "";
    if (window.getSelection) {
        text = window.getSelection().toString();
    }
    this.selectedText = text;
  }

	loadTags(callback: () => any) {
		if (this.searchText == "") {
		 this.tagService.filterTags(this.model.hashTag, this.model.source, this.model.value, this.sortKey, this.theUser)
				   .subscribe(
				      (tags: any) => {
							this.tags = tags; //Bind to view
							if(this.tags !== undefined) {
								this.totalCount = this.tags.length;
								this.pageCount = Math.ceil(this.totalCount / this.pageSize);
								callback();
								this.setPageVisible();
							}
					},
					 (err: any) => {
					    console.log(err);
					});
		} else {
		 this.model = new Tag('', '', '', '', '');
		 this.tagService.searchTags(this.searchText, this.sortKey, this.theUser)
				   .subscribe(
				      (tags: any) => {
							this.tags = tags; //Bind to view
							if(this.tags !== undefined) {
								this.totalCount = this.tags.length;
								this.pageCount = Math.ceil(this.totalCount / this.pageSize);
								callback();
								this.setPageVisible();
							}
					},
					 (err: any) => {
					    console.log(err);
					});
		}
	}

	submitTag(){
		let commentOperation:Observable<Tag[]>;
		//console.log(this.model);
		if (this.model.source == "" && this.model.value == "" )
		{
			return;
		}

		this.model.email = this.theUser;
		this.tagService.addTag(this.model).subscribe(
					() => {
						this.model = new Tag('', '', '', '', '');
						this.setFirstPage();
					},
					 (err: any) => {
						console.log(err);
					});
	}

	updateTag(tag: Tag){
		let commentOperation:Observable<Tag[]>;
		//console.log(tag);
		this.tagService.updateTag(tag).subscribe(
				() => {
        //				this.model = new Tag('', '', '', '', '');
        //	this.setFirstPage();
				},
				 (err: any) => {
					console.log(err);
				});
	}

	deleteTag(tag: any){
		let commentOperation:Observable<Tag[]>;
		if(confirm("Are you sure to delete this selected item from '#" + tag.hashTag + "'?")) {
			this.tagService.deleteTag(tag.ID).subscribe(
					() => {
						this.model = new Tag('', '', '', '', '');
						this.setFirstPage();
					},
					 (err: any) => {
					    	console.log(err);
					});
		}
	    }

	public selectTag( tag: any, i: number) {
		tag.rank = i + 1;
		this.selectedIndex = i;
		this.selectedTag =  tag;
		this.setMessage(this.selectedTag);
	}

	public setMessage(tag: any) {
		//this.message = " { \"rank\" : \"" + tag.rank + "\", \"loc\" : { \"x\" : \"" + tag.loc.x + "\", \"y\" : \"" + tag.loc.y + "\" }, \"tag\" : \"" + tag.hashTag + "\", \"source\" : \"" +  tag.source + "\",  \"value\" : \"" + tag.value + "\", \"timestamp\" : \""+tag.insertDate +"\" } ";
		this.message = " { \"rank\" : \"" + tag.rank + "\", \"loc\" : { \"x\" : \"47.60\", \"y\" : \"122.33\" }, \"tag\" : \"" + tag.hashTag + "\", \"source\" : \"" +  tag.source + "\",  \"value\" : \"" + tag.value + "\", \"timestamp\" : \""+tag.insertDate +"\" } ";
	}

	keyDown(event: any) {
		switch (event.keyCode) {
			case 38:
				this.onUp();
				event.preventDefault();
				break;
			case 40:
				this.onDown()
				event.preventDefault();
				break;
			case 13:
      //this.setFirstPage()
      //	event.preventDefault();
				break;
		}
		return;
	}

	public onDown() {
		if(this.selectedIndex < this.tags.length - 1)
		{
			this.selectedTag = this.tags[++this.selectedIndex];
			this.setMessage(this.selectedTag);
		}
	}

	public onUp() {
		if(this.selectedIndex > 0)
		{
			this.selectedTag = this.tags[--this.selectedIndex];
			this.setMessage(this.selectedTag);
		}
	}

	substringOf(source: string) {
		var i1 = source.indexOf( "https://" );
		var si = 8;
		if (i1 < 0)
		{
			i1 =  source.indexOf( "http://" );
			si = 7;
		}

		if(i1 >= 0)
		{
			var i2 = source.indexOf(  "/", i1 + si );
			if(i2 > 0)
			{
				var s = source.substring(i1 + si, i2);
				return s;
			}
		}
		return source;
	}

	editTag(tag: any) {
		//this.umodel.edit =  !this.umodel.edit;
		this.umodel = new Tag(tag.hashTag, tag.userId, tag.source, tag.value, tag.topicId);
		tag.edit = !tag.edit;
	}

	cancelUpdate(tag: Tag) {
		tag.hashTag = this.umodel.hashTag;
		tag.source = this.umodel.source;
		tag.value = this.umodel.value;
		tag.insertDate = this.umodel.insertDate;
		tag.edit = false;
	}

  refIndex(source: string): number {
    return this.utags.indexOf(source) + 1;
  }

	//pagination for table
	public setFirstPage() {
		this.pageDirection = 0;
		this.loadTags(() => {
			this.currentPage = 1;
    this.selectedText = this.tags[this.tags.length - 1].value;
    this.utags = Array.from(new Set(this.tags.map((item) => item.source)));
		});
	}

	setPreviousPage() {
		this.pageDirection = 1;
		    this.loadTags(() => {
			if (this.currentPage > 1) {
			    this.currentPage--;
			}
		 });
	}

	setNextPage() {
		this.pageDirection = 2;
		this.loadTags(() => {
		    if (this.currentPage < this.pageCount) {
			this.currentPage++;
		    }
		});
	}

	setLastPage () {
		this.pageDirection = 3;
		this.loadTags( () => {
			this.currentPage = this.pageCount;
		});
	}

	setPageVisible() {
		this.selectedIndex = (this.currentPage - 1) * this.pageSize;
		var end = this.currentPage * this.pageSize
		for (let i = 0; i < this.tags.length; i++) {
			this.tags[i].show = false;
			if(i >= this.selectedIndex && i < end) {
				this.tags[i].show = true;
			}
		}
		this.selectedTag = this.tags[this.selectedIndex];
		if (this.selectedTag) {
			this.selectedTag.rank = this.selectedIndex + 1;
			this.setMessage(this.selectedTag);
		}
	}

	setMe(element: any) {
		var s_height = element.scrollHeight;
		element.setAttribute('style','height:'+s_height+'px');
	}

	selectAllTags() {
		for (let i = 0; i < this.tags.length; i++) {
			if(!this.selectAll) {
	                        this.tags[i].checked = true;
			}
			else {
	                        this.tags[i].checked = false;
			}

                }
	}

	search() {
//		if(this.loggedInUser === undefined) {
			 this.setFirstPage();
//		} else {
//			this.router.navigate(['admin']);
//		}

	}


  search2(nameKey, myArray){
    for (var i=0; i < myArray.length; i++) {
        if (myArray[i].hashTag === nameKey) {
            return myArray[i];
        }
    }
    }

	open(callback: () => any) {
		if (this.topic.hashTag != "") {
     this.topicService.searchTopics("", "hashtag", this.theUser, "128")
				   .subscribe(
				       (topics: any) => {
							this.topics = topics; //Bind to view
							if(this.topics !== undefined) {
								callback();
                            this.ltopics = this.topics;
                this.rtopics = this.topics;
        this.topic = this.search2(this.topic.hashTag, topics);
        this.getTopic(()=>{
        this.topicvsclonesEx();
        });
							}
					},
					 (err: any) => {
					    console.log(err);
					});
          }
          }

getTopic(callback: () => any) {
      this.topicService.filterTopics(this.topic.hashTag, "", "", "", this.theUser)
            .subscribe(
                (topics: any) => {
                if(topics.length > 0) {
               this.topic = topics[0];
               callback();
               }
           },
            (err: any) => {
               console.log(err);
           });
   }

	open1(callback: () => any) {
		if (this.model.hashTag != "") {
		 this.topicService.getTopic(this.topic.hashTag, this.theUser)
				   .subscribe(
				       (topic: any) => {
							this.topic= topic; //Bind to view
              if(this.topic !== undefined) {
								callback();
							}
					},
					 (err: any) => {
					    console.log(err);
					});
		}
	}

 flash(msg,duration) {
      var el = document.createElement("div");
      el.setAttribute("style","position:absolute;top:100%;left:35%;background-color: mediumseagreen; border: none; color: white; padding: 10px; text-align: center; text-decoration: none; display: inline-block; font-size: 16 px; font-family:arial; margin: 4px 2px; cursor: pointer; border-radius: 8px;");
      //el.setAttribute("class","button");
      el.innerHTML = msg;
      setTimeout(function(){
         el.parentNode.removeChild(el);
      },duration);
      var h = document.getElementById("toolBar2");
      h.appendChild(el);
  }

	save(){
		this.topicService.updateTopic(this.topic).subscribe(
					() => {
					},
					 (err: any) => {
						console.log(err);
					});
          this.flash(this.topic.hashTag + " is saved!", 1000);
	}

  home() {
  this.router.navigate(['/admin/topic']);
  }

  publish() {
        this.router.navigate(['/admin/publish'],  { queryParams: { h : this.model.hashTag}});
    }

  chat() {
        this.router.navigate(['/admin/chat'],  { queryParams: { h : this.topic.hashTag}});
    }

    editDoc() {
      this.router.navigate(['/admin/edit'],  { queryParams: { h : this.topic.hashTag}});
    }

    public fullDocRight() {
        var p1 = document.getElementById("pane1");
        if(p1.style.display === "none") {
            p1.style.display = "table-cell";
          } else {
            p1.style.display = "none";
          }
    }

    fullDocLeft() {
        var p2 = document.getElementById("pane2");
        if(p2.style.display === "none") {
          p2.style.display= "table-cell";
          } else {
            p2.style.display = "none";
          }
    }

    selectTopicRight(event: any) {
        var tb = document.getElementById("textBox2");
        tb.innerHTML = "";
        this.topic.hashTag = this.selectedRight.hashTag;
        this.getTopic(()=>{ if (this.topic.value !== "") {
        tb.innerHTML = unescape(this.topic.value);
        }
        });
    }

    selectTopicLeft(event: any) {
            var tb = document.getElementById("textBox");
            this.topic.hashTag = this.selectedLeft.hashTag;
            this.getTopic(()=>{
            if (this.topic.value !== "") {
                  tb.innerHTML = unescape(this.topic.value);
                  }
            });

        if(!this.anytoany) {
              let rt: Topic[]=[];
                for (var i=0; i < this.selectedLeft.topics.length; i++) {
                    rt.push(new Topic(this.selectedLeft.topics[i], '', ''));
                }
              this.rtopics = rt;
              this.selectedRight = this.rtopics[0];
              this.selectTopicRight(null);
        }
    }

    topicvsclones(event: any) {
        this.anytoany = !this.anytoany;
        if (!event.currentTarget.checked) {
              let  lt: Topic[]=[];
              for (var i=0; i < this.topics.length; i++) {
              if (this.topics[i].topics && this.topics[i].topics.length > 0) {
                      lt.push(this.topics[i]);
              }
              }
              this.ltopics = lt;
              this.selectTopicLeft(null);
        } else {
              this.ltopics = this.topics.slice();
              this.rtopics = this.topics.slice();
              this.selectedLeft = this.search2(this.topic.hashTag, this.ltopics);
              this.selectedRight = this.search2(this.topic.hashTag, this.rtopics);
              this.selectTopicLeft(null);
              this.selectTopicRight(null);
        }
    }

    topicvsclonesEx() {
        this.selectedRight = this.topic;
              let  lt: Topic[]=[];
              for (var i=0; i < this.topics.length; i++) {
              if (this.topics[i].topics && this.topics[i].topics.length > 0) {
                      lt.push(this.topics[i]);
              }
              }
              this.ltopics = lt;
              this.selectedLeft = this.search2(this.topic.hashTag, this.ltopics);
              if (this.selectedLeft === undefined) {
                this.selectedLeft = this.ltopics[0];
              }
              this.selectTopicLeft(null);
    }

    //editable?
    edit(event: any) {
        var tb = document.getElementById("textBox");
        var tb2 = document.getElementById("textBox2");
        if (event.currentTarget.checked) {
          tb.contentEditable = "true";
          tb2.contentEditable = "true";
        } else {
          tb.contentEditable = "false";
          tb2.contentEditable = "false";
        }
    }

    //blur events
    setTopicLeft() {
        var tb = document.getElementById("textBox");
        this.topic.value = escape(tb.innerHTML);
    }

    setTopicRight() {
        var tb = document.getElementById("textBox2");
        this.topic.value = escape(tb.innerHTML);
    }

    //focus events
    setFocusLeft() {
        this.topic.hashTag = this.selectedLeft.hashTag;
        this.getTopic(()=>{});
    }

    setFocusRight() {
        this.topic.hashTag = this.selectedRight.hashTag;
        this.getTopic(()=>{});
    }
}
