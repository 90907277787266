import { Component} from '@angular/core';
import { config } from '../../config';
import {RouterModule} from '@angular/router';
import { FormsModule } from '@angular/forms';

@Component({
  templateUrl: './doc-footer.component.html',
  styleUrls: ['./doc-footer.component.css'],
  selector: 'doc-footer',
  standalone: true
 })

export class DocFooterComponent{
public taxonomy = "";
public class = "";
public subclass = "";

ngOnInit(){
    this.taxonomy = config.taxonomy;
    this.class = config.class;
    this.subclass = config.subclass;
  }
}
