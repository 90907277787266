<header></header>
<body style="height:100%;width:100%; margin:0px;">
<div style='text-align: center;padding-top:70px;width:100%;height:30vh;' class="siloqlogofont">
  <div style='text-align: left;width:500px;height: 30vh; margin:auto;'>
  <h2 class="siloqlogofont">Thank you from <a style="text-decoration:none;" href="/" target="_self"><span

    style="color:dodgerblue;">{{taxonomy}}</span><span
    class="sup"
    style="color:mediumseagreen;">&trade;</span>
                                                     </a></h2>
  <hr>
  <span class="siloqfont">This experience could have been better.
    Requested page doesn't exist. Please, try again with correct url later or
    contact  24/7 <i><a [routerLink]="['/contact']"
        style="text-decoration:none;color:dodgerblue;">support</a></i>. Again,
    we appreciate your business.</span>
  </div>
</div>
<footer></footer>

