<admin-header xmlns:h2="http://www.w3.org/1999/html"></admin-header>
<body tyle="height:100%;width:100%; margin:0px;">
<div style="text-align:center; width:100%;padding-top:120px;min-height: calc(100vh - 70px);">

    <admin-alert></admin-alert>

<div>
<br>
	<h2 class="block_container siloqlogofont" style="text-align:left;min-width:300px;"><a href="/"
                                                     target="_self"><span
    style="color:dodgerblue;">{{taxonomy}}</span><span
    class="sup"
    style="color:mediumseagreen;">&trade;</span>
                                                     &nbsp;QR Code</a></h2>
</div>
<br>
<form>
<div class="row align-center">
   <div class="small-12  columns">
        <label>
            <input type="text" class="siloqinput" placeholder="Your Url" id="url" style="min-width:300px;"
                [(ngModel)]="url" name="url" required #em="ngModel" pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$">
            <div [hidden]="em.valid || em.pristine" class="siloqfont">
            </div>
        </label>
    </div>
    <br>
    <br>
    <div class="small-12  columns" style="text-align:left;">
      <div style="width:25%;text-align:left;margin:auto;min-width:300px;" class="siloqfont">
       <button (click)="setQrCode();" type="button" class="siloqbutton">QR&nbsp;Code</button>
       <button class="siloqbutton" style="color:orangered;"(click)='done()'>Done</button>
    <br>
    <br>
        <div *ngIf='url' style="text-align:center;margin:0 auto;vertical-align: middle; width:100%;">
          <h2>QR Code:</h2>
          <img class="intLink" title="QrCode" id="qrcode" style="width:100%;height:100%;" />
        </div>
      </div>
    </div>
</div>
</form>
    <br>
    <br>
    <br>
<admin-footer></admin-footer>
