<admin-header></admin-header>
 <body style="background-color:#ffffff00; height:100%;width:100%; margin:0px;">
  <form method="post">



    <div class="doNotPrint" style="background-color:whitesmoke;position:fixed;margin-top:47px;display:table;width:100%; height:38px; valign:middle;border:0px solid lightgrey;padding:0px 0px 0px 0px;">
      <div  style="text-align:center;margin:0 auto;color:mediumseagreen;padding:0px 5px 0px 12px;font-family:Helvetica,AppleGothic,sans-serif;font-size:1em; font-weight:600;width:90%;vertical-align: middle;">
        <a class="fa fa-arrow-left intLink" title="Done with the timeline of '{{topic.hashTag}}'" (click)='docTopic(topic)'  valign="middle" style="color:dodgerblue;background-color:#ffffff00;font-size:1em;width:22px;height:22px;border:0px; padding-top:10px;font-weight:300;"></a>
      </div>
    </div>
    <div  class="doNotPrint" style="padding:100px 0px 10px 0px;font-family:Helvetica,AppleGothic,sans-serif; width:100%;text-align:center;color:mediumseagreen;font-size:1em;font-weight:500;vertical-align: middle;">Timeline</div>
    <div class="block_container" style="background-color:#ffffff00;border-bottom:1px solid gainsboro;padding:20px 0px 0px 0px;font-family:Century Gothic,CenturyGothic,AppleGothic,sans-serif; text-align: center; valign: top; font-size:1em;font-weight:200;"><a href="" ><img src="/assets/isloq.png" align="middle" style="margin-bottom:13px;width:19px; height:19px;" /></a> <span style="color:grey;"></span> <a style="text-decoration:none;" href=""><span
      style="color:dodgerblue;">i</span><span
      style="color:mediumseagreen;">s</span><span
      style="color:orangered;">l</span><span
      style="color:orange;">o</span><span
      style="color:dodgerblue;">q</span><span
      class="sup" style="color:orangered;">&trade;</span><span style="color:dodgerblue;">&nbsp;Publish Instantly!</span> </a>
    </div>

    <div *ngIf="!topic.users" style="font-family:Helvetica,AppleGothic,sans-serif;width:92%;overflow:hidden;margin:4px 4% 0px 4%;padding-top:10px;z-index:-1px;">
      <h3 style="text-align:center;">No Activity Available</h3>
    </div>
<div *ngIf="topic.users" id="textbox" style="font-family:Helvetica,AppleGothic,sans-serif;width:80%;overflow:hidden;margin:0px 0px 50px 10%;padding-top:10px;z-index:-1px;">
  <div><h2 style="text-align:center;">{{topic.hashTag}} </h2></div>
  <div><h4 style="text-align:center;">({{topic.id}})</h4></div>
  <div><h3 style="text-align:center;">{{topic.email || theUser}} </h3></div>
  <div><h4 style="text-align:center;">({{topic.users}})</h4></div>
  <div><h4 style="text-align:center;">{{topic.insertDate | date: 'M/d/yy, h:mm a'}}</h4></div>
      <h3>Research </h3>
			<div *ngFor="let tag of tags; let i = index" (click)="selectTag(tag, i)" (mouseenter)="browseIndex = i" >
        <div class = "regular" [class.browse]="i === browseIndex" [class.active]="i === selectedIndex">
    <div style="background-color:whitesmoke;width:100%;margin:0px;height:20px;font-family:Helvetica,AppleGothic,sans-serif;padding:0px 0px 0px 0px;float:center;-moz-border-radius: 5px;border-radius: 5px">

    <div style="float:left; text-align:left;width:16px;height:16px;"><input class="intLink" type="button" value="🔒" align="middle" (click)='docTopic(topic)' title="Lock {{topic.hashTag}}" style="color:dodgerblue;background-color:#ffffff00;border:0px; padding:0px 5px 5px 0px;font-weight:300;font-size:1em;"/>
    </div>
    <div style="text-align:right;display:table-cell;float:right; text-align:right;width:16px;height:16px;padding-left:0px;">
      <input class="intLink" type="button" value="🔗" valign="middle" style=" vertical-align: middle;color:orangered;background-color:#ffffff00;height:16px;width:16px;border:0px; padding:0px 0px 16px 0px;font-weight:300;font-size:.75em;"/>
    </div>
  </div>
  <div style="width:98%;vertical-align:top;margin:0px;display:inline-table;padding:5px;" >
  <div style="width:100%;vertical-align:top;margin:0px;display:inline-table;" >
 <div style="display: table-cell;">
    <input *ngIf="edit===true" class="doNotPrint" type="checkbox" style="width: 13px; height: 13px; vertical-align: bottom; position: relative; top: -1px; *overflow: hidden;padding:0px;margin:0px;font-weight:300;font-size:.75em;"/>
    <div  valign="top" style="display: inline-block;font-weight:600;font-size:1em;padding:0px 1px 2px 0px; height:20px;text-align;left;" [innerHTML]="tag.name | safeHtml"></div><input *ngIf="!tag.value" class="doNotPrint intLink" type="button" value="⭐️" valign="middle" style="display: inline-block;background-color:#ffffff00;height:16px;width:16px;border:0px; padding:0px 0px 5px 0px;font-weight:300;font-size:.75em;"/>

    <div  valign="top"style="display:table-cell;padding:0px 1px 2px 0px;text-align:justify;text-justify:display: inline-block; margin: 0 auto !important; inter-word;" [innerHTML]="renderImage(tag.value) + ' ' + tag.topicId | safeHtml"></div></div>&nbsp;<div style="float:right; width:20px;display:table-cell;vertical-align:top;"><a *ngIf="tag.source.startsWith('http')" href="{{tag.source}}" target="_blank">[{{refIndex(tag.source)}}]</a><a *ngIf="tag.source.indexOf('@') > 0"  (click)="profileEx(model.hashTag, tag.source)" target="_self">[{{refIndex(tag.source)}}]</a></div>
  </div>

  <div class="doNotPrint" style="border:0px;width:98%;text-align:left;">
  <span class="doNotPrint" type="button" valign="middle" style="color:mediumseagreen;background-color:#ffffff00;height:16px;width:16px;border:0px; padding:0px 0px 5px 5px;font-weight:300;font-size:.75em;">{{i+1}}&nbsp;&nbsp;</span><span class="doNotPrint" style="background-color:#ffffff00;height:16px;border:0px;padding:0px 0px 5px 0px;font-weight:300;font-size:.75em;color:navy;">{{tag.insertDate | date: 'M/d/yy, h:mm a'}}&nbsp;&nbsp;</span><span class="doNotPrint" style="background-color:#ffffff00;height:16px;border:0px;padding:0px 0px 5px 0px;font-weight:300;font-size:.75em;color:grey;">{{tag.email}}&nbsp;&nbsp;</span>
  </div>
  </div>
        </div>
      <br>
      <br>
      </div>
      <h3>References </h3>
      <div *ngFor="let tag of utags let i = index" style="padding:0px 1px 2px
      2px;">
        <span *ngIf="tag.indexOf('@') > 0" style="display: inline-block;" valign="top">[{{i+1}}]&nbsp;<a (click)="profileEx(model.hashTag, tag)" >{{tag}}</a></span>
        <span *ngIf="tag.startsWith('http')" style="display: inline-block;" valign="top">[{{i+1}}]&nbsp;<a href="{{tag}}" target="_blank">{{tag | truncate : 64}}</a></span>
      <br>
      </div>
	</div>
    <div class="block_container" style="background-color:#ffffff00;border-top:1px solid gainsboro;padding:10px 0px 0px 0px;font-family:Century Gothic,CenturyGothic,AppleGothic,sans-serif; text-align: center; valign: top; font-size:1em;font-weight:200;"><a style="text-decoration:none;" href="http://siloq.com" >
      <span style="color:dodgerblue;">Powered by</span> <img src="assets/isloq.png" align="middle" style="margin-bottom:13px;width:19px; height:19px;" /></a> <span style="color:grey;"></span> <a style="text-decoration:none;" href="http://siloq.com"><span style="color:dodgerblue;">i</span><span
      style="color:mediumseagreen;">s</span><span
      style="color:dodgerblue;">l</span><span
      style="color:orange;">o</span><span
      style="color:dodgerblue;">q</span><span
      class="sup"
      style="color:orangered;">™</span>
    </a>
    </div>
  <br>
  <doc-footer></doc-footer>
