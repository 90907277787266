<body style="height:100%;width:100%; margin:0px;">
 <div style="width:100%;overflow:hidden;margin-left:10px;padding-top:30px;" class="doNotPrint">
   <div style="width:415px;text-align:center;margin:auto;" class="siloqfont">
                <br>
	<h2 class="block_container siloqlogofont" style="text-align:center;">Thank you from <a href="/"
                                                     target="_self"><span
    style="color:dodgerblue;">{{taxonomy}}</span><span
    class="sup"
    style="color:mediumseagreen;">&trade;</span>
  </a></h2>
                <br>
                <br>
      <button class="button" style="background-color:mediumseagrean;"(click)='done()'>OK</button> </div>
                <br>
                <br>
</div>

