<admin-header></admin-header>
<html style="height:100%">
 <body style="background-color:#ffffff00; height:100%;width:100%; margin:0px;">
  <form method="post">


    <div class="doNotPrint" style="background-color:whitesmoke;position:fixed;margin-top:47px;display:table;width:100%; height:38px; valign:middle;border:0px solid lightgrey;padding:0px 0px 0px 0px;">
      <div  style="text-align:center;margin:0 auto;color:mediumseagreen;padding:0px 5px 0px 12px;font-family:Helvetica,AppleGothic,sans-serif;font-size:1em; font-weight:600;width:90%;vertical-align: middle;">
        <a class="fa fa-arrow-left intLink" title="Done with the demo of '{{topic.hashTag}}'" [routerLink]="['/admin/deck']" [queryParams]="{h:topic.hashTag}" valign="middle" style="padding-top:10px;color:dodgerblue;;background-color:#ffffff00;font-size:1em;width:22px;height:22px;border:0px;font-weight:300;"></a>
        <a class="fa fa-download intLink" valign="middle" (click)="onPrint();" style="color:dodgerblue;background-color:#ffffff00;border:0px; padding-top:10px;font-weight:300;font-size:1em;width:22px;height:22px;"title="Download the slide {{currentPage}} of '{{topic.hashTag}}'" ></a>
      </div>
    </div>
    <div  class="doNotPrint" style="padding:100px 0px 10px 0px;font-family:Helvetica,AppleGothic,sans-serif; width:100%;text-align:center;color:mediumseagreen;font-size:1em;font-weight:500;vertical-align: middle;">Demo</div>
    <div class="block_container" style="background-color:#ffffff00;border-bottom:1px solid gainsboro;padding:20px 0px 0px 0px;font-family:Century Gothic,CenturyGothic,AppleGothic,sans-serif; text-align: center; valign: top; font-size:1em;font-weight:200;"><a href="" ><img src="/assets/isloq.png" align="middle" style="margin-bottom:13px;width:19px; height:19px;" /></a> <span style="color:grey;"></span> <a style="text-decoration:none;" href=""><span
      style="color:dodgerblue;">i</span><span
      style="color:mediumseagreen;">s</span><span
      style="color:orangered;">l</span><span
      style="color:orange;">o</span><span
      style="color:dodgerblue;">q</span><span
      class="sup" style="color:orangered;">&trade;</span><span style="color:dodgerblue;">&nbsp;Publish Instantly!</span> </a>
    </div>
    <div id="doc" style="font-family:Helvetica,AppleGothic,sans-serif;width:92%;overflow:hidden;margin:4px 4% 40px 4%;padding-top:10px;z-index:-1px;">
      <h2 style="vertical-align: middle;padding:0px 0px 15px 5px;margin:0 auto;width:90%;text-align:center;">
        <div  style="font-family: Arial, Helvetica, sans-serif;padding:0px 0px 0px 2px; margin:0px 0px 0px 3px;" >{{model.hashTag}}</div>
      </h2>

<div id="textbox" style="font-family:Helvetica,AppleGothic,sans-serif;width:100%;height:70%;overflow:hidden;margin:0px 0px 50px 0%;padding-top:10px;z-index:-1px;">

<div style="width: 10%; min-height:300px;dispaly:table-cell;float:left;background-color:#00000000;cursor:pointer;" (click)="onUp();">
    &nbsp;
  </div>

  <div  class = "regular" style="min-height:300px;margin:0 auto;float:left;width:75%;">
    <br>
    <div  style="width:100%;min-height:300px;vertical-align:top;margin:0px;display:inline-table;padding:5px;" >
      <div style="width:100%;min-height:300px;vertical-align:top;margin:0px;display:inline-table;" >
        <div  style="text-align;center; padding:20px 0px 0px 0px;margin-bottom:30px;">
          <div  valign="top" style="text-align: center;font-weight:300;font-size:1em;color:dodgerblue;padding:0px 1px 2px 0px; height:20px;margin:0 auto;" >{{selectedSlide}}</div>
        </div>
        <ul *ngFor="let tag of slides[selectedSlide]; let i = index" (click)="selectTag(tag, i)" style="width:90%; list-style: none;background-color:white;display:table; margin:0 auto; font-family:Helvetica,AppleGothic,sans-serif; inline-block;font-size:1em;font-weight:400;padding: 10px; height:20px;text-align:left;">
          <li *ngIf="selectedSlide!=='Thank you'">
            <div [class.active]="i === selectedTagIndex">
            <div style="font-weight:200;font-size:.9em;color:mediumseagreen; float:left;display:inline-block;">•</div><div style="padding:10px 0px 0px 20px;color:dark"[innerHTML]="renderImage(tag.value) | safeHtml"></div>
            </div>
          </li>

          <li *ngIf="selectedSlide==='Thank you'">
            <div [class.active]="i === selectedTagIndex">
            <div style="font-weight:600;font-size:2em;color:mediumseagreen; float:left;display:inline-block;">•</div>
            <div style="padding:10px 0px 0px 20px;color:dark">
              <span *ngIf="tag.value===theUser" style="display: inline-block;" valign="top"><a (click)='profile();' target="_blank">{{curbemail(tag.value)}}</a></span>
              <span *ngIf="tag.value!==theUser" style="display: inline-block;" valign="top"><a href="{{tag.value}}" target="_blank">{{tag.value | truncate : 32}}</a></span>
            </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <br>
    <br>
  </div>

<div style="width: 10%; min-height:300px;dispaly:table-cell;background-color:#00000000;float:left;cursor:pointer;" (click)="onDown();">
    &nbsp;
  </div>

 </div>
<div  *ngIf="subtitles && subtitles.length" class="col-md-12" style="margin:auto; width:100%;font-family:Century Gothic,CenturyGothic,AppleGothic,sans-serif; padding-top:4px;padding-bottom:1px;background-color:white; color: navy; height:23px;text-align:center;width:90%;">
		<div style="display: inline-block;"> <a *ngIf='currentPage>1' href="admin/demo?p=1&h={{model.hashTag}}" style="cursor:pointer;color:dodgerblue;"><<</a>&nbsp;&nbsp;<a *ngIf='currentPage>1' href="admin/demo?p={{currentPage-1}}&h={{model.hashTag}}" style="cursor:pointer;color:dodgerblue;"><</a>&nbsp;&nbsp;Slide {{currentPage}} of {{pageCount | number:0}}&nbsp;&nbsp;<a *ngIf='currentPage<pageCount' href="admin/demo?&p={{currentPage+1}}&h={{model.hashTag}}" style="color:dodgerblue;cursor:pointer;">></a>&nbsp;&nbsp;<a *ngIf='currentPage<pageCount' href="admin/demo?&&p={{pageCount}}&h={{model.hashTag}}" style="color:dodgerblue;cursor:pointer;">>></a>
		</div>
	</div>
    <br>
    <br>
    <div class="block_container" style="background-color:#ffffff00;border-top:1px solid gainsboro;padding:10px 0px 0px 0px;font-family:Century Gothic,CenturyGothic,AppleGothic,sans-serif; text-align: center; valign: top; font-size:1em;font-weight:200;"><a style="text-decoration:none;" href="http://siloq.com" >
      <span style="color:dodgerblue;">Powered by</span> <img src="assets/isloq.png" align="middle" style="margin-bottom:13px;width:19px; height:19px;" /></a> <span style="color:grey;"></span> <a style="text-decoration:none;" href="http://siloq.com"><span style="color:dodgerblue;">i</span><span
      style="color:mediumseagreen;">s</span><span
      style="color:dodgerblue;">l</span><span
      style="color:orange;">o</span><span
      style="color:dodgerblue;">q</span><span
      class="sup"
      style="color:orangered;">™</span>
    </a>
    </div>
  <br>
  <br>
  <br>
  <br>
  <admin-footer></admin-footer>
