import { RouterModule, Routes, ActivatedRoute } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { Component, NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
//
import { AppComponent } from './app.component';
//
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { LogoutComponent } from './logout/logout.component';
import { SignUpComponent } from './signUp/sign-up.component';
import { ForgotComponent } from './forgot/forgot.component';
import { GoldimembersComponent } from './goldimembers/goldimembers.component';
import { AdminGoldimembersComponent } from './admin/adminGoldimembers/adminGoldimembers.component';
import { HeaderComponent } from './header/header.component';
import { SisHeaderComponent } from './sisHeader/sis-header.component';
import { FooterComponent } from './footer/footer.component';
import { HomeFooterComponent } from './homeFooter/home-footer.component';
import { ContactComponent } from './contact/contact.component';
import { RedirectComponent } from './admin/redirect/redirect.component';
import { DocComponent } from './admin/doc/doc.component';
import { DedocComponent } from './admin/dedoc/dedoc.component';
import { ExamComponent } from './admin/exam/exam.component';
import { ExamsComponent } from './admin/exams/exams.component';
import { MockComponent } from './admin/mock/mock.component';
import { MocksComponent } from './admin/mocks/mocks.component';
import { ScoresComponent } from './admin/scores/scores.component';
import { ResultsComponent } from './admin/results/results.component';
import { EditComponent } from './admin/edit/edit.component';
import { DeckComponent } from './admin/deck/deck.component';
import { DemoComponent } from './admin/demo/demo.component';
import { TopicComponent } from './admin/topic/topic.component';
import { GraphComponent } from './graph/graph.component';
import { AdminGraphComponent } from './admin/adminGraph/admin-graph.component';
import { RawComponent } from './admin/raw/raw.component';
import { UploadComponent } from './admin/upload/upload.component';
import { CareerComponent } from './admin/career/career.component';
import { CareersComponent } from './careers/careers.component';
import { FeedbackComponent } from './admin/feedback/feedback.component';
import { AdminHeaderComponent }  from './admin/adminHeader/admin-header.component';
import { AdminSisHeaderComponent }  from './admin/adminSisHeader/admin-sis-header.component';
import { AdminFooterComponent }  from './admin/adminFooter/admin-footer.component';
import { DocFooterComponent }  from './admin/docFooter/doc-footer.component';
import { AccountComponent }    from './admin/account/account.component';
import { PublishComponent } from './publish/publish.component';
import { PublicComponent } from './public/public.component';
import { ChatComponent } from './chat/chat.component';
import { ProfileComponent } from './profile/profile.component';
import { PressComponent } from './press/press.component';
import { ReleaseComponent } from './release/release.component';
import { ErrorComponent } from './error/error.component';
import { AlertComponent } from './alert/alert.component';
import { ModalComponent } from './modal/modal.component';
import { AdminModule }  from './admin/admin.module';
import { AutoComponent } from './admin/auto/auto.component';
import { SearchComponent } from './search/search.component';
import { AdminSearchComponent } from './admin/adminSearch/admin-search.component';
import { TelComponent } from './admin/tel/tel.component';
import { TinyComponent } from './admin/tiny/tiny.component';
import { QrCodeComponent } from './admin/qrcode/qrcode.component';
import { ReviewComponent } from './admin/review/review.component';
import { SiloqpayComponent } from './admin/siloqpay/siloqpay.component';
//
import {CookieService} from 'ng2-cookies';
import {UserService} from './services/user.service';
import { TagService } from './services/tag-service.service';
import { TopicService } from './services/topic.service';
import { TinyService } from './services/tiny.service';
import { QrCodeService } from './services/qrcode.service';
import { TelService } from './services/tel.service';
import { IdService } from './services/id.service';
import { AuthGuardService } from './services/auth-guard.service';
import {Login} from './services/user.service';
import {Contact} from './services/user.service';
import {AutoService} from './services/auto.service';
import {SearchService} from './services/search.service';
import {DataService} from './services/data.service';
import { AlertService } from './alert/services/alert.service';
import { ModalService } from './modal/services/modal.service';

const appRoutes: Routes = [
  { path: '', component: HomeComponent},
  //
  { path: 'login', component: LoginComponent},
  { path: 'logout', component: LogoutComponent},
  { path: 'signup', component: SignUpComponent},
  { path: 'forgot', component: ForgotComponent},
  { path: 'goldimembers', component: GoldimembersComponent},
  { path: 'contact', component: ContactComponent},
  { path: 'publish', component: PublishComponent},
  { path: 'chat', component: ChatComponent},
  { path: 'public', component: PublicComponent},
  { path: 'profile', component: ProfileComponent},
  { path: 'press', component: PressComponent},
  { path: 'release', component: ReleaseComponent},
  { path: 'search', component: SearchComponent},
  { path: 'graph', component: GraphComponent},
  { path: 'careers', component: CareersComponent},
  { path: 'home', component: HomeComponent},
  //
  { path: 'admin/redirect', component: RedirectComponent},
  //
  { path: 'admin/goldimembers', component: AdminGoldimembersComponent, canActivate : [AuthGuardService]},
  { path: 'admin/search', component: AdminSearchComponent, canActivate : [AuthGuardService]},
  { path: 'admin/topic', component: TopicComponent, canActivate : [AuthGuardService]},
  { path: 'admin/graph', component: AdminGraphComponent, canActivate : [AuthGuardService]},
  { path: 'admin/doc', component: DocComponent, canActivate : [AuthGuardService]},
  { path: 'admin/dedoc', component: DedocComponent, canActivate : [AuthGuardService]},
  { path: 'admin/exam', component: ExamComponent, canActivate : [AuthGuardService]},
  { path: 'admin/exams', component: ExamsComponent, canActivate : [AuthGuardService]},
  { path: 'admin/mock', component: MockComponent, canActivate : [AuthGuardService]},
  { path: 'admin/mocks', component: MocksComponent, canActivate : [AuthGuardService]},
  { path: 'admin/scores', component: ScoresComponent, canActivate : [AuthGuardService]},
  { path: 'admin/results', component: ResultsComponent, canActivate : [AuthGuardService]},
  { path: 'admin/edit', component: EditComponent, canActivate : [AuthGuardService]},
  { path: 'admin/deck', component: DeckComponent, canActivate : [AuthGuardService]},
  { path: 'admin/demo', component: DemoComponent, canActivate : [AuthGuardService]},
  { path: 'admin/upload', component: UploadComponent, canActivate : [AuthGuardService]},
  { path: 'admin/tel', component: TelComponent, canActivate : [AuthGuardService]},
  { path: 'admin/tiny', component: TinyComponent, canActivate : [AuthGuardService]},
  { path: 'admin/qrcode', component: QrCodeComponent, canActivate : [AuthGuardService]},
  { path: 'admin/raw', component: RawComponent, canActivate : [AuthGuardService]},
  { path: 'admin/account', component: AccountComponent, canActivate : [AuthGuardService]},
  { path: 'admin/feedback', component: FeedbackComponent, canActivate : [AuthGuardService]},
  { path: 'admin/review', component: ReviewComponent, canActivate : [AuthGuardService]},
  { path: 'admin/siloqpay', component: SiloqpayComponent, canActivate : [AuthGuardService]},
  { path: 'admin/career', component: CareerComponent,  canActivate : [AuthGuardService]},
  //
  { path: '**', component: ErrorComponent }
];


@NgModule({
  declarations: [
    AppComponent
    /*
    HomeComponent,
    HeaderComponent,
    SisHeaderComponent,
    LoginComponent,
    LogoutComponent,
    SignUpComponent,
    ForgotComponent,
    GoldimembersComponent,
    AdminGoldimembersComponent,
    FooterComponent,
    HomeFooterComponent,
    ContactComponent,
    PublishComponent,
    ChatComponent,
    PublicComponent,
    ProfileComponent,
    PressComponent,
    ReleaseComponent,
    RedirectComponent,
    DocComponent,
    DedocComponent,
    ExamComponent,
    ExamsComponent,
    MockComponent,
    MocksComponent,
    ScoresComponent,
    ResultsComponent,
    EditComponent,
    DeckComponent,
    DemoComponent,
    TopicComponent,
    GraphComponent,
    AdminGraphComponent,
    RawComponent,
    AdminHeaderComponent,
    AdminSisHeaderComponent,
    AdminFooterComponent,
    DocFooterComponent,
    AccountComponent,
    UploadComponent,
    CareersComponent,
    CareerComponent,
    FeedbackComponent,
    AutoComponent,
    SearchComponent,
    TelComponent,
    TinyComponent,
    QrCodeComponent,
    AdminSearchComponent,
    AlertComponent,
    ModalComponent,
    ReviewComponent,
    SiloqpayComponent,
    ErrorComponent
    */
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot(appRoutes),
    AdminModule,
    HttpClientModule
  ],
  exports: [
  /*
    SisHeaderComponent,
    GoldimembersComponent,
    */
    RouterModule
   // ModalComponent
  ],
  providers: [
    CookieService,
    UserService,
    AuthGuardService,
    TagService,
    TopicService,
    TinyService,
    QrCodeService,
    IdService,
    AutoService,
    SearchService,
    TelService,
    DataService,
    AlertService,
    ModalService,
    Login,
    Contact
  ],
  bootstrap: [
    AppComponent
  ]
})

export class AppModule { }
