 <header class="doNotPrint"></header>
 <div style="width:100%;overflow:hidden;margin-left:0px;padding-top:55px;" class="doNotPrint">
   <div style="float:right;padding-right:10px;"><button class="button" type="button" style="background-color:orangered;"(click)='editDoc(model)'>Done</button> </div>
 </div>
   <hr>











<div style="width:100%;text-align:center;padding-top:0px;font-family:Helvetica,AppleGothic,sans-serif; color:#696969; font-size: 12px;">
   <h1>{{model.hashTag}} </h1>
   <div style="width:100%;text-align:left;display:inline;">
       <div style="overflow:auto;padding:15px;height:460px;border: 1px solid gainsboro;margin:0px;text-align:left;float:left;width:200px;">
           <h2>Users</h2>
           <div style="display: inline;" *ngFor="let email of topic.users; let i = index"><div (click)='directTopic(email)'>{{email}}</div> </div>
           <br>
           <br>
           <hr>
           <h2>Topics / 1:1</h2>
           <div style="display: inline;" *ngFor="let topic of user.topics; let i = index"><a style="text-decoration:none;color:dodgerblue;" [routerLink]="['/admin/chat']" [queryParams]="{h:topic, d:theUser}">{{topic}}</a> <br> </div>
       </div>
       <div style="width:80%; float:right;margin:0px;">
      <div style="border: 1px gainsboro solid; text-align: left; padding:20px;
      width:90%;height:400px; margin:0 auto !important; display:inline-block; overflow:auto;" [scrollTop]="scrollMe.scrollHeight" #scrollMe >
          <div style="display: inline;" *ngFor="let tag of tags; let i = index">
              <div style="display: inline; color:green; font-weight:400;">{{tag.insertDate | date: 'M/d/yy, h:mm a'}}&nbsp;:&nbsp;{{tag.source}}&nbsp;:&nbsp;{{tag.email}}</div>
                 <br>
              <div style="display: inline;">{{tag.value}}</div>
             <br>
             <br>
             </div>
          </div>

          <div class="divh" >
      <div style="float:left;width:90%;margin:0 auto !important;border: 1px gainsboro solid; text-align:left; padding:16px;" [textContent]='model.value' contenteditable="true" id="textBox"> </div>
      <div style="padding-top:0px; margin-top:0px;width:90px;"><button class="button" (click)='submitTag()' type="button" style="border:0px;background-color:orange;">Send</button></div>
</div>
   </div>
</div>
</div>
 <footer></footer>
