import { Component } from '@angular/core';
import { config } from '../config';
import {RouterModule} from '@angular/router';
import { HeaderComponent } from '../header/header.component';
import { FooterComponent } from '../footer/footer.component';
import { FormsModule } from '@angular/forms';

@Component({
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.css'],
  imports: [FormsModule, RouterModule, FooterComponent, HeaderComponent],
  standalone: true
 })
export class ErrorComponent {
  public taxonomy = "";
  public class = "";
  public subclass = "";

  ngOnInit() {
    this.taxonomy = config.taxonomy;
    this.class = config.class;
    this.subclass = config.subclass;
	}
}
