<dummy-header></dummy-header>
<body tyle="height:100%;width:100%; margin:0px;">
<div style="text-align:center; width:100%;padding-top:120px;min-height: calc(100vh - 70px);">
  <admin-alert></admin-alert>
  <ng-template id="modal" #modalTemplate>
    </ng-template>

  <br>
<div>
<br>
	<h2 class="block_container siloqlogofont" style="text-align:left;min-width:300px;">Invite a friend to <a href="/"
                                                     target="_self"><span
    style="color:dodgerblue;">{{taxonomy}}</span><span
    class="sup"
    style="color:mediumseagreen;">&trade;</span>
                                                    </a></h2>
</div>
<br>
<form>
<div class="row align-center">
   <div class="small-12  columns">
        <label>
            <input type="text" class="siloqinput" placeholder="Email" id="email" style="min-width:300px;"
                [(ngModel)]="femail" name="email" required #em="ngModel" pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$">
            <div [hidden]="em.valid || em.pristine" class="siloqfont">
            </div>
        </label>
    </div>
    <br>
    <br>
    <div class="small-12  columns" style="text-align:left;">
      <div style="width:25%;text-align:left;margin:auto;min-width:300px;" class="siloqfont">
       <button (click)="invite()" type="button" class="siloqbutton">Invite</button>
       <button (click)="refer()" type="button" class="siloqbutton">Copy Referral Url</button>
       <button class="siloqbutton" style="color:orangered;"(click)='done()'>Done</button>
    <br>
    <br>
      </div>
    </div>
    <div class="small-12  columns" style="text-align:left;">
      <div style="width:25%;text-align:left;margin:auto;min-width:300px;" class="siloqfont">
    <h4 style="width:100%;font-family:Century Gothic,CenturyGothic,AppleGothic,sans-serif;">Your Friend(s)</h4>
        <table  *ngIf="user.users && user.users.length > 0" border = "0" cellpadding = "0" cellspacing = "0" style="font-family:arial,sans-serif; font-size:.79em; border-color:gainsboro;width:90%; margin: 0 auto;">
          <tr><td>
          <div class="container2 siloqfont" style="font-family:Helvetica,AppleGothic,sans-serif; font-size:1em; font-weight: 200;width:100%;">
            <div style="text-align: center;width:90%;margin:0 auto;" *ngFor="let u of user.users; let j = index">
              <div style="display:table-cell;float:left;">{{u}}</div>
              <div  *ngIf="theUser != u" style="display:table-cell;color:mediumseagreen; width:10%;text-align:right;">
                    <input style="border:0px;background-color:#ffffff00;color:orangered;height:19px;width:19px;font-weight:300;font-size:1em;" type="button" class="intLink" title="Remove friend" value="✘" (click)="remove(u)"/>
              </div>
            </div>
          </div>
          </td></tr>
        </table>
        </div>
    </div>
</div>
</form>
    <br>
    <br>
    <br>
<dummy-footer></dummy-footer>
