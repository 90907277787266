
import { RouterModule, Routes, ActivatedRoute } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { Component, NgModule } from '@angular/core';
import {Title} from '@angular/platform-browser';
//
import { AdminComponent } from './adminComponent/admin.component';
import { DummyFooterComponent } from './dummyFooter/dummy-footer.component';
import { DummyHeaderComponent } from './dummyHeader/dummy-header.component';
import { BranchComponent } from './branch/branch.component';
import { ReexamComponent } from './reexam/reexam.component';
import { SqbComponent } from './sqb/sqb.component';
import { ShareComponent }    from './share/share.component';
import { InviteComponent }    from './invite/invite.component';
import { AcceptedComponent }    from './accepted/accepted.component';
import { ResetComponent }    from './reset/reset.component';
//import { TruncatePipe } from './tag/tag.truncate.pipe';
//import { SafeHtmlPipe } from './tag/tag.safehtml.pipe';
//import { SafeResourceHtmlPipe } from './tag/tag.saferesourcehtml.pipe';
import { AdminAlertComponent } from './adminAlert/admin-alert.component';
//
import {UserService} from '../services/user.service';
import { TagService } from '../services/tag-service.service';
import { TopicService } from '../services/topic.service';
import { AdminAlertService } from './adminAlert/services/admin-alert.service';

const AdminRoutes: Routes = [
  {
    path: 'admin',
    component: AdminComponent,
    children: [
      { path: 'share', component: ShareComponent },
      { path: 'invite', component: InviteComponent },
      { path: 'accepted', component: AcceptedComponent },
      { path: 'branch', component: BranchComponent},
      { path: 'reexam', component: ReexamComponent},
      { path: 'sqb', component: SqbComponent},
      { path: 'share', component: ShareComponent},
      { path: 'reset', component: ResetComponent},
    ]
  },
];
@NgModule({
  imports: [
    RouterModule.forChild(AdminRoutes),
  ],
  exports: [
    RouterModule,
    //TruncatePipe,
    /*
    SafeHtmlPipe
    SafeResourceHtmlPipe
    */
  ],
  declarations: [
    AdminComponent,
  /*
    AdminComponent,
    DummyHeaderComponent,
    DummyFooterComponent,
    BranchComponent,
    ReexamComponent,
    SqbComponent,
    ShareComponent,
    InviteComponent,
    AcceptedComponent,
    ResetComponent,
    TruncatePipe,
    SafeHtmlPipe,
    SafeResourceHtmlPipe,
    CUSTOM_ELEMENTS_SCHEMA,
    AdminAlertComponent
    */
  ],
  providers: [
    UserService,
    TagService,
    TopicService,
    AdminAlertService
  ]
})

export class AdminModule {}

