import { Pipe, PipeTransform, NgModule } from '@angular/core';

@Pipe({name: 'truncate', standalone: true})

export class TruncatePipe implements PipeTransform {
	transform(value: string, chars: number): string {
  if(value) {
        	let text = `${value.substring(0, chars)}`;
        	return text;
          }

          return value
    	}
}
