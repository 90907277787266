<admin-header></admin-header>
<body style="background-color:#ffffff00; height:100%;width:100%; margin:0px;">
  <form method="post">
    <div *ngIf="topic.users" class="doNotPrint" style="background-color:whitesmoke;position:fixed;margin-top:50px;display:table;width:100%; height:38px; valign:middle;border:0px solid lightgrey;padding:0px 0px 0px 0px;">
      <div style="text-align:center;margin:0 auto;color:mediumseagreen;padding:6px 5px 0px 12px;font-family:Helvetica,AppleGothic,sans-serif;font-size:1em; font-weight:600;width:90%;vertical-align: middle;">
        <a *ngIf="theUser==='tpalanisamy@yahoo.com' && !newDoc"  title="Open SLQ '{{topic.hashTag}}'" class="fa fa-folder-open-o intLink" type="button" valign="middle" (click)="open();" style="color:dodgerblue;background-color:#ffffff00;border:0px; padding:0px 5px 0px 0px;font-weight:300;font-size:1em;width:22px;height:22px;"><input type="file" id="upload" (change)="fileChangeEvent($event)"></a>
        <a *ngIf="theUser==='tpalanisamy@yahoo.com' && !newDoc"  title="Save SLQ '{{topic.hashTag}}'" class="fa fa-save intLink" type="button" valign="middle" (click)="save();" style="color:dodgerblue;background-color:#ffffff00;border:0px; padding:0px 5px 0px 0px;font-weight:300;font-size:1em;width:22px;height:22px;"></a>
        <a *ngIf="!newDoc && (theUser === topic.email || theUser === model.email) && topic.users && topic.users.indexOf('exam.imoqr@siloq.com') < 0" title="Create Exam '{{topic.hashTag}}'" class="fa fa-dot-circle-o intLink" type="button" valign="middle" (click)="branchTopic('exam');" style="color:dodgerblue;background-color:#ffffff00;border:0px; padding:0px 5px 0px 0px;font-weight:300;font-size:1em;width:22px;height:22px;"></a>
        <a *ngIf="!newDoc" title="Create SubDoc '{{topic.hashTag}}'" class="fa fa-file-text-o intLink" type="button" valign="middle" (click)="branchTopic('sub');" style="color:dodgerblue;background-color:#ffffff00;border:0px; padding:0px 5px 0px 0px;font-weight:300;font-size:1em;width:22px;height:22px;"></a>
        <a *ngIf="theUser==='tpalanisamy@yahoo.com' && !newDoc && (theUser === topic.email || theUser === model.email) && topic.users && topic.users.indexOf('exam.imoqr@siloq.com') < 0" title="Create TeamExam '{{topic.hashTag}}'" class="fa fa-crosshairs intLink" type="button" valign="middle" (click)="branchTopic('texam');" style="color:dodgerblue;background-color:#ffffff00;border:0px; padding:0px 5px 0px 0px;font-weight:300;font-size:1em;width:22px;height:22px;"></a>
        <a *ngIf="!newDoc && !editable" class="fa fa-eye double2 intLink" style="color:mediumseagreen;background-color:#ffffff00;border:0px; padding:0px 5px 0px 0px;font-weight:300;font-size:1em;width:22px;height:22px;"(click)="editable=!editable;classTag='Not Selected Yet';" title="Edit Topic"></a>
        <a *ngIf="!newDoc && editable" class="fa fa-edit intLink" style="color:orangered;background-color:#ffffff00;border:0px; padding:0px 5px 0px 0px;font-weight:300;font-size:1em;width:22px;height:22px;" (click)="editable=!editable" title="View Topic"></a>
        <a *ngIf="!newDoc" title="Clone '{{topic.hashTag}}' fa-2xs"  class="fa fa-clone intLink" (click)="branchDoc()" type="button"  valign="middle" style="color:dodgerblue;background-color:#ffffff00;height:22px;width:22px;border:0px; padding :0px 5px 0px 0px;font-weight:300;font-size:1em;"></a>
        <a *ngIf="!newDoc" title="Share '{{topic.hashTag}}'"  class="fa fa-group double intLink" (click)="shareDoc()" type="button"  valign="middle" style="color:dodgerblue;;background-color:#ffffff00;height:22px;width:22px;border:0px; padding:0px 5px 0px 0px;font-weight:300;font-size:1em;"></a>
        <!--
        TODO: Answer --- ?
        -->
        <!--
        <span class="fa-stack fa-2xs">
          <i class="fa-regular fa-circle-o fa-stack-1x"></i>
          <i class="fa-regular fa-circle-o fa-stack-2x"></i>
        </span>
        -->
        <a *ngIf="theUser==='tpalanisamy@yahoo.com' && !newDoc" title="View Deck of '{{topic.hashTag}}'" class="fa fa-navicon intLink" [routerLink]="['/admin/deck']" [queryParams]="{h:model.hashTag}" valign="middle" style="color:mediumseagreen;background-color:#ffffff00;height:22px;width:22px;border:0px; padding:0px 5px 0px 0px;font-weight:300;font-size:1em;"></a>
        <a *ngIf="(theUser==='contact@siloq.com' || theUser==='contact@siloq.com') && !newDoc" title="Edit '{{topic.hashTag}}'"  class="fa fa-edit intLink" [routerLink]="['/admin/edit']" [queryParams]="{h:model.hashTag}" valign="middle" style="color:mediumseagreen;background-color:#ffffff00;height:22px;width:22px;border:0px; padding:0px 5px 0px 0px;font-weight:300;font-size:1em;"></a>
        <a *ngIf="!newDoc" class="fa fa-clock-o intLink" [routerLink]="['/admin/raw']" [queryParams]="{i:topic.id}" type="button"  title="Timeline of '{{topic.hashTag}}' "valign="middle" style="color:mediumseagreen;background-color:#ffffff00;height:22px;width:22px;border:0px; padding:0px 5px 0px 0px;font-weight:300;font-size:1em;"></a>
        <a *ngIf="theUser==='tpalanisamy@yahoo.com' && !newDoc" class="fa fa-bullhorn intLink" (click)="publicDoc()" type="button" title="Publish '{{topic.hashTag}}'" valign="middle" style="color:orangered;background-color:#ffffff00;height:20px;border:0px; padding :0px 5px 0px 0px;font-weight:300;font-size:1em;"></a>
        <!--
        TODO: Only press account
        -->
        <a *ngIf="theUser==='tpalanisamy@yahoo.com' && !newDoc" class="fa fa-newspaper-o intLink" (click)="pressDoc()" type="button" title="Publish '{{topic.hashTag}}'" valign="middle" style="color:orange;background-color:#ffffff00;height:20px;border:0px; padding :0px 5px 0px 0px;font-weight:300;font-size:1em;"></a>
        <a *ngIf="theUser==='tpalanisamy@yahoo.com' && !newDoc" title="Import Finalized SLQ '{{model.hashTag}}'" class="fa fa-sign-in fa-flip-horizontal intLink" type="button" valign="middle" (click)="import();" style="color:dodgerblue;background-color:#ffffff00;border:0px; padding:0px 5px 0px 0px;font-weight:300;font-size:1em;width:22px;height:22px;"></a>
        <a *ngIf="!newDoc" title="Export To PDF '{{topic.hashTag}}'" class="fa fa-sign-out intLink" type="button" valign="middle" (click)="export();" style="color:dodgerblue;background-color:#ffffff00;border:0px; padding:0px 5px 0px 0px;font-weight:300;font-size:1em;width:22px;height:22px;"></a>
        <a *ngIf="!newDoc" title="Go To Doc '{{topic.hashTag}}'"  class="fa fa-ban intLink"   type="button"  valign="middle" (click)="dedoc(); "style="color:orangered;background-color:#ffffff00;height:22px;width:22px;border:0px; padding :0px 5px 0px 0px;font-weight:300;font-size:1em;"></a>
        <a *ngIf="!newDoc" title="Go To Top '{{topic.hashTag}}'"  class="fa fa-circle intLink"  href="{{routeToPageSection('doc')}}" type="button"  valign="middle" style="color:orangered;background-color:#ffffff00;height:22px;width:22px;border:0px; padding :0px 5px 0px 0px;font-weight:300;font-size:1em;"></a>
        <a *ngIf="theUser==='tpalanisamy@yahoo.com' && !newDoc" title="Go To review '{{topic.hashTag}}'"  class="fa fa-columns"  (click)="review()" type="button"  valign="middle" style="color:orangered;background-color:#ffffff00;height:22px;width:22px;border:0px; padding :0px 5px 0px 0px;font-weight:300;font-size:1em;"></a>
      </div>
    </div>
    <div  class="doNotPrint" style="padding:100px 0px 0px 0px;font-family:Helvetica,AppleGothic,sans-serif; width:100%;text-align:center;color:mediumseagreen;font-size:1em;font-weight:500;vertical-align: middle;">Doc</div>
    <div class="block_container" style="background-color:#ffffff00;border-bottom:1px solid gainsboro;padding:10px 0px 0px 0px;font-family:Century Gothic,CenturyGothic,AppleGothic,sans-serif; text-align: center; valign: top; font-size:1em;font-weight:200;"><a href="" ><img src="/assets/isloq.png" align="middle" style="margin-bottom:13px;width:19px; height:19px;" /></a> <span style="color:grey;"></span> <a style="text-decoration:none;" href=""><span
                                                  style="color:dodgerblue;">i</span><span
                                                     style="color:mediumseagreen;">s</span><span
                                                     style="color:orangered;">l</span><span
                                                     style="color:orange;">o</span><span
                                                     style="color:dodgerblue;">q</span><span
                                                     class="sup" style="color:orangered;">&trade;</span><span style="color:dodgerblue;">&nbsp;Publish Instantly Runtime!</span> </a>
    </div>

    <admin-alert></admin-alert>
       <ng-template #modalTemplate>
    </ng-template>


    <div  (dblclick)="edit=!edit; "id="doc" style="font-family:Helvetica,AppleGothic,sans-serif;width:92%;overflow:hidden;margin:4px 4% 40px 4%;padding-top:10px;z-index:-1px;">
      <h2 *ngIf="model.hashTag" style="vertical-align: middle;padding:0px 0px 15px 5px;margin:0 auto;width:90%;text-align:center;">
        <div *ngIf="newTopic" id="editUTopic" [contentEditable]="editable" [class.active]="editable" style="min-width:30%; min-height:30px; font-family: Arial, Helvetica, sans-serif;padding:0px 0px 0px 2px; margin:0px 0px 0px 3px auto;" (input)="model.hashTag = $event.target.innerHTML; addNewTopic();">{{model.hashTag}}</div>
        <div *ngIf="!newTopic" id="editTopic" [contentEditable]="editable" [class.active]="editable" style="min-width:30%;  min-height:30px; font-family: Arial, Helvetica, sans-serif;padding:0px 2px 0px 2px; margin:0px 0px 0px 3px auto;" (blur)="model.hashTag = $event.target.innerHTML; renameTopic();">{{model.hashTag}}</div>
      </h2>
      <div *ngIf="!model.hashTag" style="font-family:Helvetica,AppleGothic,sans-serif;width:92%;overflow:hidden;margin:4px 4% 0px 4%;padding-top:10px;z-index:-1px;">
        <h3 style="text-align:center;">No Activity Available</h3>
      </div>
  <div  *ngFor="let selectedSlide of subtitles; let i = index" (click)="selectSlide(selectedSlide, i)" (mouseenter)="browseSlideIndex = i" style="width:98%;margin:0px;padding:2px">

    <div *ngIf="slides[selectedSlide].length > 1" class = "regular" [class.browse]="i === browseSlideIndex" [class.active]="i === selectedSlideIndex">

      <div *ngFor="let tag of slides[selectedSlide]; let j = index" (click)="selectTag(tag, j)" (mouseenter)="browseTagIndex = j" style="width:98%; list-style: none;background-color:white;display:table; margin:0; font-family:Helvetica,AppleGothic,sans-serif; inline-block;padding: 2px; height:20px;text-align:left;" >


          <div *ngIf="i === 0" style="display:table;">
            <div *ngIf="j === 0" style="display:table-cell;padding:0px 1px 15px 0px;text-align:left;display: inline-block; margin: 0 auto !important; font-weight:600;font-size:14px;"[innerHTML]="selectedSlide | safeHtml"></div>
            <div *ngIf="j > 0" id="{{j}}s" class = "regular" [class.browse]="i === browseSlideIndex && j === browseTagIndex" [class.active]="i === selectedSlideIndex && j === selectedTagIndex" style="vertical-align:top;display:table; text-align:left;margin:0px; ">
              <span><span>{{j}}.&nbsp;</span><span *ngIf="+tag.value[0] >= 1 && +tag.value[0] <= 9 ">SQBID:&nbsp;</span><span class="editTag"  style="padding:0px 1px 2px 0px; font-weight:600;font-size:14px;" ></span></span><div style="cursor:pointer;text-align: justify; text-justify: inter-word;padding:0px 0px 0px 0px;color:dark;font-weight:500;font-size:12px;"> <a valign="top" href="{{routeToPageSection(j)}}" [innerHTML]="tag.value | safeHtml"></a> </div>
            </div>
          </div>

        <div class="doNotPrint" *ngIf="i===1 && j===0">
          <br>
          <input  (click)="setStateAll($event);" type="checkbox" style="float:left;width: 13px; height: 13px; vertical-align: top; position: relative; top: -2px; *overflow: hidden;padding:0px;font-weight:300;font-size:.75em;padding:0px 0px 0px 0px; margin-right:0px;"/>
          <br>
          <br>
        </div>

          <div *ngIf="i === 1 && j  > 0 && tag.name === tag.hashTag">
            <div class = "regular" [class.browse]="i === browseSlideIndex && j === browseTagIndex" [class.active]="i === selectedSlideIndex && j === selectedTagIndex" style="vertical-align:top;display:table; text-align:left;margin:0px; ">
              <div *ngIf="j > 0 && tag.value.length > 1" [contentEditable]="editable" class="editTag" tabindex="{{i+j+1}}"(focus)="selectedTag = tag;" (blur)="tag.value = $event.target.innerHTML; updateTag(tag);" valign="top" style="display:table-cell;padding:0px 1px 2px 0px;text-align: justify; text-justify: inter-word;display: inline-block; margin: 0 auto !important; inter-word;font-weight:500;font-size:12px;"[innerHTML]="renderImage(tag.value) | safeHtml"></div>
              <div  style="display:table-cell;padding:0px 1px 2px 0px;font-weight:500;font-size:12px;" >&nbsp;<a *ngIf="tag.source.startsWith('http')" href="{{cutUntil(tag)}}" target="_blank">[{{refIndex(tag.source)}}]</a><a *ngIf="tag.source.indexOf('@') > 0" (click)="profileEx(model.hashTag, tag.source)" style="cursor:pointer;" target="_self">[{{refIndex(tag.source)}}]</a>&nbsp;</div><div class="doNotPrint"  *ngIf="i === selectedSlideIndex && j === selectedTagIndex" style="display:table-cell;width:16px;"><input *ngIf="editable" class="intLink" type="button" title="Archive Loq" value="✘" (click)="deleteTag(tag)" style="border:0px;background-color:#ffffff00;color:orangered;height:19px;width:19px;vertical-align: middle;"  valign="middle"/> </div>
            </div>
          </div>

        <div *ngIf="i >= 1 && i < subtitles.length-1 && tag.name !== tag.hashTag" style="display:table;">
          <div class = "regular" [class.browse]="i === browseSlideIndex && j === browseTagIndex" [class.active]="i === selectedSlideIndex && j === selectedTagIndex" style="vertical-align:top;display:table; text-align:left;margin:0px; ">
            <input *ngIf="edit===true" name="{{tag.id}}" [(ngModel)]="tag.checked" class="doNotPrint" type="checkbox" style="width: 13px; height: 13px; vertical-align: middle; position: relative; top: -1px; *overflow: hidden;padding:0px;margin:0px;font-weight:300;font-size:.75em;" (click)="setState($event, tag);"/>&nbsp;<a *ngIf="j === 0" href="{{routeToPageSection((i-1)+'s')}}"><span><span>{{i}}.&nbsp;</span><span *ngIf="+tag.name[0] >= 1 && +tag.name[0] <= 9 ">SQBID:&nbsp;</span><span class="editTag"  style="padding:0px 1px 2px 0px; font-weight:600;font-size:14px;" ></span></span></a><div *ngIf="j === 0"   class="editTag" [contentEditable]="editable" tabindex="{{i}}" (focus)="selectedTag = tag;" (blur)="tag.name = $event.target.innerHTML; updateTagName(tag);" valign="top" style="display:table-cell;padding:0px 1px 2px 0px;text-align:left;display: inline-block; margin: 0 auto !important; font-weight:600;font-size:14px;" id="{{i}}" [innerHTML]="renderImage(tag.name) | safeHtml"></div>
            <div *ngIf="j > 0" class="editTag" [contentEditable]="editable" tabindex="{{i+j+1}}"(focus)="selectedTag = tag;" (blur)="tag.value = $event.target.innerHTML; updateTag(tag);" valign="top" style="display:table-cell;padding:0px 1px 2px 0px;text-align: justify; text-justify: inter-word;display: inline-block; margin: 0 auto !important; inter-word;font-weight:500;font-size:12px;"[innerHTML]="renderImage(tag.value) | safeHtml"></div>
            <div style="display:table-cell;padding:0px 1px 2px 0px;font-weight:500;font-size:12px;" >&nbsp;<a *ngIf="tag.source.startsWith('http')" href="{{cutUntil(tag)}}" target="_blank">[{{refIndex(tag.source)}}]</a><a *ngIf="validateEmail(tag.source)" (click)="profileEx(model.hashTag, tag.source)" style="cursor:pointer;" target="_self">[{{refIndex(tag.source)}}]</a>&nbsp;</div><div class="doNotPrint" *ngIf="i === selectedSlideIndex && j === selectedTagIndex" style="display:table-cell;width:38px;"><input *ngIf="editable" class="intLink" type="button" title="Archive Loq" value="＋" (click)="addClass(tag)" style="border:0px;background-color:#ffffff00;color:dodgerblue;height:19px;width:19px;vertical-align: middle;"  valign="middle"/><input *ngIf="editable" class="intLink" type="button" title="Archive Loq" value="✘" (click)="deleteTag(tag)" style="border:0px;background-color:#ffffff00;color:orangered;height:19px;width:19px;vertical-align: middle;"  valign="middle"/> </div>
          </div>
        </div>

          <div *ngIf="i === subtitles.length-1" style="display:table;">
            <div *ngIf="j === 0" id="{{i}}" style="display:table-cell;padding:0px 1px 15px 0px;text-align:left;display: inline-block; margin: 0 auto !important; font-weight:600;font-size:14px;"> {{selectedSlide}} </div>
            <div *ngIf="j > 0 && tag.value.trim().length > 1" class = "regular" [class.browse]="i === browseSlideIndex && j === browseTagIndex" [class.active]="i === selectedSlideIndex && j === selectedTagIndex" style="vertical-align:top;display:table; text-align:left;margin:0px;font-weight:500;font-size:12px; ">
            <div style="padding:0px 0px 0px 0px;color:dark">
              <span *ngIf="tag.value.startsWith('http')" style="display: inline-block;" valign="top">[{{j}}]&nbsp;<a href="{{tag.value}}" target="_blank">{{tag.value | truncate : 64}}</a></span>
              <span *ngIf="validateEmail(tag.value)" style="display: inline-block;" valign="top">[{{j}}]&nbsp;<a (click)="profileEx(model.hashTag, tag.value)" style="cursor:pointer;"target="_self">{{curbemail(tag.value)}}</a></span>
            </div>
          </div>
    </div>
  </div>
</div>
</div>
<div class="doNotPrint" *ngIf="editable" style="margin:auto; text-align:center; width:92%; padding:0px;margin-top:0px; vertical-align: top;">
  <div style="padding-left:20px;height:20px; font-family:sans-serif;font-size:.8em;font-weight:600; text-align:left;" id="subtitle"><span style="color: orangered;">Subtitle:&nbsp;</span><span style="color: navy;" [innerHTML]="classTag"></span></div>
  <div class="doNotPrint" style="display:table; width:100%;text-align:left;">
    <div class="siloqinput2" id="loq" [contentEditable]="editable" data-text=">>>Add/Remove Subtitle or Just Enter Content." style="display:table-cell;float:left;height:20px;width:75%;min-width:200px;margin:0 auto !important; text-align:left; padding:16px;overflow:auto;" (blur)="getContent($event.target.innerHTML)" (input)="getContent($event.target.innerHTML)" ></div><input (click)='submitTag()'  title="Enter to add text" class="siloqbutton" type="button" value="⏎" valign="middle" style="color:orangered;min-width:50px;width:5%"/>
    </div>
  </div>
    <br class="doNotPrint">
    <br class="doNotPrint">
    <div class="block_container" style="background-color:#ffffff00;border-top:1px solid gainsboro;padding:10px 0px 0px 0px;font-family:Century Gothic,CenturyGothic,AppleGothic,sans-serif; text-align: center; valign: top; font-size:1em;font-weight:200;"><a style="text-decoration:none;" href="http://siloq.com" >
      <span style="color:dodgerblue;">Powered by</span> <img src="assets/isloq.png" align="middle" style="margin-bottom:13px;width:19px; height:19px;" /></a> <span style="color:grey;"></span> <a style="text-decoration:none;" href="http://isloq.connnnnnn/m"><span style="color:dodgerblue;">i</span><span
      style="color:mediumseagreen;">s</span><span
      style="color:dodgerblue;">l</span><span
      style="color:orange;">o</span><span
      style="color:dodgerblue;">q</span><span
      class="sup"
      style="color:orangered;">™</span>
    </a>
    </div>
<doc-footer></doc-footer>
