<admin-header></admin-header>
<div class="container" style="margin-top:50px;font-family:Helvetica,AppleGothic,sans-serif;">
  <div class="row" style="min-width:330px;width:330px;margin:0 auto;">
    <div class="col-sm"></div>
    <div class="col-sm" style="text-align:center;">
      <br>
      <div class="ms-text-center" style="color:navy; font-weight:600;font-size:2em;"><img src="assets/sweat.png" align="middle" title="Not Logged In" alt="imockr™: Timeline Logo" style="margin-bottom:24px;width:28px; height:28px;" /><span style="color:dodgerblue;">S</span><span style="color:mediumseagreen;">i</span><span style="color:orangered;">l</span><span style="color:orange;">o</span><span style="color:dodgerblue;">q</span><span  style="color:navy;">pay</span><span class="sup" style="color:orangered;">&reg;</span></div>
      <div  style="background-color:#383838; border:4px solid gold; font-weight:400;border-radius: 4px; -moz-border-radius: 4px;padding:10px;">
        <div  style="color:gold;padding-bottom:5px;">{{p}}</div>
        <div  style="padding:4px;margin:0 auto;color:whitesmoke;"><i>${{a}} USD</i></div>
      </div>
      <br>
      <div id="alerts" class="ms-text-center"></div>
      <div id="loading" class="spinner-container ms-div-center">
        <div class="spinner"></div>
      </div>
      <div id="content" class="hide">
        <div class="ms-card ms-fill">
          <div class="ms-card-content">
            <ngx-paypal [config]="payPalConfig"></ngx-paypal>
          </div>
        </div>
        <div id="payment_options"></div>
      </div>
    </div>
    <div class="col-sm"></div>
  </div>
</div>
<admin-footer></admin-footer>
