import { Component, OnInit, NgZone } from '@angular/core';
import {Title} from '@angular/platform-browser';
import {TagService} from '../../services/tag-service.service';
import {TopicService} from '../../services/topic.service';
import {Tag} from '../tag/model/tag';
import {Topic} from '../topic/model/topic';
import {Observable, throwError} from 'rxjs';
import {HostListener} from '@angular/core';
import {ActivatedRoute, Router } from '@angular/router';
import {UserService} from '../../services/user.service';
import {Login} from '../../services/user.service';
import {CookieService} from 'ng2-cookies';
import {DataService } from "../../services/data.service";
import { config } from '../../config';
import {RouterModule} from '@angular/router';
import { AdminHeaderComponent } from '../adminHeader/admin-header.component';
import { AdminFooterComponent } from '../adminFooter/admin-footer.component';
import { FormsModule } from '@angular/forms';
import {User} from '../user/model/user';
import { AdminAlertComponent } from '../adminAlert/admin-alert.component';
import { AdminAlertService } from '../adminAlert/services/admin-alert.service';
import { AlertTypeEnum } from '../adminAlert/types/alertType.enum';

declare function escape(s:string): string;
declare function unescape(s:string): string;

declare function testJs(): any;

@HostListener('window:keydown', ['$event'])
@Component({
  host: {
    '(document:keydown)': 'keyDown($event)',
  },
  selector: 'app-demo',
  templateUrl: './demo.component.html',
  styleUrls: ['./demo.component.css'],
  imports: [AdminAlertComponent, FormsModule, RouterModule, AdminFooterComponent, AdminHeaderComponent],
  standalone: true
})

export class DemoComponent implements OnInit {
  tags: Tag[];
  topics: Topic[];
  public topic = new Topic('', '', '');
  utags: any;
  public model = new Tag('', '', '', '', '');
  public umodel = new Tag('', '', '', '', '');
  public selectedSlide;
  public selectedTag;
  public classTag = "";
  selectedTagIndex = -1;
  selectAll = false;
  public theUser = "";
 public theGoldiUser = "";
  currentPage: number = 1;
  pageSize = 1;
  totalCount: number = 0;
  pageCount: number = 0;
  host = window.location.protocol+"//"+window.location.hostname;
  public slides = [];
  public subtitles: string[] = [];
  public printable: boolean = false;

  constructor(private titleService: Title, private ngZone: NgZone, private tagService: TagService, private topicService: TopicService, private activatedRoute: ActivatedRoute, public userSVC: UserService, private router: Router, private _cookieService:CookieService, private data: DataService){
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  getCookie(key: string){
    return this._cookieService.get(key);
  }

  ngOnInit() {
    this.theUser = this.getCookie("email");
  this.theGoldiUser = this.getCookie("gmt");
    if(this.theUser!=="tpalanisamy@yahoo.com") {
      this.router.navigate(['/error']);
    }

    this.activatedRoute.queryParams.subscribe(params => {
      this.model.hashTag = unescape(params['h']) || "";
      this.topic.hashTag = this.model.hashTag;
      this.currentPage = parseInt(params['p']) || 1;
    });

    if(this.model.hashTag) {
      this.titleService.setTitle(this.model.hashTag);
      this.data.changeMessage(this.model.hashTag);
      this.setFirstPage();
    }
  }

  loadTags(callback: () => any) {
    this.getTopicUserTags(callback);
  }

  getTopicUserTags(callback: () => any) {
    this.tagService.getTopicUserTags(escape(this.model.hashTag), this.theUser)
      .subscribe(
       (tags: any) => {
          this.tags = tags; //Bind to view
          if(this.tags !== undefined) {
            if(callback !== undefined) {
              callback();
            }
            for (let i = 0; i < this.tags.length; i++) {
              this.tags[i].hashTag = unescape(this.tags[i].hashTag);
              this.tags[i].name = unescape(this.tags[i].name);
              this.tags[i].value = unescape(this.tags[i].value);``
              this.renderTag(this.tags[i]);
            }
            for (let i = 0; i < this.subtitles.length; i++) {
              this.addValueToName("Welcome", new Tag(this.model.hashTag, '', window.location.href, this.subtitles[i], this.model.id));
            }
            this.subtitles.unshift("Welcome");
            this.subtitles.push("Thank you");
            for (let i = 0; i < this.utags.length; i++) {
              this.addValueToName("Thank you", new Tag(this.model.hashTag, '', window.location.href, this.utags[i], this.model.id));
            }

            this.totalCount = this.subtitles.length;
            this.pageCount = Math.ceil(this.totalCount / this.pageSize);

            this.setPageVisible();
          }
        },
         (err: any) => {
          console.log(err);
        });
  }

  getTopic(h: string, e: string, callback: (t: any) => any) {
    this.topicService.getTopic(escape(h), e).subscribe(
      (data: any) => {
        callback(data);
      },
       (err: any) => {
        console.log(err);
      });
  }

 renderTag(tag: Tag){
     if (tag.position > 0) {
       if (tag.name && !tag.value) {
         this.subtitles.indexOf(unescape(tag.name)) === -1 && this.subtitles.push(unescape(tag.name));
       }

       if (tag.name && tag.value) {
         this.addValueToName(tag.name, tag);
       }
     }
   }

   addValueToName(name: any, value: any) {
     // Shorcut || returns left side if it is "truthy," or the right otherwise.
     // This means that we only assign a new Array to the Object's property
     // if it has not previously been used.
     this.slides[unescape(name)] = this.slides[unescape(name)] || [];
     // Adds a value to the end of the Array
     this.slides[unescape(name)].push(value);
   }

   setSearchText() {
     this.data.changeMessage(this.model.hashTag.split(' ').slice(0,6).join(' ')  + " " + this.selectedSlide.split(' ').slice(0,6).join(' ') + " " + unescape(this.selectedTag.value).split(' ').slice(0,6).join(' '));
   }

 public selectTag( tag: any, i: number) {
    tag.rank = i + 1;
    this.selectedTagIndex = i;
    this.selectedTag =  tag;
    this.setSearchText();
  }


 public keyDown(event: any) {
    switch (event.keyCode) {
      case 38:
        this.onUp();
        break;
      case 40:
        this.onDown();
        break;
      case 37:
        this.onUp();
        break;
      case 39:
        this.onDown();
        break;
      case 75:
        this.onTagUp();
        break;
      case 74:
        this.onTagDown();
        break;
      case 13:
        this.onDown();
        break;
      case 27:
        this.selectedTagIndex = -1;
        break;
    }
    return;
  }

  public onUp() {
    if (this.currentPage > 1) {
      this.router.navigate(['/admin/demo'],  { queryParams: { p : this.currentPage-1, h : this.model.hashTag}});
    }
  }

  public onDown() {
    if (this.currentPage < this.pageCount) {
      this.router.navigate(['/admin/demo'],  { queryParams: { p : this.currentPage+1, h : this.model.hashTag}});
    }
  }

  public onTagDown() {
    if(this.selectedTagIndex < this.tags.length - 1)
    {
      this.selectedTag = this.slides[++this.selectedTagIndex];
      this.setSearchText();
    }
  }

  public onTagUp() {
    if(this.selectedTagIndex > 0)
    {
      this.selectedTag = this.slides[--this.selectedTagIndex];
      this.setSearchText();
    }
  }

  refIndex(source: string): number {
    return this.utags.indexOf(source) + 1;
  }

  //pagination for table
  public setFirstPage() {
    this.loadTags(() => {
      if(this.tags.length > 0) {
        this.utags = Array.from(new Set(this.tags.map((item) => item.source)));
      }
    });
  }

  setPageVisible() {
    this.selectedSlide = this.subtitles[this.currentPage-1];
  }

  rankup(i: number){
    if (i > 1) {
      var t1 = this.tags[i];
      t1.rank = t1.rank - 1;
      this.rankTag(t1)

      var t = this.tags[i].rank;
      this.tags[i].rank = this.tags[i-1].rank;
      this.tags[i-1].rank = t;

      var tag = this.tags[i];
      this.tags[i] = this.tags[i-1];
      this.tags[i-1] = tag;
    }
  }

  rankdown(i: number){
    if (i < this.tags.length - 1) {
      var t1 = this.tags[i];
      t1.rank = t1.rank + 1;
      this.rankTag(t1)

      var t = this.tags[i].rank;
      this.tags[i].rank = this.tags[i+1].rank;
      this.tags[i+1].rank = t;

      var tag = this.tags[i];
      this.tags[i] = this.tags[i+1];
      this.tags[i+1] = tag;
    }
  }

  rankTag(tag: Tag){
    this.tagService.rankTag(tag).subscribe(
      () => {
      },
       (err: any) => {
        console.log(err);
      });
  }

  onPrint() {
    this.selectedTagIndex = -1;
    this.printable = false;
    setTimeout(()=>{
      window.print();
      this.printable = true;
    }, 1000);
  }

  curbemail(email: string): string {
       if(!email) {
         return email;
       }

       var index = email.indexOf("@");
       if(index > 0) {
         return email.substring(0, index);
       }
    return email;
     }

  profile() {
    this.router.navigate(['profile'],  { queryParams: {d : window.btoa(this.theUser)}});
    return;
  }

renderImage(t: any) {
  var s = t;
  t = t.replace(/<(?:.|\n)*?>/gm, '');
   if (t.startsWith("http://") || t.startsWith("https://")) {
       const img = new Image();
       img.src = t;
       var w = 300;
       var h = (w/img.width)*img.height;
       t = '<img src="' + t + '" style="width:' + w + 'px; height:' + h + 'px;" />'
       return t;
    }
       return s;
  }

}

