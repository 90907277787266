import { Component, OnInit } from '@angular/core';
import {UserService} from '../../services/user.service';
import {Login} from '../../services/user.service';
import {ActivatedRoute, Router } from '@angular/router';
import {CookieService} from 'ng2-cookies';
import { config } from '../../config';
import {RouterModule} from '@angular/router';
import { AdminHeaderComponent } from '../adminHeader/admin-header.component';
import { AdminFooterComponent } from '../adminFooter/admin-footer.component';
import { FormsModule } from '@angular/forms';
@Component({
host: {
      '(document:keydown)': 'keyDown($event)',
      },

  templateUrl: './accepted.component.html',
  styleUrls: ['./accepted.component.css'],
  imports: [RouterModule, AdminFooterComponent, AdminHeaderComponent],
  standalone: true
})

export class AcceptedComponent implements OnInit {
 public theUser = "";
 public theGoldiUser = "";
 public femail= "";
 public taxonomy = "";
 public class = "";
 public subclass = "";

  constructor(private _cookieService:CookieService, private activatedRoute: ActivatedRoute, public userSVC: UserService, private router: Router, private loginInfo: Login){
  }

  ngOnInit() {
    this.theUser = this.getCookie("email");
  this.theGoldiUser = this.getCookie("gmt");
    this.class = config.class;
    this.taxonomy = config.taxonomy;
    this.subclass = config.subclass;

     this.activatedRoute.queryParams.subscribe(params => {
     //TODO: Gibrish and use f-Id
       this.femail = params['f'] || "";
       if (this.theUser != ""  && this.femail != "") {
             this.accepted();
       }
     });
   }

    getCookie(key: string){
      return this._cookieService.get(key);
    }

  accepted() {
      this.userSVC.accepted(this.theUser, this.femail);
    }

  done() {
      this.router.navigate(['/admin/topic']);
    }

    public keyDown(event: any) {
      switch (event.keyCode) {
        case 13:
        this.done()
          event.preventDefault();
          break;
      }
      return;
    }
 }
