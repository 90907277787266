<admin-header></admin-header>
<body style="background-color:#ffffff00; height:100%;width:100%; margin:0px;">
  <form method="post">
    <div  class="doNotPrint" style="background-color:whitesmoke;position:fixed;margin-top:50px;display:table;width:100%; height:38px; valign:middle;border:0px solid lightgrey;padding:0px 0px 0px 0px;">
      <div style="text-align:center;margin:0 auto;color:mediumseagreen;padding:6px 5px 0px 12px;font-family:Helvetica,AppleGothic,sans-serif;font-size:1em; font-weight:600;width:90%;vertical-align: middle;">
        <a title="Go To Loq '{{topic.hashTag}}'"  class="fa fa-arrow-left intLink"   type="button"  valign="middle" (click)="doc(); "style="color:dodgerblue;background-color:#ffffff00;height:22px;width:22px;border:0px; padding :0px 5px 0px 0px;font-weight:300;font-size:1em;"></a>
        <a *ngIf="!editable" class="fa fa-eye double2 intLink" style="color:mediumseagreen;background-color:#ffffff00;border:0px; padding:0px 5px 0px 0px;font-weight:300;font-size:1em;width:22px;height:22px;"(click)="editable=!editable;classTag='Not Selected Yet';" title="Edit Topic"></a>
        <a *ngIf="editable" class="fa fa-edit intLink" style="color:orangered;background-color:#ffffff00;border:0px; padding:0px 5px 0px 0px;font-weight:300;font-size:1em;width:22px;height:22px;" (click)="editable=!editable" title="View Topic"></a>
        <div title="Go To Top '{{topic.hashTag}}'"  class="fa fa-angle-double-up intLink"  (click)="routeToPageSection('doc')" type="button"  valign="middle" style="color:orangered;background-color:#ffffff00;height:22px;width:22px;border:0px; padding :0px 5px 0px 0px;font-weight:300;font-size:1em;"></div>
        <div title="Go To End '{{topic.hashTag}}'"  class="fa fa-angle-double-down intLink"  (click)="routeToPageSection('end')" type="button"  valign="middle" style="color:orangered;background-color:#ffffff00;height:22px;width:22px;border:0px; padding :0px 5px 0px 0px;font-weight:300;font-size:1em;"></div>
      </div>
    </div>
    <div  class="doNotPrint" style="padding:100px 0px 0px 0px;font-family:Helvetica,AppleGothic,sans-serif; width:100%;text-align:center;color:mediumseagreen;font-size:1em;font-weight:500;vertical-align: middle;">Deloq</div>
    <div class="block_container" style="background-color:#ffffff00;border-bottom:1px solid gainsboro;padding:10px 0px 0px 0px;font-family:Century Gothic,CenturyGothic,AppleGothic,sans-serif; text-align: center; valign: top; font-size:1em;font-weight:200;"><a href="" ><img src="/assets/isloq.png" align="middle" style="margin-bottom:13px;width:19px; height:19px;" /></a> <span style="color:grey;"></span> <a style="text-decoration:none;" href=""><span
                                                  style="color:dodgerblue;">i</span><span
                                                     style="color:mediumseagreen;">s</span><span
                                                     style="color:orangered;">l</span><span
                                                     style="color:orange;">o</span><span
                                                     style="color:dodgerblue;">q</span><span
                                                     class="sup" style="color:orangered;">&trade;</span><span style="color:dodgerblue;">&nbsp;Publish Instantly Runtime!</span> </a>
    </div>

    <admin-alert></admin-alert>
       <ng-template #modalTemplate>
    </ng-template>


    <div id="doc" style="font-family:Helvetica,AppleGothic,sans-serif;width:92%;overflow:hidden;margin:4px 4% 40px 4%;padding-top:10px;z-index:-1px;">
      <h2 style="vertical-align: middle;padding:0px 0px 15px 5px;margin:0 auto;width:90%;text-align:center;">
        <div id="editTopic" [contentEditable]="editable" [class.active]="editable" style="min-width:30%;  min-height:30px; font-family: Arial, Helvetica, sans-serif;padding:0px 2px 0px 2px; margin:0px 0px 0px 3px auto;" (blur)="model.hashTag = $event.target.innerHTML; renameTopic();">{{model.hashTag}}</div>
      </h2>
      <h4 style="vertical-align: middle;padding:0px 0px 15px 5px;margin:0 auto;width:90%;text-align:center;">#Subtitles:&nbsp;{{subtitlesCount}}(#Loqs:&nbsp;{{tagsCount}})</h4>
      <b>Items: slqid / subid (Loqs#)</b>
      <br>
      <br>
      <div style="word-wrap: break-word;overflow: auto;font-family:Helvetica,AppleGothic,sans-serif;width:90%;overflow:hidden;padding:2%;">
      <span  *ngFor="let selectedSlide of subtitles; let i = index" style=" word-wrap; normal; display: inline-block;font-weight:600;font-size:.8em;vertical-align: middle;">
        <span>
          <span *ngIf="subids[this.slqids[i+1]] == 1" style="vertical-align: middle;padding:0px 0px 15px 5px;margin:0 auto;width:90%;text-align:center;"><a style="cursor:pointer;color:dodgerblue;" valign="top" (click)="routeToPageSection(i)">{{i+1}}/{{slqids[i+1]}}</a></span>
          <span *ngIf="subids[this.slqids[i+1]] < 1" style="vertical-align: middle; padding:0px 0px 15px 5px;margin:0 auto;width:90%;text-align:center;"><a style="cursor:pointer;color:orangered;" valign="top" (click)="routeToPageSection(i)">{{i+1}}/{{slqids[i+1]}}</a></span>
          <span *ngIf="subids[this.slqids[i+1]] > 1" style="vertical-align: middle;padding:0px 0px 15px 5px;margin:0 auto;width:90%;text-align:center;"><a style="cursor:pointer;color:orange;" valign="top" (click)="routeToPageSection(i)">{{i+1}}/{{slqids[i+1]}}</a></span>
          <span  *ngIf="slides[selectedSlide].length > 5" style="vertical-align: middle;padding:0px 0px 15px 5px;margin:0 auto;width:90%;text-align:center;"><a style="cursor:pointer;color:orange;" valign="top" (click)="routeToPageSection(i)">({{slides[selectedSlide].length-1}})</a></span>
          <span *ngIf="slides[selectedSlide].length === 5" style="vertical-align: middle;padding:0px 0px 15px 5px;margin:0 auto;width:90%;text-align:center;"><a style="cursor:pointer;color:mediumseagreen;" valign="top" (click)="routeToPageSection(i)">({{slides[selectedSlide].length-1}})</a></span>
          <span *ngIf="slides[selectedSlide].length < 5" style="vertical-align: middle;padding:0px 0px 15px 5px;margin:0 auto;width:90%;text-align:center;"><a style="cursor:pointer;color:orangered;" valign="top" (click)="routeToPageSection(i)">({{slides[selectedSlide].length-1}})</a></span>
          <span  style="vertical-align: middle;padding:0px 0px 15px 5px;margin:0 auto;width:90%;text-align:center;">|</span>
        </span>
      </span>
      </div>
        <br>
      <br>
      <b>Subtitles:</b>

      <div  *ngFor="let selectedSlide of subtitles; let i = index" (click)="selectSlide(selectedSlide, i)" (mouseenter)="browseSlideIndex = i" style="width:98%;margin:0px;padding:2px">

      <!-- editable -->
      <div *ngIf="slides[selectedSlide] && slides[selectedSlide].length >= 0 && editable" class = "regular" [class.browse]="i === browseSlideIndex" [class.active]="i === selectedSlideIndex">
        <div *ngFor="let tag of slides[selectedSlide]; let j = index" (click)="selectTag(tag, j)" (mouseenter)="browseTagIndex = j" style="width:98%; list-style: none;background-color:white;display:table; margin:0; font-family:Helvetica,AppleGothic,sans-serif; inline-block;padding: 2px; height:20px;text-align:left;" >

          <div  style="display:table;">
            <div class = "regular" [class.browse]="i === browseSlideIndex && j === browseTagIndex" [class.active]="i === selectedSlideIndex && j === selectedTagIndex" style="vertical-align:top;display:table; text-align:left;margin:0px; ">
              <input *ngIf="edit===true" name="{{tag.id}}" [(ngModel)]="tag.checked" class="doNotPrint" type="checkbox" style="width: 13px; height: 13px; vertical-align: middle; position: relative; top: -1px; *overflow: hidden;padding:0px;margin:0px;font-weight:300;font-size:.75em;" (click)="setState($event, tag);"/>&nbsp;<a *ngIf="j === 0" (click)="routeToPageSection((i-1)+'s')"><span><span>{{i+1}}.&nbsp;</span><span *ngIf="+tag.name[0] >= 1 && +tag.name[0] <= 9 ">SQBID:&nbsp;</span><span class="editTag"  style="padding:0px 1px 2px 0px; font-weight:600;font-size:14px;" ></span></span></a>
              <div *ngIf="j === 0"   class="editTag" [contentEditable]="editable" tabindex="{{i}}" (focus)="selectedTag = tag;" (blur)="tag.name = $event.target.innerHTML;" valign="top" style="display:table-cell;padding:0px 1px 2px 0px;text-align:left;display: inline-block; margin: 0 auto !important; font-weight:600;font-size:14px;" id="{{i}}" [innerHTML]="renderImage(tag.name) | safeHtml"></div>
              <div style="border:2px solid blue;" *ngIf="j > 0" class="editTag" [contentEditable]="editable" tabindex="{{i+j+1}}"(focus)="selectedTag = tag;"  (blur)="tag.value = $event.target.innerHTML;" valign="top" style="display:table-cell;padding:0px 1px 2px 0px;text-align: justify; text-justify: inter-word;display: inline-block; margin: 0 auto !important; inter-word;font-weight:500;font-size:12px;"[innerHTML]="renderImage(tag.value) | safeHtml"></div>
              <div style="display:table-cell;padding:0px 1px 2px 0px;font-weight:500;font-size:12px;" >&nbsp;<a *ngIf="tag.source.startsWith('http')" href="{{cutUntil(tag)}}" target="_blank">[{{refIndex(tag.source)}}]</a><a *ngIf="validateEmail(tag.source)" (click)="profileEx(model.hashTag, tag.source)" style="cursor:pointer;" target="_self">[{{refIndex(tag.source)}}]</a>&nbsp; </div>
              <div class="doNotPrint" *ngIf="i === selectedSlideIndex && j === selectedTagIndex" style="display:table-cell;width:80px;">
                <input *ngIf="j === 0 && editable && blurTimeout" class="intLink" type="button" title="Save Subtitle" value="💾" (click)="updateTagName(tag);" style="border:0px;background-color:#ffffff00;color:dodgerblue;font-size:12px;vertical-align: middle;"  valign="middle"/>
                <input *ngIf="j > 0 && editable && blurTimeout" class="intLink" type="button" title="Save Loq" value="💾" (click)="updateTag(tag, j);" style="border:0px;background-color:#ffffff00;color:dodgerblue;font-size:12px;vertical-align: middle;"  valign="middle"/>
                <input *ngIf="editable && j === 0" class="intLink" type="button" title="Archive Loq" value="＋" (click)="newLOQ()" style="border:0px;background-color:#ffffff00;color:dodgerblue;font-size:16px;vertical-align: middle;"  valign="middle"/>
                <input *ngIf="editable && j > 0" class="intLink" type="button" title="Add Loq" value="＋" (click)="cloneTag(tag)" style="border:0px;background-color:#ffffff00;color:dodgerblue;font-size:16px;vertical-align: middle;"  valign="middle"/>
                <input *ngIf="editable" class="intLink" type="button" title="Archive Loq" value="✘" (click)="deleteTag(tag)" style="border:0px;background-color:#ffffff00;color:orangered;height:19px;width:19px;vertical-align: middle;"  valign="middle"/>
              </div>
            </div>
          </div>

        </div>
        </div>


      <!-- non editable -->
        <div *ngIf="slides[selectedSlide] && slides[selectedSlide].length >= 0 && !editable" class = "regular" [class.browse]="i === browseSlideIndex" [class.active]="i === selectedSlideIndex">
          <div *ngFor="let tag of slides[selectedSlide]; let j = index" (click)="selectTag(tag, j)" (mouseenter)="browseTagIndex = j" style="width:98%; list-style: none;background-color:white;display:table; margin:0; font-family:Helvetica,AppleGothic,sans-serif; inline-block;padding: 2px; height:20px;text-align:left;" >

          <div style="display:table;">
            <div class = "regular" [class.browse]="i === browseSlideIndex && j === browseTagIndex" [class.active]="i === selectedSlideIndex && j === selectedTagIndex" style="vertical-align:top;display:table; text-align:left;margin:0px; ">
              <input *ngIf="edit===true" name="{{tag.id}}" [(ngModel)]="tag.checked" class="doNotPrint" type="checkbox" style="width: 13px; height: 13px; vertical-align: middle; position: relative; top: -1px; *overflow: hidden;padding:0px;margin:0px;font-weight:300;font-size:.75em;" (click)="setState($event, tag);"/>&nbsp;<a *ngIf="j === 0" (click)="routeToPageSection((i-1)+'s')"><span><span>{{i+1}}.&nbsp;</span><span *ngIf="+tag.name[0] >= 1 && +tag.name[0] <= 9 ">SQBID:&nbsp;</span><span class="editTag"  style="padding:0px 1px 2px 0px; font-weight:600;font-size:14px;" ></span></span></a><div *ngIf="j === 0"   class="editTag" [contentEditable]="editable" tabindex="{{i}}" (focus)="selectedTag = tag;" (blur)="tag.name = $event.target.innerHTML;" valign="top" style="display:table-cell;padding:0px 1px 2px 0px;text-align:left;display: inline-block; margin: 0 auto !important; font-weight:600;font-size:14px;" id="{{i}}" [innerHTML]="renderImage(tag.name) | safeHtml"></div>
              <div *ngIf="j > 0" class="editTag" [contentEditable]="editable" tabindex="{{i+j+1}}"(focus)="selectedTag = tag;" (blur)="tag.value = $event.target.innerHTML;" valign="top" style="display:table-cell;padding:0px 1px 2px 0px;text-align: justify; text-justify: inter-word;display: inline-block; margin: 0 auto !important; inter-word;font-weight:500;font-size:12px;"[innerHTML]="renderImage(tag.value) | safeHtml"></div>
              <!--
              <div style="display:table-cell;padding:0px 1px 2px 0px;font-weight:500;font-size:12px;" >&nbsp;<a *ngIf="tag.source.startsWith('http')" href="{{cutUntil(tag)}}" target="_blank">[{{refIndex(tag.source)}}]</a><a *ngIf="validateEmail(tag.source)" (click)="profileEx(model.hashTag, tag.source)" style="cursor:pointer;" target="_self">[{{refIndex(tag.source)}}]</a>&nbsp;</div><div class="doNotPrint" *ngIf="i === selectedSlideIndex && j === selectedTagIndex" style="display:table-cell;width:38px;">
              -->
            </div>
            </div>
          </div>

      </div>
    </div>
      </div>

    <br class="doNotPrint">
    <br class="doNotPrint">
    <div class="block_container" id="end" style="background-color:#ffffff00;border-top:1px solid gainsboro;padding:10px 0px 0px 0px;font-family:Century Gothic,CenturyGothic,AppleGothic,sans-serif; text-align: center; valign: top; font-size:1em;font-weight:200;"><a style="text-decoration:none;" href="http://siloq.com" >
      <span style="color:dodgerblue;">Powered by</span> <img src="assets/isloq.png" align="middle" style="margin-bottom:13px;width:19px; height:19px;" /></a> <span style="color:grey;"></span> <a style="text-decoration:none;" href="http://isloq.com"><span style="color:dodgerblue;">i</span><span
      style="color:mediumseagreen;">s</span><span
      style="color:dodgerblue;">l</span><span
      style="color:orange;">o</span><span
      style="color:dodgerblue;">q</span><span
      class="sup"
      style="color:orangered;">™</span>
    </a>
    </div>
    <loq-footer></loq-footer>
