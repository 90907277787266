import { Component } from '@angular/core';
import {UserService} from '../services/user.service';
import {Login} from '../services/user.service';
import { Router } from '@angular/router';
import { AlertComponent } from '../alert/alert.component';
import { AlertService } from '../alert/services/alert.service';
import { AlertTypeEnum } from '../alert/types/alertType.enum';
import { config } from '../config';
import {RouterModule} from '@angular/router';
import { HeaderComponent } from '../header/header.component';
import { FooterComponent } from '../footer/footer.component';
import { FormsModule } from '@angular/forms';

@Component({
  host: {
      '(document:keydown)': 'keyDown($event)',
  },

  templateUrl: './goldimembers.component.html',
  styleUrls: ['./goldimembers.component.css'],
  imports: [AlertComponent, FormsModule, RouterModule, FooterComponent, HeaderComponent],
  standalone: true
})

export class GoldimembersComponent {
  password2="";
  passwordFail: boolean = false;
  public taxonomy = "";
  public class = "";
  public subclass = "";
  public goldimembersMonthlyPrice = "";

  constructor( private alertService: AlertService, public userSVC: UserService, private router: Router, public loginInfo: Login){
  	loginInfo = new Login();
  }

  ngOnInit() {
    this.taxonomy = config.taxonomy;
    this.class = config.class;
    this.subclass = config.subclass;
    this.goldimembersMonthlyPrice = config.goldimembersMonthlyPrice;
	}

   validateEmail(email: any) {
     const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
     return re.test(email);
   }

  showAlert(type: AlertTypeEnum, msg: string) {
      this.alertService.setAlert({
        type,
        text: msg,
      });
    }

  signin(){
    this.router.navigate(['admin/goldimembers']);
  }
 }
