import { Component, OnInit } from '@angular/core';
import {TagService} from '../services/tag-service.service';
import {Observable, throwError} from 'rxjs';
import {HostListener} from '@angular/core';
import {ActivatedRoute, Router } from '@angular/router';
import {Tag} from '../admin/tag/model/tag';
import { AlertComponent } from '../alert/alert.component';
import { AlertService } from '../alert/services/alert.service';
import { AlertTypeEnum } from '../alert/types/alertType.enum';
import { config } from '../config';
import {RouterModule} from '@angular/router';
import { HeaderComponent } from '../header/header.component';
import { FooterComponent } from '../footer/footer.component';
import { FormsModule } from '@angular/forms';
import {UserService} from '../services/user.service';
import {CookieService} from 'ng2-cookies';
import {Login} from '../services/user.service';

@HostListener('window:keydown', ['$event'])
@Component({
	host: {
    '(document:keydown)': 'keyDown($event)',
	},
	selector: 'app-contact',
	templateUrl: './contact.component.html',
	styleUrls: ['./contact.component.css'],
  imports: [AlertComponent, FormsModule, RouterModule, FooterComponent, HeaderComponent],
  standalone: true
})

  export class ContactComponent implements OnInit {
	public model = new Tag('', '', '', '', '');
	public theUser = "";
  public theGoldiUser = "";
  sub= "";
  public taxonomy = "";
  public class = "";
  public subclass = "";
  public otpVerified = false;
  public emailVerified = false;
  email= "";
  passwd= "";
  passwordType: string = 'password';
  cookies = false;

	constructor(private loginInfo : Login, public userSVC: UserService, private alertService: AlertService, private tagService: TagService, private activatedRoute: ActivatedRoute, private router: Router, private _cookieService:CookieService) {loginInfo = new Login();}

	setCookie(key: string, value: string){
      this._cookieService.set(key, value);
      key = this._cookieService.get(key);
      if(!key) {
        this.cookies = false;
      }  else {
        this.cookies = true;
      }
    }

	ngOnInit() {
	  this.userSVC.setCookie("ctest", "ctest");
    if(!this.cookies){
      this.showAlert(AlertTypeEnum['danger'], "Please, enable cookies!");
    }

		this.activatedRoute.queryParams.subscribe(params => {
    this.sub = params['s'] || "Contact";
		});
		this.model.email = "support.goldimocks@siloq.com";
		this.model.hashTag = "contact";
    this.taxonomy = config.taxonomy;
    this.class = config.class;
    this.subclass = config.subclass;
    this.model.source = this.email;
	}

showAlert(type: AlertTypeEnum, msg: string) {
    this.alertService.setAlert({
      type,
      text: msg,
    });
  }

 submitTag(){
     var tb = document.getElementById("value");
     this.model.value = tb.innerText;
     this.model.source = this.email;
     if (this.model.value === "")
     {
       this.showAlert(AlertTypeEnum['warning'], "Value can not be empty");
       return;
     }

     this.tagService.addTag(this.model).subscribe(
       () => {
        tb.innerText = "";
        this.showAlert(AlertTypeEnum['info'], "Thanks for contacting us. We'll get back to you in 72 hours.");
       },
        (err: any) => {
         console.log(err);
       });
   }

   public keyDown(event: any) {
     switch (event.keyCode) {
       case 13:
       this.submitTag()
         event.preventDefault();
         break;
     }
     return;
   }

    forgot() {
       this.userSVC.forgot(this.loginInfo).subscribe(
         (data: any) => {
          this.showAlert(AlertTypeEnum['warning'], "Email has been sent with temporary OTP Code. Please check your email inbox and/or spam folders within 24 hours!");
         },
          (err: any) => {
           console.log(err);
         });
     }

     register() {
       let login = new Login();
       this.userSVC.signUp(this.loginInfo)
       .subscribe((json: Object) => {
         login.fromJSON(json);
         if (login.email === this.email) {
              this.forgot();
          }
         },
         (err: any) => {
            this.showAlert(AlertTypeEnum['danger'], "The email may be already existing. Please login to leave a feedback or question. Thanks");
            console.log(err);
            window.setTimeout(() => {
              this.router.navigate(['/admin/feedback']);
            }, 3000);
         });
     }

     doRegister() {
          this.loginInfo.email = btoa(this.email);
          if (!this.validateEmail(this.email)) {
            this.showAlert(AlertTypeEnum['danger'], "Please, enter valid email and try again!");
            this.emailVerified = false;
            return;
          }

          this.register();
      }


   validateEmail(email: any) {
      const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    }

    submitOtp(){
      this.loginInfo.email = btoa(this.email);
      this.loginInfo.passwd = btoa(this.passwd);
      if (this.email && this.passwd) {
        this.atomicLogin(this.loginInfo, (login) => {
        if(login) {
           if (login.email === this.email) {
            this.userSVC.setCookie("gwt", login.sessionId);
            this.showAlert(AlertTypeEnum['info'], "OTP Code is verified!");
            this.submitTag();
            return;
           }
            this.showAlert(AlertTypeEnum['danger'], "OTP Code could not be verified yet!");
            return;
          }
        });
      }
    }

    atomicLogin(login: Login, callback: (login: any) => any) {
       this.userSVC.login(login).subscribe(
         (data: any) => {
           callback(data);
         },
          (err: any) => {
           console.log(err);
           this.showAlert(AlertTypeEnum['danger'], "Re-enter the email and/or OTP Code.");
         });
     }

    togglePasswordVisibility() {
      this.passwordType = this.passwordType === 'password' ? 'text' : 'password';
    }

}

