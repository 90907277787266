import {TagService} from '../services/tag-service.service';
import { Component } from '@angular/core';
import {UserService} from '../services/user.service';
import {Login} from '../services/user.service';
import { Observable, Subscription } from 'rxjs';
import {ActivatedRoute, Router } from '@angular/router';
import {CookieService} from 'ng2-cookies';
import {DataService } from "../services/data.service";
import { AlertComponent } from '../alert/alert.component';
import { AlertService } from '../alert/services/alert.service';
import { AlertTypeEnum } from '../alert/types/alertType.enum';
import { config } from '../config';
import {RouterModule} from '@angular/router';
import { HeaderComponent } from '../header/header.component';
import { FooterComponent } from '../footer/footer.component';
import { FormsModule } from '@angular/forms';

declare function escape(s:string): string;
declare function unescape(s:string): string;

@Component({
  host: {
    '(document:keydown)': 'keyDown($event)',
  },

  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  imports: [AlertComponent, FormsModule, RouterModule, FooterComponent, HeaderComponent],
  standalone: true
})
export class LoginComponent {
  email= "";
  passwd= "";
  gwt = "";
  cookies = false;
  guser = "";
  timeout = 900;
  maxretry = 6;
  public taxonomy = "";
  public class = "";
  public subclass = "";
  returnUrl = "";
  passwordType: string = 'password';

  constructor(private alertService: AlertService, private tagService: TagService, private activatedRoute: ActivatedRoute,public userSVC: UserService, private router: Router, private loginInfo : Login, private _cookieService:CookieService, private data: DataService) {loginInfo = new Login();}

  setCookie(key: string, value: string){
    this._cookieService.set(key, value);
    key = this._cookieService.get(key);
    if(!key) {
      this.cookies = false;
    }  else {
      this.cookies = true;
    }
  }

  ngOnInit() {
    this.setCookie("ctest", "ctest");
    this.taxonomy = config.taxonomy;
    this.class = config.class;
    this.subclass = config.subclass;
    if(!this.cookies){
      this.showAlert(AlertTypeEnum['danger'], "Please, enable cookies!");
    }

    this.activatedRoute.queryParams.subscribe(params => {
      this.gwt = unescape(params['g']) || "";
      this.guser = params['n'] || "";
      this.returnUrl = params['returnUrl'] || "";
    });
  }

showAlert(type: AlertTypeEnum, msg: string) {
    this.alertService.setAlert({
      type,
      text: msg,
    });
  }

  validateEmail(email: any) {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  login(){
    if(!this.cookies){
      return;
    }

    if (!this.validateEmail(this.email)) {
      this.showAlert(AlertTypeEnum['danger'], "Re-enter a valid Email, Password, or click on <b>Forgot Password?</b>");
      return;
    }

    this.loginInfo.email = btoa(this.email);
    this.loginInfo.passwd = btoa(this.passwd);
    if (this.email && this.passwd) {
      if(this.gwt && this.guser === "yes") {
        this.loginInfo.sessionId = this.gwt;
      }

      //this.gwt = "";
      //alert(JSON.stringify(this.loginInfo));
      this.atomicLogin(this.loginInfo, (login) => {
      if(login) {
          this.userSVC.authUser.email = login.email;
          this.userSVC.authUser.sessionId = login.sessionId;
          this.userSVC.authUser.userId = "";
          this.userSVC.authUser.goldimembersSessionId = "";
          //
          var s = login.userId.replace(/0/g,"");
          if(s != "") {
            this.userSVC.authUser.userId = login.userId;
            this.userSVC.loggedInUser.userId = login.userId;
          }
          //
          this.userSVC.loggedInUser.email = login.email;
          this.userSVC.loggedInUser.sessionId = login.sessionId;
          this.userSVC.loggedInUser.userId = "";
          this.userSVC.loggedInUser.goldimembersSessionId = "";
          s = login.goldimembersSessionId.replace(/0/g,"");
          if(s != "") {
            this.userSVC.authUser.goldimembersSessionId = login.goldimembersSessionId;
            this.userSVC.loggedInUser.goldimembersSessionId = login.goldimembersSessionId;
          }
        }
        //
       if (this.userSVC.authUser.email && this.userSVC.authUser.sessionId) {
            this.userSVC.setCookie("email", this.userSVC.authUser.email);
            this.userSVC.setCookie("gwt", this.userSVC.authUser.sessionId);
            this.userSVC.setCookie("gmt", this.userSVC.authUser.goldimembersSessionId);
            this.userSVC.setCookie("uid", this.userSVC.authUser.userId);
            this.data.changeMessage(this.curbemail(this.userSVC.authUser.email));
            let isnum = /^\d+$/.test(this.passwd);
            if(isnum && this.passwd.length===8) {
              this.router.navigate(['/admin/reset']);
            } else if(this.returnUrl) {
              this.router.navigateByUrl(this.returnUrl);
            } else {
              this.router.navigate(['/admin/mocks']);
            }
          } else {
            this.userSVC.setCookie("email", "");
            this.userSVC.setCookie("gwt", "");
            this.userSVC.setCookie("gmt", "");
            this.userSVC.setCookie("uid", "");
            this.data.changeMessage("");
          }
      });
    }
  }

  atomicLogin(login: Login, callback: (login: any) => any) {
     this.userSVC.login(login).subscribe(
       (data: any) => {
         callback(data);
       },
        (err: any) => {
         console.log(err);
         this.showAlert(AlertTypeEnum['danger'], "Re-enter registered Username, Password, or click on <b>Forgot Password?</b>");
       });
   }

  signup(){
    this.router.navigate(['signup'],  { queryParams: { g : this.gwt}});
  }

  forgot(){
    this.router.navigate(['forgot'],  { queryParams: { g : this.gwt}});
  }

 public keyDown(event: any) {
    switch (event.keyCode) {
      case 13:
        this.login()
        event.preventDefault();
        break;
    }
    return;
  }

  curbemail(email: string): string {
    var index = email.indexOf("@");
    if(index > 0) {
      return email.substring(0, index);
    }
    return email;
  }

  togglePasswordVisibility() {
      this.passwordType = this.passwordType === 'password' ? 'text' : 'password';
    }
}
