import { Component, OnInit } from '@angular/core';
import {TagService} from '../../services/tag-service.service';
import {Tag} from '../tag/model/tag';
import {TopicService} from '../../services/topic.service';
import {Topic} from '../topic/model/topic';
import {Observable, throwError} from 'rxjs';
import {HostListener} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {UserService} from '../../services/user.service';
import {Login} from '../../services/user.service';
import {CookieService} from 'ng2-cookies';
import { DataService } from "../../services/data.service";
import { config } from '../../config';
import {RouterModule} from '@angular/router';
import { AdminSisHeaderComponent } from '../adminSisHeader/admin-sis-header.component';
import { AdminFooterComponent } from '../adminFooter/admin-footer.component';
import { FormsModule } from '@angular/forms';
import {IdService} from '../../services/id.service';
import {Tiny} from '../tiny/model/tiny';

@HostListener('window:keydown', ['$event'])
@Component({
  host: {
    '(document:keydown)': 'keyDown($event)',
  },
  selector: 'app-admin-graph',
  templateUrl: './admin-graph.component.html',
  styleUrls: ['./admin-graph.component.css'],
  imports: [FormsModule, RouterModule, AdminFooterComponent, AdminSisHeaderComponent],
  standalone: true
})

export class AdminGraphComponent implements OnInit {
  topics: Topic[] = [];
  selectedTopics: Topic[] = [];
  public showAuto: boolean;
  private model: Topic = new Topic('', '', '');
  public unmodel: Topic = new Topic('', '', '');
  private umodel: Topic = new Topic('', '', '');
  private branch: Topic = new Topic('', '', '');
  public selectedTopic: Topic = new Topic('', '', '');
  selectedIndex: number = 0;
  searchText = "";
  currentPage: number = 1;
  pageSize = 9;
  totalCount: number = 0;
  pageCount: number = 0;
  sortKey = "-updatedate";
  selectAll = false;
  public theUser = "";
  host = window.location.protocol+"//"+window.location.hostname;
  public theGoldiUser = "";
  public taxonomy = "";
  public pageTitle = "";
  public class = "";
  public subclass = "";

  constructor(private tagService: TagService, private topicService: TopicService, private activatedRoute: ActivatedRoute, public userSVC: UserService, private router: Router, private loginInfo : Login, private _cookieService:CookieService, private data: DataService){
    loginInfo = new Login();
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  getCookie(key: string){
    return this._cookieService.get(key);
  }

  ngOnInit() {
    this.theUser = this.getCookie("email");
    this.theGoldiUser = this.getCookie("gmt");
    this.class = config.class;
    this.subclass = config.subclass;
    this.taxonomy = config.taxonomy;
    this.host = window.location.protocol+"//"+window.location.hostname;
    this.unmodel = new Topic('', '', '');
    this.activatedRoute.queryParams.subscribe(params => {
      this.searchText = params['q'] || "";
      this.currentPage = parseInt(params['p']) || 1;
    });

    this.setCurrentPage(this.currentPage);
  }

  loadTopics(callback: () => any) {
    this.model = new Topic('', '', '');
    this.topicService.searchTopics(this.searchText, this.sortKey, this.theUser, "128")
      .subscribe(
        (topics: any) => {
          this.topics = topics; //Bind to view
          if(this.topics !== undefined) {
            this.totalCount = this.topics.length;
            this.pageCount = Math.ceil(this.totalCount / this.pageSize);
            callback();
            this.setPageVisible();
          }
        },
         (err: any) => {
          console.log(err);
        });
  }

  submitTopic(){
    let commentOperation:Observable<Topic[]>;
    if (this.model.hashTag == "" )
    {
      return;
    }

    this.model.email = this.theUser;
    this.topicService.addTopic(this.model).subscribe(
      () => {
        this.model = new Topic('', '', '');
        this.setFirstPage();
      },
       (err: any) => {
        console.log(err);
      });
  }

  loadBranch(bname: string) {
    this.topicService.filterTopics(this.model.hashTag, "", "", "", "")
      .subscribe(
        (topics: any) => {
          if(topics !== undefined) {
            this.branch = topics[0];
          }
        },
         (err: any) => {
          console.log(err);
        });
  }

  updateBranch(topic: Topic, bname: string, nbId: string){
    var nbname = document.getElementById(nbId).textContent;
    let commentOperation:Observable<Topic[]>;
    var branch = this.topics.filter(obj=>obj.hashTag===bname)[0];
    branch.hashTag = nbname;
    this.updateTopic(branch)
    var id = topic.topics.indexOf(bname);
    topic.topics.splice(id,  1);
    topic.topics.push(branch.hashTag);
    this.updateTopic(topic)
  }

  updateTopic(topic: Topic){
    let commentOperation:Observable<Topic[]>;
    this.topicService.updateTopic(topic).subscribe(
      () => {
        this.model = new Topic('', '', '');
        this.setFirstPage();
      },
       (err: any) => {
        console.log(err);
      });
  }

  archiveTopic(topic: Topic){
    if(confirm("Do you want to archive " + topic.hashTag + "?")) {
      let commentOperation:Observable<Topic[]>;
      this.topicService.archiveTopic(topic).subscribe(
        () => {
          this.model = new Topic('', '', '');
          this.setFirstPage();
        },
         (err: any) => {
          console.log(err);
        });
    }
  }

  branchTopic(topic: Topic){
    this.model.value = topic.value;
    this.model.hashTag = topic.hashTag+"-"+this.theUser;
    this.submitTopic();

    topic.topics.push(this.model.hashTag);
    this.updateTopic(topic);
  }

  public selectTopic( topic: any, i: number) {
    topic.rank = i + 1;
    this.selectedIndex = i;
    this.selectedTopic =  topic;
     this.data.changeMessage(this.selectedTopic.hashTag.split(' ').slice(0,6).join(' ') + " " + this.selectedTopic.value.split(' ').slice(0,9).join(' '));
  }

 public keyDown(event: any) {
    switch (event.keyCode) {
      case 38:
        this.onUp();
        break;
      case 40:
        this.onDown()
        break;
      case 13:
        break;
    }
    return;
  }

  public onDown() {
    if(this.selectedIndex < this.topics.length - 1)
    {
      this.selectedTopic = this.topics[++this.selectedIndex];
      this.data.changeMessage(this.selectedTopic.hashTag.split(' ').slice(0,6).join(' ') + " " + this.selectedTopic.value.split(' ').slice(0,9).join(' '));
    }
  }

  public onUp() {
    if(this.selectedIndex > 0)
    {
      this.selectedTopic = this.topics[--this.selectedIndex];
      this.data.changeMessage(this.selectedTopic.hashTag.split(' ').slice(0,6).join(' ') + " " + this.selectedTopic.value.split(' ').slice(0,9).join(' '));
    }
  }

  docTopic(topic: any) {
    this.router.navigate(['/admin/doc'],  { queryParams: { h : topic.hashTag, i : topic.ID}});
  }

  palyTopic(topic: any) {
    this.router.navigate(['/admin/play'],  { queryParams: { h : topic.hashTag, i : topic.ID}});
  }

  editTopic2(topic: any) {
    this.router.navigate(['/admin/edit'],  { queryParams: { h : topic.hashTag, i : topic.ID}});
  }

  docBranch(branch: any) {
    this.router.navigate(['/admin/edit'],  { queryParams: { h : branch}});
  }

  manageTopicUsers(topic: any) {
    this.router.navigate(['/admin/user'],  { queryParams: { h : topic.hashTag}});
  }

  invite() {
    this.router.navigate(['/admin/invite']);
  }

  inbox() {
    //TODO:
    //this.router.navigate(['/admin/inbox'], { queryParams: { h : "inbox-"+this.theUser, d : this.theUser}});
    this.router.navigate(['/admin/publish'], { queryParams: { h : "inbox", d : "tagndoc@tagndoc.com"}});
  }

  tagndoc() {
    this.router.navigate(['/admin/tagndoc']);
  }

  editTopic(topic: any) {
    //this.umodel.edit =  !this.umodel.edit;
    this.umodel = new Topic(topic.hashTag, topic.value, topic.userId);
    topic.edit = !topic.edit;
  }

  cancelUpdate(topic: Topic) {
    topic.hashTag = this.umodel.hashTag;
    topic.value = this.umodel.value;
    topic.insertDate = this.umodel.insertDate;
    topic.edit = false;
  }

  //pagination for table
  setCurrentPage(page: number) {
    this.loadTopics(() => {
      this.currentPage = page;
    });
  }

  public setFirstPage() {
    this.loadTopics(() => {
      this.currentPage = 1;
    });
  }

  setPageVisible() {
    this.selectedIndex = (this.currentPage - 1) * this.pageSize;
    var end = this.currentPage * this.pageSize
    this.selectedTopics = [];
    for (let i = 0; i < this.topics.length; i++) {
      this.topics[i].show = false;
      if(i >= this.selectedIndex && i < end) {
        this.getTopicValue(this.topics[i]);
        this.topics[i].show = true;
        this.selectedTopics.push(this.topics[i]);
      }
    }
    this.selectedTopic = this.topics[this.selectedIndex];
    this.data.changeMessage(this.selectedTopic.hashTag);
    if (this.selectedTopic) {
      //this.selectedTopic.rank = this.selectedIndex + 1;
    }
  }

  setMe(element: any) {
    var s_height = element.scrollHeight;
    element.setAttribute('style','height:'+s_height+'px');
  }

  selectAllTopics() {
    for (let i = 0; i < this.topics.length; i++) {
      if(!this.selectAll) {
        this.topics[i].checked = true;
      }
      else {
        this.topics[i].checked = false;
      }

    }
  }

  removeUserFromTopic(model: Topic, user: string){
    var id = model.users.indexOf(user);
    model.users.splice(id,  1);
    let commentOperation:Observable<Topic[]>;
    this.topicService.updateTopic(model).subscribe(
      () => {
      },
       (err: any) => {
        console.log(err);
      });
  }

  getTopicValue (topic: any) {
    this.tagService.getTopicUserTags(topic.hashTag, this.theUser)
      .subscribe(
       (tags: any) => {
          let ts  = tags;
          if(ts !== undefined && ts.length > 0) {
            for (let i = 0; i < ts.length; i++) {
              if(ts[i].value) {
                topic.value = ts[i].value;
                break;
              }
            }
          }
        },
         (err: any) => {
          console.log(err);
        });
  }

  sort(event): void {
    if(event.target) { this.sortKey=this.sortKey.includes('-') ? event.target.value : '-'+event.target.value; }
    this.setFirstPage();
  }


  setArrayFromNumber(i: number) {
     return new Array(i);
  }

curbemail(email: string): string {
    if(!email) {
      return email;
    }

    var index = email.indexOf("@");
    if(index > 0) {
      return email.substring(0, index);
    }
    return email;
  }

  profile(t: any) {
    this.router.navigate(['profile'],  { queryParams: { h : t.hashTag, d : window.btoa(t.email)}});
    return;
  }

  myProfile(t: any) {
    this.router.navigate(['profile'],  { queryParams: { h : t.hashTag, d : window.btoa(this.theUser)}});
    return;
  }

}
