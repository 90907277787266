import { Component, OnInit, OnDestroy } from '@angular/core';
import {AutoService} from '../../services/auto.service';
import {SearchService} from '../../services/search.service';
import {Auto} from '../auto/model/auto';
import {Search} from './model/search';
import {Observable, throwError} from 'rxjs';
import {HostListener} from '@angular/core';
import {ActivatedRoute, Router } from '@angular/router';
import {TinyService} from '../../services/tiny.service';
import {UserService} from '../../services/user.service';
import {CookieService} from 'ng2-cookies';
import { DataService } from "../../services/data.service";
import { Subscription } from 'rxjs';
import { AdminAlertComponent } from '../adminAlert/admin-alert.component';
import { AdminAlertService } from '../adminAlert/services/admin-alert.service';
import { AlertTypeEnum } from '../adminAlert/types/alertType.enum';
import { config } from '../../config';
import {RouterModule} from '@angular/router';
import { AdminSisHeaderComponent } from '../adminSisHeader/admin-sis-header.component';
import { AdminFooterComponent } from '../adminFooter/admin-footer.component';
import { FormsModule } from '@angular/forms';
import {IdService} from '../../services/id.service';
import {Tiny} from '../tiny/model/tiny';

@HostListener('window:keydown', ['$event'])
@Component({
  host: {
    '(document:keydown)': 'keyDown($event)',
  },
  selector: 'app-admin-search',
  templateUrl: './admin-search.component.html',
  styleUrls: ['./admin-search.component.css'],
  imports: [AdminAlertComponent, FormsModule, RouterModule, AdminFooterComponent, AdminSisHeaderComponent],
  standalone: true
})

export class AdminSearchComponent implements OnInit, OnDestroy {
  autos: Auto[];
  public searchs = new Search();
  public psearchs = new Search();
  public selectedAuto;
  searchText = "";
  subscription: Subscription;
  oldSearchData = "";
  selectedIndex = 0;
  show = true;
  showAuto = false;
  currentPage = 0;
  pageSize = 8;
  pageDirection = -1;
  totalCount = 0;
  pageCount = 0;
  working = true;
  counter = 0;
  start = 0;
  tin = ""
  searchData = ""
  selectedItemIndex = -1;
  selectedItem;
  tinUrl = config.slqioUrl;
  public theGoldiUser = "";
  public taxonomy = "";
  public pageTitle = "";
  public class = "";
  public subclass = "";
  public theUser = "";

  constructor(public userSVC: UserService, private autoService: AutoService, private tinyService: TinyService, private searchService: SearchService, private activatedRoute: ActivatedRoute, private router: Router, private data: DataService, private idService: IdService, private alertService: AdminAlertService) { }

  showAlert(type: AlertTypeEnum, msg: string) {
    this.alertService.setAlert({
      type,
      text: msg,
    });
  }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      this.class = config.class;
      this.subclass = config.subclass;
      this.taxonomy = config.taxonomy;
      this.searchText = params['q'] || "";
      var s = params['s'];
      this.start = + s || 0;
      this.tin = params['l'] || ""
      if (this.start == 0) {
        this.tiny();
      }

      if (this.searchText != "") {
        this.data.changeMessage(this.searchText);
      }

      this.subscription = this.data.currentMessage.subscribe(message => this.searchData = message)

      this.search();
    });
  }

   ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  auto() {
    //Use a queue for managing in-flight requests

    var res = this.searchText.trim().split(" ");
    var txt = res[res.length - 1];
    txt = txt.trim()
    var head  = this.searchText.substring(0,this.searchText.indexOf(txt)).trim();

    this.counter++;
    var thisCounter = this.counter;
    var timeoutId = setTimeout(()=> {
      this.showAuto = false;
      if (txt.length > 2) {
        this.autos = new Array();
        //TODO: [0] current auto textbox value
        //	let t = new Auto();
        //	 t.Name = this.searchText;
        //	 t.Rank = 0;
        //	 this.autos = new Array()
        //	 this.autos[0] = t;

        this.autoService.auto(txt)
          .subscribe(
            (data: any) => {
              //				this.autos = this.autos.concat(data); //Bind to view
              if (this.counter == thisCounter) {
                this.autos = data; //Bind to view
                if (this.autos.length > 0) {
                  this.showAuto = true;
                } else {
                  this.showAuto = false;
                }
                var i;
                for (i = 0; i < this.autos.length; i++) {
                  this.autos[i].Name = head  + " " + this.autos[i].Name;
                }
              }
            },
             (err: any) => {
              console.log(err);
            });
      } else {
        this.autos = new Array();
        this.showAuto = false;
      }
    }, 1000/60);
    //	clearTimeout(timeoutId);
  }

  search() {
    this.showAuto = false;
    this.start = this.start % this.pageSize == 0 ? this.start : this.start - (this.start % this.pageSize);
    if (this.searchData != "" && this.searchData != this.oldSearchData) {
      this.searchs = new Search();
      this.totalCount = 0;
      this.pageCount = 0;
      this.show = false;
      this.working = true;

      this.searchService.search(this.searchData, this.start)
        .subscribe(
          (data: any) => {
            this.searchs = data; //Bind to view
            if(this.searchs.Results !== undefined) {
              this.totalCount = this.searchs.Total;
              this.pageSize = this.searchs.PageSize
              this.oldSearchData = this.searchData;
              this.pageCount = Math.ceil(this.totalCount / this.pageSize);
              this.start = this.searchs.Start;
              this.currentPage = (this.start + this.pageSize)/this.pageSize;
              this.working = false;
            }
          },
           (err: any) => {
            console.log(err);
            this.working = false;
          });
    }
  }

  public selectAuto( auto: any, i: number) {
    this.selectedIndex = i;
    this.selectedAuto =  auto;
    this.searchText = this.selectedAuto.Name;
    this.setFirstPage()
    this.showAuto = false;
  }

 public keyDown(event: any) {
    switch (event.keyCode) {
      case 37:
        this.setPreviousPage();
        break;
      case 39:
        this.setNextPage()
        break;
      case 38:
        this.onUp();
        break;
      case 40:
        this.onDown()
        break;
      case 75:
        this.onItemUp();
        break;
      case 76:
        this.onItemDown()
        break;
      case 13:
        this.setFirstPage()
      case 27:
        this.selectedItemIndex = -1
        this.setFirstPage()
        break;
    }
    return;
  }

public onDown() {
    if(this.selectedIndex < this.autos.length - 1)
    {
      this.selectedAuto = this.autos[++this.selectedIndex];
      this.searchText = this.selectedAuto.Name;
    }
  }

  public onUp() {
    if(this.selectedIndex > 0)
    {
      this.selectedAuto = this.autos[--this.selectedIndex];
      this.searchText = this.selectedAuto.Name;
    }
  }

  tiny() {
    this.tin = "";
    this.tinyService.tiny()
      .subscribe(
        (data: any) => {
          this.tin = data.text().slice(1, -1);
        });
  }

  //pagination
  public setFirstPage() {
    if(this.searchText.length > 0) {
      window.location.href='/admin/search?q=' + this.searchText + '&l=' + this.tin;
      event.preventDefault();
    }
  }

  setPreviousPage() {
    if (this.currentPage > 1) {
      window.location.href='/admin/search?q=' + this.searchText + '&s=' + (this.start - this.pageSize) + '&l=' + this.tin;
    }
  }

  setNextPage() {
    if (this.currentPage < this.pageCount) {
      window.location.href='/admin/search?q=' + this.searchText + '&s=' + (this.start + this.pageSize) + '&l=' + this.tin;
    }
  }

  setPage(page: number) {
    if (page >= 0 && page < this.pageCount) {
      window.location.href='/admin/search?q=' + this.searchText + '&s=' + (page * this.pageSize) + '&l=' + this.tin;
    }
  }

  id(callback: (id: string) => any) {
    this.idService.id()
      .subscribe(
        (id: any) => {
          if(id!=undefined){
            callback(id);
          }
        },
         (err: any) => {
          console.log(err);
        });
  }

  tinyUrl(tiny: Tiny) {
    this.tinyService.tinyUrl(tiny)
      .subscribe(
        (tiny: any) => {
          if(tiny!=undefined){
            ;
          }
        },
         (err: any) => {
          console.log(err);
        });
  }

public selectItem( item: any, i: number) {
    this.selectedItemIndex = i;
    this.selectedItem =  item;
  }

  public onItemDown() {
    if(this.selectedItemIndex < this.searchs.Results.length - 1)
    {
      this.selectedItem = this.searchs.Results[++this.selectedItemIndex];
    }
  }

  public onItemUp() {
    if(this.selectedItemIndex > 0)
    {
      this.selectedItem = this.searchs.Results[--this.selectedItemIndex];
    }
  }

  curbemail(email: string): string {
    if(!email) {
      return email;
    }

    var index = email.indexOf("@");
    if(index > 0) {
      return email.substring(0, index);
    }
    return email;
  }
}

