<admin-header></admin-header>
<body tyle="height:100%;width:100%; margin:0px;">
<div style="text-align:center; width:100%;padding-top:120px;min-height: calc(100vh - 70px);">

    <admin-alert></admin-alert>

<div>
<br>
	<h2 class="block_container siloqlogofont" style="text-align:left;min-width:300px;"><a href="/"
                                                     target="_self"><span

    style="color:dodgerblue;">{{taxonomy}}</span><span
    class="sup"
    style="color:mediumseagreen;">&trade;</span>
    Tiny Url</a></h2>
</div>
<br>
<form>
<div class="row align-center">
   <div class="small-12  columns">
        <label>
            <input type="text" class="siloqinput" placeholder="Your Url" id="url" style="min-width:300px;" [(ngModel)]="url" name="url" required #em="ngModel" pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$">
            <div [hidden]="em.valid || em.pristine" class="siloqfont">
            </div>
        </label>
    </div>
    <br>
    <br>
    <div class="small-12  columns" style="text-align:left;">
      <div style="width:25%;text-align:left;margin:auto;min-width:300px;" class="siloqfont">
       <button (click)="tiny()" type="button" class="siloqbutton">Tiny&nbsp;Url</button>
        <button (click)="refer()" type="button" class="siloqbutton">Copy Referral Url</button>
        <button class="siloqbutton" style="color:orangered;"(click)='done()'>Done</button>
    <br>
    <br>
      </div>
    </div>
    <!--
    <div class="small-12  columns" style="text-align:left;">
      <div style="width:25%;text-align:left;margin:auto;min-width:300px;" class="siloqfont">
    <h4 style="width:100%;font-family:Century Gothic,CenturyGothic,AppleGothic,sans-serif;padding-top:10px;">Your
               Tiny Url(s)</h4>
        <div *ngIf="user.users && user.users.length > 0" style="width:100%;height:30%;overflow:auto;color:grey;" class="siloqbox">
                <table border = "0" cellpadding = "0" cellspacing = "0" style="font-family:arial,sans-serif; font-size:.79em; border-color:gainsboro;padding:10px; width:90%; margin:auto;">
      <tr style="width:10px;text-align: left;" *ngFor="let u of user.users; let j = index"> <td style="width:70%">{{u}}</td><td style="color:mediumseagreen; width:40px;"><div *ngIf="theUser != u" >
                              <input style="border:0px;background-color:#ffffff00;color:orangered;height:19px;width:19px;font-weight:300;font-size:1em;" type="button" class="intLink" title="Remove friend" value="✘" (click)="remove(u)"/>
                      </div></td></tr>
        </table>
        </div>
        </div>
    </div>
        -->
</div>
</form>
    <br>
    <br>
    <br>
<admin-footer></admin-footer>
