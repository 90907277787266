import { Component, OnInit } from '@angular/core';
import {Title} from '@angular/platform-browser';
import {TagService} from '../../services/tag-service.service';
import {TopicService} from '../../services/topic.service';
import {Tag} from '../tag/model/tag';
import {Topic} from '../topic/model/topic';
import {Observable, throwError} from 'rxjs';
import {HostListener} from '@angular/core';
import {ActivatedRoute, Router } from '@angular/router';
import {UserService} from '../../services/user.service';
import {Login} from '../../services/user.service';
import { config } from '../../config';
import {RouterModule} from '@angular/router';
import { AdminFooterComponent } from '../adminFooter/admin-footer.component';
import { FormsModule } from '@angular/forms';
import {User} from '../user/model/user';

declare function unescape(s:string): string;
declare function escape(s:string): string;

@Component({
	host: {
  '(document:keyup)': 'keyUp($event)',
  	'(document:keydown)': 'keyDown($event)',
	},
	selector: 'app-edit',
	templateUrl: './edit.component.html',
	styleUrls: ['./edit.component.css'],
  imports: [FormsModule, RouterModule, AdminFooterComponent],
  standalone: true
})
export class EditComponent implements OnInit {
@HostListener('window:keydown', ['$event'])

	tags: Tag[];
	topics: Topic[];
  public topic = new Topic('', '', '');
  utags: any;
	public model = new Tag('', '', '', '', '');
	public umodel = new Tag('', '', '', '', '');
	public selectedTag;
	selectedIndex = 0;
	message = "";
	searchText = "";
	currentPage = 0;
	pageSize = 10;
	pageDirection = -1;
	totalCount = 0;
	pageCount = 0;
	sortKey = "insertdate";
	selectAll = false;
	public theUser = "";
 public theGoldiUser = "";
  public selected = new Topic('', '', '');
  isCtrl = false;
  action = "Viewing";
  refreshImageSrc = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAACBElEQVQ4T42Tz0tUYRSGn3PHIl31B9S6FirVIiEwSIJob4FzR+YOCpmoFM3Y0n5synsXmUiJxNyRmSmSVhEtCgRrUe0qaBXUPhCSHEzzvnGvY+hI4lmec77nfO/3vcdoiJ5rxaOO4wyadLwceJ1x2R0tdSPNNfaC+mwzeeHq0+b9+2p3EMNAnP9Z8b2DG4DiALIHDYCxiu/dSgDeWPHAWs2eA2eTJmPBICyPe8X/AL6vtrQcmbt5cTUBpPPhpBlDwKrQpaqfC7dOa7jBOpACpiq+N2Tu9WIbkX1KDkhuJchVG7VuAiS7YQ4/kKaAKHJoN7dQug8aFryq+tlzYGoEZEbDHJEOlYPcbZC5+dm3mE5h3DO3EMbT2yT6q4H3aOdL78yk8+GIGRPAlxiwDLSYs36sfLfv414AvfnZjsiid4hfMaAGNMusozqe/bAXQKZQ7BS2sAn4DLRK6qkGuSd7A5T6hWYSCVu+cL7ie117AbiF8D1wUmjCevKldseUaDfoLvves90gmcK/6XLktCdGcgthbNMBYM1Ephxk53Z+pyw9WkqbiE3WBDys+N7lBJDsQVPtBXCmPv2NjOlU5HyV/VmD1AmZehGn6/X5xZWl8y8nR35vX6bUcoDZ4C4SYpNNL64sXYkP12Vvb+/Nz7bKokFhXaDDBinBN4nXwmYeB9kN29fjL94T4mitWIrxAAAAAElFTkSuQmCC";
  editImageSrc = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAABh0lEQVR42mNkoBAwDk4D+Pn5OXl4eCxZWVmVQfzfv3/f/fr16/EPHz58x2uAmJiYDBBXs7CwxAC5PGhqv/z582fJ69evW1++fPkEwwB1dfUcTk7ODiDzIRDf/fv37xdmZmY/IJsbiP8D8RkoW/7Hjx8VN27cmAI2AOhUNkVFxQVAxUHfv3+v+f///3kg/ePx48dHVVRUcoDyk6F2/AeKuwJpQ6BFLUAL1j148CCBUU9PbysTE5Ppu3fv/B49enRCU1NzBtDPB+7cubMCyO5kZ2cvg7ny169fM69du5YhKytrISwsvOnfv3+nGXV1dbcCbTcFBpAf0ESwAUBNKUD1IH/KIXsTZoC8vLyFoKAgxABubm6wF4ABFwT0G9gLQCfuxhJDYC8wMjIacnBwgL1w//79BLgiNTW1HC4uLlggfgViEyRDUAIRaFDFzZs3p2BEo4iIiIy4uHg1MP6xRiMwbJa8evWqFRiVmNGIDPj4+DiBXgMnJKCTQX4HJ6RPnz7hT0jkgIE3AAAWhbPmZIjzsAAAAABJRU5ErkJggg==";

	constructor(private titleService: Title, private tagService: TagService, private topicService: TopicService, private activatedRoute: ActivatedRoute, public userSVC: UserService, private router: Router) { }

	ngOnInit() {
  		this.theUser = this.userSVC.loggedInUser.email;
  	if (!this.theUser) {
  		this.router.navigate(['']);
  	}

		this.activatedRoute.queryParams.subscribe(params => {
      this.searchText == ""

			this.model.hashTag = params['h'] || "";
			this.topic.hashTag = params['h'] || "";
			if(this.model.hashTag != "") {
         this.titleService.setTitle(this.topic.hashTag);
         this.open(()=>{});
			}
		});
	}

  selectedText: string = '';

  showSelectedText(oField: any) {
    var text = "";
    if (window.getSelection) {
        text = window.getSelection().toString();
    }
    this.selectedText = text;
  }

	loadTags(callback: () => any) {
		if (this.searchText == "") {
		 this.tagService.filterTags(this.model.hashTag, this.model.source, this.model.value, this.sortKey, this.theUser)
				   .subscribe(
				      (tags: any) => {
							this.tags = tags; //Bind to view
							if(this.tags !== undefined) {
								this.totalCount = this.tags.length;
								this.pageCount = Math.ceil(this.totalCount / this.pageSize);
								callback();
								this.setPageVisible();
							}
					},
					 (err: any) => {
					    console.log(err);
					});
		} else {
		 this.model = new Tag('', '', '', '', '');
		 this.tagService.searchTags(this.searchText, this.sortKey, this.theUser)
				   .subscribe(
				      (tags: any) => {
							this.tags = tags; //Bind to view
							if(this.tags !== undefined) {
								this.totalCount = this.tags.length;
								this.pageCount = Math.ceil(this.totalCount / this.pageSize);
								callback();
								this.setPageVisible();
							}
					},
					 (err: any) => {
					    console.log(err);
					});
		}
	}

	submitTag(){
		let commentOperation:Observable<Tag[]>;
		//console.log(this.model);
		if (this.model.source == "" && this.model.value == "" )
		{
			return;
		}

		this.model.email = this.theUser;
		this.tagService.addTag(this.model).subscribe(
					() => {
						this.model = new Tag('', '', '', '', '');
						this.setFirstPage();
					},
					 (err: any) => {
						console.log(err);
					});
	}

	updateTag(tag: Tag){
		let commentOperation:Observable<Tag[]>;
		//console.log(tag);
		this.tagService.updateTag(tag).subscribe(
				() => {
        //				this.model = new Tag('', '', '', '', '');
        //	this.setFirstPage();
				},
				 (err: any) => {
					console.log(err);
				});
	}

	public selectTag( tag: any, i: number) {
		tag.rank = i + 1;
		this.selectedIndex = i;
		this.selectedTag =  tag;
		this.setMessage(this.selectedTag);
	}

	public setMessage(tag: any) {
		//this.message = " { \"rank\" : \"" + tag.rank + "\", \"loc\" : { \"x\" : \"" + tag.loc.x + "\", \"y\" : \"" + tag.loc.y + "\" }, \"tag\" : \"" + tag.hashTag + "\", \"source\" : \"" +  tag.source + "\",  \"value\" : \"" + tag.value + "\", \"timestamp\" : \""+tag.insertDate +"\" } ";
		this.message = " { \"rank\" : \"" + tag.rank + "\", \"loc\" : { \"x\" : \"47.60\", \"y\" : \"122.33\" }, \"tag\" : \"" + tag.hashTag + "\", \"source\" : \"" +  tag.source + "\",  \"value\" : \"" + tag.value + "\", \"timestamp\" : \""+tag.insertDate +"\" } ";
	}

	keyUp(event: any) {
		switch (event.keyCode) {
        case 17:
				event.preventDefault();
				break;
			case 83:
      if (this.isCtrl) {
				this.save();
        this.isCtrl = false;
				event.preventDefault();
        }
				break;
		}
		return;
	}

	keyDown(event: any) {
		switch (event.keyCode) {
			case 83:
      if (this.isCtrl || event.metaKey) {
        this.isCtrl = true;
				event.preventDefault();
        }
        break;
        case 17:
				event.preventDefault();
        this.isCtrl = true;
				break;
		}
		return;
	}

	public onDown() {
		if(this.selectedIndex < this.tags.length - 1)
		{
			this.selectedTag = this.tags[++this.selectedIndex];
			this.setMessage(this.selectedTag);
		}
	}

	public onUp() {
		if(this.selectedIndex > 0)
		{
			this.selectedTag = this.tags[--this.selectedIndex];
			this.setMessage(this.selectedTag);
		}
	}

	substringOf(source: string) {
		var i1 = source.indexOf( "https://" );
		var si = 8;
		if (i1 < 0)
		{
			i1 =  source.indexOf( "http://" );
			si = 7;
		}

		if(i1 >= 0)
		{
			var i2 = source.indexOf(  "/", i1 + si );
			if(i2 > 0)
			{
				var s = source.substring(i1 + si, i2);
				return s;
			}
		}
		return source;
	}

	editTag(tag: any) {
		//this.umodel.edit =  !this.umodel.edit;
		this.umodel = new Tag(tag.hashTag, tag.userId, tag.source, tag.value, tag.topicId);
		tag.edit = !tag.edit;
	}

	cancelUpdate(tag: Tag) {
		tag.hashTag = this.umodel.hashTag;
		tag.source = this.umodel.source;
		tag.value = this.umodel.value;
		tag.insertDate = this.umodel.insertDate;
		tag.edit = false;
	}

  refIndex(source: string): number {
    return this.utags.indexOf(source) + 1;
  }

	//pagination for table
	public setFirstPage() {
		this.pageDirection = 0;
		this.loadTags(() => {
			this.currentPage = 1;
    this.selectedText = this.tags[this.tags.length - 1].value;
    this.utags = Array.from(new Set(this.tags.map((item) => item.source)));
		});
	}

	setPreviousPage() {
		this.pageDirection = 1;
		    this.loadTags(() => {
			if (this.currentPage > 1) {
			    this.currentPage--;
			}
		 });
	}

	setNextPage() {
		this.pageDirection = 2;
		this.loadTags(() => {
		    if (this.currentPage < this.pageCount) {
			this.currentPage++;
		    }
		});
	}

	setLastPage () {
		this.pageDirection = 3;
		this.loadTags( () => {
			this.currentPage = this.pageCount;
		});
	}

	setPageVisible() {
		this.selectedIndex = (this.currentPage - 1) * this.pageSize;
		var end = this.currentPage * this.pageSize
		for (let i = 0; i < this.tags.length; i++) {
			this.tags[i].show = false;
			if(i >= this.selectedIndex && i < end) {
				this.tags[i].show = true;
			}
		}
		this.selectedTag = this.tags[this.selectedIndex];
		if (this.selectedTag) {
			this.selectedTag.rank = this.selectedIndex + 1;
			this.setMessage(this.selectedTag);
		}
	}

	setMe(element: any) {
		var s_height = element.scrollHeight;
		element.setAttribute('style','height:'+s_height+'px');
	}

	selectAllTags() {
		for (let i = 0; i < this.tags.length; i++) {
			if(!this.selectAll) {
	                        this.tags[i].checked = true;
			}
			else {
	                        this.tags[i].checked = false;
			}

                }
	}

	search() {
//		if(this.loggedInUser === undefined) {
			 this.setFirstPage();
//		} else {
//			this.router.navigate(['admin']);
//		}

	}

   search2(nameKey, myArray){
     for (var i=0; i < myArray.length; i++) {
         if (myArray[i].hashTag === nameKey) {
             return myArray[i];
         }
     }
     }



	open(callback: () => any) {
		if (this.topic.hashTag != "") {
		 this.topicService.searchTopics("", "hashtag", this.theUser, "128")
				   .subscribe(
				       (topics: any) => {
							this.topics = topics; //Bind to view
							if(this.topics !== undefined) {
								callback();
                this.selected = this.search2(this.topic.hashTag, topics);
                this.getTopic(()=>{
                if(this.topic.value !== "") {
                var tb = document.getElementById("textBox");
                tb.innerHTML = unescape(this.topic.value);
                }
                });
							}
					},
					 (err: any) => {
					    console.log(err);
					});
          }
          }


          getTopic(callback: () => any) {
		 this.topicService.filterTopics(this.topic.hashTag, "", "", "", this.theUser)
				   .subscribe(
				       (topics: any) => {
               if(topics.length > 0) {
              this.topic = topics[0];
              callback();
							}
					},
					 (err: any) => {
					    console.log(err);
					});
  }

 flash(msg,duration) {
     var el = document.createElement("div");
     el.setAttribute("style","position:absolute;top:100%;left:35%;background-color: mediumseagreen; border: none; color: white; padding: 10px; text-align: center; text-decoration: none; display: inline-block; font-size: 16px; font-family:arial; margin: 4px 2px; cursor: pointer; border-radius: 8px;");
     //el.setAttribute("class","button");
     el.innerHTML = msg;
     setTimeout(function(){
        el.parentNode.removeChild(el);
     },duration);
      var h = document.getElementById("toolBar2");
     h.appendChild(el);
 }

	save(){
  var tb = document.getElementById("textBox");
  this.topic.value = escape(tb.innerHTML);
  this.topic.email = this.theUser;
		this.topicService.updateTopic(this.topic).subscribe(
					() => {
					},
					 (err: any) => {
						console.log(err);
					});
          this.topic.value = unescape(tb.innerHTML);
    this.flash(this.topic.hashTag + " is saved!", 1000);
	}

  home() {
  //this.router.navigate(['/admin/editlatest'],  { queryParams: { h : tag.hashTag}});
  this.router.navigate(['/admin/topic']);
  }

  publish() {
  this.router.navigate(['/admin/publish'],  { queryParams: { h : this.topic.hashTag}});//.then(result => {  window.open(link, '_blank'); });;
    }

  chat() {
        this.router.navigate(['/admin/chat'],  { queryParams: { h : this.topic.hashTag}});
    }

  review() {
        this.router.navigate(['/admin/review'],  { queryParams: { h : this.topic.hashTag}});
    }

  edit(event: any) {
   var tb = document.getElementById("textBox");
   if (tb.contentEditable == "false") {
   tb.contentEditable = "true";
   this.action = "Editing";
  this.editImageSrc = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAACAklEQVR42mNkIBFo6+v4MjIxCn54+37Jk0dP/jESq1FGXoZTWkaywc/NqkyAl5vhwIlL84+fvJhCtAFa+jotViba1WqK0gxAQxi+ff/BkFjcnU6UARq6WpHCgnxLFGQlmEAazQ01GZxtjBhSS3vjCRqgrqPpxc/Hs15PQ5Ht6JmrDIY6KgzcnBwM124/XHL37kP8BgA1O/Fwc20x0VfjPHTiEoOlsRbDlRv3Gb58+zH325ev5Q/u3n/LiEezAzcX5xZzQw3ug0DNNqY6DGcu3fz37fvP4huXr02AqWPEodmVh4tzg5mhBhfIZmszHYZT52/8/vHzVxJQ8xJktRgGaOhoBvLwcC0z0VPjOHTyMtjmE+ev//r181fkjSvX16GrRzEAGNpJAnw8M3U1FFmOnL7CYG2qzXD6ws1fP3/+CgFq3ozNtXADNPW0q0SE+FuU5aUYT56/zmBhpMlw/sqdPz9+/IwAal6LK6zABqhqqmm72ptefv7qHeONO48YjPXUGK7ffvj/67cfKUA/z8MXU2ADfP3cOnvrMsouXr/LsHjNboaXbz4wfPr8tfL6pasdhNIJ2IDC/IT7OQkBCiD2hh1HGeYs3zYNqDmbkGawAUpqymbsHBwngzxtPj94/HLducu35l+/fO0gMZrBBqhpaVgD3SH15dPnrc8eP/1GrEYYAAB9ssujsMrBcQAAAABJRU5ErkJggg==";
   } else {
   tb.contentEditable = "false";
   this.action = "Viewing";
  this.editImageSrc = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAABh0lEQVR42mNkoBAwDk4D+Pn5OXl4eCxZWVmVQfzfv3/f/fr16/EPHz58x2uAmJiYDBBXs7CwxAC5PGhqv/z582fJ69evW1++fPkEwwB1dfUcTk7ODiDzIRDf/fv37xdmZmY/IJsbiP8D8RkoW/7Hjx8VN27cmAI2AOhUNkVFxQVAxUHfv3+v+f///3kg/ePx48dHVVRUcoDyk6F2/AeKuwJpQ6BFLUAL1j148CCBUU9PbysTE5Ppu3fv/B49enRCU1NzBtDPB+7cubMCyO5kZ2cvg7ny169fM69du5YhKytrISwsvOnfv3+nGXV1dbcCbTcFBpAf0ESwAUBNKUD1IH/KIXsTZoC8vLyFoKAgxABubm6wF4ABFwT0G9gLQCfuxhJDYC8wMjIacnBwgL1w//79BLgiNTW1HC4uLlggfgViEyRDUAIRaFDFzZs3p2BEo4iIiIy4uHg1MP6xRiMwbJa8evWqFRiVmNGIDPj4+DiBXgMnJKCTQX4HJ6RPnz7hT0jkgIE3AAAWhbPmZIjzsAAAAABJRU5ErkJggg==";
   }
    }

    sleep(ms) {
    var unixtime_ms = new Date().getTime();
    while(new Date().getTime() < unixtime_ms + ms) {}
}

     selectImage(event: any) {
  this.refreshImageSrc = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAA10lEQVQ4T2NkoBAwUqifAW4A1+RHd/79Z0z+kSd7EJ+hPJMfOP3/xzD9a76COkgd3ACOSY/tGf79W8XAxBSGyxCQ5j9/GZczsDIE/8iWP4JiAIiDzxBsmjEMwGUILs1YDUA3hIXxLzO6s5HDCGcsgLzzn+H/BsZ/DP8ZWP/7wfyMHsA4DYA4m2kNAyMjKKgDcQUsVgOQ/czwl4kZX+xgGIAtwPDFDooB+EIblyFwA/BphgUcNkPgBnBPfHDzLwtjMq7QRjaEifH/tG+5cto40wEpGYzi3AgAFaeTEVO8V3MAAAAASUVORK5CYII=";
     }
     selectTopic(event: any) {
         var tb = document.getElementById("textBox");
         this.topic.hashTag = this.selected.hashTag;
         this.getTopic(()=>{tb.innerHTML = unescape(this.topic.value);});
         this.refreshImageSrc = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAACBElEQVQ4T42Tz0tUYRSGn3PHIl31B9S6FirVIiEwSIJob4FzR+YOCpmoFM3Y0n5synsXmUiJxNyRmSmSVhEtCgRrUe0qaBXUPhCSHEzzvnGvY+hI4lmec77nfO/3vcdoiJ5rxaOO4wyadLwceJ1x2R0tdSPNNfaC+mwzeeHq0+b9+2p3EMNAnP9Z8b2DG4DiALIHDYCxiu/dSgDeWPHAWs2eA2eTJmPBICyPe8X/AL6vtrQcmbt5cTUBpPPhpBlDwKrQpaqfC7dOa7jBOpACpiq+N2Tu9WIbkX1KDkhuJchVG7VuAiS7YQ4/kKaAKHJoN7dQug8aFryq+tlzYGoEZEbDHJEOlYPcbZC5+dm3mE5h3DO3EMbT2yT6q4H3aOdL78yk8+GIGRPAlxiwDLSYs36sfLfv414AvfnZjsiid4hfMaAGNMusozqe/bAXQKZQ7BS2sAn4DLRK6qkGuSd7A5T6hWYSCVu+cL7ie117AbiF8D1wUmjCevKldseUaDfoLvves90gmcK/6XLktCdGcgthbNMBYM1Ephxk53Z+pyw9WkqbiE3WBDys+N7lBJDsQVPtBXCmPv2NjOlU5HyV/VmD1AmZehGn6/X5xZWl8y8nR35vX6bUcoDZ4C4SYpNNL64sXYkP12Vvb+/Nz7bKokFhXaDDBinBN4nXwmYeB9kN29fjL94T4mitWIrxAAAAAElFTkSuQmCC";
     }

     setTopic() {
         var tb = document.getElementById("textBox");
         this.topic.value = escape(tb.innerHTML);
     }
}
