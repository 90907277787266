import { Component, OnInit } from '@angular/core';
import {TagService} from '../services/tag-service.service';
import {TopicService} from '../services/topic.service';
import {Tag} from '../admin/tag/model/tag';
import {User} from '../admin/user/model/user';
import {Topic} from '../admin/topic/model/topic';
import {interval, Observable, throwError} from 'rxjs';
import {HostListener} from '@angular/core';
import {ActivatedRoute, Router } from '@angular/router';
import {UserService} from '../services/user.service';
import {Login} from '../services/user.service';
import { config } from '../config';
import {RouterModule} from '@angular/router';
import { HeaderComponent } from '../header/header.component';
import { FooterComponent } from '../footer/footer.component';
import { FormsModule } from '@angular/forms';

@HostListener('window:keydown', ['$event'])
@Component({
	host: {
    '(document:keydown)': 'keyDown($event)',
	},
	selector: 'app-chat',
	templateUrl: './chat.component.html',
	styleUrls: ['./chat.component.css'],
  imports: [FormsModule, RouterModule, FooterComponent, HeaderComponent],
  standalone: true
})

export class ChatComponent implements OnInit {
	tags: Tag[];
	users: User[];
  public topic = new Topic('', '', '');
  topics: Topic[];
  utags: any;
	public model = new Tag('', '', '', '', '');
	public umodel = new Tag('', '', '', '', '');
	public selectedTag;
	selectedIndex = 0;
	message = "";
	searchText = "";
	currentPage = 0;
	pageSize = 10;
	pageDirection = -1;
	totalCount = 0;
	pageCount = 0;
	sortKey = "insertdate";
	selectAll = false;
	public theUser = "";
 public theGoldiUser = "";
	theHashTag= "";
  sub: any;
  private user = new User("", "", "");


	constructor(private userService: UserService, private tagService: TagService, private topicService: TopicService, private activatedRoute: ActivatedRoute, public userSVC: UserService, private router: Router) { }

	ngOnInit() {

   this.sub = interval(5000)
   .subscribe((val) => {

                    this.model = new Tag(this.model.hashTag, '', '', '', this.model.id);
          					this.setFirstPage();
   });

  		this.theUser = this.userSVC.loggedInUser.email;
  		/*
  	if (!this.theUser) {
  		this.router.navigate(['']);
  	}
*/
		this.activatedRoute.queryParams.subscribe(params => {
      this.searchText == ""

			this.theHashTag = params['h'] || "";
			this.model.hashTag = params['h'] || "";
      this.topic.hashTag = this.model.hashTag;
			if(this.model.hashTag != "") {
				this.setFirstPage();
			}
		});
	}

  selectedText: string = '';

  showSelectedText(oField: any) {
    var text = "";
    if (window.getSelection) {
        text = window.getSelection().toString();
        //    } else if (document.selection && document.selection.type != "Control") {
        //text = document.selection.createRange().text;
    }
    this.selectedText = text;
  }

	loadTags(callback: () => any) {
		if (this.searchText == "") {
		 this.tagService.filterTags(this.model.hashTag, this.model.source, this.model.value, this.sortKey, this.theUser)
				   .subscribe(
				      (tags: any) => {
							this.tags = tags; //Bind to view
							if(this.tags !== undefined) {
								this.totalCount = this.tags.length;
								this.pageCount = Math.ceil(this.totalCount / this.pageSize);
								callback();
								this.setPageVisible();
							}
					},
					 (err: any) => {
					    console.log(err);
					});
		} else {
		 this.model = new Tag('', '', '', '', '');
		 this.tagService.searchTags(this.searchText, this.sortKey, this.theUser)
				   .subscribe(
				      (tags: any) => {
							this.tags = tags; //Bind to view
							if(this.tags !== undefined) {
								this.totalCount = this.tags.length;
								this.pageCount = Math.ceil(this.totalCount / this.pageSize);
								callback();
								this.setPageVisible();
							}
					},
					 (err: any) => {
					    console.log(err);
					});
		}
	}

	getTopicUserTags(callback: () => any) {
		 this.tagService.getTopicUserTags(this.model.hashTag, this.theUser)
				   .subscribe(
				      (tags: any) => {
							this.tags = tags; //Bind to view
							if(this.tags !== undefined) {
								this.totalCount = this.tags.length;
								this.pageCount = Math.ceil(this.totalCount / this.pageSize);
								callback();
								this.setPageVisible();
							}
					},
					 (err: any) => {
					    console.log(err);
					});
	}

   loadTopic() {
      this.topicService.filterTopics(this.model.hashTag, "", "", "", this.theUser)
            .subscribe(
                (topics: any) => {
               this.topics = topics; //Bind to view
               if(this.topics !== undefined) {
                  this.topic = this.topics[0];
               }
           },
            (err: any) => {
               console.log(err);
           });
     }


	getUserByEmail() {
		 this.userService.getUserByEmail(this.theUser)
				   .subscribe(
				       (user: any) => {
                if(user!=undefined){
							this.user = user;
              }
					},
					 (err: any) => {
					    console.log(err);
					});
		}

	submitTag(){
  	let commentOperation:Observable<Tag[]>;
    var tb = document.getElementById("textBox");
    this.model.value = tb.innerText;
    if (this.model.value == "" )
		{
			return;
		}

		this.model.email = this.theUser;
		this.model.source = this.theUser;
		this.model.hashTag = this.model.hashTag;
		this.tagService.addTag(this.model).subscribe(
					() => {
                    this.model = new Tag(this.model.hashTag, '', '', '', this.model.id);
          					this.setFirstPage();
					},
					 (err: any) => {
						console.log(err);
					});
	}

	updateTag(tag: Tag){
		let commentOperation:Observable<Tag[]>;
		//console.log(tag);
		this.tagService.updateTag(tag).subscribe(
				() => {
        //				this.model = new Tag('', '', '', '', '');
        //	this.setFirstPage();
				},
				 (err: any) => {
					console.log(err);
				});
	}

	deleteTag(tag: any){
		let commentOperation:Observable<Tag[]>;
		if(confirm("Are you sure to delete this selected item from '#" + tag.hashTag + "'?")) {
			this.tagService.deleteTag(tag.ID).subscribe(
					() => {
						this.model = new Tag('', '', '', '', '');
						this.setFirstPage();
					},
					 (err: any) => {
					    	console.log(err);
					});
		}
	    }

	public selectTag( tag: any, i: number) {
		tag.rank = i + 1;
		this.selectedIndex = i;
		this.selectedTag =  tag;
		this.setMessage(this.selectedTag);
	}

	public setMessage(tag: any) {
		//this.message = " { \"rank\" : \"" + tag.rank + "\", \"loc\" : { \"x\" : \"" + tag.loc.x + "\", \"y\" : \"" + tag.loc.y + "\" }, \"tag\" : \"" + tag.hashTag + "\", \"source\" : \"" +  tag.source + "\",  \"value\" : \"" + tag.value + "\", \"timestamp\" : \""+tag.insertDate +"\" } ";
		this.message = " { \"rank\" : \"" + tag.rank + "\", \"loc\" : { \"x\" : \"47.60\", \"y\" : \"122.33\" }, \"tag\" : \"" + tag.hashTag + "\", \"source\" : \"" +  tag.source + "\",  \"value\" : \"" + tag.value + "\", \"timestamp\" : \""+tag.insertDate +"\" } ";
	}

	keyDown(event: any) {
		switch (event.keyCode) {
			case 38:
				this.onUp();
				event.preventDefault();
				break;
			case 40:
				this.onDown()
				event.preventDefault();
				break;
			case 13:
      //this.setFirstPage()
       this.submitTag();
      	event.preventDefault();
				break;
		}
		return;
	}

	public onDown() {
		if(this.selectedIndex < this.tags.length - 1)
		{
			this.selectedTag = this.tags[++this.selectedIndex];
			this.setMessage(this.selectedTag);
		}
	}

	public onUp() {
		if(this.selectedIndex > 0)
		{
			this.selectedTag = this.tags[--this.selectedIndex];
			this.setMessage(this.selectedTag);
		}
	}

	substringOf(source: string) {
		var i1 = source.indexOf( "https://" );
		var si = 8;
		if (i1 < 0)
		{
			i1 =  source.indexOf( "http://" );
			si = 7;
		}

		if(i1 >= 0)
		{
			var i2 = source.indexOf(  "/", i1 + si );
			if(i2 > 0)
			{
				var s = source.substring(i1 + si, i2);
				return s;
			}
		}
		return source;
	}

	editTag(tag: any) {
		//this.umodel.edit =  !this.umodel.edit;
		this.umodel = new Tag(tag.hashTag, tag.userId, tag.source, tag.value, tag.topicId);
		tag.edit = !tag.edit;
	}

	cancelUpdate(tag: Tag) {
		tag.hashTag = this.umodel.hashTag;
		tag.source = this.umodel.source;
		tag.value = this.umodel.value;
		tag.insertDate = this.umodel.insertDate;
		tag.edit = false;
	}

  refIndex(source: string): number {
    return this.utags.indexOf(source) + 1;
  }

	//pagination for table
	public setFirstPage() {
		this.pageDirection = 0;
		this.getTopicUserTags(() => {
			this.currentPage = 1;
		});

	  this.getUserByEmail();
	  this.loadTopic();
	}

	setPreviousPage() {
		this.pageDirection = 1;
		    this.loadTags(() => {
			if (this.currentPage > 1) {
			    this.currentPage--;
			}
		 });
	}

	setNextPage() {
		this.pageDirection = 2;
		this.loadTags(() => {
		    if (this.currentPage < this.pageCount) {
			this.currentPage++;
		    }
		});
	}

	setLastPage () {
		this.pageDirection = 3;
		this.loadTags( () => {
			this.currentPage = this.pageCount;
		});
	}

	setPageVisible() {
		this.selectedIndex = (this.currentPage - 1) * this.pageSize;
		var end = this.currentPage * this.pageSize
		for (let i = 0; i < this.tags.length; i++) {
			this.tags[i].show = false;
			if(i >= this.selectedIndex && i < end) {
				this.tags[i].show = true;
			}
		}
		this.selectedTag = this.tags[this.selectedIndex];
		if (this.selectedTag) {
			this.selectedTag.rank = this.selectedIndex + 1;
			this.setMessage(this.selectedTag);
		}
	}

	setMe(element: any) {
		var s_height = element.scrollHeight;
		element.setAttribute('style','height:'+s_height+'px');
	}

	selectAllTags() {
		for (let i = 0; i < this.tags.length; i++) {
			if(!this.selectAll) {
	                        this.tags[i].checked = true;
			}
			else {
	                        this.tags[i].checked = false;
			}

                }
	}

	search() {
//		if(this.loggedInUser === undefined) {
			 this.setFirstPage();
//		} else {
//			this.router.navigate(['admin']);
//		}

	}

  editDoc(tag: any) {
     this.router.navigate(['/admin/edit'],  { queryParams: { h : this.theHashTag}});
   }

  editLatestDoc(tag: any) {
     this.router.navigate(['/admin/editlatest'],  { queryParams: { h : this.theHashTag}});
   }

   save() {
   //let elem: Element = document.getElementById("textbox")
   this.topic.value = document.getElementById("textbox").innerHTML; //Text;
     this.topic.email = this.theUser;
     this.topicService.addTopic(this.topic).subscribe(
           () => {
           },
            (err: any) => {
             console.log(err);
             });
  }

  directTopic(email: any) {
  var a = [email, this.theUser];
  a.sort()
             var t = new Topic('', '', '');
             t.users = a;
             t.hashTag = a[0]+"-"+a[1];
             t.email = this.theUser
  this.topicService.addTopic(t).subscribe(
           () => {
           },
            (err: any) => {
             console.log(err);
           });
     this.router.navigate(['/admin/chat'],  { queryParams: { h : t.hashTag, d : this.theUser}});
   }
}

