import { Component, OnInit } from '@angular/core';
import {UserService} from '../../services/user.service';
import {Login} from '../../services/user.service';
import {ActivatedRoute, Router } from '@angular/router';
import {CookieService} from 'ng2-cookies';
import {User} from '../user/model/user';
import {TinyService} from '../../services/tiny.service';
import {Tiny} from '../tiny/model/tiny';
import {IdService} from '../../services/id.service';
import {DataService } from "../../services/data.service";
import { AdminAlertComponent } from '../adminAlert/admin-alert.component';
import { AdminAlertService } from '../adminAlert/services/admin-alert.service';
import { AlertTypeEnum } from '../adminAlert/types/alertType.enum';
import {TopicService} from '../../services/topic.service';
import { config } from '../../config';
import {RouterModule} from '@angular/router';
import { AdminHeaderComponent } from '../adminHeader/admin-header.component';
import { AdminFooterComponent } from '../adminFooter/admin-footer.component';
import { FormsModule } from '@angular/forms';
import {Topic} from '../topic/model/topic';

@Component({
  host: {
    '(document:keydown)': 'keyDown($event)',
  },

  templateUrl: './qrcode.component.html',
  styleUrls: ['./qrcode.component.css'],
  imports: [AdminAlertComponent, FormsModule, RouterModule, AdminFooterComponent, AdminHeaderComponent],
  standalone: true
})

export class QrCodeComponent implements OnInit {
  public theUser = "";
 public theGoldiUser = "";
  public user = new User('', '', '');
  public topic = new Topic('', '', '');
  qrcode: any;
  url: string = "";
  tinUrl = config.slqioUrl;
 public taxonomy = "";
 public class = "";
 public subclass = "";

  constructor(private topicService: TopicService, private activatedRoute: ActivatedRoute, public userSVC: UserService, private router: Router, private loginInfo: Login, private _cookieService:CookieService, private tinyService: TinyService, private idService: IdService, private data: DataService, private alertService: AdminAlertService){
  }

  showAlert(type: AlertTypeEnum, msg: string) {
    this.alertService.setAlert({
      type,
      text: msg,
    });
  }

  ngOnInit() {
    this.theUser = this.getCookie("email");
  this.theGoldiUser = this.getCookie("gmt");
    this.taxonomy = config.taxonomy;
    this.class = config.class;
    this.subclass = config.subclass;
    this.data.changeMessage(this.url + " " + this.curbemail(this.theUser));
    this.userSVC.getUserByEmail(this.theUser)
      .subscribe(
        (user: any) => {
          if (user) {
            this.user = user; //Bind to view
          }
        },
         (err: any) => {
          console.log(err);
        });
  }

  curbemail(email: string): string {
    if(!email) {
      return email;
    }

    var index = email.indexOf("@");
    if(index > 0) {
      return email.substring(0, index);
    }
    return email;
  }

  done() {
    this.router.navigate(['/admin/account']);
  }

 public keyDown(event: any) {
    switch (event.keyCode) {
      case 13:
        this.setQrCode()
        event.preventDefault();
        break;
    }
    return;
  }

  resetPwd() {
    this.router.navigate(['/admin/reset']);
  }

  getCookie(key: string){
    return this._cookieService.get(key);
  }

  id(callback: (id: string) => any) {
    this.idService.id()
      .subscribe(
        (id: any) => {
          if(id!=undefined){
            callback(id);
          }
        },
         (err: any) => {
          console.log(err);
        });
  }

  tinyUrl(tiny: Tiny, callback: () => any) {
    this.tinyService.tinyUrl(tiny)
      .subscribe(
        (tiny: any) => {
          if(tiny!=undefined){
            callback();
          }
        },
         (err: any) => {
          console.log(err);
        });
  }

  qrCode(tinUrl: string, callback: (qrcode: string) => any) {
      this.topic.value = tinUrl;
      this.topicService.qrCode(this.topic)
        .subscribe(
          qrcode => {
            if(qrcode!=undefined){
              callback(qrcode);
            }
          },
           (err: any) => {
            console.log(err);
          });
    }

    setQrCode() {
      this.id((id: string) => {
        let t = new Tiny('', '', '');
        t.hashTag = id.replace(/^"(.*)"$/, '$1');
        t.email = this.theUser;
        t.value = this.url;
        this.tinyUrl(t, () => {
            this.qrCode(this.tinUrl+t.hashTag, (qrcode: string)=> {
              this.qrcode = `data:image/png;base64,`+qrcode.replace('\"','').replace('\"','');
              var myImg = document.getElementById('qrcode');
              myImg.setAttribute('src', this.qrcode);
            });
         });
      });
    }
}
