import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import {TopicService} from '../../services/topic.service';
import {Topic} from '../topic/model/topic';
import {TagService} from '../../services/tag-service.service';
import {Tag} from '../tag/model/tag';
import {Observable, throwError} from 'rxjs';
import {HostListener} from '@angular/core';
import {ActivatedRoute, Router } from '@angular/router';
import {UserService} from '../../services/user.service';
import {Login} from '../../services/user.service';
import {CookieService} from 'ng2-cookies';
import { AdminAlertComponent } from '../adminAlert/admin-alert.component';
import { AdminAlertService } from '../adminAlert/services/admin-alert.service';
import { AlertTypeEnum } from '../adminAlert/types/alertType.enum';
import { ModalService } from '../../modal/services/modal.service';
import { ModalComponent } from '../../modal/modal.component';
import { config } from '../../config';
import {RouterModule} from '@angular/router';
import { DummyHeaderComponent } from '../dummyHeader/dummy-header.component';
import { DummyFooterComponent } from '../dummyFooter/dummy-footer.component';
import { FormsModule } from '@angular/forms';
declare function escape(s:string): string;
declare function unescape(s:string): string;

@Component({
  selector: 'app-sqb',
  templateUrl: './sqb.component.html',
  styleUrls: ['./sqb.component.css'],
  imports: [AdminAlertComponent, FormsModule, RouterModule, DummyFooterComponent, DummyHeaderComponent],
  standalone: true
})

export class SqbComponent implements OnInit {

  tags: Tag[]
  tagse: Tag[]
  public model = new Topic('', '', '');
  public topic = new Topic('', '', '');
  public umodel = new Topic('', '', '');
  private branch = new Topic('', '', '');
  public selectedTopic;
  selectedIndex = 0;
  selectAll = false;
  public theUser = "";
 public theGoldiUser = "";
  currentFile:File;
  options = 4;
  answerFinalized = false;
  once = false;
 public taxonomy = "";
 public class = "";
 public subclass = "";

  @ViewChild('modalTemplate', {read: TemplateRef}) modalTemplate: TemplateRef<any>;

  constructor(private alertService: AdminAlertService, private modalService: ModalService, private tagService: TagService, private topicService: TopicService, private activatedRoute: ActivatedRoute, public userSVC: UserService, private router: Router, private _cookieService:CookieService){}

  getCookie(key: string){
    return this._cookieService.get(key);
  }

  showAlert(type: AlertTypeEnum, msg: string) {
    this.alertService.setAlert({
      type,
      text: msg,
    });
  }

  openModal(modalTemplate: TemplateRef<any>, size: string, title: string, message: string, callback: () => any) {
    this.modalService.open(modalTemplate, { size: size, title: title, message: message })
      .subscribe((action) => { callback(); });
  }

  ngOnInit() {
    this.theUser = this.getCookie("email");
  this.theGoldiUser = this.getCookie("gmt");
    this.taxonomy = config.taxonomy;
    this.class = config.class;
    this.subclass = config.subclass;
    this.activatedRoute.queryParams.subscribe(params => {
      this.model.hashTag = unescape(params['h']) || "";
    });

    this.getTopic(escape(this.model.hashTag), this.theUser, (t: any) => {
      this.topic = t;
      this.showAlert(AlertTypeEnum['warning'], "Please <b>Exclude Special Characters</b> from uploading to SQB");
    });
  }

 public keyDown(event: any) {
    switch (event.keyCode) {
      case 13:
            if(event.shiftKey) {
              this.submitSqb();
              event.preventDefault();
            }
            event.stopPropagation();
            break;
    }
    return;
  }

  docTopic(t: any) {
    this.router.navigate(['/admin/doc'],  { queryParams: { h : t}});
  }

  getTopic(h: string, e: string, callback: (t: any) => any) {
    this.topicService.getTopic(h, e).subscribe(
      (data: any) => {
        callback(data);
      },
       (err: any) => {
        console.log(err);
      });
  }

submitSqb() {
    let commentOperation:Observable<Tag[]>;
    this.showAlert(AlertTypeEnum['info'], "Please paste or upload SQB!");
    if (this.model.value.trim() === "" ) {
      return;
    }

    this.tags = [];
    this.tagse = [];
    var ltag = this.model.value.trim().replace(/[^\w\s]/gi, ' ').replace(/\r?\n|\r/g, "\n");
    //this.model.value = "";
    var tags = ltag.split(/\n/g)
    for(var i = 0; i < tags.length; i++) {
        tags[i] = tags[i].trim();
        if(tags[i].length <= 0 || tags[i] === " ") {
          tags.splice(i, 1);
        }
    }

    if (tags.length <= 0 && !this.model.hashTag) {
      return;
    }

    if (this.currentFile.name.indexOf(".slq") < 1) {
        this.showAlert(AlertTypeEnum['danger'], "This is not a Slq file type!");
        return;
    }

    if (this.answerFinalized) {
      ltag = this.model.value.trim().replace(/\r?\n|\r/g, "\n");
      this.model.value = "";
      tags = ltag.split(/\n/g)
      for(var i = 0; i < tags.length; i++) {
          tags[i] = tags[i].trim();
          if(tags[i].length <= 0 || tags[i] === " ") {
            tags.splice(i, 1);
          }
      }
      return this.submitAnswerFinalized(tags);
    }

   this.openModal(this.modalTemplate, "md", "Upload: Upload SQB", "Do you want to upload this <b> "+ this.currentFile +" file to SQB?", () => {
       this.showAlert(AlertTypeEnum['info'], "Please hold on while we are uploading this *.slq file to SQB");
       document.getElementById("loading").style.display = "inline";
        var t = new Tag('', '', '', '', '');
        t.hashTag =  this.model.hashTag.trim();
        t.name = this.model.hashTag.trim();
        t.email = this.theUser;
        t.source = this.theUser;
        t.value = "";
        this.addTopicTag(t, (tt: Tag)=>{
            var n = "";
            var j = 0;
            var options = this.options +  1;
            var i = 0;
              for(; i < tags.length; i++) {
                if(tags[i].length > 0) {
                  var t = new Tag('', '', '', '', '');
                    if(j%options == 0) {
                      n = tags[i].trim();
                    } else {
                      t.value = tags[i].trim();
                      t.name = n;
                      this.addTag(t, tt);
                    }
                }
                j++;
              }
              this.addTags(this.tags);
        });
    });
  }

  submitAnswerFinalized(tags: string[]){
    this.openModal(this.modalTemplate, "md", "Upload: Upload SQB", "Do you want to upload this <b> '"+ this.currentFile.name +"' file to SQB?", () => {
      this.showAlert(AlertTypeEnum['info'], "Please hold on while we are uploading this *.slq file to SQB");
      document.getElementById("loading").style.display = "inline";
      var t = new Tag('', '', '', '', '');
      t.hashTag = escape(this.model.hashTag.trim()+"_exam_finalized");
      t.name = t.hashTag.trim()+"_exam_finalized";
      t.users = [];
      t.users.push(this.theUser);
      t.users.push('finalized.imoqr@siloq.com');
      t.email = this.theUser;
      t.source = this.theUser;
      t.value = "";
      this.addTopicTag(t, (tt: Tag)=>{
        var n = tags[0];
          var i = 1;
          for(; i < tags.length; i++) {
            if(tags[i].length > 0) {
              var t = new Tag('', '', '', '', '');
               if(tags[i-1].startsWith("Ans")) {
                  n = tags[i].trim();
               } else {
                 t.value = tags[i].trim();
                 t.name = n;
                 this.addTag(t, tt);
               }
             }
           }
         this.addTags(this.tags);
      });
      //
     t = new Tag('', '', '', '', '');
     t.hashTag = escape(this.model.hashTag.trim()+"_exam_conduct");
     t.name = this.model.hashTag.trim()+"_exam_conduct";
     t.users = [];
     t.users.push(this.theUser);
     t.users.push('conduct.imoqr@siloq.com');
     t.email = this.theUser;
     t.source = this.theUser;
     t.value = "";
     this.addTopicTag(t, (tt: Tag)=>{
             var n = tags[0].trim();
               var i = 1;
               for(; i < tags.length; i++) {
                 if(tags[i].trim().length > 0) {
                   var t = new Tag('', '', '', '', '');
                    if(tags[i].trim().startsWith("Ans")) {
                    i++;
                    for(; i < tags.length;i++) {
                       if(tags[i].trim().length > 0) {
                         n = tags[i].trim();
                         break;
                       }
                     }
                   } else {
                     t.value = tags[i].trim();
                     t.name = n;
                     this.addTage(t, tt);
                   }
                 }
                }
             this.addTags(this.tagse);
           });
    });
}
  addTag(t: Tag, tt: Tag) {
    t.hashTag = tt.hashTag;
    t.topicId = tt.topicId;
    t.users = tt.users;
    t.email = this.theUser;
    t.source = this.theUser;
    t.name = escape(t.name);
    t.value = escape(t.value);
    this.tags.push(t);
  }

  addTage(t: Tag, tt: Tag) {
    t.hashTag = tt.hashTag;
    t.topicId = tt.topicId;
    t.users = tt.users;
    t.email = this.theUser;
    t.source = this.theUser;
    t.name = escape(t.name);
    t.value = escape(t.value);
    this.tagse.push(t);
  }

  addTags(tags: Tag[]) {
    this.tagService.addTags(tags).subscribe(
     (t: any) => {
         this.model.value = "";
         document.getElementById("loading").style.display = "none";
         this.router.navigate(['/admin/doc'],  { queryParams: { h : t.hashTag}});
      },
       (err: any) => {
        console.log(err);
      });
  }

  addTopicTag(tag: Tag, callback: (tt: Tag) => any) {
       this.tagService.addTag(tag).subscribe(
        (tt: any) => {
                callback(tt);
         },
          (err: any) => {
           console.log(err);
         });
     }

   fileChangeEvent(fileInput:any){
     this.currentFile = fileInput.target.files[0];
     let reader = new FileReader();
     reader.onload = () => {
       this.model.value = reader.result as string;
     };
     reader.readAsText(this.currentFile);
   }

   fileChangeEventToo(fileInput:any){
        this.showAlert(AlertTypeEnum['info'], "Please hold on while we are uploading this *.slq file to SQB");
        document.getElementById("loading").style.display = "inline";
        this.currentFile = fileInput.target.files[0];
        let reader = new FileReader();
        reader.onload = () => {
          var tags = JSON.parse(reader.result as string);
          if(tags.length > 0) {
            this.getTopic(escape(this.model.hashTag), this.theUser, (t: any) => {
                  if(!t.hashTag){
                   for (let i = 0; i < tags.length; i++) {
                      var ht = this.currentFile.name.split(".slq")[0]
                      tags[i].hashTag = escape(ht);
                      tags[i].email = this.theUser;
                    }
                   this.addTags(tags);
                  }
            });
           }
        };
        reader.readAsText(this.currentFile);
  }

  notify() {
      alert("Coming soon!");
    }
}
