import { Component, OnInit } from '@angular/core';
import {UserService} from '../../services/user.service';
import {Login} from '../../services/user.service';
import {ActivatedRoute, Router } from '@angular/router';
import {CookieService} from 'ng2-cookies';
import {User} from '../user/model/user';
import {TinyService} from '../../services/tiny.service';
import {Tiny} from '../tiny/model/tiny';
import {IdService} from '../../services/id.service';
import {DataService } from "../../services/data.service";
import { AdminAlertComponent } from '../adminAlert/admin-alert.component';
import { AdminAlertService } from '../adminAlert/services/admin-alert.service';
import { AlertTypeEnum } from '../adminAlert/types/alertType.enum';
import { config } from '../../config';
import {RouterModule} from '@angular/router';
import { AdminHeaderComponent } from '../adminHeader/admin-header.component';
import { AdminFooterComponent } from '../adminFooter/admin-footer.component';
import { FormsModule } from '@angular/forms';
@Component({
  host: {
    '(document:keydown)': 'keyDown($event)',
  },

  templateUrl: './tiny.component.html',
  styleUrls: ['./tiny.component.css'],
  imports: [AdminAlertComponent, FormsModule, RouterModule, AdminFooterComponent, AdminHeaderComponent],
  standalone: true
})

export class TinyComponent implements OnInit {
  public theUser = "";
 public theGoldiUser = "";
  url: string = "";
  public user = new User('', '', '');
  tinUrl = config.slqioUrl;
 public taxonomy = "";
 public class = "";
 public subclass = "";

  constructor(private activatedRoute: ActivatedRoute, public userSVC: UserService, private router: Router, private loginInfo: Login, private _cookieService:CookieService, private tinyService: TinyService, private idService: IdService, private data: DataService, private alertService: AdminAlertService){
  }

  showAlert(type: AlertTypeEnum, msg: string) {
    this.alertService.setAlert({
      type,
      text: msg,
    });
  }

  ngOnInit() {
    this.theUser = this.getCookie("email");
  this.theGoldiUser = this.getCookie("gmt");
    this.taxonomy = config.taxonomy;
    this.class = config.class;
    this.subclass = config.subclass;
    this.data.changeMessage(this.url + " " + this.curbemail(this.theUser));
    this.userSVC.getUserByEmail(this.theUser)
      .subscribe(
        (user: any) => {
          if (user) {
            this.user = user; //Bind to view
          }
        },
         (err: any) => {
          console.log(err);
        });
  }

  curbemail(email: string): string {
    if(!email) {
      return email;
    }

    var index = email.indexOf("@");
    if(index > 0) {
      return email.substring(0, index);
    }
    return email;
  }

  id(callback: (id: string) => any) {
    this.idService.id()
      .subscribe(
        (id: any) => {
          if(id!=undefined){
            callback(id);
          }
        },
         (err: any) => {
          console.log(err);
        });
  }

  tinyUrl(tiny: Tiny) {
    this.tinyService.tinyUrl(tiny)
      .subscribe(
        (tiny: any) => {
          if(tiny!=undefined){
            ;
          }
        },
         (err: any) => {
          console.log(err);
        });
  }

  tiny() {
    if (this.url !== "") {
      this.copyTinyUrlToClipboard();
      this.showAlert(AlertTypeEnum['success'], "Tiny URL is copied to the clipboard, use it anywhere!");
      return;
    }
    this.showAlert(AlertTypeEnum['danger'], "The tiny url value can not be empty!");
    return;
  }

  refer() {
    if (this.url !== "") {
      this.copyTinyUrlToClipboard();
      this.showAlert(AlertTypeEnum['success'], "Referral URL is copied to the clipboard, please send it to prospects!");
      return;
    }
    this.showAlert(AlertTypeEnum['danger'], "The referral url value can not be empty!");
    return;
  }

  public copyTinyUrlToClipboard() {
    this.id((id: string) => {
    let t = new Tiny('', '', '');
    t.hashTag = id.replace(/^"(.*)"$/, '$1');
    t.email = this.theUser;
    t.value = this.url;
    this.tinyUrl(t);

    var dummy = document.createElement('input');
    document.body.appendChild(dummy);
    dummy.value = this.tinUrl+t.hashTag;
    dummy.select();
    document.execCommand('copy');
    document.body.removeChild(dummy);
    this.url = "";
    });
}
  done() {
    this.router.navigate(['/admin/account']);
  }

 public keyDown(event: any) {
    switch (event.keyCode) {
      case 13:
        this.tiny()
        event.preventDefault();
        break;
    }
    return;
  }

  resetPwd() {
    this.router.navigate(['/admin/reset']);
  }

  getCookie(key: string){
    return this._cookieService.get(key);
  }
}
