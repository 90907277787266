<header></header>
<div style="text-align:center; height:100%; width:100%;padding-top:70px;">
  <br>
<div class="row expanded align-center" style="text-align: center; margin: auto;">
<h2 class="block_container siloqlogofont" style="min-width:320px;">Direct Message to <a href="/" target="_self"> <span
   style="color:dodgerblue;">{{taxonomy}}</span><span
  class="sup"
  style="color:mediumseagreen;">&trade;</span>

  </a> </h2>
  <alert></alert>
  <br>
</div>
	<br>
	<form>
		<div class="small-12 columns">
      <input type="text" class="siloqinput" placeholder="Email"  id="email" [(ngModel)]="email" name="email" required #em="ngModel" style="min-width:300px;"/>
		</div>
		<br>
    <div class="small-12 columns">
      <button [disabled]="emailVerified === true" (click)="doRegister();" type="button" class="siloqbutton">Email OTP Code</button>
    </div>
    <br>
    <br>
    <div class="small-12 columns">
      <input [type]="passwordType" autocomplete="current-password" class="siloqinput" placeholder="Password" id="passwd"  [(ngModel)]="passwd" name="passwd" required #pwd1="ngModel" style="min-width:300px;margin-left:20px;">&nbsp;<a (click)="togglePasswordVisibility()" class="fa fa-eye"></a>
    </div>
    <br>
		<div class="small-12 columns">
			<div contenteditable="true" style="height:140px;min-height:100px;min-width:300px;" class="siloqinput" data-text="Short Description *" id="value"> </div>
    </div>
		<br>
    <div class="small-12 columns">
       <button [disabled]="otpVerified === true"  (click)="submitOtp()" type="button" class="siloqbutton" style="color:dodgerblue;">Submit</button>
     </div>
     <br>
    <br>
    <br>
    <br>
    <br>
    <br>
	</form>
</div>
<footer></footer>
