import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import {Title} from '@angular/platform-browser';
import {TagService} from '../../services/tag-service.service';
import {TopicService} from '../../services/topic.service';
import {Tag} from '../tag/model/tag';
import {Topic} from '../topic/model/topic';
import {Observable, throwError} from 'rxjs';
import {HostListener} from '@angular/core';
import {ActivatedRoute, Router } from '@angular/router';
import {UserService} from '../../services/user.service';
import {Login} from '../../services/user.service';
import {CookieService} from 'ng2-cookies';
import {DataService } from "../../services/data.service";
import { AdminAlertComponent } from '../adminAlert/admin-alert.component';
import { AdminAlertService } from '../adminAlert/services/admin-alert.service';
import { AlertTypeEnum } from '../adminAlert/types/alertType.enum';
import { ModalService } from '../../modal/services/modal.service';
import { Subscription } from 'rxjs';
import { config } from '../../config';
import {RouterModule} from '@angular/router';
import { AdminHeaderComponent } from '../adminHeader/admin-header.component';
import { DocFooterComponent } from '../docFooter/doc-footer.component';
import { FormsModule } from '@angular/forms';
import {User} from '../user/model/user';
import { CommonModule } from '@angular/common';

declare function escape(s:string): string;
declare function unescape(s:string): string;
declare function testJs(): any;

@Component({
  host: {
    '(document:keydown)': 'keyDown($event)',
    //'(dblclick)': 'ignore($event)',
  },
  selector: 'app-exam',
  templateUrl: './mock.component.html',
  styleUrls: ['./mock.component.css'],
  imports: [AdminAlertComponent, FormsModule, RouterModule, DocFooterComponent, AdminHeaderComponent, CommonModule],
  standalone: true
})

export class MockComponent implements OnInit {
@HostListener('window:keydown', ['$event'])
//@HostListener('dblclick', ['$event'])

  tags: Tag[] = [];
  public topic  = new Topic('', '', '');
  public model = new Tag('', '', '', '', '');
  public umodel = new Tag('', '', '', '', '');
  public ttopic = new Topic("","","");
  @ViewChild('modalTemplate', {read: TemplateRef}) modalTemplate: TemplateRef<any>;
  public selectedSlide;
  public selectedTag;
  public classTag = "Not Selected Yet";
  selectedTagIndex = -1;
  selectedSlideIndex = -1;
  browseTagIndex = -1;
  browseSlideIndex = -1;
  selectAll = false;
  editable = false;
  public theUser = "";
  theUserId= "";
  public theGoldiUser = "";
  currentPage: number = 1;
  pageSize = 1;
  totalCount: number = 0;
  pageCount: number = 1;
  host = window.location.protocol+"//"+window.location.hostname;
  public slides = [];
  public subtitles: string[] = [];
  public printable: boolean = false;
  loq = "";
  subscription: Subscription;
  random: number = 5;
  public scheduled: boolean = false;
  public clicked: boolean = false;

  public taxonomy = "";
  public pageTitle = "";
  public class = "";
  public subclass = "";
  public responsePageSize = 0;
  public goldimembersMonthlyPrice = 0;
  public startTime = "";
  public timeDuration = 0;
  public rightQuestionReward = 4;
  public wrongQuestionPenalty = 1;
  public audioEnabled  = false;
  public playing: boolean = false;
  public intervalId: any;
  public timeoutId: any;
  public timeoutIdQ: any;
  public goldimembersEnabled: boolean = false;
  public rentPrice = 0.0;
  public buyPrice = 0.0;
  public siloqpayUrl = "";
  public currency = "USD"
  public userEx = new User("", "", "");

  constructor(private titleService: Title, private tagService: TagService, private topicService: TopicService, private activatedRoute: ActivatedRoute, public userSVC: UserService, private router: Router, private _cookieService:CookieService, private data: DataService, private alertService: AdminAlertService, private modalService: ModalService){}

  showAlert(type: AlertTypeEnum, msg: string) {
    this.alertService.setAlert({
      type,
      text: msg,
    });
  }

  openModal(modalTemplate: TemplateRef<any>, size: string, title: string, message: string, callback: () => any) {
    this.modalService.open(modalTemplate, { size: size, title: title, message: message })
      .subscribe((action) => { callback(); });
  }

  getCookie(key: string){
    return this._cookieService.get(key);
  }

  ngOnInit() {
    this.theUser = this.getCookie("email");
    this.theUserId = this.getCookie("uid");
    this.theGoldiUser = this.getCookie("gmt");
    this.activatedRoute.queryParams.subscribe(params => {
      this.model.hashTag = unescape(params['h']) || "";
      var u = params['d'] || "";
      var e = params['e'] || "";
      this.model.topicId = unescape(params['i']) || "";
      this.class = config.class;
      this.subclass = config.subclass;
      //TODO: from config
      this.startTime = new Date().toLocaleString();
      this.buyPrice = +config.buyPrice;
      this.rentPrice = +config.rentPrice;
      this.timeDuration = +config.timeDuration;
      this.responsePageSize = +config.responsePageSize;
      this.random = +config.randomQuestions;
      this.rightQuestionReward = +config.rightQuestionReward;
      this.wrongQuestionPenalty = +config.wrongQuestionPenalty;
      this.audioEnabled = (config.audioEnabled === "true");
      this.goldimembersEnabled = (config.goldimembersEnabled === "true");
      this.goldimembersMonthlyPrice = +config.goldimembersMonthlyPrice;
      this.topic.id = this.model.topicId;
      this.siloqpayUrl = config.siloqpayUrl;
      this.currency = config.currency;

      if (u != "" && this.theUser !== window.atob(u)) {
        this.showAlert(AlertTypeEnum['danger'], "It looks like that you do not have access to this page!");
        this.router.navigate(['']);
      }

      this.subscription = this.data.currentClassTag.subscribe(classTag => this.classTag = classTag)
      if (e) {
        this.editable = true;
        this.classTag = e;
        //this.scrollToBottom();
      }
    });

    this.activatedRoute.fragment.subscribe((fragment: string) => {
      this.selectedSlideIndex = Number(fragment);
      if (this.selectedSlideIndex === 0) {
        this.selectedSlideIndex = -1;
      }
    })

    if(this.selectedTagIndex > 0) {
      window.location.replace(window.location.href);
    }

    if(this.model.hashTag === "newDoc") {
      var d = new Date();
      this.model.hashTag = "imoqr " + d.toDateString().toLowerCase();
      this.editable = true;
    }

    if(this.model.topicId) {
      this.slides = [];
      this.subtitles = [];
      this.tags = [];
      this.getTopic(this.topic.id, (t: any) => {
        if (t.email) {
          this.topic = t;
          this.topic.value = unescape(t.value);
          this.topic.hashTag = unescape(t.hashTag);
          this.data.changeMessage(unescape(t.hashTag));
          this.titleService.setTitle(unescape(t.hashTag));
         //if(this.theUser!==this.topic.email && this.topic.users[1].indexOf('mock') >= 0) {
         //if(this.topic.users[1].indexOf('mock') >= 0) {
          // this.mockExamRequest();
         //}
        }

        if(this.topic.taxonomy) {
          for (let i = 0; i < this.topic.taxonomy.length; i++) {
            switch (this.topic.taxonomy[i].key) {
             case "random":
              this.random = +this.topic.taxonomy[i].value[0];
              break;
            case "timeDuration":
              this.timeDuration = +this.topic.taxonomy[i].value[0];
              break;
            case "startTime":
              this.startTime = this.topic.taxonomy[i].value[0];
              break;
            case "pageSize":
              this.pageSize = +this.topic.taxonomy[i].value[0];
              break;
            case "rightQuestionReward":
              this.rightQuestionReward = +this.topic.taxonomy[i].value[0];
              break;
            case "wrongQuestionPenalty":
              this.wrongQuestionPenalty = +this.topic.taxonomy[i].value[0];
              break;
            case "audioEnabled":
              this.audioEnabled = (this.topic.taxonomy[i].value[0] === "true");
              break;
            case "goldimembersEnabled":
              this.goldimembersEnabled = (this.topic.taxonomy[i].value[0] === "true");
              break;
            case "rentPrice":
              this.rentPrice = +this.topic.taxonomy[i].value[0];
              break;
            case "buyPrice":
              this.buyPrice = +this.topic.taxonomy[i].value[0];
              break;
            }
          }
        }
      });
    }
    this.examScheduled();
  }

examScheduled() {
  setTimeout(()=>{
     this.scheduled = this.model.hashTag.endsWith('_exam') && !this.topic.hashTag;
     this.tags = [];
  }, 1000);
}

  loadTags(callback: () => any) {
    this.getTopicTags(callback);
  }

  getTopicTags(callback: () => any) {
  this.model.hashTag = escape(this.model.hashTag);
    this.topicService.getTopicTags(this.topic)
      .subscribe(
       (tags: any) => {
          this.tags = tags; //Bind to view
          if(this.tags !== undefined) {
            if(callback !== undefined) {
              callback();
            }

            this.slides = [];
            this.subtitles = [];
            for (let i = 0; i < this.tags.length; i++) {
              if(this.tags[i] || this.tags[i] === this.tags[0]) {
                this.tags[i].hashTag = unescape(this.tags[i].hashTag);
                this.tags[i].name = unescape(this.tags[i].name);
                this.tags[i].value = unescape(this.tags[i].value);
                this.mapTags(this.tags[i]);
              }
            }

            //TODO: Fix this
            //this.subtitles.push(this.tags[this.tags.length-1].name.toLowerCase());

            this.totalCount = this.subtitles.length;
            this.pageSize = this.totalCount;

            this.setPageVisible();

            if(this.model.hashTag.endsWith("_exam")){
              this.showAlert(AlertTypeEnum['success'], "Your exam is ready!");
            }

            if(this.model.hashTag.endsWith("_exam_answer")){
              this.editable = true;
              this.showAlert(AlertTypeEnum['success'], "Start your answers with DOUBLE click!");
            }

           if(this.model.hashTag.endsWith("_exam_response_"+this.curbemail(this.theUser))){
              this.editable = true;
              this.showAlert(AlertTypeEnum['success'], "Start your answers with DOUBLE click!");
            }

           if(this.model.hashTag.endsWith("_exam_result_"+this.curbemail(this.theUser))){
              this.showAlert(AlertTypeEnum['success'], "Congratulations!");
            }

           if(this.model.hashTag.endsWith("_exam_scheduled")){
              this.showAlert(AlertTypeEnum['success'], "Exam scheduled successfully!");
            }
          }
        },
         (err: any) => {
          console.log(err);
        });
  }

  getTopic(id: string, callback: (t: any) => any) {
    this.topicService.getTopicById(id).subscribe(
      (data: any) => {
        callback(data);
      },
       (err: any) => {
        console.log(err);
      });
  }

  mapTags(tag: Tag){
    //if (tag.position > 0) { //Do not add the title at index 0
      this.subtitles.indexOf(tag.name.toLowerCase()) === -1 && this.subtitles.push(tag.name.toLowerCase());
      this.addValueToName(tag.name.toLowerCase(), tag);
    //}
  }

  addValueToName(name: any, value: any) {
    // Shorcut || returns left side if it is "truthy," or the right otherwise.
    // This means that we only assign a new Array to the Object's property
    // if it has not previously been used.
    this.slides[name] = this.slides[name] || [];
    // Adds a value to the end of the Array
    this.slides[name].push(value);
  }

  setSearchText() {
    this.data.changeMessage(this.model.hashTag.split(' ').slice(0,6).join(' ')  + " " + this.selectedSlide.split(' ').slice(0,6).join(' ') + " " + this.selectedTag.value.split   (' ').slice(0,6).join(' '));
  }

  public selectSlide( slide: any, i: number) {
    this.selectedSlideIndex = i;
    this.selectedSlide =  slide;
    this.setSearchText();
  }

 public selectTag( tag: any, j: number) {
    tag.rank = j + 1;
    this.selectedTagIndex = j;
    this.selectedTag =  tag;
    this.setSearchText();
  }

  public keyDown(event: any) {
    switch (event.keyCode) {
      case 38:
        if(event.shiftKey) {
          this.onTagUp();
        } else {
          this.onUp();
        }
        break;
      case 40:
        if(event.shiftKey) {
          this.onTagDown();
        } else {
          this.onDown();
        }
        break;
      case 13:
        event.stopPropagation();
        if(event.shiftKey) {
        } else {
          this.submitTag();
        }
        break;
      case 27:
        this.escapeIndex();
        break;
    }
    return;
  }

  escapeIndex() {
    this.selectedSlideIndex = -1;
    this.selectedTagIndex = -1;
    this.browseTagIndex = -1;
    this.browseSlideIndex = -1;
  }

  public onDown() {
    if(this.selectedSlideIndex < this.subtitles.length - 1)
    {
      this.selectedSlide = this.slides[++this.selectedSlideIndex];
      this.selectTag(this.slides[this.selectedSlide][0], 0);
    }
  }

  public onUp() {
    if(this.selectedSlideIndex > 0)
    {
      this.selectedSlide = this.slides[--this.selectedSlideIndex];
      this.selectTag(this.slides[this.selectedSlide][0], 0);
    }
  }

  public onTagDown() {
    if(this.selectedTagIndex < this.tags.length - 1)
    {
      this.selectedTag = this.slides[++this.selectedTagIndex];
      this.setSearchText();
    }
  }

  public onTagUp() {
    if(this.selectedTagIndex > 0)
    {
      this.selectedTag = this.slides[--this.selectedTagIndex];
      this.setSearchText();
    }
  }

  public setFirstPage() {
    this.loadTags(() => {
      if(this.tags.length > 0) {
      }
    });
  }

  setPageVisible() {
    this.selectedSlide = this.subtitles[this.currentPage-1];
  }

  renderTag(tag: Tag){
    if (tag.value == "" && tag.position > 1) {
      tag.name = '<div style="margin-left:' + (tag.position - 1) * 40 + 'px;">' + tag.name + '</div>';
    }
    if (tag.value != "" && tag.position > 1) {
      tag.value = '<div style="margin-left:' + (tag.position - 1) * 40 + 'px;">' + tag.value + '</div>';
    }
  }

  updateTag(tag: Tag) {
    var t = Object.assign({}, tag);

    t.hashTag = escape(t.hashTag);
    t.name = escape(t.name);
    t.value = escape(t.value);
    let commentOperation:Observable<Tag[]>;
    this.tagService.updateTag(t).subscribe(
      () => {
      },
       (err: any) => {
        console.log(err);
      });
  }

  updateTagName(tag: Tag){
    for (let i = 0; i < this.slides[this.selectedSlide].length; i++) {
      this.slides[this.selectedSlide][i].name = tag.name;
    }
    this.updateTag(tag);
  }

  addClass(tag: any){
    let commentOperation:Observable<Tag[]>;
    if(this.editable) {
      document.getElementById("loq").focus();
      this.classTag = unescape(tag.name);
    }
  }

////////////// below belongs to doc //////////

  editTag(tag: any) {
    this.umodel = new Tag(tag.hashTag, tag.userId, tag.source, tag.value, tag.topicId);
    tag.edit = !tag.edit;
  }

  shareDoc() {
    if(this.topic.hashTag === undefined) {
      this.showAlert(AlertTypeEnum['warning'], "Only owners of the topic can share!");
      return;
    }

    this.router.navigate(['/admin/share'],  { queryParams: { h : this.topic.hashTag}});
  }


  branchDoc() {
    if(this.topic.hashTag === undefined) {
      this.showAlert(AlertTypeEnum['warning'], "Only owners of the topic can clone!");
      return;
    }
    this.router.navigate(['/admin/branch'],  { queryParams: { h : this.topic.hashTag}});
  }

  mockExamRequest() {
        this.openModal(this.modalTemplate, "md", "Exam: Subscribe", "Do you want to subscribe <b>" + this.topic.hashTag.replace("_", " ") + "</b> Exam Request ?", () => {
            this.showAlert(AlertTypeEnum['info'], "Please hold on while we're scheduling the Mock Exam Request for you");
            document.getElementById("loading").style.display = "inline";
            this.topic.hashTag = escape(this.topic.hashTag);
               this.topic.email = this.theUser;
               var u = this.topic.users[0];
               this.topic.users = [];
               this.topic.users.push(u);
               this.topic.users.push('mock.imoqr@siloq.com');
               this.topic.users.push(this.theUser);
            this.mockRequest(this.topic, (t: any) => {
              document.getElementById("loading").style.display = "none";
              this.router.navigate(['/admin/exam'], { queryParams: {h : t.hashTag, t : "Request", u: t.users[1]}});
              //TODO: Email notification
              //Timed anytime offline exams
              //document.getElementById("loading").style.display = "none";
              //this.router.navigate(['/admin/exam'], { queryParams: { h : t.hashTag}});
            });
      });
    }

  renameTopic(){
    if(this.topic.hashTag === undefined) {
      this.showAlert(AlertTypeEnum['warning'], "Only owners of the topic can rename!");
      return;
    }

    var d = new Date();
    var h = "imoqr " + d.toDateString().toLowerCase();
    if (this.model.hashTag === "") {
      return;
    }

    this.topic.hashTag = escape(this.topic.hashTag);
    //TODO: Remove this hack
    //Or, is it a good idea to keep and follow?!
    this.topic.value = escape(this.model.hashTag);
    this.topic.email = this.theUser;
    this.topicService.renameTopic(this.topic).subscribe(
      () => {
        this.router.navigate(['/admin/exam'],  { queryParams: { h : this.model.hashTag, e : this.classTag}});
      },
       (err: any) => {
        console.log(err);
      });
    this.data.changeMessage(this.model.hashTag);
  }

  rankup(i: number){
    if (i > 1) {
      var t1 = this.tags[i];
      t1.rank = t1.rank - 1;
      this.rankTag(t1)

      var t = this.tags[i].rank;
      this.tags[i].rank = this.tags[i-1].rank;
      this.tags[i-1].rank = t;

      var tag = this.tags[i];
      this.tags[i] = this.tags[i-1];
      this.tags[i-1] = tag;
    }
  }

  rankdown(i: number){
    if (i < this.tags.length - 1) {
      var t1 = this.tags[i];
      t1.rank = t1.rank + 1;
      this.rankTag(t1)

      var t = this.tags[i].rank;
      this.tags[i].rank = this.tags[i+1].rank;
      this.tags[i+1].rank = t;

      var tag = this.tags[i];
      this.tags[i] = this.tags[i+1];
      this.tags[i+1] = tag;
    }
  }

  rankTag(tag: Tag){
    this.tagService.rankTag(tag).subscribe(
      () => {
      },
       (err: any) => {
        console.log(err);
      });
  }

  convertToId(source: string): string {
    return source.replace(/ /g, "-");
  }

  routeToPageSection(i: number): string {
    return '/admin/exam?h=' + this.topic.hashTag + '#' + i;
  }

  cutUntil(tag: Tag): string {
    var index = tag.source.indexOf("#");
    if(index > 1) {
      var url = tag.source.substring(0, index);
      return url+"#:~:text="+encodeURIComponent(tag.value).replace(/-/g, "%2D");
    } else {
      return tag.source+"#:~:text="+encodeURIComponent(tag.value).replace(/-/g, "%2D");
    }
  }

  publicMock() {
      if(this.topic.hashTag === undefined) {
        this.showAlert(AlertTypeEnum['warning'], "Only owners of the topic can publish!");
        return;
      }

      if(this.topic.topics.indexOf(this.topic.hashTag+"_public") >= 0) {
        this.router.navigate(['/public'],  { queryParams: { h : escape(this.topic.hashTag), d : window.btoa(this.topic.email)}});
        return;
      }

      var lx = this.topic.hashTag.lastIndexOf("_public");
      if( lx == this.topic.hashTag.length-"_public".length) {
        //TODO: Check if this belong to public user
        this.router.navigate(['/public'],  { queryParams: { h : escape(this.topic.hashTag.substring(0, lx)), d : window.btoa(this.topic.email)}});
        return;
      }

      this.openModal(this.modalTemplate, "md", "Publish Doc", "Do you want to publish " + this.topic.hashTag + "?", () => {
        this.atomicPublishTopic(escape(this.topic.hashTag), this.topic.email, () => {
          this.router.navigate(['/public'],  { queryParams: { h : escape(this.topic.hashTag), d : window.btoa(this.topic.email)}});
        });
      });
    }

 atomicPublishTopic(h: string, e: string, callback: (t: any) => any) {
    this.topicService.publishTopic(h, e).subscribe(
      (data: any) => {
        callback(data);
      },
       (err: any) => {
        console.log(err);
      });
  }

      mockRequest(t: Topic, callback: (t: any) => any) {
      this.topicService.mockRequest(t).subscribe(
        (data: any) => {
          callback(data);
        },
         (err: any) => {
          console.log(err);
        });
    }

     examResponse(t: Topic, callback: (t: any) => any) {
              this.topicService.examResponse(t).subscribe(
                (data: any) => {
                  callback(data);
                },
                 (err: any) => {
                  console.log(err);
                });
            }

     examFinalize(t: Topic, callback: (t: any) => any) {
                  this.topicService.examFinalize(t).subscribe(
                    (data: any) => {
                      callback(data);
                    },
                     (err: any) => {
                      console.log(err);
                    });
                }

      examResult(t: Topic, callback: (t: any) => any) {
              this.topicService.examResult(t).subscribe(
                (data: any) => {
                  callback(data);
                },
                 (err: any) => {
                  console.log(err);
                });
            }

  updateTopic(topic: Topic,  callback: (t: any) => any){
    let commentOperation:Observable<Topic[]>;
    this.topicService.updateTopic(topic).subscribe(
             (data: any) => {
                  callback(data);
              },
       (err: any) => {
        console.log(err);
      });
  }

  updateTopicValue(topic: Topic,  callback: (t: any) => any){
    let commentOperation:Observable<Topic[]>;
    this.topicService.updateTopicValue(topic).subscribe(
             (data: any) => {
                  callback(data);
              },
       (err: any) => {
        console.log(err);
      });
  }

  onPrint() {
    this.escapeIndex();
    this.printable = false;
    setTimeout(()=>{
      window.print();
      this.printable = true;
        }, 1000);
  }

  curbemail(email: string): string {
       if(!email) {
         return email;
       }

       var index = email.indexOf("@");
       if(index > 0) {
         return email.substring(0, index);
       }
    return email;
     }

   profileEx(h: any, d: any) {
    this.router.navigate(['profile'],  { queryParams: { h : h, d : window.btoa(d)}});
    return;
  }

  addNewTopic(){
    if (this.model.hashTag.trim() === "") {
      return;
    }

    this.topic = new Topic('', '', '');
    this.topic.hashTag = escape(this.model.hashTag);
    this.topic.email = this.theUser;
    this.topicService.addTopic(this.topic).subscribe(
      () => {
      },
       (err: any) => {
        console.log(err);
      });

    //this.showAlert(AlertTypeEnum['success'], "The new topic '" + this.topic.hashTag + "' has been added!");
    this.router.navigate(['/admin/exam'],  { queryParams: { h : this.model.hashTag, e : this.classTag}});
  }

  getContent(innerHTML){
    this.model.value = innerHTML.trim();
    this.data.changeMessage(this.model.hashTag.split(' ').slice(0,6).join(' ') + " " + this.classTag.replace('&gt;&gt;&gt;','').split(' ').slice(0,6).join(' ') + " " + this.model.value.split(' ').slice(0,6).join(' '));
  }

  submitTag(){
    document.getElementById("loq").innerHTML = '';
    if (this.model.hashTag === "") {
      return;
    }

    let commentOperation:Observable<Tag[]>;
    if (this.model.value.trim() === "" ) {
      return;
    }

    var t = new Tag(this.model.hashTag, '', '', '', this.model.id)
    t.hashTag = this.model.hashTag;
    t.value = this.model.value;

    if (t.value.startsWith("&gt;&gt;&gt;") || t.value.startsWith("&lt;&lt;&lt;")) {
      if (t.value.length > 12) {
          this.classTag = t.value.substring(12);
      } else {
        this.classTag = "Not Selected Yet";
      }
      this.model.value = "";
      this.data.changeClassTag(this.classTag);

      return;
    }

    if(this.classTag.trim() !== "" &&  this.classTag !== "Not Selected Yet") {
      t.hashTag = escape(t.hashTag)+">>>"+escape(this.classTag);
    } else {
      t.hashTag = escape(t.hashTag);
    }

    t.email = this.theUser;
    t.source = this.theUser;
    this.data.changeMessage(t.hashTag.split(' ').slice(0,6).join(' ') + " " + this.classTag.replace('&gt;&gt;&gt;','').split(' ').slice(0,6).join(' ') + " " + t.value.split(' ').slice(0,6).join(' '));
    t.name = escape(t.name);
    t.value = escape(t.value);
    this.tagService.addTag(t).subscribe(  () => {
        this.router.navigate(['/admin/exam'],  { queryParams: { h : t.hashTag, e : t.name}});
      },
       (err: any) => {
        console.log(err);
      });

    t.hashTag = t.hashTag;
    t.name = t.hashTag;
    var v = t.value;
    if (this.classTag.trim() !== "" && this.classTag !== "Not Selected Yet") {
      t.value = "";
      t.name = this.classTag;
      if(this.slides[t.name.toLowerCase()]){
      } else {
        t.name = t.name;
        t.value = t.value;
        this.tags.push(t);
      }
    } else {
      if(this.slides[t.name.toLowerCase()]){
      } else {
        t.name = t.name;
        t.value = t.value;
        this.tags.push(t);
      }
    }
    t.value = v;
    this.tags.push(t);
    this.setTopicUserTags();

    //TODO:
    //this.scrollToBottom();
  }

  scrollToBottom() {
    const element = document.getElementById('loq');
    //element.scrollTop = element.scrollHeight;
    element.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }

  scrollToTop() {
    const element = document.getElementById('top');
    element.scrollTop = 0;
  }

  setTopicUserTags() {
    this.slides = [];
    this.subtitles = [];

    if(this.tags.length > 0) {
    }

    for (let i = 0; i < this.tags.length; i++) {
      this.tags[i].hashTag = unescape(this.tags[i].hashTag);
      this.tags[i].name = unescape(this.tags[i].name);
      this.tags[i].value = unescape(this.tags[i].value);
      this.mapTags(this.tags[i]);
    }

    this.totalCount = this.subtitles.length;
    this.pageSize = this.totalCount;

    this.setPageVisible();
  }

  renderImage(t: any) {
  var s = t;
  t = t.replace(/<(?:.|\n)*?>/gm, '');
   if (t.startsWith("Ans - ")) {
      var v = unescape(t);
     t = v.replace("Ans - ", "");
   }

   if (t.startsWith("http://") || t.startsWith("https://")) {
       const img = new Image();
       img.src = t;
       var w = 300;
       var h = (w/img.width)*img.height;
       t = '<img src="' + t + '" style="width:' + w + 'px; height:' + h + 'px;" />'

       if (s.startsWith("Ans - ")) {
        t = "Ans - " + t;
       }
       return t;
    }
       return s;
  }

  cloneTopic(t: Topic, callback: (t: any) => any){
         this.topicService.cloneTopic(t).subscribe(
          (data: any) => {
                 callback(data);
               },
            (err: any) => {
             console.log(err);
           });
       }

       cloneTopicToUser(t: Topic, callback: (t: any) => any){
       this.topicService.cloneTopicToUser(t).subscribe(
        (data: any) => {
               callback(data);
             },
          (err: any) => {
           console.log(err);
         });
     }

  branchTopic(suffix: string){
       if (this.topic.hashTag === undefined) {
         return;
       }

       if(suffix === "scores") {
           this.router.navigate(['/admin/scores'], { queryParams: { h : this.topic.hashTag}});
        }

        if(suffix === "download") {
           this.showAlert(AlertTypeEnum['info'], "Coming soon!");
        }

       if(suffix === "reexam") {
           this.router.navigate(['/admin/reexam'], { queryParams: { h : this.topic.hashTag}});
        }

       if(suffix === "finalized") {
           this.router.navigate(['/admin/exam'], { queryParams: { h : this.topic.hashTag.replace('_mock', '_exam_finalized'), t : 'Finalized', u : 'finalized.imoqr@siloq.com'}});
        }

}

    ignore(event: any) {
       //event.srcElement.parent.setAttribute('disabled', true);
       //event.srcElement.disabled = true;
       this.clicked = true;
       setTimeout(function(){
             //event.srcElement.parent.removeAttribute('disabled');
             //event.srcElement.disabled = false;
             this.clicked = false;
           }, 100);
      }

     parseFloat64(n: any): any {
      return parseFloat(n);
     }

     mocks() {
        this.router.navigate(['/admin/mocks']);
     }

    goldimembers() {
          this.router.navigate(['/admin/goldimembers']);
      }

      suscribe(){
          window.open("https://pay.siloq.com/basic?callbackURL=https://api.isloq.com/gmt/user/"+this.theUserId, "_self");
      }

          subscribeRent() {
              this.userSVC.getUserByEmail(this.theUser)
                .subscribe(
                  (user: any) => {
                    if(user!=undefined){
                      this.userEx = user;
                      window.open(this.siloqpayUrl+"?a="+this.rentPrice+"&c="+this.currency+"&u="+this.userEx.id+"&t="+this.topic.id+"&l=2&g=rent", "_self");
                    }
                  },
                   (err: any) => {
                    console.log(err);
                  });
            }

          subscribeBuy() {
              this.userSVC.getUserByEmail(this.theUser)
                .subscribe(
                  (user: any) => {
                    if(user!=undefined){
                      this.userEx = user;
                      window.open(this.siloqpayUrl+"?a="+this.buyPrice+"&c="+this.currency+"&u="+this.userEx.id+"&t="+this.topic.id+"&g=buy", "_self");
                    }
                  },
                   (err: any) => {
                    console.log(err);
                  });
            }
}
