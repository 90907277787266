<admin-sis-header></admin-sis-header>
<body style="background-color:#ffffff00;height:100%;width:100%; margin:0; padding: 0px 0% 0px 0%;">
  <div class="doNotPrint" style="display:table;width:100%; height:18px; margin-top:60px; valign:middle;">
    <div style="vertical-align: middle;padding:0px;margin:0px;width:40%;display:table-cell;">
       <a class="intLink" style="font-family: Arial, Helvetica, sans-serif;float:left;padding:0px 2px 0px 2px; margin:0px 0px 0px 3px;cursor:pointer;"(click)="myProfile(selectedTopic);" title="{{curbemail(theUser)}}"><img src="assets/user.png" align="middle" title="uiloq™: Timeline Logo" title="{{userSVC.authUser.email}}"alt="uiloq™: Timeline Logo"style="margin-bottom:10px;width:18px; height:18px;" /></a>
        <input class="intLink" type="button" value="⭐️" valign="middle" style="background-color:#ffffff00;height:16px;width:16px;border:0px; padding:0px 0px 5px 0px;font-weight:300;font-size:.75em;"/>
    </div>
    <div style="display:table-cell;font-family:Helvetica,AppleGothic,sans-serif; width:20%;text-align:center;margin:0;color:mediumseagreen;font-size:1em;font-weight:200;vertical-align: middle;">Trends</div>
    <div style="display:table-cell;font-family:Helvetica,AppleGothic,sans-serif;float:right;width:40%;margin:0px 0px 0px 20px;text-align:right;padding:0px 5px 0px 0px;font-size:1em;font-weight:200;">
      <select name="tags" id="tags" (change)="sort($event);" style="cursor:pointer;color:orangered;background-color:#ffffff00;height:18px;border:0px; padding:0px 0px 5px 0px;font-weight:300;font-size:.8em;"> <option value="email">User</option> <option value="hashtag">Topic</option> <option value="updatedate">Date</option> </select>
    </div>
  </div>
  <br>

  <div style="display:table; white-space:nowrap;padding-left:10%;width:100%;border-top:0px gainsboro solid;">
    <div style="width:75%;padding-top:0px;margin:auto;display:flex;justify-content:left;align-items:left;">
      <a href="/admin/graph" target="_self" style="text-decoration:none;" > <img title="🔍 Sisloq™" alt="🔍 Sisloq™" src="assets/uiloq-{{taxonomy}}.png" style="width:32px; height:32px;padding:17px 2px 14px 0px;" /></a> &nbsp;<input class="siloqinput" type="search" placeholder="🔍 Siloq&reg; Secure Search" name="siloqSearch" #siloqSearch  [(ngModel)]='searchText' autofocus="autofocus" style="color: grey;padding:7px;height: 32px; width:70%;min-width:160px;margin-top:17px;"/><a href="/admin/search" target="_self" style="text-decoration:none;" >&nbsp;<img title="🔍 Siloq®" alt="🔍 Siloq®" (click)="setFirstPage()" src="assets/siloq-{{taxonomy}}.png" style="width:32px; height:32px;padding:17px 0px 0px 2px; cursor:pointer;" /></a><span style="font-family:Century Gothic,CenturyGothic,AppleGothic,sans-serif;font-size:2.3em;font-weight:400;padding:12px 0px 0px 0px;height:36px;"></span>
      <div  class="intLink" valign="middle"  style="display:table-cell;width:5%;color:mediumseagreen;background-color:#ffffff00;border:0px; padding:19px 0px 0px 5px;font-weight:300;font-size:1.5em;height:22px;width:22px;cursor:pointer;" title="Copy Tiny Url of Siloq® Search page"> 🔗 </div>
    </div>
    <div style="margin:0%;width:75%;padding:0px;text-align:left;">
      <ul *ngIf="showAuto" style="list-style: none;display:table; margin:0px auto; font-family:Helvetica,AppleGothic,sans-serif; -moz-border-radius: 15px; border-radius: 15px;inline-block;font-size:1em;border:3px lightsteelblue solid;padding: 5px 5px 5px 5px; height:20px; width:100%;">
        <li *ngFor="let auto of autos; let i = index" style="-moz-border-radius: 15px; border-radius: 15px;" (click)="selectAuto(auto, i)" class="regular" [class.active]="i === selectedIndex">
          <div style="cursor:pointer;-moz-border-radius: 15px; border-radius: 15px;font-size:1em;inline-block;text-align: left;padding:5px;">{{auto.Name}}</div>
        </li>
      </ul>
    </div>
  </div>

  <div class="block_container" style="background-color:#ffffff00;border-bottom:1px solid gainsboro;padding:10px 0px 0px 0px;font-family:Century Gothic,CenturyGothic,AppleGothic,sans-serif; text-align: center; valign: top; font-size:1em;font-weight:200;"><a href="" ><img src="assets/uiloq-{{taxonomy}}.png" align="middle" style="margin-bottom:13px;width:19px; height:19px;" /></a> <span style="color:grey;"></span> <a style="text-decoration:none;" href=""><span style="color:dodgerblue;">S</span><span
                             style="color:mediumseagreen;">i</span><span
                             style="color:orangered;">s</span><span
                             style="color:mediumseagreen;">l</span><span
                             style="color:dodgerblue;">o</span><span
                             style="color:orange;">q</span><span
                             class="sup"
                             style="color:orangered;">&trade;</span><span style="color:dodgerblue;">&nbsp;Sustainable Intelligence!</span>
                           </a>
</div>

<div style="width:90%; margin:0; padding: 0px 5% 0px 5%;">
<div class="container" style="padding:3%;width:60%;margin:0 auto;text-align:center;">
	<div style="width:100%;overflow:hidden;">
		<table border = "0" cellpadding = "0" cellspacing = "0" style="font-family:arial,sans-serif; font-size:1em;font-weight:200;width:100%;">
			<tr class="block_container" style="color:grey;padding:40px;">
        <th style="width:100%;" valign="top">
       <div class = "regular" style="margin:0 auto;padding:0px;width:75%;">
          <div style="font-weight:200;">
            <div *ngIf="!showAuto && topics && topics.length > 0" style="width:100%;overflow:hidden;padding-top:0px;" class="doNotPrint">
               <div *ngIf="!showAuto && searchText" style="float:left;display:inline;width:60%;padding:15px 0px 1px 10px;font-family:Century Gothic,CenturyGothic,AppleGothic,sans-serif; font-size:.75em;font-weight:600; color: mediumseagreen; height:23px;text-align:left;vertical-align: middle;" >
                {{searchText}}
              </div>
                 <br>
              <div *ngIf="!showAuto" style="padding:15px 0px 1px 10px;font-family:Century Gothic,CenturyGothic,AppleGothic,sans-serif; font-size:.75em;font-weight:200; color: navy; height:23px;text-align:left;" >
               <div *ngIf="topics && topics.length >= 64" style="display: inline-block;">
                 Page {{currentPage | number:'1.0-0'}} of more than {{totalCount | number:'1.0-0'}} Topics (0 seconds)
               </div>
               <div *ngIf="topics && topics.length > 0 && topics.length < 64" style="display: inline-block;">
                  Page {{currentPage | number:'1.0-0'}} of {{totalCount | number:'1.0-0'}} Topics (0 seconds)
               </div>
               <div  *ngIf="topics === undefined" style="display: inline-block; text-align:left;">
                 Working...
               </div>
               <div  *ngIf="topics && topics.length === 0" style="display: inline-block;">
                 No Activity
               </div>
             </div>
           </div>
          </div>
       </div>
        </th>
    </tr>
    <tr class="block_container" style="color:grey;width:100%;padding:0px;">
		<td style="height:20px;width:100%;padding:0px;" valign="top">
       <div class = "regular" style="margin:0 auto;padding:0px 0px 0px 3%;width:75%;">
		<div *ngFor="let topic of selectedTopics; let i = index"  valign="top" class = "regular" [class.active]="i === selectedIndex" style="border-radius: 0px;-moz-border-radius: 0px; margin:0 auto;width:9%;height:300px;display:inline-block;">
      <div *ngIf="(!topic.edit || topic !== selectedTopic) && topic.show" (mouseout)='hover=false;' (mouseenter)='hover=true; selectTopic(topic, i)' (click)="docTopic(topic, i)"[ngClass]="{'col0': 0 === i % 2, 'col1': 1 === i % 2}" style="width:9%;height:300px;display:inline-block;padding-top:5px;width:100%;cursor:pointer;">
        <div (click)='docTopic(selectedTopic)' style="text-align:center; border:0px; background-color:#ffffff00;height:16px;border:0px;padding:0px 0px 5px 0px;font-weight:200;font-size:.60em;color:white;vertical-align:middle;">{{topic.updateDate | date: 'M/d/yy'}}</div>
    </div>
    </div>
    </div>
      </td>
    </tr>
    <tr class="block_container" style="color:grey;width:100%;">
		<td  style="width:99%;padding:1px 0px 0px 0px;height:120px;" valign="top">
       <div *ngIf="hover" class = "active" style="margin:0 auto;padding:0px;width:69%;"> <div style="background-color:whitesmoke;width:100%;margin:0px;height:20px;font-family:Helvetica,AppleGothic,sans-serif;padding:0px 0px 0px 0px;float:center;-moz-border-radius: 5px;border-radius: 5px">
            <div style="float:left; text-align:left;width:80%;">
<input class="intLink" type="button" value="🔒" align="middle" (click)='docTopic(selectedTopic)' title="Lock {{selectedTopic.hashTag}}" style="color:dodgerblue;background-color:#ffffff00;border:0px; padding:0px 5px 5px 0px;font-weight:300;font-size:1em;"/><span contenteditable="false" style="font-weight:500;font-size:1em;color:navy;" class="intLink" title="Archive {{selectedTopic.hashTag}}" (click)='docTopic(selectedTopic)' >{{selectedTopic.hashTag | truncate : 128}}<input class="intLink" type="button" value="⭐️" valign="middle" (click)='flagTopic(selectedTopic)' style="background-color:#ffffff00;height:16px;width:16px;border:0px; padding:0px 0px 5px 0px;font-weight:300;font-size:.75em;"/></span>
     </div>
     <div style="text-align:right;">
       <input class="intLink" type="button" value="❐" v align="middle" (click)='docTopic(selectedTopic)' title="View {{selectedTopic.hashTag}}" style="vertical-align:middle;color:mediumseagreen;background-color:#ffffff00;height:16px;width:16px;border:0px; padding:0px 0px 16px 0px;font-weight:300;font-size:.75em;"/> <input class="intLink" type="button" value="✕" v align="middle" (click)='archiveTopic(selectedTopic)' title="Archive {{selectedTopic.hashTag}}" style="vertical-align:middle;color:orangered;background-color:#ffffff00;height:16px;width:16px;border:0px; padding:0px 0px 16px 0px;font-weight:300;font-size:.75em;"/>
     </div>
   </div>
    <div style="vertical-align:top;margin:0px;text-align:left;padding:5px;" >
      <div style="cursor:pointer;display:inline-block;width:100%;">
                      <div (click)='docTopic(selectedTopic)' style="font-family:Century Gothic,CenturyGothic,AppleGothic,sans-serif; text-align: left; valign: top; font-size:.85em;font-weight:400;color:black">{{selectedTopic.value | truncate : 256}}
              </div>
<span class="doNotPrint" (click)='docTopic(selectedTopic) 'style="cursor:pointer;text-align:center; border:0px; background-color:#ffffff00;height:16px;border:0px;padding:0px 0px 5px 0px;font-weight:300;font-size:.75em;color:navy;">{{selectedTopic.updateDate | date: 'M/d/yy, h:mm a'}}&nbsp;&nbsp;</span><span (click)="profile(selectedTopic);" class="doNotPrint" style="cursor:pointer;background-color:#ffffff00;height:16px;border:0px;padding:0px 0px 5px 0px;font-weight:300;font-size:.75em;color:grey;">{{curbemail(selectedTopic.email)}}</span>
        </div>
    </div>
    </div>

    </td>
      </tr>
	</table>
	</div>

</div>
<br>
<div  *ngIf="topics && topics.length" class="col-md-12" style="margin:auto;
             width:100%;font-family:Century
             Gothic,CenturyGothic,AppleGothic,sans-serif;
             padding-top:4px;padding-bottom:1px;background-color:white; color:
             navy; height:23px;text-align:center;width:85%;">
		<div style="display: inline-block;">
			<a *ngIf='currentPage>1' href="admin/graph?p=1" style="cursor:pointer;color:dodgerblue;"><<</a>&nbsp;&nbsp;<a *ngIf='currentPage>1' href="admin/graph?&p={{currentPage-1}}" style="cursor:pointer;color:dodgerblue;"><</a>&nbsp;&nbsp;Page {{currentPage}} of {{pageCount | number:0}}&nbsp;&nbsp;<a *ngIf='currentPage<pageCount' href="admin/graph?&p={{currentPage+1}}" style="color:dodgerblue;cursor:pointer;">></a>&nbsp;&nbsp;<a *ngIf='currentPage<pageCount' href="admin/graph?&&p={{pageCount}}" style="color:dodgerblue;cursor:pointer;">>></a>
		</div>
	</div>
	 </div>
 <div class="block_container" style="background-color:#ffffff00;border-top:1px solid gainsboro;padding:10px 0px 0px 0px;font-family:Century Gothic,CenturyGothic,AppleGothic,sans-serif; text-align: center; valign: top; font-size:1em;font-weight:200;"><a href="http://sisloq.com" >
               <span style="color:dodgerblue;">Powered by</span> <img src="assets/uiloq-{{taxonomy}}.png" align="middle" style="margin-bottom:13px;width:19px; height:19px;" /></a> <span style="color:grey;"></span> <a style="text-decoration:none;" href="http://sisloq.com"><span style="color:dodgerblue;">S</span><span
                             style="color:mediumseagreen;">i</span><span
                             style="color:orangered;">s</span><span
                             style="color:dodgerblue;">l</span><span
                             style="color:orange;">o</span><span
                             style="color:dodgerblue;">q</span><span
                             class="sup"
                             style="color:orangered;">&trade;</span>
                           </a>
</div>

<br>
<br>
<br>
<br>
<admin-footer></admin-footer>
