<header class="doNotPrint"></header>
      <div style="margin-top:18px;" class="doNotPrint hcontainer">
      <div class="fa fa-chevron-left" style="background-color:orangered;"></div>
        <div style="width:90%;" class="item hcontainer">
         <div class="item" *ngFor="let tag of tags; let i = index;">
           <div *ngIf="!tag.value && tag.key" style="margin:0 auto;" >
                     <a  valign="top" style="font-family: Arial, Helvetica, sans-serif;float:left;color:whitesmoke;font-weight:500;font-size:14px;padding:0px 1px 2px 0px; height:20px;text-align;left;" href="{{routeToTag(i)}}" [innerHTML]="tag.name | safeHtml"></a>
           </div>
           </div>
           <!--
         <div class="item">
<a style="font-family: Arial, Helvetica, sans-serif;font-weight:500;font-size:14px;display:table-cell;padding:0px 1px 2px 0px; height:20px;text-align;left;" href="/public?h={{topic.hashTag}}&d={{window.btoa(this.topic.email)}}#references">References</a>
         </div>
           -->
        </div>
           <div class="fa fa-chevron-right" style="float:right;background-color:orangered;"></div>
   </div>
   <!--
<div class="doNotPrint" style="display:table;height:30px;padding-top:0px;margin-top:33px;border:0px;text-align:center;background-color:tomato;font-family:Helvetica,AppleGothic,sans-serif;color:#f5f5f5;width:100%;">
    <div style="display:table-cell;width:30%; text-align:left;padding-left:15px;">
      <a style="font-size:1em;font-weight:600;" >Let's get in Touch</a>
    </div>
    <div style="display:table-cell;width:40%;">
      <a *ngIf="!loggedinUser" style="font-size:.9em;font-weight:500;" >For
        more info or a demo, drop us a message.</a>
      <a *ngIf="loggedinUser" style="font-size:.9em;font-weight:500;" >For
        more info or a feedback, drop us a message.</a>
    </div>
    <div
        style="display:table-cell;width:30%;text-align:right;padding-right:15px;">
      <a *ngIf="!loggedinUser" [routerLink]="['/contact']"
        class="siloqbutton"
        style="height:20px;border:0px;color:tomato;">Contact</a>
      <a *ngIf="loggedinUser" [routerLink]="['/admin/feedback']"
        class="siloqbutton"
        style="height:20px;border:0px;color:tomato;">Feedback</a>
    </div>
</div>
   -->
<body style="padding-top:36px;background-color:white; height:100%;width:100%; margin:0px;">
  <form method="post">
    <div class="doNotPrint" style="background-color:whitesmoke;margin-top:0px;display:table;width:100%; height:38px; valign:middle;border:0px solid lightgrey;padding:0px 0px 0px 0px;">
      <div  style="text-align:center;margin:0 auto;color:mediumseagreen;padding:0px 5px 0px 12px;font-family:Helvetica,AppleGothic,sans-serif;font-size:1em; font-weight:600;width:90%;vertical-align: middle;">
        <a title="Download '{{topic.hashTag}}'" class="fa fa-download intLink" type="button" valign="middle" (click)="onPrint();" style="color:dodgerblue;background-color:#ffffff00;border:0px; padding-top:5px;font-weight:300;font-size:1em;width:22px;height:22px;"></a>
        <!--
        <input class="intLink" type="button" valign="middle" (click)="copyTinyUrlToClipboard();" style="color:mediumseagreen;background-color:#ffffff00;border:0px; padding-top:5px;font-weight:200;font-size:1em;height:30px;width:30px;" value="🔗" title="Copy Tiny Url of '{{topic.hashTag}}'"/>
        -->

      </div>
    </div>
    <div  class="doNotPrint" style="padding:20px 0px 10px 0px;font-family:Helvetica,AppleGothic,sans-serif; width:100%;text-align:center;color:mediumseagreen;font-size:1em;font-weight:500;vertical-align: middle;">Published on {{topic.insertDate | date: 'M/d/yy, h:mm a'}}</div>
    <div class="block_container" style="background-color:#ffffff00;border-bottom:1px solid gainsboro;padding:10px 0px 0px 0px;font-family:Century Gothic,CenturyGothic,AppleGothic,sans-serif; text-align: center; valign: top; font-size:1em;font-weight:200;"><a href="" ><img src="/assets/isloq.png" align="middle" style="margin-bottom:13px;width:19px; height:19px;" /></a> <span style="color:grey;"></span> <a style="text-decoration:none;" href=""><span
      style="color:dodgerblue;">i</span><span
      style="color:mediumseagreen;">s</span><span
      style="color:orangered;">l</span><span
      style="color:orange;">o</span><span
      style="color:dodgerblue;">q</span><span
      class="sup" style="color:orangered;">&trade;</span><span style="color:dodgerblue;">&nbsp;Publish Instantly!</span> </a>
    </div>
    <alert></alert>

    <h2 style="vertical-align: middle;padding:0px 0px 15px 5px;margin:0 auto;width:90%;text-align:center;">
      <!--
      TODO: title 'undefined' http://localhost:4200/public?h=Final%20Biology%20MCQ%20Exam_exam_finalized&d=dmlzd2FuYXRoYW4uc2F0eWFAZ21haWwuY29t
      -->
      <div  style="font-family: Arial, Helvetica, sans-serif;padding:0px 0px 0px 2px; margin:0px 0px 0px 3px;" >{{topic.hashTag}}<input class="intLink" type="button" valign="middle" (click)="copyTitleUrlToClipboard();" style="color:mediumseagreen;background-color:#ffffff00;border:0px; padding-top:5px;font-weight:200;font-size:1em;height:40px;width:40px;" value="🔗" title="Copy Tiny Url with title '{{topic.hashTag}}'"/></div>
      <div  (click)="profile(topic);" class="doNotPrint" style="cursor:pointer;padding:0px 0px 10px 0px;font-family:Helvetica,AppleGothic,sans-serif; width:100%;text-align:center;color:dodgerblue;font-size:.85em;font-weight:500;vertical-align: middle;">{{curbemail(topic.email)}}</div>
    </h2>
    <div class="doNotPrint" style="border-bottom:1px solid gainsboro;text-align:center;width:100%;">17M Views | <input class="doNotPrint intLink" type="button" value="👍" align="middle" title="Like '{{topic.hashTag}}'" style="vertical-align:middle;color:mediumseagreen;background-color:#ffffff00;height:22px;width:22px;border:0px; padding:0px 0px 16px 0px;font-weight:300;font-size:1em;"/> 96K | <input class="doNotPrint intLink" type="button" value="👎" align="middle" title="Not interested in '{{topic.hashTag}}' at the moment" style="vertical-align:middle;color:mediumseagreen;background-color:#ffffff00;height:22px;width:22px;border:0px; padding:0px 0px 16px 0px;font-weight:300;font-size:1em;"/> 0 |
    </div>
    <br>
    <h2 style="vertical-align: middle;padding:0px 0px 15px 5px;margin:0 auto;width:90%;text-align:center;">
    <div *ngIf="ismock" style="text-align:center;color:mediumseagreen">MOCK EXAM<input *ngIf="ismock" class="intLink" type="button" valign="middle" (click)="copyMockUrlToClipboard();" style="color:mediumseagreen;background-color:#ffffff00;border:0px; padding-top:5px;font-weight:200;font-size:1em;height:40px;width:40px;" value="🔗" title="Copy Mock Tiny Url of '{{topic.hashTag}}'"/></div>
    </h2>
    <h3 *ngIf="ismock" style="text-align:center;color:dodgerblue">Please scan the QR Code or go to the link below to schedule this Mock Exam to yourself!</h3>
    <div style="text-align:center;margin:0 auto;vertical-align: middle; width:100%;">
      <img *ngIf="qrcode" src="{{qrcode}}" class="intLink" title="QrCode" id="qrcode" style="width:10%;height:10%;" />
     <br>
      <a *ngIf="ismock" style="text-align:center;color:mediumseagreen:cursor:pointer;" href="{{mockUrl}}">{{mockUrl}}</a>
    </div>

    <div id="doc" style="font-family:Helvetica,AppleGothic,sans-serif;width:92%;overflow:hidden;margin:4px 4% 40px 4%;padding-top:10px;z-index:-1px;">
      <div  id="textbox" style="font-family:Helvetica,AppleGothic,sans-serif;width:80%;overflow:hidden;margin:0px 0px 50px 10%;padding-top:10px;z-index:-1px;">
        <div *ngIf="!ismock">
      <div  *ngFor="let selectedSlide of subtitles; let i = index" (click)="selectSlide(selectedSlide, i)" (mouseenter)="browseSlideIndex = i" style="border:0px solid grey;width:98%;margin:0px;padding:2px">

        <div *ngIf="slides[selectedSlide].length > 1" class = "regular" [class.browse]="i === browseSlideIndex" [class.active]="i === selectedSlideIndex">

          <div *ngFor="let tag of slides[selectedSlide]; let j = index" (click)="selectTag(tag, j)" (mouseenter)="browseTagIndex = j" style="width:98%; list-style: none;background-color:white;display:table; margin:0; font-family:Helvetica,AppleGothic,sans-serif; inline-block;padding: 2px; height:20px;text-align:left;">

            <!--
          <div *ngIf="selectedSlide==='Synopsis'" style="display:table;">
              <div *ngIf="j === 0" style="display:table-cell;padding:0px 1px 15px 0px;text-align:left;display: inline-block; margin: 0 auto !important; font-weight:600;font-size:14px;"> {{selectedSlide}} </div>
              <div *ngIf="j > 0 && slides[selectedSlide][j].value.length > 1" class = "regular" [class.browse]="i === browseSlideIndex && j === browseTagIndex" [class.active]="i === selectedSlideIndex && j === selectedTagIndex" style="vertical-align:top;display:table; text-align:left;margin:0px; ">
                <div style="cursor:pointer;text-align: justify; text-justify: inter-word;padding:0px 0px 0px 0px;color:dark;font-weight:500;font-size:12px;"> <a valign="top" href="{{routeToPageSection(j+1)}}">{{tag.value}}</a> </div>
              </div>
            </div>
-->

            <div *ngIf="selectedSlide === model.hashTag.toLowerCase()" style="display:table;">
              <div *ngIf="j > 0" class = "regular" [class.browse]="i === browseSlideIndex && j === browseTagIndex" [class.active]="i === selectedSlideIndex && j === selectedTagIndex" style="vertical-align:top;display:table; text-align:left;margin:0px; ">

                <div  class="editTag" tabindex="{{i+j+1}}" id="{{i+j+1}}" (focus)="selectedTag = tag;"  valign="top" style="display:table-cell;padding:0px 1px 2px 0px;text-align: justify; text-justify: inter-word;display: inline-block; margin: 0 auto !important; inter-word;font-weight:500;font-size:12px;"[innerHTML]="renderImage(tag.value) | safeHtml"></div>&nbsp;<a *ngIf="i === selectedSlideIndex && j === selectedTagIndex" class="doNotPrint intLink" valign="top" style="font-weight:100;font-size:.9em;display:inline-block;padding:5px 1px 2px 5px; height:16px;width:16px;text-align;left;" title="Copy Loq Url of '{{tag.value}}'" (click)="copyLoqUrlToClipboard(i+j+1);">🔗</a>

                <div  style="display:table-cell;padding:0px 1px 2px 0px;font-weight:500;font-size:12px;" >&nbsp;<a *ngIf="tag.source.startsWith('http')" href="{{cutUntil(tag)}}" target="_blank">[{{refIndex(tag.source)}}]</a><a *ngIf="tag.source.indexOf('@') > 0" (click)="profileEx(model.hashTag, tag.source)" style="cursor:pointer;" target="_self">[{{refIndex(tag.source)}}]</a>&nbsp;</div>
              </div>
            </div>

            <div *ngIf="selectedSlide !== model.hashTag.toLowerCase() && selectedSlide!=='Synopsis' && selectedSlide!=='Appendix'" style="display:table;">
              <div class = "regular" [class.browse]="i === browseSlideIndex && j === browseTagIndex" [class.active]="i === selectedSlideIndex && j === selectedTagIndex" style="vertical-align:top;display:table; text-align:left;margin:0px; ">
                <div *ngIf="j === 0" class="editTag" tabindex="{{i}}" (focus)="selectedTag = tag;"  valign="top" style="display:table-cell;padding:0px 1px 2px 0px;text-align:left;display: inline-block; margin: 0 auto !important; font-weight:600;font-size:14px;" id="{{i}}" [innerHTML]="tag.name | safeHtml"></div>&nbsp;<a *ngIf="j === 0 && i === selectedSlideIndex && j === selectedTagIndex" class="doNotPrint intLink" title="Copy Loq Url of '{{tag.name}}'" valign="top" style="font-weight:100;font-size:.9em;display:table-cell;padding:5px 1px 2px 0px; height:20px;text-align;left;" (click)="copySubtitleUrlToClipboard(i);">🔗</a>

                <div *ngIf="j > 0" class="editTag" tabindex="{{i+j+1}}"(focus)="selectedTag = tag;"  valign="top" style="display:table-cell;padding:0px 1px 2px 0px;text-align: justify; text-justify: inter-word;display: inline-block; margin: 0 auto !important; inter-word;font-weight:500;font-size:12px;" id="{{i+j+1}}" [innerHTML]="renderImage(tag.value) | safeHtml"></div>&nbsp;<a *ngIf="j > 0 && i === selectedSlideIndex && j === selectedTagIndex" class="doNotPrint intLink" valign="top" style="font-weight:100;font-size:.9em;display:inline-block;padding:5px 1px 2px 5px; height:16px;width:16px;text-align;left;" title="Copy Loq Url of '{{tag.value}}'" (click)="copyLoqUrlToClipboard(i+j+1);">🔗</a>

                <div style="display:table-cell;padding:0px 1px 2px 0px;font-weight:500;font-size:12px;" >&nbsp;<a *ngIf="tag.source.startsWith('http')" href="{{cutUntil(tag)}}" target="_blank">[{{refIndex(tag.source)}}]</a><a *ngIf="tag.source.indexOf('@') > 0" (click)="profileEx(model.hashTag, tag.source)" style="cursor:pointer;" target="_self">[{{refIndex(tag.source)}}]</a>&nbsp;</div>
              </div>
            </div>

            <div *ngIf="selectedSlide==='Appendix'" style="display:table;">
              <div *ngIf="j === 0" id="{{i}}" style="display:table-cell;padding:0px 1px 15px 0px;text-align:left;display: inline-block; margin: 0 auto !important; font-weight:600;font-size:14px;"> {{selectedSlide}} </div>
              <div *ngIf="j > 0" class = "regular" [class.browse]="i === browseSlideIndex && j === browseTagIndex" [class.active]="i === selectedSlideIndex && j === selectedTagIndex" style="vertical-align:top;display:table; text-align:left;margin:0px;font-weight:500;font-size:12px; ">
                <div style="padding:0px 0px 0px 0px;color:dark">
                  <span *ngIf="tag.value.startsWith('http')" style="display: inline-block;" valign="top">[{{j}}]&nbsp;<a href="{{tag.value}}" target="_blank">{{tag.value | truncate : 64}}</a></span>
                  <span *ngIf="tag.value.indexOf('@') > 0" style="display: inline-block;" valign="top">[{{j}}]&nbsp;<a (click)="profileEx(model.hashTag, tag.value)" style="cursor:pointer;"target="_self">{{curbemail(tag.value)}}</a></span>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
        </div>
    <br>
      <div class="doNotPrint" style="display:table;height:30px;padding-top:0px;margin-top:15px;border:0px;text-align:center;background-color:tomato;font-family:Helvetica,AppleGothic,sans-serif;color:#f5f5f5;width:100%;">
        <div style="display:table-cell;width:20%; text-align:left;padding-left:15px;">
          <a style="font-size:1em;font-weight:600;" >Report Abuse </a>
        </div>
        <div style="display:table-cell;width:59%;">
          <a *ngIf="!loggedinUser" style="font-size:.9em;font-weight:500;" >For more info or a demo, drop us a message.</a>
          <a *ngIf="loggedinUser" style="font-size:.9em;font-weight:500;" >For more info or a feedback, drop us a message.</a>
        </div>
        <div
          style="display:table-cell;width:20%;text-align:right;padding-right:15px;">
          <a *ngIf="!loggedinUser" [routerLink]="['/contact']"
             class="siloqbutton"
             style="height:20px;border:0px;color:tomato;">Contact</a>
          <a *ngIf="loggedinUser" [routerLink]="['/admin/feedback']"
             class="siloqbutton"
             style="height:20px;border:0px;color:tomato;">Feedback</a>
        </div>
      </div>
    <br>
    <h3 class="doNotPrint" style="text-align:left;">Comment</h3>
    <div class="doNotPrint" style="display:table; width:100%;">
      <div *ngIf="!loggedinUser" class="siloqinput2" contenteditable="true" data-text="Please login to enter your comment." style="display:table-cell;float:left;height:20px;width:70%;min-width:200px;margin:0 auto !important; text-align:left; padding:16px;overflow:auto;" [textContent]='model.value' id="textBox" (blur)="getContent($event.target.innerText)" (input)="getContent($event.target.innerText)"> </div>
      <div *ngIf="loggedinUser" class="siloqinput2" contenteditable="true" data-text="Please enter your comment." style="display:table-cell;float:left;height:20px;width:70%;min-width:200px;margin:0 auto !important; text-align:left; padding:16px;overflow:auto;" [textContent]='model.value' id="textBox" (blur)="getContent($event.target.innerText)" (input)="getContent($event.target.innerText)">
      </div><input (click)='submitTag()' title="Submit comment" class="siloqbutton" type="button" value="&nbsp;&nbsp;⏎&nbsp;&nbsp;" valign="middle" style="color:orangered;"/>
</div>
    <br>
    <div class="doNotPrint" style="display:table; width:100%;">
    <!--
   <div class="siloqinput2" style="text-align: left; padding:25px; min-width:250px; width:80%; margin:0 auto !important; display:table-cell; overflow:auto;" [scrollTop]="scrollMe.scrollHeight" #scrollMe >
    -->
   <div  style="text-align: left; min-width:250px; width:100%; margin:0 auto !important; display:table-cell; overflow:auto;border:0px">
<div *ngIf="!ctags || ctags.length<=0" style="display: inline; color:green; font-weight:600;" >Please be the first one to comment!</div>
   <div style="display: inline;" *ngFor="let ctag of ctags; let i = index">
          <div style="cursor:pointer;display: inline;color:green; font-weight:600;">{{ctag.insertDate | date: 'M/d/yy, h:mm a'}}&nbsp;:&nbsp;<a style="color:navy; font-weight:500;" (click)="profileEx(ctag.hashTag, ctag.email)" target="_self">{{curbemail(ctag.email)}}</a></div>
          <br>
          <div style="display: inline;font-weight:500;font-size:12px;">{{ctag.value}}</div>
          <br>
          <br>
   </div>
 </div>
 </div>
    </div>
    <div class="block_container" style="background-color:#ffffff00;border-top:1px solid gainsboro;padding:10px 0px 0px 0px;font-family:Century Gothic,CenturyGothic,AppleGothic,sans-serif; text-align: center; valign: top; font-size:1em;font-weight:200;"><a style="text-decoration:none;" href="http://siloq.com" >
      <span style="color:dodgerblue;">Powered by</span> <img src="assets/isloq.png" align="middle" style="margin-bottom:13px;width:19px; height:19px;" /></a> <span style="color:grey;"></span> <a style="text-decoration:none;" href="http://siloq.com"><span style="color:dodgerblue;">i</span><span
      style="color:mediumseagreen;">s</span><span
      style="color:dodgerblue;">l</span><span
      style="color:orange;">o</span><span
      style="color:dodgerblue;">q</span><span
      class="sup"
      style="color:orangered;">™</span>
    </a>
    </div>
    </div>
    <br>
    <br>
    <home-footer></home-footer>
