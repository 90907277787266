import { Component, OnInit } from '@angular/core';
import {TopicService} from '../../services/topic.service';
import {Topic} from '../topic/model/topic';
import {Observable, throwError} from 'rxjs';
import {HostListener} from '@angular/core';
import {ActivatedRoute, Router } from '@angular/router';
import {UserService} from '../../services/user.service';
import {Login} from '../../services/user.service';
import {CookieService} from 'ng2-cookies';
import { AdminAlertComponent } from '../adminAlert/admin-alert.component';
import { AdminAlertService } from '../adminAlert/services/admin-alert.service';
import { AlertTypeEnum } from '../adminAlert/types/alertType.enum';
import { config } from '../../config';
import {RouterModule} from '@angular/router';
import { AdminHeaderComponent } from '../adminHeader/admin-header.component';
import { AdminFooterComponent } from '../adminFooter/admin-footer.component';
import { FormsModule } from '@angular/forms';
@HostListener('window:keydown', ['$event'])
@Component({
  host: {
    '(document:keydown)': 'keyDown($event)',
  },
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.css'],
  imports: [AdminAlertComponent, FormsModule, RouterModule, AdminFooterComponent, AdminHeaderComponent],
  standalone: true
})

export class UploadComponent implements OnInit {

  public showSelectedMsg: boolean;
  topics: Topic[];
  public model = new Topic('', '', '');
  public topic = new Topic('', '', '');
  public umodel = new Topic('', '', '');
  private branch = new Topic('', '', '');
  public selectedTopic;
  selectedIndex = 0;
  selectAll = false;
  public theUser = "";
 public theGoldiUser = "";
  CurrentFile:File;
  ImageSource:string;
 public taxonomy = "";
 public class = "";
 public subclass = "";

  constructor(private alertService: AdminAlertService, private topicService: TopicService, private activatedRoute: ActivatedRoute, public userSVC: UserService, private router: Router, private _cookieService:CookieService){}

  getCookie(key: string){
    return this._cookieService.get(key);
  }

  FileChangeEvent(fileInput:any){
    this.CurrentFile = fileInput.target.files[0];

    let reader = new FileReader();
    reader.onload = () => {
      this.ImageSource = reader.result as string;
    };
    reader.readAsDataURL(this.CurrentFile);
  }

  ngOnInit() {
    this.theUser = this.getCookie("email");
    this.theGoldiUser = this.getCookie("gmt");
    this.taxonomy = config.taxonomy;
    this.class = config.class;
    this.subclass = config.subclass;
    if(this.theUser!=="tpalanisamy@yahoo.com") {
      this.router.navigate(['/error']);
    }

    this.activatedRoute.queryParams.subscribe(params => {
      this.model.hashTag = params['h'] || "";
    });
  }

showAlert(type: AlertTypeEnum, msg: string) {
    this.alertService.setAlert({
      type,
      text: msg,
    });
  }

  getTopic(h: string, e: string, callback: (t: any) => any) {
    this.topicService.getTopic(h, e).subscribe(
      (data: any) => {
        callback(data);
      },
       (err: any) => {
        console.log(err);
      });
  }

  public selectTopic( topic: any, i: number) {
    topic.rank = i + 1;
    this.selectedIndex = i;
    this.selectedTopic =  topic;
  }

 public keyDown(event: any) {
    switch (event.keyCode) {
      case 38:
        this.onUp();
        event.preventDefault();
        break;
      case 40:
        this.onDown()
        event.preventDefault();
        break;
      case 13:
        this.branchTopic();
        event.preventDefault();
        break;
    }
    return;
  }

  public onDown() {
    if(this.selectedIndex < this.topics.length - 1)
    {
      this.selectedTopic = this.topics[++this.selectedIndex];
    }
  }

  public onUp() {
    if(this.selectedIndex > 0)
    {
      this.selectedTopic = this.topics[--this.selectedIndex];
    }
  }

  accTopic(topic: any) {
    this.router.navigate(['/admin/account'],  { queryParams: { h : topic.hashTag}});
  }

  //pagination for table
  public setFirstPage() {
    this.getTopic(this.model.hashTag, this.theUser, (t: any) => {
      this.topic = t;
    });
  }

  cloneTopic(t: Topic){
    this.topicService.cloneTopic(t).subscribe(
      () => {
      },
       (err: any) => {
        console.log(err);
      });
  }

  branchTopic(){
    if (this.topic.hashTag === undefined) {
      return;
    }

    if (this.model.hashTag == "" || this.model.hashTag == this.topic.hashTag) {
      return;
    }

    //TODO: Do Not Use topic.value
    this.topic.value = this.model.hashTag;
    this.cloneTopic(this.topic)

    this.topic.topics.push(this.model.hashTag);
    this.updateTopic(this.topic);
  }

  updateTopic(topic: Topic){
    let commentOperation:Observable<Topic[]>;
    this.topicService.updateTopic(topic).subscribe(
      () => {
        this.model = new Topic('', '', '');
        this.setFirstPage();
      },
       (err: any) => {
        console.log(err);
      });
  }

  curbemail(email: string): string {
    if(!email) {
      return email;
    }

    var index = email.indexOf("@");
    if(index > 0) {
      return email.substring(0, index);
    }
    return email;
  }

  profile() {
    this.router.navigate(['/profile'],  { queryParams: {d : window.btoa(this.theUser)}});
    return;
  }

  notify() {
    this.showAlert(AlertTypeEnum['warning'], "Coming soon!");
  }
}
