<header></header>
<div  style="text-align:center; width:100%; padding-top:70px;">
<br>
<div class="row expanded align-center" style="text-align: center; margin: auto;">
	<h2 class="block_container siloqlogofont" style="min-width:300px;">Forgot <a href="/"
                                                     target="_self"><span
    style="color:dodgerblue;">{{taxonomy}}</span><span
    class="sup"
    style="color:mediumseagreen;">&trade;</span>
                                                     </a> Password</h2>
</div>
<br>
  <alert></alert>
  <br>
<form>
<div class="row align-center">
   <div class="small-12  columns">
        <label>
            <input type="text" class="siloqinput" placeholder="Email" id="email" style="min-width:300px;"
                [(ngModel)]="loginInfo.email" name="email" required #em="ngModel" pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$">
            <div [hidden]="em.valid || em.pristine" class="siloqfont">
                <!-- <div [hidden]="!email.errors('required')">Email is required</div>
                <div [hidden]="!email.errors('pattern')">Email format should be<small><b>joe@abc.com</b></small></div>
                -->
            </div>
        </label>
    </div>
    <br>
    <br>
    <div class="small-12  columns" style="text-align:left;">
	<div style="width:300px;text-align:center;margin:auto;" class="siloqfont">
		<button (click)="forgot()" type="button" class="siloqbutton">Submit</button>
                <br>
                <br>
	</div>
    </div>
</div>
</form>
</div>
<footer></footer>

