import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import {Title} from '@angular/platform-browser';
import {TagService} from '../../services/tag-service.service';
import {TopicService} from '../../services/topic.service';
import {Tag} from '../tag/model/tag';
import {Topic} from '../topic/model/topic';
import {Observable, throwError} from 'rxjs';
import {HostListener} from '@angular/core';
import {ActivatedRoute, Router } from '@angular/router';
import {UserService} from '../../services/user.service';
import {Login} from '../../services/user.service';
import {CookieService} from 'ng2-cookies';
import {DataService } from "../../services/data.service";
import { AdminAlertComponent } from '../adminAlert/admin-alert.component';
import { AdminAlertService } from '../adminAlert/services/admin-alert.service';
import { AlertTypeEnum } from '../adminAlert/types/alertType.enum';
import { ModalService } from '../../modal/services/modal.service';
import { ModalComponent } from '../../modal/modal.component';
import { Subscription } from 'rxjs';
import { compress, decompress } from 'compress-json'
import { config } from '../../config';
import {RouterModule} from '@angular/router';
import { AdminHeaderComponent } from '../adminHeader/admin-header.component';
import { DocFooterComponent } from '../docFooter/doc-footer.component';
import { FormsModule } from '@angular/forms';
declare function escape(s:string): string;
declare function unescape(s:string): string;
declare function testJs(): any;
import { CommonModule } from '@angular/common';
import { SafeHtmlPipe } from '../tag/tag.safehtml.pipe';
import { TruncatePipe } from '../tag/tag.truncate.pipe';

@HostListener('window:keydown', ['$event'])
@Component({
  host: {
    '(document:keydown)': 'keyDown($event)',
  },
  selector: 'app-doc',
  templateUrl: './doc.component.html',
  styleUrls: ['./doc.component.css'],
  imports: [AdminAlertComponent, FormsModule, RouterModule, DocFooterComponent, AdminHeaderComponent, CommonModule, SafeHtmlPipe, TruncatePipe],
  standalone: true
})

export class DocComponent implements OnInit {

  tags: Tag[] = [];
  public topic  = new Topic('', '', '');
  utags: any;
  public model = new Tag('', '', '', '', '');
  public umodel = new Tag('', '', '', '', '');
  public ttopic = new Topic("","","");
  @ViewChild('modalTemplate', {read: TemplateRef}) modalTemplate: TemplateRef<any>;
  public selectedSlide;
  public selectedTag;
  public classTag = "Not Selected Yet";
  selectedTagIndex = -1;
  selectedSlideIndex = -1;
  browseTagIndex = -1;
  browseSlideIndex = -1;
  selectAll = false;
  editable = false;
  edit = false;
  public theUser = "";
  public theGoldiUser = "";
  currentPage: number = 1;
  pageSize = 1;
  totalCount: number = 0;
  pageCount: number = 1;
  host = window.location.protocol+"//"+window.location.hostname;
  public slides = [];
  public subtitles: string[] = [];
  public printable: boolean = false;
  loq = "";
  subscription: Subscription;
  random: number = 5;
  newDoc = false;
  subtitlesCount = 0;
  tagsCount = 0;
  public stitles: number[] = [];
  public identity = "";
  public taxonomy = "";
  public class = "";
  public subclass = "";

  constructor(private titleService: Title, private tagService: TagService, private topicService: TopicService, private activatedRoute: ActivatedRoute, public userSVC: UserService, private router: Router, private _cookieService:CookieService, private data: DataService, private alertService: AdminAlertService, private modalService: ModalService){}

  showAlert(type: AlertTypeEnum, msg: string) {
    this.alertService.setAlert({
      type,
      text: msg,
    });
  }

openModal(modalTemplate: TemplateRef<any>, size: string, title: string, message: string, callback: () => any) {
    this.modalService.open(modalTemplate, { size: size, title: title, message: message })
      .subscribe((action) => { callback(); });
  }

  openModalEx(modalTemplate: TemplateRef<any>, tag: any) {
    this.modalService
      .open(modalTemplate, { size: 'lg', title: 'Archive Loq', message: "Do you want to archive the selected loq '" + unescape(tag.value) + "' ?" })
      .subscribe((action) => {
        //TODO
        this.tagService.deleteTag(tag.id).subscribe( () => {
            this.showAlert(AlertTypeEnum['info'], "The selected loq '" + tag.value + "' has been archived.");
            //Init
            this.tags = [];
            this.utags = [];
            this.slides = [];
            this.subtitles = [];
            this.classTag = "Not Selected Yet";
            this.data.changeClassTag(this.classTag);
            this.setFirstPage();
        },
         (err: any) => {
          console.log(err);
        });
      });
  }

  getCookie(key: string){
    return this._cookieService.get(key);
  }

  ngOnInit() {
    this.theUser = this.getCookie("email");
    this.theGoldiUser = this.getCookie("gmt");
    this.activatedRoute.queryParams.subscribe(params => {
      this.model.hashTag = unescape(params['h']) || "";
      var u = params['d'] || "";
      var e = params['e'] || "";

      this.class = config.class;
      this.subclass = config.subclass;
      this.identity = config.identity;
      this.taxonomy = config.taxonomy;

      if (u != "" && this.theUser !== window.atob(u)) {
        this.showAlert(AlertTypeEnum['danger'], "It looks like that you do not have access to this page!");
        this.router.navigate(['']);
      }

      this.subscription = this.data.currentClassTag.subscribe(classTag => this.classTag = classTag)
      if (e) {
        this.editable = true;
        this.classTag = e
        //this.scrollToBottom();
      }
    });

    this.activatedRoute.fragment.subscribe((fragment: string) => {
      this.selectedSlideIndex = Number(fragment);
      if (this.selectedSlideIndex === 0) {
        this.selectedSlideIndex = -1;
      }
    })

    if(this.selectedTagIndex > 0) {
      window.location.replace(window.location.href);
    }

    if(this.model.hashTag === "newDoc") {
      var d = new Date();
      this.model.hashTag = "imoqr_" + d.toDateString().toLowerCase() + "_" + this.curbemail(this.theUser);
      this.model.email = this.theUser;
      this.editable = true;
      this.newDoc = true;
      return;
    }

    if(this.model.hashTag) {
      this.slides = [];
      this.subtitles = [];
      this.utags = [];

      this.getTopic(escape(this.model.hashTag), this.theUser, (t: any) => {
        if (t.email) {
          document.getElementById("loading").style.display = "inline";
          this.topic = t;
          this.topic.hashTag = unescape(t.hashTag);
          this.data.changeMessage(unescape(t.hashTag));
          this.titleService.setTitle(unescape(t.hashTag));
          this.setFirstPage();
        }
        return;
      });
    }
  }

  loadTags(callback: () => any) {
    this.getTopicTags(callback);
  }

  getTopicTags(callback: () => any) {
    this.topicService.getTopicTags(this.topic)
      .subscribe(
       (tags: any) => {
          this.tags = tags; //Bind to view
          if(this.tags !== undefined) {
            if(this.tags.length <= 0) {
              document.getElementById("loading").style.display = "none";
              return;
            }

            if(callback !== undefined) {
              callback();
            }


            for (let i = 0; i < this.tags.length; i++) {

              this.tags[i].hashTag = unescape(this.tags[i].hashTag);
              if(!this.tags[i].name) {
                this.tags[i].name = this.tags[i].hashTag;
              }

              if(this.tags[i].value.length > 0 && this.tags[i].name.length > 0) {
                this.tagsCount++;
                this.tags[i].name = unescape(this.tags[i].name);
                this.tags[i].value = unescape(this.tags[i].value);
                if(this.classTag && this.classTag === this.tags[i].id){
                  this.classTag = this.tags[i].name;
                }
                this.mapTags(this.tags[i]);
              }
            }

            this.subtitlesCount = 0;
            this.addValueToName("Synopsis", new Tag(this.model.hashTag, 'Synopsis', window.location.href, "", this.topic.id));
            for (let i = 0; i <= this.subtitles.length || i <= 200; i++) {
              this.stitles[i] = 0;
            }

            for (let i = 0; this.subtitles && i < this.subtitles.length; i++) {
              if (this.subtitles[i].toLowerCase() !== this.topic.hashTag.toLowerCase()) {
                 this.subtitlesCount++;
                 let n = +this.subtitles[i].split(". ")[0];
                  if(n > 0) {
                    this.stitles[n]++;
                  }
              }

              var clone = Object.assign({}, this.slides[this.subtitles[i]][0]);
              clone.value = "";
              this.slides[this.subtitles[i]].unshift(clone);
              if (this.slides[this.subtitles[i]][0].name !== "") {
                this.addValueToName("Synopsis", new Tag(this.model.hashTag, '', window.location.href, this.slides[this.subtitles[i]][0].name, this.topic.id));
              }
            }

            if(this.tags[0]) {
              var ix = this.subtitles.indexOf(this.tags[0].hashTag.toLowerCase());
              if(ix > 0) {
                var tt = this.subtitles.splice(ix, 1);
                this.subtitles.unshift(this.tags[0].hashTag.toLowerCase());
              }
            }

            this.addValueToName("Synopsis", new Tag(this.model.hashTag, '', window.location.href, "Appendix", this.topic.id));
            this.subtitles.unshift("Synopsis");

            this.subtitles.push("Appendix");
            //To be consistant with synopsis and other subtitles
            this.addValueToName("Appendix", new Tag(this.model.hashTag, '', window.location.href, this.model.hashTag, this.topic.id));
            for (let i = 0; this.utags && i < this.utags.length; i++) {
              if(this.utags[i]) {
                this.addValueToName("Appendix", new Tag(this.model.hashTag, '', window.location.href, this.utags[i], this.topic.id));
              }
            }

            this.totalCount = this.subtitles.length;
            this.pageSize = this.totalCount;

            this.setPageVisible();
            document.getElementById("loading").style.display = "none";
          }
        },
         (err: any) => {
          console.log(err);
        });
  }

  getTopic(h: string, e: string, callback: (t: any) => any) {
    this.topicService.getTopic(h, e)
    .subscribe({ next: (t: any) => {
                          callback(t);
                         },
                         error: (error: any) => {
                          console.log(error);
                         }
                       });





    /*
    .subscribe(
      (data: any) => {
        callback(data);
      },
       (err: any) => {
        console.log(err);
      });
      */
  }

  mapTags(tag: Tag){
    //if (tag.position > 0) { //Do not add the title at index 0
      this.subtitles.indexOf(tag.name.toLowerCase()) === -1 && this.subtitles.push(tag.name.toLowerCase());
      this.addValueToName(tag.name.toLowerCase(), tag);
    //}
  }

  addValueToName(name: any, value: any) {
    // Shorcut || returns left side if it is "truthy," or the right otherwise.
    // This means that we only assign a new Array to the Object's property
    // if it has not previously been used.
    this.slides[name] = this.slides[name] || [];
    // Adds a value to the end of the Array
    this.slides[name].push(value);
  }

  setSearchText() {
    this.data.changeMessage(this.model.hashTag.split(' ').slice(0,6).join(' ')  + " " + this.selectedSlide.split(' ').slice(0,6).join(' ') + " " + this.selectedTag.value.split   (' ').slice(0,6).join(' '));
  }

  public selectSlide( slide: any, i: number) {
    this.selectedSlideIndex = i;
    this.selectedSlide =  slide;
    this.setSearchText();
  }

 public selectTag( tag: any, j: number) {
    tag.rank = j + 1;
    this.selectedTagIndex = j;
    this.selectedTag =  tag;
    this.setSearchText();
  }

 public keyDown(event: any) {
    switch (event.keyCode) {
      case 38:
        if(event.shiftKey) {
          this.onTagUp();
        } else {
          this.onUp();
        }
        break;
      case 40:
        if(event.shiftKey) {
          this.onTagDown();
        } else {
          this.onDown();
        }
        break;
      case 13:
        if(event.shiftKey) {
          this.submitTag();
          event.preventDefault();
        }
        event.stopPropagation();
        break;
      case 69:
        if(event.metaKey) {
          this.editable = true;
        }
        break;
      case 27:
        this.editable = false;
        this.edit = false;
        this.escapeIndex();
        break;
    }
    return;
  }

  escapeIndex() {
    this.selectedSlideIndex = -1;
    this.selectedTagIndex = -1;
    this.browseTagIndex = -1;
    this.browseSlideIndex = -1;
  }

  public onDown() {
    if(this.selectedSlideIndex < this.subtitles.length - 1)
    {
      this.selectedSlide = this.slides[++this.selectedSlideIndex];
      this.selectTag(this.slides[this.selectedSlide][0], 0);
    }
  }

  public onUp() {
    if(this.selectedSlideIndex > 0)
    {
      this.selectedSlide = this.slides[--this.selectedSlideIndex];
      this.selectTag(this.slides[this.selectedSlide][0], 0);
    }
  }

  public onTagDown() {
    if(this.selectedTagIndex < this.tags.length - 1)
    {
      this.selectedTag = this.slides[++this.selectedTagIndex];
      this.setSearchText();
    }
  }

  public onTagUp() {
    if(this.selectedTagIndex > 0)
    {
      this.selectedTag = this.slides[--this.selectedTagIndex];
      this.setSearchText();
    }
  }

  refIndex(source: string): number {
    if (source.trim() !== "") {
       return this.utags.indexOf(source) + 1;
    }

    return -1;
  }

  public setFirstPage() {
    this.loadTags(() => {
      if(this.tags.length > 0) {
        this.utags = Array.from(new Set(this.tags.map((item) => item.source)));
      }
    });
  }

  setPageVisible() {
    this.selectedSlide = this.subtitles[this.currentPage-1];
  }

  renderTag(tag: Tag){
    if (tag.value == "" && tag.position > 1) {
      tag.name = '<div style="margin-left:' + (tag.position - 1) * 40 + 'px;">' + tag.name + '</div>';
    }
    if (tag.value != "" && tag.position > 1) {
      tag.value = '<div style="margin-left:' + (tag.position - 1) * 40 + 'px;">' + tag.value + '</div>';
    }
  }

  updateTag(tag: Tag){
    var t = Object.assign({}, tag);

    t.hashTag = escape(t.hashTag);
    t.name = escape(t.name);
    t.value = escape(t.value);
    let commentOperation:Observable<Tag[]>;
    this.tagService.updateTag(t).subscribe(
      () => {
      },
       (err: any) => {
        console.log(err);
      });
  }

  updateTagName(tag: Tag){
    for (let i = 0; i < this.slides[this.selectedSlide].length; i++) {
      this.slides[this.selectedSlide][i].name = tag.name;
    }
    this.updateTag(tag)
  }

  deleteTag(tag: any){
    let commentOperation:Observable<Tag[]>;
    if(this.editable) {
      //TODO: Uncomment ASAP
      this.openModalEx(this.modalTemplate, tag);
      //this.tagService.deleteTag(tag.id).subscribe(() => {});
    }
  }

  addClass(tag: any){
    let commentOperation:Observable<Tag[]>;
    if(this.editable) {
      document.getElementById("loq").focus();
      this.classTag = unescape(tag.name);
    }
  }

////////////// below belongs to doc //////////


  editTag(tag: any) {
    this.umodel = new Tag(tag.hashTag, tag.userId, tag.source, tag.value, this.topic.id);
    tag.edit = !tag.edit;
  }

  shareDoc() {
    if(this.topic.hashTag === undefined) {
      this.showAlert(AlertTypeEnum['warning'], "Only owners of the topic can share!");
      return;
    }

    this.router.navigate(['/admin/share'],  { queryParams: { h : this.topic.hashTag}});
  }

  branchDoc() {
    if(this.topic.hashTag === undefined) {
      this.showAlert(AlertTypeEnum['warning'], "Only owners of the topic can clone!");
      return;
    }
    this.router.navigate(['/admin/branch'],  { queryParams: { h : this.topic.hashTag}});
  }

  publicDoc() {
    if(this.topic.hashTag === undefined) {
      this.showAlert(AlertTypeEnum['warning'], "Only owners of the topic can publish!");
      return;
    }

    if(this.topic.topics.indexOf(this.topic.hashTag+"_public") >= 0) {
      this.router.navigate(['/public'],  { queryParams: { h : escape(this.topic.hashTag), d : window.btoa(this.topic.email)}});
      return;
    }

    var lx = this.topic.hashTag.lastIndexOf("_public");
    if( lx == this.topic.hashTag.length-"_public".length) {
      //TODO: Check if this belong to public user
      this.router.navigate(['/public'],  { queryParams: { h : escape(this.topic.hashTag.substring(0, lx)), d : window.btoa(this.topic.email)}});
      return;
    }

    this.openModal(this.modalTemplate, "md", "Publish Doc", "Do you want to publish " + this.topic.hashTag + "?", () => {
      this.atomicPublishTopic(escape(this.topic.hashTag), this.topic.email, (t) => {
        this.router.navigate(['/public'],  { queryParams: { h : t.hashTag, d : window.btoa(t.email)}});
      });
    });
  }

dedoc() {
    this.router.navigate(['/admin/dedoc'],  { queryParams: { h : this.model.hashTag}});
    return;
  }

review() {
    this.router.navigate(['/admin/review'],  { queryParams: { h : this.model.hashTag}});
    return;
  }

  pressDoc() {
    if(this.topic.hashTag === undefined) {
      this.showAlert(AlertTypeEnum['warning'], "Only owners of the topic can press release!");
      return;
    }

    if(this.topic.topics.indexOf(this.topic.hashTag+"_press") >= 0) {
      this.router.navigate(['/release'],  { queryParams: { h : escape(this.topic.hashTag), d : window.btoa(this.topic.email)}});
      return;
    }

    var lx = this.topic.hashTag.lastIndexOf("_press");
    if( lx == this.topic.hashTag.length-"_press".length) {
      //TODO: Check if this belong to public user
      this.router.navigate(['/release'],  { queryParams: { h : escape(this.topic.hashTag.substring(0, lx)), d : window.btoa(this.topic.email)}});
      return;
    }

    this.openModal(this.modalTemplate, "md", "Press Doc", "Do you want to press release <b>" + this.topic.hashTag + "</b>?", () => {
      this.atomicPressTopic(escape(this.topic.hashTag), this.topic.email, () => {
        this.router.navigate(['/release'],  { queryParams: { h : escape(this.topic.hashTag), d : window.btoa(this.topic.email)}});
      });
    });
  }

  renameTopic(){
    if(this.topic.hashTag === undefined) {
      this.showAlert(AlertTypeEnum['warning'], "Only owners of the topic can rename!");
      return;
    }


    var d = new Date();
    var h = this.taxonomy + "_" + d.toDateString().toLowerCase() + "_" + this.curbemail(this.theUser);
    if (this.model.hashTag === "") {
      return;
    }

    this.topic.hashTag = escape(this.topic.hashTag);
    //TODO: Remove this hack
    //Or, is it a good idea to keep and follow?!
    this.topic.value = escape(this.model.hashTag);
    this.topic.email = this.theUser;
    this.topicService.renameTopic(this.topic).subscribe(
      () => {
        this.router.navigate(['/admin/doc'],  { queryParams: { h : this.model.hashTag, e : this.model.hashTag}});
      },
       (err: any) => {
        console.log(err);
      });
    this.data.changeMessage(this.model.hashTag);
  }

  rankup(i: number){
    if (i > 1) {
      var t1 = this.tags[i];
      t1.rank = t1.rank - 1;
      this.rankTag(t1)

      var t = this.tags[i].rank;
      this.tags[i].rank = this.tags[i-1].rank;
      this.tags[i-1].rank = t;

      var tag = this.tags[i];
      this.tags[i] = this.tags[i-1];
      this.tags[i-1] = tag;
    }
  }

  rankdown(i: number){
    if (i < this.tags.length - 1) {
      var t1 = this.tags[i];
      t1.rank = t1.rank + 1;
      this.rankTag(t1)

      var t = this.tags[i].rank;
      this.tags[i].rank = this.tags[i+1].rank;
      this.tags[i+1].rank = t;

      var tag = this.tags[i];
      this.tags[i] = this.tags[i+1];
      this.tags[i+1] = tag;
    }
  }

  rankTag(tag: Tag){
    this.tagService.rankTag(tag).subscribe(
      () => {
      },
       (err: any) => {
        console.log(err);
      });
  }

  convertToId(source: string): string {
    return source.replace(/ /g, "-");
  }

  routeToPageSection(i: number): string {
    return '/admin/doc?h=' + this.topic.hashTag + '#' + i;
  }

  cutUntil(tag: Tag): string {
    var index = tag.source.indexOf("#");
    if(index > 1) {
      var url = tag.source.substring(0, index);
      return url+"#:~:text="+encodeURIComponent(tag.value).replace(/-/g, "%2D");
    } else {
      return tag.source+"#:~:text="+encodeURIComponent(tag.value).replace(/-/g, "%2D");
    }
  }

/*
  cloneTopic(t: Topic, callback: () => any) {
    this.topicService.cloneTopic(t).subscribe(
      () => {
        callback();
      },
       (err: any) => {
        console.log(err);
      });
  }
  */

 atomicPublishTopic(h: string, e: string, callback: (t: any) => any) {
    this.topicService.publishTopic(h, e).subscribe(
      (data: any) => {
        callback(data);
      },
       (err: any) => {
        console.log(err);
      });
  }

  atomicPressTopic(h: string, e: string, callback: (t: any) => any) {
    this.topicService.pressTopic(h, e).subscribe(
      (data: any) => {
        callback(data);
      },
       (err: any) => {
        console.log(err);
      });
  }

  updateTopic(topic: Topic,  callback: (t: any) => any){
    let commentOperation:Observable<Topic[]>;
    this.topicService.updateTopic(topic).subscribe(
             (data: any) => {
                  callback(data);
              },
       (err: any) => {
        console.log(err);
      });
  }

  export() {
    this.escapeIndex();
    this.printable = false;
    setTimeout(()=>{
    if(!this.editable) {
      window.print();
      this.printable = true;
       } else {
        this.showAlert(AlertTypeEnum['warning'], "Can't download during edit mode!");
       }
    }, 1000);
  }

  curbemail(email: string): string {
       if(!email) {
         return email;
       }

       var index = email.indexOf("@");
       if(index > 0) {
         return email.substring(0, index);
       }
    return email;
     }

   profileEx(h: any, d: any) {
    this.router.navigate(['profile'],  { queryParams: { h : h, d : window.btoa(d)}});
    return;
  }

   docTopic2(h: any, d: any) {
    this.router.navigate(['/admin/doc'],  { queryParams: { h : this.model.hashTag, "#" : this.classTag}});
    return;
  }

  addNewTopic(){
    if (this.model.hashTag.trim() === "") {
      return;
    }

    this.topic = new Topic('', '', '');
    this.topic.hashTag = escape(this.model.hashTag);
    this.topic.email = this.theUser;
    this.topicService.addTopic(this.topic).subscribe(
      () => {
      },
       (err: any) => {
        console.log(err);
      });

    //this.showAlert(AlertTypeEnum['success'], "The new topic '" + this.topic.hashTag + "' has been added!");
    this.router.navigate(['/admin/doc'],  { queryParams: { h : this.model.hashTag, e : this.model.hashTag}});
  }

  getContent(innerHTML){
    this.model.value = innerHTML.trim();
    this.data.changeMessage(this.model.hashTag.split(' ').slice(0,6).join(' ') + " " + this.classTag.replace('&gt;&gt;&gt;','').split(' ').slice(0,6).join(' ') + " " + this.model.value.split(' ').slice(0,6).join(' '));
  }


  open(){
   var link = document.getElementById('upload');
   link.click();
  }

  import(){
   if(this.topic.hashTag === undefined) {
      this.showAlert(AlertTypeEnum['warning'], "Only owners of the topic can upload SQB!");
      return;
    }
    this.router.navigate(['/admin/sqb'],  { queryParams: { h : this.model.hashTag}});

    return;
  }

     fileChangeEvent(fileInput:any){
          document.getElementById("loading").style.display = "inline";
          var currentFile = fileInput.target.files[0];
          var ht = currentFile.name.split(".slq")[0]
          let reader = new FileReader();
          reader.onload = () => {
            var tags = decompress(JSON.parse(reader.result as string));
            //var tags = JSON.parse(reader.result);
            if(tags.length > 0) {
              this.getTopic(escape(ht), this.theUser, (t: any) => {
                    if(!t.hashTag){
                     for (let i = 0; i < tags.length; i++) {
                       //alert(tags[i].name+" >>>>>>>>>>> "+tags[i].value);
                       if (!tags[i]) {
                          tags.splice(i, 1);
                        }
                          tags[i].hashTag = escape(ht);
                          tags[i].email = this.theUser;
                      }
                     this.addTags(tags);
                    }
              });
             }
          };
          reader.readAsText(currentFile);
    }

addTags(tags: Tag[]) {
    this.tagService.addTags(tags).subscribe(
     (t: any) => {
         this.model.value = "";
         document.getElementById("loading").style.display = "none";
         this.router.navigate(['/admin/doc'],  { queryParams: { h : t.hashTag}});
      },
       (err: any) => {
        document.getElementById("loading").style.display = "none";
        console.log(err);
      });
  }

  submitTag(){
    document.getElementById("loq").innerHTML = "";
    document.getElementById("loq").innerHTML = document.getElementById("loq").innerHTML.trim();
    if (this.model.hashTag === "") {
      return;
    }

    let commentOperation:Observable<Tag[]>;
    if (this.model.value.trim() === "" ) {
      return;
    }

    var t = new Tag(this.model.hashTag, '', '', '', '')
    t.hashTag = this.model.hashTag;
    t.name = "";
    t.value = this.model.value;

    if (t.value.startsWith("&gt;&gt;&gt;") || t.value.startsWith("&lt;&lt;&lt;")) {
      if (t.value.length > 12) {
          this.classTag = t.value.substring(12);
      } else {
        this.classTag = "Not Selected Yet";
      }
      this.model.value = "";
      this.data.changeClassTag(this.classTag);

      return;
    }

    if(this.classTag.trim() !== "" &&  this.classTag !== "Not Selected Yet") {
      t.name = escape(this.classTag);
    } else {
      t.hashTag = escape(t.hashTag);
    }

    t.email = this.theUser;
    t.source = this.theUser;
    //this.data.changeMessage(t.hashTag.split(' ').slice(0,6).join(' ') + " " + this.classTag.replace('&gt;&gt;&gt;','').split(' ').slice(0,6).join(' ') + " " + t.value.split(' ').slice(0,6).join(' '));
    t.value = escape(t.value);
    t.topicId = this.topic.id;
    this.tagService.addTag(t).subscribe(
    (t: any) => {
        this.router.navigate(['/admin/doc'],  { queryParams: { h : t.hashTag, e : t.id}});
      },
       (err: any) => {
        console.log(err);
      });

    t.hashTag = t.hashTag;
    t.name = "";
    var v = t.value;
    if (this.classTag.trim() !== "" && this.classTag !== "Not Selected Yet") {
      t.value = "";
      t.name = this.classTag;
      if(this.slides[t.name.toLowerCase()]){
      } else {
        t.name = t.name;
        t.value = t.value;
        this.tags.push(t);
      }
    } else {
      if(this.slides[t.name.toLowerCase()]){
      } else {
        t.name = t.name;
        t.value = t.value;
        this.tags.push(t);
      }
    }
    t.value = v;

     this.tags.push(t); this.setTopicUserTags();

    //TODO:
    //this.scrollToBottom();
  }

  scrollToBottom() {
    const element = document.getElementById('loq');
    //element.scrollTop = element.scrollHeight;
    element.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }

  scrollToTop() {
    const element = document.getElementById('top');
    element.scrollTop = 0;
  }

  setTopicUserTags() {
    this.slides = [];
    this.subtitles = [];

    if(this.tags.length > 0) {
      this.utags = Array.from(new Set(this.tags.map((item) => item.source)));
    }

    for (let i = 0; i < this.tags.length; i++) {
      this.tags[i].hashTag = unescape(this.tags[i].hashTag);
      this.tags[i].name = unescape(this.tags[i].name);
      this.tags[i].value = unescape(this.tags[i].value);
      this.mapTags(this.tags[i]);
    }

    for (let i = 0; this.subtitles && i < this.subtitles.length; i++) {
      this.addValueToName("Synopsis", new Tag(this.model.hashTag, '', window.location.href, this.subtitles[i], this.model.id));
    }
    this.addValueToName("Synopsis", new Tag(this.model.hashTag, '', window.location.href, "Appendix", this.model.id));
    this.subtitles.unshift("Synopsis");

    this.subtitles.push("Appendix");
    //To be consistant with synopsis and other subtitles
    this.addValueToName("Appendix", new Tag(this.model.hashTag, '', window.location.href, this.model.hashTag, this.model.id));
    for (let i = 0; this.utags && i < this.utags.length; i++) {
      this.addValueToName("Appendix", new Tag(this.model.hashTag, '', window.location.href, this.utags[i], this.model.id));
    }

    this.totalCount = this.subtitles.length;
    this.pageSize = this.totalCount;

    this.setPageVisible();
  }

  renderImage(t: any) {
  if(!t) {
       return t;
  }
  var s = t;
  t = t.replace(/<(?:.|\n)*?>/gm, '');
   if (t.startsWith("http://") || t.startsWith("https://")) {
       const img = new Image();
       img.src = t;
       var w = 300;
       var h = (w/img.width)*img.height;
       t = '<img src="' + t + '" style="width:' + w + 'px; height:' + h + 'px;" />'
       return t;
    }
       return s;
  }

  cloneTopic(t: Topic, callback: (t: any) => any){
         this.topicService.cloneTopic(t).subscribe(
          (data: any) => {
                 callback(data);
               },
            (err: any) => {
             console.log(err);
           });
       }

       cloneTopicToUser(t: Topic, callback: (t: any) => any){
       this.topicService.cloneTopicToUser(t).subscribe(
        (data: any) => {
               callback(data);
             },
          (err: any) => {
           console.log(err);
         });
     }

     branchTopic(suffix: string){
       if (this.topic.hashTag === undefined) {
         return;
       }

       if(suffix === "exam") {
        this.openModal(this.modalTemplate, "md", "Start Exam", "Do you want to start the exam for <b>" + this.topic.hashTag + "</b>?", () => {
          this.showAlert(AlertTypeEnum['info'], "Please hold on while we are preparing the exam.");
          document.getElementById("loading").style.display = "inline";
          var tans = Object.assign({}, this.topic);
          var t = this.topic;
          t.hashTag = escape(unescape(t.hashTag+"_exam"));
          t.users = [];
          t.users.push(this.theUser);
          t.users.push('exam.imoqr@siloq.com');
          this.updateTopic(t, (t1: any) => { });

          tans.hashTag = escape(unescape(tans.hashTag+"_exam_answer"));
          tans.users = [];
          tans.users.push(this.theUser);
          tans.users.push('answer.imoqr@siloq.com');
          this.cloneTopicToUser(tans, (t: any) => {
            document.getElementById("loading").style.display = "none";
            this.router.navigate(['/admin/exam'], { queryParams: { h : t.hashTag, t : "Answer", u : t.users[1]}});
          });
        });
      }

       if(suffix === "sub") {
         let ht = escape(prompt("New Doc name for the selected LOQs: ", this.topic.hashTag));
         var tags = [];
         if (ht) {
            if(this.tags.length > 0) {
            //TODO: escape
              this.getTopic(ht, this.theUser, (t: any) => {
                    if(!t.hashTag){
                     for (let i = 0; i < this.tags.length; i++) {
                        if(this.tags[i].checked) {
                          this.tags[i].checked = false;
                          var clone = Object.assign({},this.tags[i]);
                          clone.hashTag = ht;
                          clone.email = this.theUser;
                          tags.push(clone);
                        }
                      }
                     this.addTags(tags);
                     this.router.navigate(['/admin/doc'], { queryParams: { h : t.hashTag}});
                    }
              });
             }
          //TODO: Add Tags to the new Doc
         }
       }

   }

     jsunescape(t: string) {
     return unescape(t)
     }

     validateEmail(email: any) {
         const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
         return re.test(email);
       }

      save() {
         this.showAlert(AlertTypeEnum['info'], "File will be automatically downloaded!");
         var sqb = JSON.stringify(compress(this.tags));
         //var sqb = JSON.stringify(this.tags);

         if(sqb.length > 0) {
           this.download(this.topic.hashTag+".slq", sqb);
         }
      }

    download(filename: string, text: string) {
      var element = document.createElement('a');
      element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
      element.setAttribute('download', filename);

      element.style.display = 'none';
      document.body.appendChild(element);

      element.click();

      document.body.removeChild(element);
    }

   setState(event: any, tag: Tag) {
      if(tag.value === "") {
        var key = tag.name.toLowerCase();
        for (let i = 1; i < this.slides[key].length; i++) {
          this.slides[key][i].checked=event.target.checked;
        }
      } else {
        tag.checked=event.target.checked;
      }
      event.stopPropagation();

      return;
   }

   setStateAll(event: any) {
      event.target.checked=!event.target.checked;
      this.edit=!this.edit;
   }
}

