import { Component, OnInit, NgZone } from '@angular/core';
import {Title} from '@angular/platform-browser';
import {TagService} from '../services/tag-service.service';
import {TopicService} from '../services/topic.service';
import {TinyService} from '../services/tiny.service';
import {IdService} from '../services/id.service';
import {Tag} from '../admin/tag/model/tag';
import {Topic} from '../admin/topic/model/topic';
import {Taxon} from '../admin/topic/model/topic';
import {Tiny} from '../admin/tiny/model/tiny';
import {User} from '../admin/user/model/user';
import {Observable, throwError} from 'rxjs';
import {HostListener} from '@angular/core';
import {ActivatedRoute, Router } from '@angular/router';
import {UserService} from '../services/user.service';
import {Login} from '../services/user.service';
import {CookieService} from 'ng2-cookies';
import {DataService } from "../services/data.service";
import { AlertComponent } from '../alert/alert.component';
import { AlertService } from '../alert/services/alert.service';
import { AlertTypeEnum } from '../alert/types/alertType.enum';
import { DomSanitizer } from '@angular/platform-browser';
import { config } from '../config';
import {RouterModule} from '@angular/router';
import { HeaderComponent } from '../header/header.component';
import { HomeFooterComponent } from '../homeFooter/home-footer.component';
import { FormsModule } from '@angular/forms';
import {DatePipe} from '@angular/common';

declare function escape(s:string): string;
declare function unescape(s:string): string;

@HostListener('window:keydown', ['$event'])
@Component({
  host: {
    '(document:keydown)': 'keyDown($event)',
  },
  selector: 'app-public',
  templateUrl: './public.component.html',
  styleUrls: ['./public.component.css'],
  imports: [AlertComponent, FormsModule, RouterModule, HomeFooterComponent, HeaderComponent, DatePipe],
  standalone: true
})
export class PublicComponent implements OnInit {

  tags: Tag[];
  ctags: Tag[];
  public topic = new Topic('', '', '');
  utags: any;
  public model = new Tag('', '', '', '', '');
  public theUser = "";
 public theGoldiUser = "";
  loggedinUser="";
  tinUrl = "";//config.slqioUrl;
  user = new User("", "", "");
  selectedIndex: number = -1;
  public selectedSlide;
  selectedTagIndex = -1;
  selectedSlideIndex = -1;
  browseTagIndex = -1;
  browseSlideIndex = -1;
  public selectedTag;
  printable: boolean = true;
  ismock: boolean = false;
  qrcode: any;
  public slides = [];
  public subtitles: string[] = [];
  mockUrl="";


  constructor(private _sanitizer: DomSanitizer, private titleService: Title, private ngZone: NgZone, private tagService: TagService, private topicService: TopicService, private tinyService: TinyService, private idService: IdService, private activatedRoute: ActivatedRoute, public userSVC: UserService, private router: Router, private _cookieService:CookieService, private data: DataService, private alertService: AlertService){}

  showAlert(type: AlertTypeEnum, msg: string) {
    this.alertService.setAlert({
      type,
      text: msg,
    });
  }

  getCookie(key: string){
    return this._cookieService.get(key);
  }

  ngOnInit() {
    this.loggedinUser = this.getCookie("email");
    this.activatedRoute.queryParams.subscribe(params => {
      this.topic.hashTag = unescape(params['h']) || "";
      this.topic.email = window.atob(params['d']) || "";
      this.tinUrl = config.slqioUrl;

      this.getTopic(this.topic.hashTag, this.topic.email, (t: any) => {
        this.topic = t;
        this.topic.hashTag = unescape(this.topic.hashTag);
      });
    });

    this.activatedRoute.fragment.subscribe((fragment: string) => {
      this.selectedIndex = Number(fragment);
      if (this.selectedIndex === 0) {
        this.selectedIndex = -1;
      }
    })

    if(this.topic.hashTag) {
      this.slides = [];
      this.subtitles = [];

      this.data.changeMessage(this.topic.hashTag + " " + this.curbemail(this.topic.email));
      this.theUser = "tagndoc@tagndoc.com";
      this.model.hashTag = "comment";
      this.model.email = this.theUser;
      this.model.value = "";
      this.model.source = escape(this.topic.hashTag);
      this.titleService.setTitle(this.topic.hashTag);
      this.setFirstPage();

      if(this.selectedIndex > 0) {
        window.location.replace(window.location.href);
      }

      if(this.topic.users && this.topic.users.indexOf("mock@siloq.com") >= 0 ){
        this.ismock = true;
        this.gotomock(this.topic);
      }
    }
  }

  getTopicUserTags(callback: () => any) {
    var ht = this.topic.hashTag;
    var lx = ht.lastIndexOf("_public");
    if(lx < 0) {
      ht += "_public"
    }

    this.tagService.getTopicUserTags(escape(ht), this.theUser)
      .subscribe(
       (tags: any) => {
          this.tags = tags; //Bind to view
          if(this.tags !== undefined) {
            if(this.topic.hashTag==="" || this.topic.hashTag==='undefined') {
              this.topic.hashTag="Not Available";
              return;
            }

            if(callback !== undefined) {
              callback();
            }
             for (let i = 0; i < this.tags.length; i++) {
                this.tags[i].hashTag = unescape(this.tags[i].hashTag);
                this.tags[i].name = unescape(this.tags[i].name);
                this.tags[i].value = unescape(this.tags[i].value);
                this.mapTags(this.tags[i]);
              }
              for (let i = 0; this.subtitles && i < this.subtitles.length; i++) {
                if (this.slides[this.subtitles[i]][0].name && this.slides[this.subtitles[i]][0].name.length  > 0) {
                  this.addValueToName("Synopsis", new Tag(this.model.hashTag, '', window.location.href, this.slides[this.subtitles[i]][0].name, this.model.id));
                }
              }

/*
              this.addValueToName("Synopsis", new Tag(this.model.hashTag, '', window.location.href, "Appendix"));
              this.subtitles.unshift("Synopsis");
*/
              this.subtitles.push("Appendix");
              //To be consistant with synopsis and other subtitles
              this.addValueToName("Appendix", new Tag(this.model.hashTag, '', window.location.href, this.model.hashTag, this.model.id));
              for (let i = 0; this.utags && i < this.utags.length; i++) {
                this.addValueToName("Appendix", new Tag(this.model.hashTag, '', window.location.href, this.utags[i], this.model.id));
              }

            this.setPageVisible();
          }
        },
         (err: any) => {
          console.log(err);
        });
  }

  ngAfterViewInit() {
       this.setQrCode();
  }

  mapTags(tag: Tag){
    //if (tag.position > 0) { //Do not add the title at index 0
      this.subtitles.indexOf(tag.name.toLowerCase()) === -1 && this.subtitles.push(tag.name.toLowerCase());
      this.addValueToName(tag.name.toLowerCase(), tag);
    //}
  }

  addValueToName(name: any, value: any) {
    // Shorcut || returns left side if it is "truthy," or the right otherwise.
    // This means that we only assign a new Array to the Object's property
    // if it has not previously been used.
    this.slides[name] = this.slides[name] || [];
    // Adds a value to the end of the Array
    this.slides[name].push(value);
  }

  getTopicSourceTags(callback: () => any) {
    this.tagService.getTopicSourceTags(escape(this.model.hashTag), this.topic.hashTag)
      .subscribe(
       (tags: any) => {
          this.ctags = tags; //Bind to view
          if(this.ctags !== undefined) {
            if(callback !== undefined) {
              callback();
            }

            for (let i = 0; i < this.ctags.length; i++) {
                          this.ctags[i].hashTag = unescape(this.ctags[i].hashTag);
                          this.ctags[i].name = unescape(this.ctags[i].name);
                          this.ctags[i].value = unescape(this.ctags[i].value);
                        }
          }
        },
         (err: any) => {
          console.log(err);
        });
  }

  getTopic(h: string, e: string, callback: (t: any) => any) {
    this.topicService.getTopic(escape(h), e).subscribe(
      (data: any) => {
        callback(data);
      },
       (err: any) => {
        console.log(err);
      });
  }

  getContent(innerText){
    this.model.value = innerText;
  }


  submitTag(){
    if (this.model.value === "" )
    {
      return;
    }

    if (this.loggedinUser  === undefined || !this.loggedinUser || this.loggedinUser.length === 0)
    {
      return;
    }

    this.model.hashTag = "comment";
    this.theUser = "tagndoc@tagndoc.com";
    this.model.email = this.loggedinUser;
    this.model.source = this.topic.hashTag;

    this.tagService.addTag(this.model).subscribe(
      () => {
        this.model.value = "";
        this.setFirstPage();
      },
       (err: any) => {
        console.log(err);
      });
  }

  renderTag(tag: Tag){
    if (tag.value == "" && tag.position > 1) {
      tag.name = '<div style="margin-left:' + (tag.position - 1) * 40 + 'px;">' + tag.name + '</div>';
    }
    if (tag.value != "" && tag.position > 1) {
      tag.value = '<div style="margin-left:' + (tag.position - 1) * 40 + 'px;">' + tag.value + '</div>';
    }
  }

  refIndex(source: string): number {
    return this.utags.indexOf(source) + 1;
  }

  //pagination for table
  public setFirstPage() {
    this.getTopicUserTags(() => {
      if(this.tags.length > 0) {
        this.utags = Array.from(new Set(this.tags.map((item) => item.source)));
      }
    });

    this.getTopicSourceTags(() => {
      });

    this.getUserByEmail();
  }

  getUserByEmail() {
    this.userSVC.getUserByEmail(this.theUser)
      .subscribe(
        (user: any) => {
          if(user!=undefined){
            this.user = user;
           // window.location.replace(window.location.href);
          }
        },
         (err: any) => {
          console.log(err);
        });
  }

  convertToId(source: string): string {
   if(source) {
      return source.replace(/ /g, "-");
   }
   return source;
  }

public selectSlide( slide: any, i: number) {
    this.selectedSlideIndex = i;
    this.selectedSlide =  slide;
    this.setSearchText();
  }

 public selectTag( tag: any, j: number) {
    tag.rank = j + 1;
    this.selectedTagIndex = j;
    this.selectedTag =  tag;
    this.setSearchText();
  }

  routeToPageSection(tag: any, i: number): string {
    return '/public?h=' + this.topic.hashTag + "&d=" + window.btoa(this.topic.email) + '#' + this.convertToId(tag.name) + i;
  }

  routeToTag(i: number): string {
    return '/public?h=' + this.topic.hashTag + "&d=" + window.btoa(this.topic.email) + '#' + i;
  }

  cutUntil(tag: Tag): string {
    var index = tag.source.indexOf("#");
    if(index > 1) {
      var url = tag.source.substring(0, index);
      return url+"#:~:text="+encodeURIComponent(tag.value).replace(/-/g, "%2D");
    } else {
      return tag.source+"#:~:text="+encodeURIComponent(tag.value).replace(/-/g, "%2D");
    }
  }

  curbemail(email: string): string {
    if(!email) {
      return email;
    }

    var index = email.indexOf("@");
    if(index > 0) {
      return email.substring(0, index);
    }
   return email;
  }

  id(callback: (id: string) => any) {
    this.idService.id()
      .subscribe(
        (id: any) => {
          if(id!=undefined){
            callback(id);
          }
        },
         (err: any) => {
          console.log(err);
        });
  }

  tinyUrl(tiny: Tiny) {
    this.tinyService.tinyUrl(tiny)
      .subscribe(
        (tiny: any) => {
          if(tiny!=undefined){
            ;
          }
        },
         (err: any) => {
          console.log(err);
        });
  }

  qrCode(tinUrl: string, callback: (qrcode: string) => any) {
    this.topic.value = tinUrl;
    this.topicService.qrCode(this.topic)
      .subscribe(
        qrcode => {
          if(qrcode!=undefined){
            callback(qrcode);
          }
        },
         (err: any) => {
          console.log(err);
        });
  }

  setQrCode() {
    this.id((id: string) => {
    let t = new Tiny('', '', '');
    t.hashTag = id.replace(/^"(.*)"$/, '$1');
    t.email = this.loggedinUser;
    t.value = window.location.href;
    if(this.ismock) {
     t.value = "https://"+window.location.host+"/admin/mock?h="+this.topic.hashTag;
    }
    this.tinyUrl(t);
    this.qrCode(this.tinUrl+t.hashTag, (qrcode: string)=> {
        if(qrcode) {
          this.qrcode = `data:image/png;base64,`+qrcode.replace('\"','').replace('\"','');
        }
      });
    });
  }

 copyMockTitleUrlToClipboard() {
    this.id((id: string) => {
    let t = new Tiny('', '', '');
    t.hashTag = id.replace(/^"(.*)"$/, '$1');
    t.email = this.loggedinUser;
    t.value = "https://"+window.location.host+"/admin/mock?h="+this.topic.hashTag;
    this.tinyUrl(t);

    var dummy = document.createElement('input');
    document.body.appendChild(dummy);
    dummy.value = this.topic.hashTag + " " + this.tinUrl+t.hashTag;
    dummy.select();
    document.execCommand('copy');
    document.body.removeChild(dummy);

    this.showAlert(AlertTypeEnum['success'], "TinyURL is copied to the clipboard with title, Paste anyware!");
    });
}
  public copyTinyUrlToClipboard() {
    this.id((id: string) => {
    let t = new Tiny('', '', '');
    t.hashTag = id.replace(/^"(.*)"$/, '$1');
    t.email = this.loggedinUser;
    t.value = window.location.href;
    this.tinyUrl(t);

    var dummy = document.createElement('input');
    document.body.appendChild(dummy);
    dummy.value = this.tinUrl+t.hashTag;
    dummy.select();
    document.execCommand('copy');
    document.body.removeChild(dummy);

    this.showAlert(AlertTypeEnum['success'], "TinyURL is copied to the clipboard, Paste anyware!");
    });
}

 copyTitleUrlToClipboard() {
    this.id((id: string) => {
    let t = new Tiny('', '', '');
    t.hashTag = id.replace(/^"(.*)"$/, '$1');
    t.email = this.loggedinUser;
    t.value = window.location.href;
    this.tinyUrl(t);

    var dummy = document.createElement('input');
    document.body.appendChild(dummy);
    dummy.value = this.topic.hashTag + " " + this.tinUrl+t.hashTag;
    dummy.select();
    document.execCommand('copy');
    document.body.removeChild(dummy);

    this.showAlert(AlertTypeEnum['success'], "TinyURL is copied to the clipboard with title, Paste anyware!");
    });
}

  copySubtitleUrlToClipboard(i: any) {
    this.selectedIndex = i;
    this.id((id: string) => {
    let t = new Tiny('', '', '');
    t.hashTag = id.replace(/^"(.*)"$/, '$1');
    t.email = this.loggedinUser;
    t.value = window.location.href.split('#')[0]+"#"+i;
    this.tinyUrl(t);

    var dummy = document.createElement('input');
    document.body.appendChild(dummy);

    dummy.value = this.tags[i].name + "... read more here >> " + this.tinUrl+t.hashTag;
    if (this.tags[i].name.length > 64) {
      dummy.value = this.tags[i].name.substring(0, 64) + "... read more here >> " + this.tinUrl+t.hashTag;
    }

    dummy.select();
    document.execCommand('copy');
    document.body.removeChild(dummy);

    this.showAlert(AlertTypeEnum['success'], "SubtitleURL is copied to the clipboard, Paste anyware!");
    });
}

copyLoqUrlToClipboard(i: any) {
  this.selectedIndex = i;
  this.id((id: string) => {
    let t = new Tiny('', '', '');
    t.hashTag = id.replace(/^"(.*)"$/, '$1');
    t.email = this.loggedinUser;
    t.value = window.location.href.split('#')[0]+"#"+i;
    this.tinyUrl(t);

    var dummy = document.createElement('input');
    document.body.appendChild(dummy);

    dummy.value = this.tags[i].value + "... read more here >> " + this.tinUrl+t.hashTag;
    if (this.tags[i].value.length > 64) {
      dummy.value = this.tags[i].value.substring(0, 64) + "... read more here >> " + this.tinUrl+t.hashTag;
    }

    dummy.select();
    document.execCommand('copy');
    document.body.removeChild(dummy);

    this.showAlert(AlertTypeEnum['success'], "LoqURL is copied to the clipboard, Paste anyware!");
  });
}

gotomock(t: any) {
 this.id((id: string) => {
    let t = new Tiny('', '', '');
    t.hashTag = id.replace(/^"(.*)"$/, '$1');
    t.email = this.loggedinUser;
    t.value = "https://"+window.location.host+"/admin/mock?h="+escape(this.topic.hashTag);
    this.tinyUrl(t);
    this.mockUrl = this.tinUrl+t.hashTag;
    });
}

profile(t: any) {
  this.router.navigate(['profile'],  { queryParams: { h : t.hashTag, d : window.btoa(t.email)}});
  return;
}

profileEx(h: any, d: any) {
  this.router.navigate(['profile'],  { queryParams: { h : h, d : window.btoa(d)}});
  return;
}


  onPrint() {
    this.selectedIndex = -1;
    this.printable = false;
    setTimeout(()=>{
      window.print();
      this.printable = true;
        }, 1000);
  }


   setSearchText() {
    this.data.changeMessage(this.selectedTag.hashTag.split(' ').slice(0,6).join(' ')  + " " + this.selectedTag.name.split(' ').slice(0,3).join(' ') + " " + this.selectedTag.value.split(' ').slice(0,3).join(' ') + " " + this.curbemail(this.selectedTag.email));
  }

 public keyDown(event: any) {
    switch (event.keyCode) {
      case 38:
        this.onUp();
        break;
      case 40:
        this.onDown()
        break;
      case 13:
       if(event.shiftKey) {
                    this.submitTag();
                    event.preventDefault();
                  }
                  event.stopPropagation();
        break;
      case 9:
        if(event.shiftKey) {
          this.selectedTag.position = this.selectedTag.position - 1;
        } else {
          this.selectedTag.position = this.selectedTag.position + 1;
        }
        this.renderTag(this.selectedTag);
        break;
      case 27:
        this.selectedIndex = -1;
        break;
    }
    return;
  }

  public onDown() {
    if(this.selectedIndex < this.tags.length - 1)
    {
      this.selectedTag = this.tags[++this.selectedIndex];
      this.setSearchText();
    }
  }

  public onUp() {
    if(this.selectedIndex > 0)
    {
      this.selectedTag = this.tags[--this.selectedIndex];
      this.setSearchText();
    }
  }

  setPageVisible() {
    this.selectedTag = this.tags[this.selectedIndex];
    if (this.selectedTag) {
      this.selectedTag.rank = this.selectedIndex + 1;
    }
  }

    renderImage(t: any) {
      var s = t;
      t = t.replace(/<(?:.|\n)*?>/gm, '');
       if (t.startsWith("http://") || t.startsWith("https://")) {
           const img = new Image();
           img.src = t;
           var w = 300;
           var h = (300/img.width)*img.height;
           t = '<img src="' + t + '" style="width:' + w + 'px; height:' + h + 'px;" />'
           return t;
        }
           return s;
      }
}

