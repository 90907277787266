import { Component, OnInit } from '@angular/core';
import { Location, CommonModule } from '@angular/common';
import {ActivatedRoute, Router } from '@angular/router';
import { ICreateOrderRequest, IPayPalConfig, ITransactionItem, NgxPayPalModule } from 'ngx-paypal';
import { config } from '../../config';
import { FormsModule } from '@angular/forms';
import { AdminAlertComponent } from '../adminAlert/admin-alert.component';
import { AdminAlertService } from '../adminAlert/services/admin-alert.service';
import { AlertTypeEnum } from '../adminAlert/types/alertType.enum';
import { AdminHeaderComponent } from '../adminHeader/admin-header.component';
import { AdminFooterComponent } from '../adminFooter/admin-footer.component';
import {RouterModule, RouterOutlet} from '@angular/router';

@Component({
  selector: 'siloqpay',
  templateUrl: './siloqpay.component.html',
  styleUrls: ['./siloqpay.component.css'],
  imports: [NgxPayPalModule, AdminAlertComponent, FormsModule, RouterModule, AdminHeaderComponent, AdminFooterComponent, RouterModule, RouterOutlet],
  standalone: true
})

export class SiloqpayComponent implements OnInit {
      public payPalConfig?: IPayPalConfig;
      price="";
      title = "siloqpay";
      u = "-";
      t = "-";
      c = "USD";
      a = "0.99";
      l = "1";
      p = "Goldimembers™ Monthly Subscription";
      intent = "CAPTURE";
      siloqpayUrl = "https://pay.siloq.com";
      siloqpayApiUrl = "https://api.pay.siloq.com";
      gmtUrl = "https://api.isloq.com";
      siloqpayCallbackUrl = "https://imockr.siloq.com/admin/mocks?t=Goldimembers&u=gold.imoqr@siloq.com";
      public taxonomy = "";
      public class = "";
      public subclass = "";

    constructor(private activatedRoute: ActivatedRoute, private router: Router) {}

    ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      this.u = params['u'] || "-";
      this.t = params['t'] || "-";
      this.l = params['l'] || "1";
      this.c = params['c'] || "USD";
      this.a = params['a'] || "0.99";
      this.p = params['p'] || this.p;
      });
      this.taxonomy = config.taxonomy;
      this.class = config.class;
      this.subclass = config.subclass;
      this.initConfig();
    }

    private initConfig(): void {
        this.payPalConfig = {
            clientId: 'AV1q7cOXuelZBkFHNhaHag9R340aSByqGzquzJE_janPLxxdUl2ZxE7EGh9mOCPM2pe1Ti03skzygTch',
            createOrderOnServer: (data: any) => fetch(this.siloqpayApiUrl+'/order/'+this.intent+'/'+this.p+'/'+this.u+'/'+this.t+'/'+this.c+'/'+this.a+'/'+this.l,)
               .then((res) => res.json())
               .then((order) => order),
            onApprove: (data: any, actions: any) => {
                console.log('onApprove - transaction was approved, but not authorized', data, actions);
                actions.order.get().then((details: any) => {
                fetch(this.siloqpayApiUrl+'/order/complete',
                 {
                    mode: 'no-cors',
                    method: 'get',
                    headers: {
                      "Content-type": "application/json"
                    },
                    body: JSON.stringify({"order_id": data})
                  } )
                  .then((res: any) => res.json());
                        console.log('onApprove - you can get full order details inside onApprove: ', details);
                    });
            },
            onClientAuthorization: (data: any) => {
                fetch(this.gmtUrl+'/gmt/'+data.id+'/'+this.p+'/'+this.u+'/'+this.t+'/'+this.c+'/'+this.a+'/'+this.l,
                 {
                    mode: 'no-cors',
                    method: 'get',
                    headers: {
                      "Content-type": "application/json"
                    },
                  } )
                  .then((res: any) => {
                      alert("Thank You, and Welcome to Goldimembers!");
                      this.router.navigate(['/admin/mocks'],  { queryParams: { t : "Goldimembers", u : "gold.imoqr@siloq.com" }});
                    });
                   console.log('onClientAuthorization - you should probably inform your server about completed transaction at this point', data);
            },
            onCancel: (data: any, actions: any) => {
                console.log('OnCancel', data, actions);

            },
            onError:  (err: any) => {
                console.log('OnError', err);
            },
            onClick: (data: any, actions: any) => {
                console.log('onClick', data, actions);
            },
        };
    }

/*
     goldimembers() {
        if(this.theGoldiUser && this.theGoldiUser !== 'null') {
          this.router.navigate(['/admin/mocks'],  { queryParams: { t : "Goldimembers", u : "gold.imoqr@siloq.com"}});
        } else {
          this.router.navigate(['/admin/goldimembers']);
        }
      }
      */
}
