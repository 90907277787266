import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class DataService {

  //TODO: Get it from cookies
  private messageSource = new BehaviorSubject('welcome');
  currentMessage = this.messageSource.asObservable();

  private classTagSource = new BehaviorSubject('Not Selected Yet');
  currentClassTag = this.classTagSource.asObservable();

  constructor() { }

  changeMessage(message: string) {
    this.messageSource.next(message)
  }

  changeClassTag(classTag: string) {
    this.classTagSource.next(classTag)
  }

}
