<admin-header xmlns:div="http://www.w3.org/1999/html"></admin-header>
<body style="background-color:#ffffff00; height:100%;width:100%; margin:0px;">
  <form method="post">
    <div class="doNotPrint" style="background-color:whitesmoke;position:fixed;margin-top:50px;display:table;width:100%; height:38px; valign:middle;border:0px solid lightgrey;padding:0px 0px 0px 0px;text-align:center;">
      <!--
      <div *ngIf="!(topic.hashTag.indexOf('_exam_request_') > 0 && topic.hashTag.indexOf('_exam_result_') > 0)" class="doNotPrint" style="background-color:whitesmoke;position:fixed;margin-top:55px;display:table;width:100%; height:38px; valign:middle;border:0px solid lightgrey;padding:0px 0px 0px 0px;text-align:center;">
      -->
      <div style="display:table-cell;text-align:center;margin: auto;color:mediumseagreen;padding-top:0px;font-family:Helvetica,AppleGothic,sans-serif;font-size:1em; font-weight:600;width:100%;vertical-align: middle;">
        <!--
        <a title="Mocks Head Quarters" class="fa fa-arrow-left intLink" type="button" valign="middle" (click)="mocks();" style="color:dodgerblue;background-color:#ffffff00;border:0px; padding:0px 5px 0px 0px;font-weight:300;font-size:1em;width:22px;height:22px;"></a>
        <a *ngIf="ready && topic.users && theUser===topic.email && topic.users.indexOf('mock.imoqr@siloq.com') >= 0" title="Exam Finalized '{{topic.hashTag}}'" class="fa fa-flag-checkered intLink" type="button" valign="middle" (click)="branchTopic('finalized');"style="color:dodgerblue;background-color:#ffffff00;border:0px; padding:0px 5px 0px 0px;font-weight:300;font-size:1em;width:22px;height:22px;"></a>
        <a *ngIf="ready && topic.users && theUser===topic.email && topic.users[1].indexOf('mock') >= 0" title="Exam Scores '{{topic.hashTag}}'" class="fa fa-star intLink" type="button" valign="middle" (click)="branchTopic('scores');"style="color:dodgerblue;background-color:#ffffff00;border:0px; padding:0px 5px 0px 0px;font-weight:300;font-size:1em;width:22px;height:22px;"></a>
        <a *ngIf="ready && topic.users && theUser===topic.email && topic.users.indexOf('mock.imoqr@siloq.com') >= 0" title="Reexam '{{topic.hashTag}}'" class="fa fa-circle-o intLink" type="button" valign="middle" (click)="branchTopic('reexam');" style="color:dodgerblue;background-color:#ffffff00;border:0px; padding:0px 5px 0px 0px;font-weight:300;font-size:1em;width:22px;height:22px;"></a>
        <a *ngIf="ready && topic.users && theUser===topic.email && topic.users.indexOf('mock.imoqr@siloq.com') >= 0" title="Remove '{{topic.hashTag}}'" class="fa fa-remove intLink" type="button" valign="middle" (click)="archiveTopic(topic);" style="color:dodgerblue;background-color:#ffffff00;border:0px; padding:0px 5px 0px 0px;font-weight:300;font-size:1em;width:22px;height:22px;"></a>
        <a *ngIf="ready && topic.users && topic.users.indexOf('mock.imoqr@siloq.com') >= 0" title="Subscribe me to this Mock '{{topic.hashTag}}'" class="fa fa-user-plus intLink" type="button" valign="middle" (click)="mockExamRequest();" style="width:60px;color:orangered;background-color:#ffffff00;border:0px; padding:0px 5px 0px 0px;font-weight:300;font-size:1em;height:22px;">&nbsp;Subscribe</a>
        -->
        <a  *ngIf="ready && topic.users && topic.users.indexOf('mock.imoqr@siloq.com') >= 0" title="Subscribe me to this Mock '{{topic.hashTag}}'" style="font-family:Helvetica,AppleGothic,sans-serif; background-color:orange;color:#383838;border:0px; border-radius: 30px; -moz-border-radius: 30px;height:20px;"(click)='mockExamRequest()' class="siloqbutton">Attend Mock</a>
      </div>
    </div>
    <div  class="doNotPrint" style="padding:100px 0px 0px 0px;font-family:Helvetica,AppleGothic,sans-serif; width:100%;text-align:center;color:mediumseagreen;font-size:1em;font-weight:500;vertical-align: middle;">Mock</div>
    <div class="block_container" style="background-color:#ffffff00;border-bottom:1px solid gainsboro;padding:10px 0px 0px 0px;font-family:Century Gothic,CenturyGothic,AppleGothic,sans-serif; text-align: center; valign: top; font-size:1em;font-weight:200;"><a href="" ><img src="/assets/isloq.png" align="middle" style="margin-bottom:13px;width:19px; height:19px;" /></a> <span style="color:grey;"></span> <a style="text-decoration:none;" href=""><span
                                                  style="color:dodgerblue;">i</span><span
                                                     style="color:mediumseagreen;">s</span><span
                                                     style="color:orangered;">l</span><span
                                                     style="color:orange;">o</span><span
                                                     style="color:dodgerblue;">q</span><span
                                                     class="sup" style="color:orangered;">&trade;</span><span style="color:dodgerblue;">&nbsp;Mock Instantly Runtime!</span> </a>
    </div>

    <admin-alert></admin-alert>
       <ng-template #modalTemplate>
    </ng-template>


    <div id="doc" style="font-family:Helvetica,AppleGothic,sans-serif;width:92%;overflow:hidden;margin:4px 4% 40px 4%;padding-top:10px;z-index:-1px;">
      <!--
      3: title + additional questions
      -->
      <div style="text-align:center;min-width:30%; min-height:30px; font-family: Arial, Helvetica, sans-serif;font-size:1.2em;font-weight:600;">{{model.hashTag}}</div>
      <div *ngIf="topic.users && topic.users.length > 0" style="font-family: Arial, Helvetica, sans-serif;font-size;1em;font-weight:200;text-align:center;">(<b>{{topic.users[1].split(".")[0]}}:</b><i>{{curbemail(topic.email)}})</i></div>
      <h3 style="text-align:left;margin:auto;" class="siloqfont">
      </h3>
      <br>
        <!--
      <h4 style="text-align:left;color:mediumseagreen">Rent or Own this mock exam?</h4>
      -->
        <div class="container2 siloqfont" style="font-family:Helvetica,AppleGothic,sans-serif; font-size:1em; font-weight: 200;">
          <div  *ngIf="random > 0" style="pointer-events:none;color:navy">Number Of Questions <span style="color:dark;font-weight:600;font-size:1;"> <br>{{random}} in Random</span></div>
          <div *ngIf="random === 0" style="pointer-events:none;color:navy">Number Of Questions <span style="color:dark;font-weight:600;font-size:1;"> <br>All in Order</span></div>
          <div style="pointer-events:none;user-select:none;color:navy">Time Duration in Minutes<span style="color:dark;;font-weight:600;font-size:1;"> <br>{{timeDuration}}</span></div>
          <div *ngIf="taxonomy==='iexamr'" style="pointer-events:none;user-select:none;color:navy">Start Time<span style="color:dark;color:dark;font-weight:600;font-size:1;"> <br>{{startTime}} </span></div>
          <div  style="pointer-events:none;user-select:none;color:navy">Page Size<span style="color:dark;color:dark;font-weight:600;font-size:1;"> <br>{{pageSize}}</span></div>
          <div  style="pointer-events:none;color:navy">Right Question Reward<span style="color:dark;color:dark;font-weight:600;font-size:1;"> <br>{{rightQuestionReward}}</span></div>
          <div  style="pointer-events:none;user-select:none;color:navy">Wrong Question Penalty<span style="color:dark;color:dark;font-weight:600;font-size:1;"> <br>{{wrongQuestionPenalty}}</span></div>
          <div *ngIf="audioEnabled"  style="pointer-events:none;user-select:none;color:navy">Audio Enabled<span style="color:dark;font-weight:600;font-size:1;"> <br>{{audioEnabled}} </span></div>
          <div  *ngIf="goldimembersEnabled" style="pointer-events:none;user-select:none;color:navy">Goldimembers Enabled<span style="color:dark;color:dark;font-weight:600;font-size:2;"> <br>{{goldimembersEnabled}}</span></div>
        </div>
      <br>
      <div style="text-align: center">
      <button (click)="refer()" type="button" class="siloqbutton" style="margin:10 auto">Copy Referral Url</button>
      </div>

   <br>
      <div  *ngIf="(!theGoldiUser || theGoldiUser==='null') && goldimembersEnabled" class="small-12  columns" style="text-align:center;">
        <div style="font-family:sans-serif;font-size:2em;font-weight:400; color: dodgerblue;">
          Want to become one of the successful Goldimembers?
        </div>
        <br>
        <div style="font-family:sans-serif;font-size:2em;font-weight:500; color: mediumseagreen;">
          free for 30 days
        </div>
        <br>
        <div style="font-family:sans-serif;font-size:1.2em;font-weight:600; color: navy;">
          Free mocks, award-winning exams, exclusive deals, and more
        </div>

        <br>
        <div style="font-family:sans-serif;font-size:1em;font-weight:200; color: navy;">
          Only <b>{{goldimembersMonthlyPrice}}</b>/month. Cancel anytime.
        </div>
      <br>
      <div class="small-12  columns" style="text-align:left;">
        <div style="width:300px;text-align:center;margin:auto;" class="siloqfont">
          <button (click)="subscribe()" type="button" class="siloqbutton" style="borde:0px; border-radius: 50px; -moz-border-radius: 50px;background-color:orange;color:#383838;">Start your perfect practice</button>
          <br>
          <br>
        </div>
      </div>
      </div>

      <h2  *ngIf="scheduled" style="text-align:center;color:orangered">This exam may have been scheduled or not available!</h2>

      <div *ngIf="topic.users && !(topic.users.indexOf('conduct.imoqr@siloq.com') >= 0 || topic.users.indexOf('request.imoqr@siloq.com') >= 0)">
        <div  *ngFor="let selectedSlide of subtitles; let i = index" (click)="selectSlide(selectedSlide, i)" (mouseenter)="browseSlideIndex = i" style="width:98%;margin:0px;padding:2px">
            <div *ngFor="let tag of slides[selectedSlide]; let j = index" (click)="selectTag(tag, j)" (mouseenter)="browseTagIndex = j" style="width:98%; list-style: none;background-color:white;display:table; margin:0; font-family:Helvetica,AppleGothic,sans-serif; inline-block;padding: 2px; height:20px;text-align:left;">
              <div *ngIf="selectedSlide !== model.hashTag.toLowerCase()" style="display:table;">
                <div *ngIf="slides[selectedSlide].length > 1" class = "regular" [class.browse]="i === browseSlideIndex && j === browseTagIndex" [class.active]="i === selectedSlideIndex && j === selectedTagIndex" style="vertical-align:top;display:table; text-align:left;margin:0px; ">
                  <div *ngIf="j === 0" class="editTag" [contentEditable]="false" tabindex="{{i}}" (focus)="selectedTag = tag;" (blur)="tag.name = $event.target.innerHTML; updateTagName(tag);" valign="top" style="display:table-cell;padding:0px 1px 2px 0px;text-align:left;display: inline-block; margin: 0 auto !important; font-weight:600;font-size:14px;" id="{{i}}" [innerHTML]="tag.name | safeHtml" ></div>
                  <div *ngIf="j > 0" class="editTag" [contentEditable]="false" tabindex="{{i+j+1}}"(focus)="selectedTag = tag;" (blur)="tag.value = $event.target.innerHTML; updateTag(tag);" valign="top" style="cursor:pointer;display:table-cell;padding:0px 1px 2px 0px;text-align: justify; text-justify: inter-word;display: inline-block; margin: 0 auto !important; inter-word;font-weight:500;font-size:12px;"[innerHTML]="renderImage(tag.value) | safeHtml" (ngModelChange)="tag.value = $event"></div>
                </div>
              </div>
            </div>
        </div>
      </div>
    </div>
    <br class="doNotPrint">
    <br class="doNotPrint">
    <div class="block_container" style="background-color:#ffffff00;border-top:1px solid gainsboro;padding:10px 0px 0px 0px;font-family:Century Gothic,CenturyGothic,AppleGothic,sans-serif; text-align: center; valign: top; font-size:1em;font-weight:200;"><a style="text-decoration:none;" href="http://siloq.com" >
      <span style="color:dodgerblue;">Powered by</span> <img src="assets/isloq.png" align="middle" style="margin-bottom:13px;width:19px; height:19px;" /></a> <span style="color:grey;"></span> <a style="text-decoration:none;" href="http://isloq.com"><span style="color:dodgerblue;">i</span><span
      style="color:mediumseagreen;">s</span><span
      style="color:dodgerblue;">l</span><span
      style="color:orange;">o</span><span
      style="color:dodgerblue;">q</span><span
      class="sup"
      style="color:orangered;">™</span>
    </a>
    </div>
<loq-footer></loq-footer>
