<body onload="initDoc();" style="overflow:scroll; margin: 0px 0px 0px 0px;">
<form name="compForm" >
<input type="hidden" name="myDoc">
<div id="header" style="position:fixed; border-width:1px; padding:5px 0px 0px 0px; margin-top:0px;display: inline;background-color:white;width:100%;height:20px;" class="doNotPrint">
  <div style="float:left;padding:0px 15px 0px 0px;">
     <img (click)="home()" src="/assets/isloq.png" style="padding:6px 0px 0px 7px;width:40px; height:40px;" />
  </div>
  <div style="float:right; margin: 3px; 0px 5px 0px; width:315px;padding-bottom:5px;">
    <!--
    <a style="background-color:mediumseagreen;" [routerLink]="['/admin/user']" [queryParams]="{h:topic.hashTag}" class="button">Share</a> <a style="background-color:dodgerblue;" [routerLink]="['/admin/branch']" [queryParams]="{h:topic.hashTag, i:topic.ID}" class="button">Branch</a>
                             <a style="background-color:ghostwhite;border:1px solid lightgrey;" href="https://gleanbee.com/admin/redirect?h={{topic.hashTag}}&i={{topic.ID}}&u=doc" class="button">
                               <span style="color:dodgerblue;">g</span><span
                               style="color:mediumseagreen;">l</span><span
                               style="color:orangered;">e</span><span
                               style="color:orange;">a</span><span
                               style="color:dodgerblue;">n</span><span
                               style="color:orange;">bee</span><span
                              class="sup" style="color:orangered;">&trade;</span></a> <a style="background-color:orange;" title="{{theUser}}" [routerLink]="['/admin/invite']" class="button">Me</a>
                              -->
                              </div>
   <div style="width:100%;">
       <div style="display: inline block; padding:0px;margin:0px;width:100%">
                <div contenteditable="true" style="display: inline-block; font-family: Arial, Helvetica, sans-serif;float:left;padding:0px 5px 0px 5px; margin:0px;" (blur)="topic.hashTag = $event.target.innerText; save();">{{topic.hashTag}}
                </div>
                <div> <img style="margin:0px;padding:0px;width:10px; height:10px;"class="intLink" title="Redo" onclick="formatDoc('redo');" src="data:image/gif;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAACxUlEQVR42pWRa0iTURjH/+fdnJu7JJZpqTm1NC8VYkmhFSiWgWVXVh8yL1RUfqiUAkFEsvrQzSD9EGjLiEL7kBVGYmEiQ3OWlJq5jLlVaGvqNufu79txhCRq1AuH85z3PM/v+T//Q/CXr+/m2T7whJ74wktrF8ohC128OZ+dEVeY1wy7Fv23VenJV+pf/Regt7JEG3/MFI7JD+hVRg6vOaeU/zOgq3hvUnh2qjowsQ1wOzCikkCvEicmVyh7/groOJ0S6rZag4VBG+4mlUXEcaMvQEgA4EOgvkH6ncZvR3h+zOjGSpV+BvD+gqKa5fmv9AtPkEvD4uU+EomPOHQCQnIZnGMR4KJJvEnY7GmYsqbBZTM7LbqPWpt+QEdcRg0ZrCnlVuVnUpaaJnfSZQacLrBWHggnADwMHcMFIqBiCT1zFCpaASzZBk3tE5Dukn37g1LHGkJ2sGDHRYCdT2F8Kt2XFtPQQwtZurvp7hijM9sBXweGmzwYM4Qd8HrQcSb3VEia/VZolhMw+oH1WADbJC0wAlPfaWwCzA4wnJcNdRXwRRdcqHg7UjVjYnfpiaKAWN1VeboaHqMJnM0OhjbjaHfiglcNwycYfCRCa4Oz+PgP97U5r3BfHtCoeDmxi1hZcM7fsqdNpDEzPYoDqD0pflygt+yZ9xmfpsW2ZNWPpHv047SQAWE5b3fORhPdjNfIZxWLW3a2jmbMC2jPjxpKKR+KdBsI+CLO2927qALWwANDfW2vlmo2N05EzwEol/FjkosiBmKPDlHDWFjf8dBZLaTagU2HHBBFUBM4gv4HEnQ1s9G5nyc1swA1UkHm7qaQ5/5Lh9F9UYCBVsGdHJ05f/quLlJWu3q9Ky9J4cK4XoTG69heoLM0zwI8TBBuCVwne235ZLtn/smU52hNQ3+OVxcji5KJuTLpcvaw4Suz9WCPpW36/y8kUh1DpensuQAAAABJRU5ErkJggg==" />
                </div>
      </div>
</div>
<div id="toolBar1" class="doNotPrint" style="background-color:white; padding:5px; 0px 0px 0px;marging:0px;">
  <div class="navbar">
   <div class="dropdown">
     <button class="dropbtn">File</button>
     <div class="dropdown-content" style="padding-top:0px;">
       <a href="#"> <img src="/assets/isloq.png" style="padding:6px 10px 0px 0px; height:16px; widht:16px" />Open</a>
       <a (click)="save()"> <img style="padding-bottom: 3px;" class="intLink" src="data:image/gif;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAC/klEQVR42pWSX2hbZRiHny85SZum a5tsWZvEdq0tq2mnVKG7mkyKrSjeiMKEDS8cbAjeiGwwL2SDjaIou5DJYOCNogwd7GIrq1q0+Gdb u7pNzZbUpmyznbT5t8SYnJw05/VrusFuZO6FwzkHznm+5/29rzr0c0qUYVDBwFst0Fhfh+2QPLY0 oRQKcDoNXIYLRMhm7nAh5+LPpaJ/+vVNWfXujynZMeDnm4zi2X8S9D8W1B+u/qZL9OXwkJi/wXRi icHuVtx1bj63QkxcWiSbLofUge+X5dHOJqZyTl74O8Zzfa3kq47ayVVbCG5s4cDRL7jetY2R9BQ9 /RG+W/8EPrvI+KU0at+3SxLpamY672Ikd5VnegMUbEMDRHchtPsbGT11kfTwMP0/jBEKBBjzD9JZ bzETy6DeHl+Uni5fDTCcmWFoswbIXYA2aNcG+4+dJtY7wtCtcSJbIpzzbyXkMtcAe07OyeMDIaay GpA6z/N9AfLirgHEhnXrvEz8epPk3CwtHZ3U1Rt83TxIWAN+ua5b2PtlQnr6gkxXPDwZm2SovZ6i WgPcy9Hb4MHtBKNS5sxsjmjfdtqsAr9Fl1BvfDUvbZvD/PHTBYyFOPZKBXE4uL/k7t1hayWnC3dn L3UDW4lfvYXa9WlMfN1t7PWl9Ai7+T8VjSU4eNvPclwbvPZZXFwdrbwVSBHwtTB2dgLRFkWzSKlk YpVKlKwyFdMkmUwy+sEoyewd9t3wkYsvonZ+ck1kUyvvhLI1Az251QV8oMGbc00UYrdRr574XUxt cLgj91AGu6ONWPEF1MsfX5FCR4gPu/OEg+tJ61136hBFq4iStbXWz0rnWimvsGGDn7+Ws+y47IVZ HeJLx2YkGQxzPFKoGYyfm2SlYlEy9cnFIuVVE7OMVTbJpNMcee9wzeDF8x5cCQ14+qOolHtCvN88 z/anHqGUzuNwrvWq7o1wNRj9Vq1WafD7mLyywCs32/FcvoYK758UW2taxQo5a+W+qP47SbfhwKs0 rLmBfwEejXGgqAgtxQAAAABJRU5ErkJggg==" style="padding:6px 10px 0px 0px; height:16px; widht:16px" />Save</a>
       <a href="#"> <img src="/assets/isloq.png" style="padding:6px 10px 0px 0px; height:16px; widht:16px" />Save As</a>
       <hr style="width:300px">
       <a [routerLink]="['/admin/user']" [queryParams]="{h:topic.hashTag}"><img src="/assets/isloq.png" style="padding:6px 10px 0px 0px; height:16px; widht:16px" />Share</a>
       <a [routerLink]="['/admin/branch']" [queryParams]="{h:topic.hashTag, i:topic.id}"><img src="/assets/isloq.png" style="padding:6px 10px 0px 0px; height:16px; widht:16px" />Branch</a>
       <a (click)='chat()'><img src="/assets/isloq.png" style="padding:6px 10px 0px 0px; height:16px; widht:16px" />Chat</a>
       <a (click)='review()'><img src="/assets/isloq.png" style="padding:6px 10px 0px 0px; height:16px; widht:16px" />Compare</a>
       <a (click)='publish()'><img src="/assets/isloq.png" style="padding:6px 10px 0px 0px; height:16px; widht:16px" />Publish</a>
       <hr style="width:300px">
       <a href="#"><img src="/assets/isloq.png" style="padding:6px 10px 0px 0px; height:16px; widht:16px" />Post</a>
       <a href="#"><img src="/assets/isloq.png" style="padding:6px 10px 0px 0px; height:16px; widht:16px" />Export</a>
       <a onclick="printWindow('textBox', 'clsb', 'clsa');"> <img class="intLink" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAWCAYAAADEtGw7AAAABGdBTUEAALGPC/xhBQAAAAZiS0dEAP8A/wD/oL2nkwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAAd0SU1FB9oEBxcZFmGboiwAAAAIdEVYdENvbW1lbnQA9syWvwAAAuFJREFUOMvtlUtsjFEUx//n3nn0YdpBh1abRpt4LFqtqkc3jRKkNEIsiIRIBBEhJJpKlIVo4m1RRMKKjQiRMJRUqUdKPT71qpIpiRKPaqdF55tv5vvusZjQTjOlseUkd3Xu/3dPzusC/22wtu2wRn+jG5So/OCDh8ycMJDflehMlkJkVK7KUYN+ufzA/RttH76zaVocDptRxzQtNi3mRWuPc+6cKtlXZ/sddP2uu9uXlmYXZ6Qm8v4Tz8lhF1H+zDQXt7S8oLMXtbF4e8QaFHjj3kbP2MzkktHpiTjp9VH6iHiA+whtAsX5brpwueMGdONdf/2A4M7ukDs1JW662+XkqTkeUoqjKtOjm2h53YFL15pSJ04Zc94wdtibr26fXlC2mzRvBccEbz2kiRFD414tKMlEZbVGT33+qCoHgha81SWYsew0r1uzfNylmtpx80pngQQ91LwVk2JGvGnfvZG6YcYRAT16GFtW5kKKfo1EQLtfh5Q2etT0BIWF+aitq4fDbk+ImYo1OxvGF03waFJQvBCkvDffRyEtxQiFFYgAZTHS0zwAGD7fG5TNnYNTp8/FzvGwJOfmgG7GOx0SAKKgQgDMgKBI0NJGMEImpGDk5+WACEwEd0ywblhGUZ4Hw5OdUekRBLT7DTgdEgxACsIznx8zpmWh7k4rkpJcuHDxCul6MDsmmBXDlWCH2+XozSgBnzsNCEE4euYV4pwCpsWYPW0UHDYBKSWu1NYjENDReqtKjwn2+zvtTc1vMSTB/mvev/WEYSlASsLimcOhOBJxw+N3aP/SjefNL5GePZmpu4kG7OPr1+tOfPyUu3BecWYKcwQcDFmwFKAUo90fhKDInBCAmvqnyMgqUEagQwCoHBDc1rjv9pIlD8IbVkz6qYViIBQGTJPx4k0XpIgEZoRN1Da0cij4VfR0ta3WvBXH/rjdCufv6R2zPgPH/e4pxSBCpeatqPrjNiso203/5s/zA171Mv8+w1LOAAAAAElFTkSuQmCC" style="padding:6px 10px 0px 7px; height:16px; widht:16px" />Print</a>
     </div>
   </div>
   <div class="dropdown">
     <button class="dropbtn">Edit</button>
     <div class="dropdown-content">
       <a href="#">Title 1 &lt;h1&gt;</a>
       <a href="#">Title 2 &lt;h2&gt;</a>
       <a href="#">Title 3 &lt;h3&gt;</a>
       <a href="#">Title 4 &lt;h4&gt;</a>
       <a href="#">Title 5 &lt;h5&gt;</a>
       <hr style="width:300px">
       <a href="#">Subtitle &lt;h6&gt;</a>
       <a href="#">Paragraph &lt;p&gt;</a>
       <a href="#">Preformatted &lt;pre&gt;</a>
       <hr style="width:300px">
       <a href="#">Select all</a>
       <a href="#">Find and replace</a>
       <hr style="width:300px">
       <a href="#">Show Html</a>
       <a href="#">Full Screen</a>
     </div>
   </div>
   <div class="dropdown">
     <div class="dropdown-content">
       <a href="#"><img src="/assets/isloq.png" style="padding:6px 10px 0px 0px;
       height:16px; widht:16px" />Recent Popular/Used Papers</a>
       <a href="#"><img src="/assets/isloq.png" style="padding:6px 10px 0px 0px;
       height:16px; widht:16px" />Demonstrated Pages/Docs Referred</a>
       <hr style="width:300px">
       <a href="#"><img src="/assets/isloq.png" style="padding:6px 10px 0px 0px;
       height:16px; widht:16px" />Spellcheck</a>
       <a href="#"><img src="/assets/isloq.png" style="padding:6px 10px 0px 0px;
       height:16px; widht:16px" />Grammer</a>
       <hr style="width:100%">
       <div><b>Overnight</b></div>
       <hr style="width:100%">
       <a href="#"><img src="/assets/isloq.png" style="padding:6px 10px 0px 0px;
       height:16px; widht:16px" />Comprehension</a>
       <a href="#"><img src="/assets/isloq.png" style="padding:6px 10px 0px 0px;
       height:16px; widht:16px" />Authenticity</a>
       <a href="#"><img src="/assets/isloq.png" style="padding:6px 10px 0px 0px;
       height:16px; widht:16px" />Accuracy</a>
       <a href="#"><img src="/assets/isloq.png" style="padding:6px 10px 0px 0px;
       height:16px; widht:16px" />Rank</a>
       <a href="#"><img src="/assets/isloq.png" style="padding:6px 10px 0px 0px;
       height:16px; widht:16px" />Probe Plagiarism</a>
       <a href="#"><img src="/assets/isloq.png" style="padding:6px 10px 0px 0px;
       height:16px; widht:16px" />Purge Plagiarism</a>
     </div>
   </div>
   <div class="dropdown">
     <button class="dropbtn">View</button>
     <div class="dropdown-content">
       <button onclick="formatDoc('forecolor', this.innerText);">Red</button>
       <button onclick="formatDoc('forecolor', this.innerText);">Green</button>
       <button onclick="formatDoc('forecolor', this.innerText);">Blue</button>
       <button onclick="formatDoc('forecolor', this.innerText);">Black</button>
       <button onclick="formatDoc('forecolor', this.innerText);">OrangeRed</button>
       <button onclick="formatDoc('forecolor', this.innerText);">MediumSeaGreen</button>
       <button onclick="formatDoc('forecolor', this.innerText);">DodgerBlue</button>
       <button onclick="formatDoc('forecolor', this.innerText);">Orange</button>
       <button onclick="formatDoc('forecolor', this.innerText);">Olive</button>
       <button onclick="formatDoc('forecolor', this.innerText);">Teal</button>
       <button onclick="formatDoc('forecolor', this.innerText);">Violet</button>
       <hr style="width:300px">
       <button onclick="formatDoc('forecolor', this.innerText);">Pink</button>
       <button onclick="formatDoc('forecolor', this.innerText);">White</button>
       <button onclick="formatDoc('forecolor', this.innerText);">Yellow</button>
       <button onclick="formatDoc('forecolor', this.innerText);">YellowGreen</button>
       <button onclick="formatDoc('forecolor', this.innerText);">Gold</button>
       <button onclick="formatDoc('forecolor', this.innerText);">GoldenRed</button>
       <button onclick="formatDoc('forecolor', this.innerText);">FireBrick</button>
       <button onclick="formatDoc('forecolor', this.innerText);">Purple</button>
       <button onclick="formatDoc('forecolor', this.innerText);">Magenta</button>
       <button onclick="formatDoc('forecolor', this.innerText);">Indigo</button>
       <button onclick="formatDoc('forecolor', this.innerText);">SlateGrey</button>
     </div>
     </div>
   <div class="dropdown">
     <button class="dropbtn">Insert</button>
     <div class="dropdown-content">
       <a href="#">Image</a>
       <a href="#">Video</a>
       <a href="#">Youtube</a>
       <a href="#">Link</a>
       <hr style="width:300px">
       <a href="#">Table</a>
       <a href="#">Headers</a>
     </div>
   </div>
   <div class="dropdown">
     <button class="dropbtn">Format</button>
     <div class="dropdown-content">
       <a href="#">Arial</a>
       <a href="#">Arial Black</a>
       <a href="#">Courier New</a>
       <a href="#">Times New Roman</a>
       <a href="#">Helvetica</a>
       <a href="#">Verdana</a>
       <a href="#">Monaco</a>
       <a href="#">Brush Script MT</a>
       <a href="#">Copperplate</a>
       <hr style="width:300px">
       <a href="#">Clear Formating</a>
     </div>
   </div>
   <div class="dropdown">
     <button class="dropbtn">Tools</button>
     <div class="dropdown-content">
       <button onclick="formatDoc('backcolor', this.innerText);">Red</button>
       <button onclick="formatDoc('backcolor', this.innerText);">Green</button>
       <button onclick="formatDoc('backcolor', this.innerText);">Blue</button>
       <button onclick="formatDoc('backcolor', this.innerText);">Black</button>
       <button onclick="formatDoc('backcolor', this.innerText);">OrangeRed</button>
       <button onclick="formatDoc('backcolor', this.innerText);">MediumSeaGreen</button>
       <button onclick="formatDoc('backcolor', this.innerText);">DodgerBlue</button>
       <button onclick="formatDoc('backcolor', this.innerText);">Orange</button>
       <button onclick="formatDoc('backcolor', this.innerText);">Olive</button>
       <button onclick="formatDoc('backcolor', this.innerText);">Teal</button>
       <button onclick="formatDoc('backcolor', this.innerText);">Violet</button>
       <hr style="width:300px">
       <button onclick="formatDoc('backcolor', this.innerText);">Pink</button>
       <button onclick="formatDoc('backcolor', this.innerText);">White</button>
       <button onclick="formatDoc('backcolor', this.innerText);">Yellow</button>
       <button onclick="formatDoc('backcolor', this.innerText);">YellowGreen</button>
       <button onclick="formatDoc('backcolor', this.innerText);">Gold</button>
       <button onclick="formatDoc('backcolor', this.innerText);">GoldenRed</button>
       <button onclick="formatDoc('backcolor', this.innerText);">FireBrick</button>
       <button onclick="formatDoc('backcolor', this.innerText);">Purple</button>
       <button onclick="formatDoc('backcolor', this.innerText);">Magenta</button>
       <button onclick="formatDoc('backcolor', this.innerText);">Indigo</button>
       <button onclick="formatDoc('backcolor', this.innerText);">SlateGrey</button>
     </div>
   </div>
   <div class="dropdown">
     <button class="dropbtn">Add-Ons</button>
     <div class="dropdown-content">
       <a href="#">Very small</a>
       <a href="#">A bit small</a>
       <hr style="width:300px">
       <a href="#">Normal</a>
       <hr style="width:300px">
       <a href="#">Medium Large</a>
       <a href="#">Big</a>
       <a href="#">Very Big</a>
       <a href="#">Maximum</a>
       <a href="#">Get Add-ons</a>
     </div>
   </div>
   <div class="dropdown">
     <button class="dropbtn">Help</button>
     <div class="dropdown-content">
       <a title="{{theUser}}" [routerLink]="['/admin/invite']">Me</a>
       <hr style="width:300px">
       <a href="#">About</a>
       <a href="#">Settings</a>
       <a href="#">Feedback</a>
     </div>
   </div>
 </div>
</div>

<div id="toolBar2" class="doNotPrint" style="background-color:white;display:table; width:100%;height:35px;padding: 0px 10px 0px 0px; margin:0px 0px 0px 0px; border: 1px gainsboro solid;">

  <div style="width:70%;display:table-cell;vertical-align:middle;padding:0px 0px 0px 10px;margin:0px;">
<img style="padding-bottom: 3px;" class="intLink" title="Save" (click)="save()" src="data:image/gif;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAC/klEQVR42pWSX2hbZRiHny85SZum a5tsWZvEdq0tq2mnVKG7mkyKrSjeiMKEDS8cbAjeiGwwL2SDjaIou5DJYOCNogwd7GIrq1q0+Gdb u7pNzZbUpmyznbT5t8SYnJw05/VrusFuZO6FwzkHznm+5/29rzr0c0qUYVDBwFst0Fhfh+2QPLY0 oRQKcDoNXIYLRMhm7nAh5+LPpaJ/+vVNWfXujynZMeDnm4zi2X8S9D8W1B+u/qZL9OXwkJi/wXRi icHuVtx1bj63QkxcWiSbLofUge+X5dHOJqZyTl74O8Zzfa3kq47ayVVbCG5s4cDRL7jetY2R9BQ9 /RG+W/8EPrvI+KU0at+3SxLpamY672Ikd5VnegMUbEMDRHchtPsbGT11kfTwMP0/jBEKBBjzD9JZ bzETy6DeHl+Uni5fDTCcmWFoswbIXYA2aNcG+4+dJtY7wtCtcSJbIpzzbyXkMtcAe07OyeMDIaay GpA6z/N9AfLirgHEhnXrvEz8epPk3CwtHZ3U1Rt83TxIWAN+ua5b2PtlQnr6gkxXPDwZm2SovZ6i WgPcy9Hb4MHtBKNS5sxsjmjfdtqsAr9Fl1BvfDUvbZvD/PHTBYyFOPZKBXE4uL/k7t1hayWnC3dn L3UDW4lfvYXa9WlMfN1t7PWl9Ai7+T8VjSU4eNvPclwbvPZZXFwdrbwVSBHwtTB2dgLRFkWzSKlk YpVKlKwyFdMkmUwy+sEoyewd9t3wkYsvonZ+ck1kUyvvhLI1Az251QV8oMGbc00UYrdRr574XUxt cLgj91AGu6ONWPEF1MsfX5FCR4gPu/OEg+tJ61136hBFq4iStbXWz0rnWimvsGGDn7+Ws+y47IVZ HeJLx2YkGQxzPFKoGYyfm2SlYlEy9cnFIuVVE7OMVTbJpNMcee9wzeDF8x5cCQ14+qOolHtCvN88 z/anHqGUzuNwrvWq7o1wNRj9Vq1WafD7mLyywCs32/FcvoYK758UW2taxQo5a+W+qP47SbfhwKs0 rLmBfwEejXGgqAgtxQAAAABJRU5ErkJggg==" />
<img class="intLink" title="Print" onclick="printWindow('textBox', 'clsb', 'clsa');" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAWCAYAAADEtGw7AAAABGdBTUEAALGPC/xhBQAAAAZiS0dEAP8A/wD/oL2nkwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAAd0SU1FB9oEBxcZFmGboiwAAAAIdEVYdENvbW1lbnQA9syWvwAAAuFJREFUOMvtlUtsjFEUx//n3nn0YdpBh1abRpt4LFqtqkc3jRKkNEIsiIRIBBEhJJpKlIVo4m1RRMKKjQiRMJRUqUdKPT71qpIpiRKPaqdF55tv5vvusZjQTjOlseUkd3Xu/3dPzusC/22wtu2wRn+jG5So/OCDh8ycMJDflehMlkJkVK7KUYN+ufzA/RttH76zaVocDptRxzQtNi3mRWuPc+6cKtlXZ/sddP2uu9uXlmYXZ6Qm8v4Tz8lhF1H+zDQXt7S8oLMXtbF4e8QaFHjj3kbP2MzkktHpiTjp9VH6iHiA+whtAsX5brpwueMGdONdf/2A4M7ukDs1JW662+XkqTkeUoqjKtOjm2h53YFL15pSJ04Zc94wdtibr26fXlC2mzRvBccEbz2kiRFD414tKMlEZbVGT33+qCoHgha81SWYsew0r1uzfNylmtpx80pngQQ91LwVk2JGvGnfvZG6YcYRAT16GFtW5kKKfo1EQLtfh5Q2etT0BIWF+aitq4fDbk+ImYo1OxvGF03waFJQvBCkvDffRyEtxQiFFYgAZTHS0zwAGD7fG5TNnYNTp8/FzvGwJOfmgG7GOx0SAKKgQgDMgKBI0NJGMEImpGDk5+WACEwEd0ywblhGUZ4Hw5OdUekRBLT7DTgdEgxACsIznx8zpmWh7k4rkpJcuHDxCul6MDsmmBXDlWCH2+XozSgBnzsNCEE4euYV4pwCpsWYPW0UHDYBKSWu1NYjENDReqtKjwn2+zvtTc1vMSTB/mvev/WEYSlASsLimcOhOBJxw+N3aP/SjefNL5GePZmpu4kG7OPr1+tOfPyUu3BecWYKcwQcDFmwFKAUo90fhKDInBCAmvqnyMgqUEagQwCoHBDc1rjv9pIlD8IbVkz6qYViIBQGTJPx4k0XpIgEZoRN1Da0cij4VfR0ta3WvBXH/rjdCufv6R2zPgPH/e4pxSBCpeatqPrjNiso203/5s/zA171Mv8+w1LOAAAAAElFTkSuQmCC">
<img style="padding:0px 0px 3px 0px;" src="data:image/gif;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAEDmlDQ1BrQ0dDb2xvclNwYWNlR2VuZXJpY1JHQgAAOI2NVV1oHFUUPpu5syskzoPUpqaSDv41lLRsUtGE2uj+ZbNt3CyTbLRBkMns3Z1pJjPj/KRpKT4UQRDBqOCT4P9bwSchaqvtiy2itFCiBIMo+ND6R6HSFwnruTOzu5O4a73L3PnmnO9+595z7t4LkLgsW5beJQIsGq4t5dPis8fmxMQ6dMF90A190C0rjpUqlSYBG+PCv9rt7yDG3tf2t/f/Z+uuUEcBiN2F2Kw4yiLiZQD+FcWyXYAEQfvICddi+AnEO2ycIOISw7UAVxieD/Cyz5mRMohfRSwoqoz+xNuIB+cj9loEB3Pw2448NaitKSLLRck2q5pOI9O9g/t/tkXda8Tbg0+PszB9FN8DuPaXKnKW4YcQn1Xk3HSIry5ps8UQ/2W5aQnxIwBdu7yFcgrxPsRjVXu8HOh0qao30cArp9SZZxDfg3h1wTzKxu5E/LUxX5wKdX5SnAzmDx4A4OIqLbB69yMesE1pKojLjVdoNsfyiPi45hZmAn3uLWdpOtfQOaVmikEs7ovj8hFWpz7EV6mel0L9Xy23FMYlPYZenAx0yDB1/PX6dledmQjikjkXCxqMJS9WtfFCyH9XtSekEF+2dH+P4tzITduTygGfv58a5VCTH5PtXD7EFZiNyUDBhHnsFTBgE0SQIA9pfFtgo6cKGuhooeilaKH41eDs38Ip+f4At1Rq/sjr6NEwQqb/I/DQqsLvaFUjvAx+eWirddAJZnAj1DFJL0mSg/gcIpPkMBkhoyCSJ8lTZIxk0TpKDjXHliJzZPO50dR5ASNSnzeLvIvod0HG/mdkmOC0z8VKnzcQ2M/Yz2vKldduXjp9bleLu0ZWn7vWc+l0JGcaai10yNrUnXLP/8Jf59ewX+c3Wgz+B34Df+vbVrc16zTMVgp9um9bxEfzPU5kPqUtVWxhs6OiWTVW+gIfywB9uXi7CGcGW/zk98k/kmvJ95IfJn/j3uQ+4c5zn3Kfcd+AyF3gLnJfcl9xH3OfR2rUee80a+6vo7EK5mmXUdyfQlrYLTwoZIU9wsPCZEtP6BWGhAlhL3p2N6sTjRdduwbHsG9kq32sgBepc+xurLPW4T9URpYGJ3ym4+8zA05u44QjST8ZIoVtu3qE7fWmdn5LPdqvgcZz8Ww8BWJ8X3w0PhQ/wnCDGd+LvlHs8dRy6bLLDuKMaZ20tZrqisPJ5ONiCq8yKhYM5cCgKOu66Lsc0aYOtZdo5QCwezI4wm9J/v0X23mlZXOfBjj8Jzv3WrY5D+CsA9D7aMs2gGfjve8ArD6mePZSeCfEYt8CONWDw8FXTxrPqx/r9Vt4biXeANh8vV7/+/16ffMD1N8AuKD/A/8leAvFY9bLAAAAOGVYSWZNTQAqAAAACAABh2kABAAAAAEAAAAaAAAAAAACoAIABAAAAAEAAAAQoAMABAAAAAEAAAAQAAAAABedU8gAAAAqSURBVDgRY/iPBxgbG//fvHkzHhX//zMxUAhGDWBgGA2D0TAAZaOBTwcARJI8l3biI98AAAAASUVORK5CYII=" />
<select style="font-size: 12px;" onchange="formatDoc('fontname',this[this.selectedIndex].value);this.selectedIndex=0;">
 <option selected>Arial</option>
 <option>Arial Black</option>
 <option>Courier New</option>
 <option>Times New Roman</option>
 <option>Helvetica</option>
 <option>Verdana</option>
 <option>Monaco</option>
 <option>Brush Script MT</option>
 <option>Copperplate</option>
 </select>
<select style="font-size: 12px;" onchange="formatDoc('fontsize',this[this.selectedIndex].value);this.selectedIndex=0;">
 <option value="1">Very small</option>
 <option value="2">A bit small</option>
 <option value="3" selected>Normal</option>
 <option value="4">Medium-large</option>
 <option value="5">Big</option>
 <option value="6">Very big</option>
 <option value="7">Maximum</option>
 <option value="7">Get add-ons</option>
 </select>
 <select style="font-size: 12px;" onchange="formatDoc('forecolor',this[this.selectedIndex].value);this.selectedIndex=0;">
 <option value="red">Red</option>
 <option value="blue">Blue</option>
 <option value="green">Green</option>
 <option value="black" selected>Black</option>
 <option value="violet">Violet</option>
 <option value="pink">Pink</option>
 <option value="OrangeRed">OrangeRed</option>
 <option value="MediumSeaGreen">MediumSeaGreen</option>
 <option value="DodgerBlue">DodgerBlue</option>
 <option value="Orange">Orange</option>
 <option value="Olive">Olive</option>
 <option value="Teal">Teal</option>
 <option value="White">white</option>
 <option value="Yellow">Yellow</option>
 <option value="YelloGreen">YellowGreen</option>
 <option value="Gold">Gold</option>
 <option value="GoldenRed">GoldenRed</option>
 <option value="FireBrick">FireBrick</option>
 <option value="Purple">Purple</option>
 <option value="Magenta">Magenta</option>
 <option value="Intego">Intego</option>
 <option value="Slategrey">Slategray</option>
 </select>
 <select style="font-size: 12px;" onchange="formatDoc('backcolor',this[this.selectedIndex].value);this.selectedIndex=0;">
 <option value="red">Red</option>
 <option value="blue">Blue</option>
 <option value="green">Green</option>
 <option value="black" selected>Black</option>
 <option value="violet">Violet</option>
 <option value="pink">Pink</option>
 <option value="OrangeRed">OrangeRed</option>
 <option value="MediumSeaGreen">MediumSeaGreen</option>
 <option value="DodgerBlue">DodgerBlue</option>
 <option value="Orange">Orange</option>
 <option value="Olive">Olive</option>
 <option value="Teal">Teal</option>
 <option value="White">white</option>
 <option value="Yellow">Yellow</option>
 <option value="YellowGreen">YellowGreen</option>
 <option value="Gold">Gold</option>
 <option value="GoldenRed">GoldenRed</option>
 <option value="FireBrick">FireBrick</option>
 <option value="Purple">Purple</option>
 <option value="Magenta">Magenta</option>
 <option value="Intego">Intego</option>
 <option value="Slategrey">Slategray</option>
 </select>
<img style="padding:0px 0px 3px 0px;" src="data:image/gif;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAEDmlDQ1BrQ0dDb2xvclNwYWNlR2VuZXJpY1JHQgAAOI2NVV1oHFUUPpu5syskzoPUpqaSDv41lLRsUtGE2uj+ZbNt3CyTbLRBkMns3Z1pJjPj/KRpKT4UQRDBqOCT4P9bwSchaqvtiy2itFCiBIMo+ND6R6HSFwnruTOzu5O4a73L3PnmnO9+595z7t4LkLgsW5beJQIsGq4t5dPis8fmxMQ6dMF90A190C0rjpUqlSYBG+PCv9rt7yDG3tf2t/f/Z+uuUEcBiN2F2Kw4yiLiZQD+FcWyXYAEQfvICddi+AnEO2ycIOISw7UAVxieD/Cyz5mRMohfRSwoqoz+xNuIB+cj9loEB3Pw2448NaitKSLLRck2q5pOI9O9g/t/tkXda8Tbg0+PszB9FN8DuPaXKnKW4YcQn1Xk3HSIry5ps8UQ/2W5aQnxIwBdu7yFcgrxPsRjVXu8HOh0qao30cArp9SZZxDfg3h1wTzKxu5E/LUxX5wKdX5SnAzmDx4A4OIqLbB69yMesE1pKojLjVdoNsfyiPi45hZmAn3uLWdpOtfQOaVmikEs7ovj8hFWpz7EV6mel0L9Xy23FMYlPYZenAx0yDB1/PX6dledmQjikjkXCxqMJS9WtfFCyH9XtSekEF+2dH+P4tzITduTygGfv58a5VCTH5PtXD7EFZiNyUDBhHnsFTBgE0SQIA9pfFtgo6cKGuhooeilaKH41eDs38Ip+f4At1Rq/sjr6NEwQqb/I/DQqsLvaFUjvAx+eWirddAJZnAj1DFJL0mSg/gcIpPkMBkhoyCSJ8lTZIxk0TpKDjXHliJzZPO50dR5ASNSnzeLvIvod0HG/mdkmOC0z8VKnzcQ2M/Yz2vKldduXjp9bleLu0ZWn7vWc+l0JGcaai10yNrUnXLP/8Jf59ewX+c3Wgz+B34Df+vbVrc16zTMVgp9um9bxEfzPU5kPqUtVWxhs6OiWTVW+gIfywB9uXi7CGcGW/zk98k/kmvJ95IfJn/j3uQ+4c5zn3Kfcd+AyF3gLnJfcl9xH3OfR2rUee80a+6vo7EK5mmXUdyfQlrYLTwoZIU9wsPCZEtP6BWGhAlhL3p2N6sTjRdduwbHsG9kq32sgBepc+xurLPW4T9URpYGJ3ym4+8zA05u44QjST8ZIoVtu3qE7fWmdn5LPdqvgcZz8Ww8BWJ8X3w0PhQ/wnCDGd+LvlHs8dRy6bLLDuKMaZ20tZrqisPJ5ONiCq8yKhYM5cCgKOu66Lsc0aYOtZdo5QCwezI4wm9J/v0X23mlZXOfBjj8Jzv3WrY5D+CsA9D7aMs2gGfjve8ArD6mePZSeCfEYt8CONWDw8FXTxrPqx/r9Vt4biXeANh8vV7/+/16ffMD1N8AuKD/A/8leAvFY9bLAAAAOGVYSWZNTQAqAAAACAABh2kABAAAAAEAAAAaAAAAAAACoAIABAAAAAEAAAAQoAMABAAAAAEAAAAQAAAAABedU8gAAAAqSURBVDgRY/iPBxgbG//fvHkzHhX//zMxUAhGDWBgGA2D0TAAZaOBTwcARJI8l3biI98AAAAASUVORK5CYII=" />
<img class="intLink" title="Bold" onclick="formatDoc('bold');" src="data:image/gif;base64,R0lGODlhFgAWAID/AMDAwAAAACH5BAEAAAAALAAAAAAWABYAQAInhI+pa+H9mJy0LhdgtrxzDG5WGFVk6aXqyk6Y9kXvKKNuLbb6zgMFADs=" />
<img class="intLink" title="Italic" onclick="formatDoc('italic');" src="data:image/gif;base64,R0lGODlhFgAWAKEDAAAAAF9vj5WIbf///yH5BAEAAAMALAAAAAAWABYAAAIjnI+py+0Po5x0gXvruEKHrF2BB1YiCWgbMFIYpsbyTNd2UwAAOw==" />
<img class="intLink" title="Underline" onclick="formatDoc('underline');" src="data:image/gif;base64,R0lGODlhFgAWAKECAAAAAF9vj////////yH5BAEAAAIALAAAAAAWABYAAAIrlI+py+0Po5zUgAsEzvEeL4Ea15EiJJ5PSqJmuwKBEKgxVuXWtun+DwxCCgA7" />
<img style="padding:0px 0px 3px 0px;" src="data:image/gif;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAEDmlDQ1BrQ0dDb2xvclNwYWNlR2VuZXJpY1JHQgAAOI2NVV1oHFUUPpu5syskzoPUpqaSDv41lLRsUtGE2uj+ZbNt3CyTbLRBkMns3Z1pJjPj/KRpKT4UQRDBqOCT4P9bwSchaqvtiy2itFCiBIMo+ND6R6HSFwnruTOzu5O4a73L3PnmnO9+595z7t4LkLgsW5beJQIsGq4t5dPis8fmxMQ6dMF90A190C0rjpUqlSYBG+PCv9rt7yDG3tf2t/f/Z+uuUEcBiN2F2Kw4yiLiZQD+FcWyXYAEQfvICddi+AnEO2ycIOISw7UAVxieD/Cyz5mRMohfRSwoqoz+xNuIB+cj9loEB3Pw2448NaitKSLLRck2q5pOI9O9g/t/tkXda8Tbg0+PszB9FN8DuPaXKnKW4YcQn1Xk3HSIry5ps8UQ/2W5aQnxIwBdu7yFcgrxPsRjVXu8HOh0qao30cArp9SZZxDfg3h1wTzKxu5E/LUxX5wKdX5SnAzmDx4A4OIqLbB69yMesE1pKojLjVdoNsfyiPi45hZmAn3uLWdpOtfQOaVmikEs7ovj8hFWpz7EV6mel0L9Xy23FMYlPYZenAx0yDB1/PX6dledmQjikjkXCxqMJS9WtfFCyH9XtSekEF+2dH+P4tzITduTygGfv58a5VCTH5PtXD7EFZiNyUDBhHnsFTBgE0SQIA9pfFtgo6cKGuhooeilaKH41eDs38Ip+f4At1Rq/sjr6NEwQqb/I/DQqsLvaFUjvAx+eWirddAJZnAj1DFJL0mSg/gcIpPkMBkhoyCSJ8lTZIxk0TpKDjXHliJzZPO50dR5ASNSnzeLvIvod0HG/mdkmOC0z8VKnzcQ2M/Yz2vKldduXjp9bleLu0ZWn7vWc+l0JGcaai10yNrUnXLP/8Jf59ewX+c3Wgz+B34Df+vbVrc16zTMVgp9um9bxEfzPU5kPqUtVWxhs6OiWTVW+gIfywB9uXi7CGcGW/zk98k/kmvJ95IfJn/j3uQ+4c5zn3Kfcd+AyF3gLnJfcl9xH3OfR2rUee80a+6vo7EK5mmXUdyfQlrYLTwoZIU9wsPCZEtP6BWGhAlhL3p2N6sTjRdduwbHsG9kq32sgBepc+xurLPW4T9URpYGJ3ym4+8zA05u44QjST8ZIoVtu3qE7fWmdn5LPdqvgcZz8Ww8BWJ8X3w0PhQ/wnCDGd+LvlHs8dRy6bLLDuKMaZ20tZrqisPJ5ONiCq8yKhYM5cCgKOu66Lsc0aYOtZdo5QCwezI4wm9J/v0X23mlZXOfBjj8Jzv3WrY5D+CsA9D7aMs2gGfjve8ArD6mePZSeCfEYt8CONWDw8FXTxrPqx/r9Vt4biXeANh8vV7/+/16ffMD1N8AuKD/A/8leAvFY9bLAAAAOGVYSWZNTQAqAAAACAABh2kABAAAAAEAAAAaAAAAAAACoAIABAAAAAEAAAAQoAMABAAAAAEAAAAQAAAAABedU8gAAAAqSURBVDgRY/iPBxgbG//fvHkzHhX//zMxUAhGDWBgGA2D0TAAZaOBTwcARJI8l3biI98AAAAASUVORK5CYII=" />
<img class="intLink" title="Left align" onclick="formatDoc('justifyleft');" src="data:image/gif;base64,R0lGODlhFgAWAID/AMDAwAAAACH5BAEAAAAALAAAAAAWABYAQAIghI+py+0Po5y02ouz3jL4D4JMGELkGYxo+qzl4nKyXAAAOw==" />
<img class="intLink" title="Center align" onclick="formatDoc('justifycenter');" src="data:image/gif;base64,R0lGODlhFgAWAID/AMDAwAAAACH5BAEAAAAALAAAAAAWABYAQAIfhI+py+0Po5y02ouz3jL4D4JOGI7kaZ5Bqn4sycVbAQA7" />
<img class="intLink" title="Right align" onclick="formatDoc('justifyright');" src="data:image/gif;base64,R0lGODlhFgAWAID/AMDAwAAAACH5BAEAAAAALAAAAAAWABYAQAIghI+py+0Po5y02ouz3jL4D4JQGDLkGYxouqzl43JyVgAAOw==" />
<img style="padding:0px 0px 3px 0px;" src="data:image/gif;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAEDmlDQ1BrQ0dDb2xvclNwYWNlR2VuZXJpY1JHQgAAOI2NVV1oHFUUPpu5syskzoPUpqaSDv41lLRsUtGE2uj+ZbNt3CyTbLRBkMns3Z1pJjPj/KRpKT4UQRDBqOCT4P9bwSchaqvtiy2itFCiBIMo+ND6R6HSFwnruTOzu5O4a73L3PnmnO9+595z7t4LkLgsW5beJQIsGq4t5dPis8fmxMQ6dMF90A190C0rjpUqlSYBG+PCv9rt7yDG3tf2t/f/Z+uuUEcBiN2F2Kw4yiLiZQD+FcWyXYAEQfvICddi+AnEO2ycIOISw7UAVxieD/Cyz5mRMohfRSwoqoz+xNuIB+cj9loEB3Pw2448NaitKSLLRck2q5pOI9O9g/t/tkXda8Tbg0+PszB9FN8DuPaXKnKW4YcQn1Xk3HSIry5ps8UQ/2W5aQnxIwBdu7yFcgrxPsRjVXu8HOh0qao30cArp9SZZxDfg3h1wTzKxu5E/LUxX5wKdX5SnAzmDx4A4OIqLbB69yMesE1pKojLjVdoNsfyiPi45hZmAn3uLWdpOtfQOaVmikEs7ovj8hFWpz7EV6mel0L9Xy23FMYlPYZenAx0yDB1/PX6dledmQjikjkXCxqMJS9WtfFCyH9XtSekEF+2dH+P4tzITduTygGfv58a5VCTH5PtXD7EFZiNyUDBhHnsFTBgE0SQIA9pfFtgo6cKGuhooeilaKH41eDs38Ip+f4At1Rq/sjr6NEwQqb/I/DQqsLvaFUjvAx+eWirddAJZnAj1DFJL0mSg/gcIpPkMBkhoyCSJ8lTZIxk0TpKDjXHliJzZPO50dR5ASNSnzeLvIvod0HG/mdkmOC0z8VKnzcQ2M/Yz2vKldduXjp9bleLu0ZWn7vWc+l0JGcaai10yNrUnXLP/8Jf59ewX+c3Wgz+B34Df+vbVrc16zTMVgp9um9bxEfzPU5kPqUtVWxhs6OiWTVW+gIfywB9uXi7CGcGW/zk98k/kmvJ95IfJn/j3uQ+4c5zn3Kfcd+AyF3gLnJfcl9xH3OfR2rUee80a+6vo7EK5mmXUdyfQlrYLTwoZIU9wsPCZEtP6BWGhAlhL3p2N6sTjRdduwbHsG9kq32sgBepc+xurLPW4T9URpYGJ3ym4+8zA05u44QjST8ZIoVtu3qE7fWmdn5LPdqvgcZz8Ww8BWJ8X3w0PhQ/wnCDGd+LvlHs8dRy6bLLDuKMaZ20tZrqisPJ5ONiCq8yKhYM5cCgKOu66Lsc0aYOtZdo5QCwezI4wm9J/v0X23mlZXOfBjj8Jzv3WrY5D+CsA9D7aMs2gGfjve8ArD6mePZSeCfEYt8CONWDw8FXTxrPqx/r9Vt4biXeANh8vV7/+/16ffMD1N8AuKD/A/8leAvFY9bLAAAAOGVYSWZNTQAqAAAACAABh2kABAAAAAEAAAAaAAAAAAACoAIABAAAAAEAAAAQoAMABAAAAAEAAAAQAAAAABedU8gAAAAqSURBVDgRY/iPBxgbG//fvHkzHhX//zMxUAhGDWBgGA2D0TAAZaOBTwcARJI8l3biI98AAAAASUVORK5CYII=" />
<img class="intLink" title="Numbered list" onclick="formatDoc('insertorderedlist');" src="data:image/gif;base64,R0lGODlhFgAWAMIGAAAAADljwliE35GjuaezxtHa7P///////yH5BAEAAAcALAAAAAAWABYAAAM2eLrc/jDKSespwjoRFvggCBUBoTFBeq6QIAysQnRHaEOzyaZ07Lu9lUBnC0UGQU1K52s6n5oEADs=" />
<img class="intLink" title="Dotted list" onclick="formatDoc('insertunorderedlist');" src="data:image/gif;base64,R0lGODlhFgAWAMIGAAAAAB1ChF9vj1iE33mOrqezxv///////yH5BAEAAAcALAAAAAAWABYAAAMyeLrc/jDKSesppNhGRlBAKIZRERBbqm6YtnbfMY7lud64UwiuKnigGQliQuWOyKQykgAAOw==" />
<img class="intLink" title="Quote" onclick="formatDoc('formatblock','blockquote');" src="data:image/gif;base64,R0lGODlhFgAWAIQXAC1NqjFRjkBgmT9nqUJnsk9xrFJ7u2R9qmKBt1iGzHmOrm6Sz4OXw3Odz4Cl2ZSnw6KxyqO306K63bG70bTB0rDI3bvI4P///////////////////////////////////yH5BAEKAB8ALAAAAAAWABYAAAVP4CeOZGmeaKqubEs2CekkErvEI1zZuOgYFlakECEZFi0GgTGKEBATFmJAVXweVOoKEQgABB9IQDCmrLpjETrQQlhHjINrTq/b7/i8fp8PAQA7" />
<img class="intLink" title="Delete indentation" onclick="formatDoc('outdent');" src="data:image/gif;base64,R0lGODlhFgAWAMIHAAAAADljwliE35GjuaezxtDV3NHa7P///yH5BAEAAAcALAAAAAAWABYAAAM2eLrc/jDKCQG9F2i7u8agQgyK1z2EIBil+TWqEMxhMczsYVJ3e4ahk+sFnAgtxSQDqWw6n5cEADs=" />
<img class="intLink" title="Add indentation" onclick="formatDoc('indent');" src="data:image/gif;base64,R0lGODlhFgAWAOMIAAAAADljwl9vj1iE35GjuaezxtDV3NHa7P///////////////////////////////yH5BAEAAAgALAAAAAAWABYAAAQ7EMlJq704650B/x8gemMpgugwHJNZXodKsO5oqUOgo5KhBwWESyMQsCRDHu9VOyk5TM9zSpFSr9gsJwIAOw==" />
<img style="padding:0px 0px 3px 0px;" src="data:image/gif;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAEDmlDQ1BrQ0dDb2xvclNwYWNlR2VuZXJpY1JHQgAAOI2NVV1oHFUUPpu5syskzoPUpqaSDv41lLRsUtGE2uj+ZbNt3CyTbLRBkMns3Z1pJjPj/KRpKT4UQRDBqOCT4P9bwSchaqvtiy2itFCiBIMo+ND6R6HSFwnruTOzu5O4a73L3PnmnO9+595z7t4LkLgsW5beJQIsGq4t5dPis8fmxMQ6dMF90A190C0rjpUqlSYBG+PCv9rt7yDG3tf2t/f/Z+uuUEcBiN2F2Kw4yiLiZQD+FcWyXYAEQfvICddi+AnEO2ycIOISw7UAVxieD/Cyz5mRMohfRSwoqoz+xNuIB+cj9loEB3Pw2448NaitKSLLRck2q5pOI9O9g/t/tkXda8Tbg0+PszB9FN8DuPaXKnKW4YcQn1Xk3HSIry5ps8UQ/2W5aQnxIwBdu7yFcgrxPsRjVXu8HOh0qao30cArp9SZZxDfg3h1wTzKxu5E/LUxX5wKdX5SnAzmDx4A4OIqLbB69yMesE1pKojLjVdoNsfyiPi45hZmAn3uLWdpOtfQOaVmikEs7ovj8hFWpz7EV6mel0L9Xy23FMYlPYZenAx0yDB1/PX6dledmQjikjkXCxqMJS9WtfFCyH9XtSekEF+2dH+P4tzITduTygGfv58a5VCTH5PtXD7EFZiNyUDBhHnsFTBgE0SQIA9pfFtgo6cKGuhooeilaKH41eDs38Ip+f4At1Rq/sjr6NEwQqb/I/DQqsLvaFUjvAx+eWirddAJZnAj1DFJL0mSg/gcIpPkMBkhoyCSJ8lTZIxk0TpKDjXHliJzZPO50dR5ASNSnzeLvIvod0HG/mdkmOC0z8VKnzcQ2M/Yz2vKldduXjp9bleLu0ZWn7vWc+l0JGcaai10yNrUnXLP/8Jf59ewX+c3Wgz+B34Df+vbVrc16zTMVgp9um9bxEfzPU5kPqUtVWxhs6OiWTVW+gIfywB9uXi7CGcGW/zk98k/kmvJ95IfJn/j3uQ+4c5zn3Kfcd+AyF3gLnJfcl9xH3OfR2rUee80a+6vo7EK5mmXUdyfQlrYLTwoZIU9wsPCZEtP6BWGhAlhL3p2N6sTjRdduwbHsG9kq32sgBepc+xurLPW4T9URpYGJ3ym4+8zA05u44QjST8ZIoVtu3qE7fWmdn5LPdqvgcZz8Ww8BWJ8X3w0PhQ/wnCDGd+LvlHs8dRy6bLLDuKMaZ20tZrqisPJ5ONiCq8yKhYM5cCgKOu66Lsc0aYOtZdo5QCwezI4wm9J/v0X23mlZXOfBjj8Jzv3WrY5D+CsA9D7aMs2gGfjve8ArD6mePZSeCfEYt8CONWDw8FXTxrPqx/r9Vt4biXeANh8vV7/+/16ffMD1N8AuKD/A/8leAvFY9bLAAAAOGVYSWZNTQAqAAAACAABh2kABAAAAAEAAAAaAAAAAAACoAIABAAAAAEAAAAQoAMABAAAAAEAAAAQAAAAABedU8gAAAAqSURBVDgRY/iPBxgbG//fvHkzHhX//zMxUAhGDWBgGA2D0TAAZaOBTwcARJI8l3biI98AAAAASUVORK5CYII=" />
<img class="intLink" title="Undo" onclick="formatDoc('undo');" src="data:image/gif;base64,R0lGODlhFgAWAOMKADljwliE33mOrpGjuYKl8aezxqPD+7/I19DV3NHa7P///////////////////////yH5BAEKAA8ALAAAAAAWABYAAARR8MlJq7046807TkaYeJJBnES4EeUJvIGapWYAC0CsocQ7SDlWJkAkCA6ToMYWIARGQF3mRQVIEjkkSVLIbSfEwhdRIH4fh/DZMICe3/C4nBQBADs=" />
<img class="intLink" title="Redo" onclick="formatDoc('redo');" src="data:image/gif;base64,R0lGODlhFgAWAMIHAB1ChDljwl9vj1iE34Kl8aPD+7/I1////yH5BAEKAAcALAAAAAAWABYAAANKeLrc/jDKSesyphi7SiEgsVXZEATDICqBVJjpqWZt9NaEDNbQK1wCQsxlYnxMAImhyDoFAElJasRRvAZVRqqQXUy7Cgx4TC6bswkAOw==" />
<img style="padding:0px 0px 3px 0px;" src="data:image/gif;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAEDmlDQ1BrQ0dDb2xvclNwYWNlR2VuZXJpY1JHQgAAOI2NVV1oHFUUPpu5syskzoPUpqaSDv41lLRsUtGE2uj+ZbNt3CyTbLRBkMns3Z1pJjPj/KRpKT4UQRDBqOCT4P9bwSchaqvtiy2itFCiBIMo+ND6R6HSFwnruTOzu5O4a73L3PnmnO9+595z7t4LkLgsW5beJQIsGq4t5dPis8fmxMQ6dMF90A190C0rjpUqlSYBG+PCv9rt7yDG3tf2t/f/Z+uuUEcBiN2F2Kw4yiLiZQD+FcWyXYAEQfvICddi+AnEO2ycIOISw7UAVxieD/Cyz5mRMohfRSwoqoz+xNuIB+cj9loEB3Pw2448NaitKSLLRck2q5pOI9O9g/t/tkXda8Tbg0+PszB9FN8DuPaXKnKW4YcQn1Xk3HSIry5ps8UQ/2W5aQnxIwBdu7yFcgrxPsRjVXu8HOh0qao30cArp9SZZxDfg3h1wTzKxu5E/LUxX5wKdX5SnAzmDx4A4OIqLbB69yMesE1pKojLjVdoNsfyiPi45hZmAn3uLWdpOtfQOaVmikEs7ovj8hFWpz7EV6mel0L9Xy23FMYlPYZenAx0yDB1/PX6dledmQjikjkXCxqMJS9WtfFCyH9XtSekEF+2dH+P4tzITduTygGfv58a5VCTH5PtXD7EFZiNyUDBhHnsFTBgE0SQIA9pfFtgo6cKGuhooeilaKH41eDs38Ip+f4At1Rq/sjr6NEwQqb/I/DQqsLvaFUjvAx+eWirddAJZnAj1DFJL0mSg/gcIpPkMBkhoyCSJ8lTZIxk0TpKDjXHliJzZPO50dR5ASNSnzeLvIvod0HG/mdkmOC0z8VKnzcQ2M/Yz2vKldduXjp9bleLu0ZWn7vWc+l0JGcaai10yNrUnXLP/8Jf59ewX+c3Wgz+B34Df+vbVrc16zTMVgp9um9bxEfzPU5kPqUtVWxhs6OiWTVW+gIfywB9uXi7CGcGW/zk98k/kmvJ95IfJn/j3uQ+4c5zn3Kfcd+AyF3gLnJfcl9xH3OfR2rUee80a+6vo7EK5mmXUdyfQlrYLTwoZIU9wsPCZEtP6BWGhAlhL3p2N6sTjRdduwbHsG9kq32sgBepc+xurLPW4T9URpYGJ3ym4+8zA05u44QjST8ZIoVtu3qE7fWmdn5LPdqvgcZz8Ww8BWJ8X3w0PhQ/wnCDGd+LvlHs8dRy6bLLDuKMaZ20tZrqisPJ5ONiCq8yKhYM5cCgKOu66Lsc0aYOtZdo5QCwezI4wm9J/v0X23mlZXOfBjj8Jzv3WrY5D+CsA9D7aMs2gGfjve8ArD6mePZSeCfEYt8CONWDw8FXTxrPqx/r9Vt4biXeANh8vV7/+/16ffMD1N8AuKD/A/8leAvFY9bLAAAAOGVYSWZNTQAqAAAACAABh2kABAAAAAEAAAAaAAAAAAACoAIABAAAAAEAAAAQoAMABAAAAAEAAAAQAAAAABedU8gAAAAqSURBVDgRY/iPBxgbG//fvHkzHhX//zMxUAhGDWBgGA2D0TAAZaOBTwcARJI8l3biI98AAAAASUVORK5CYII=" />
<img class="intLink" title="Cut" onclick="formatDoc('cut');" src="data:image/gif;base64,R0lGODlhFgAWAIQSAB1ChBFNsRJTySJYwjljwkxwl19vj1dusYODhl6MnHmOrpqbmpGjuaezxrCztcDCxL/I18rL1P///////////////////////////////////////////////////////yH5BAEAAB8ALAAAAAAWABYAAAVu4CeOZGmeaKqubDs6TNnEbGNApNG0kbGMi5trwcA9GArXh+FAfBAw5UexUDAQESkRsfhJPwaH4YsEGAAJGisRGAQY7UCC9ZAXBB+74LGCRxIEHwAHdWooDgGJcwpxDisQBQRjIgkDCVlfmZqbmiEAOw==" />
<img class="intLink" title="Copy" onclick="formatDoc('copy');" src="data:image/gif;base64,R0lGODlhFgAWAIQcAB1ChBFNsTRLYyJYwjljwl9vj1iE31iGzF6MnHWX9HOdz5GjuYCl2YKl8ZOt4qezxqK63aK/9KPD+7DI3b/I17LM/MrL1MLY9NHa7OPs++bx/Pv8/f///////////////yH5BAEAAB8ALAAAAAAWABYAAAWG4CeOZGmeaKqubOum1SQ/kPVOW749BeVSus2CgrCxHptLBbOQxCSNCCaF1GUqwQbBd0JGJAyGJJiobE+LnCaDcXAaEoxhQACgNw0FQx9kP+wmaRgYFBQNeAoGihCAJQsCkJAKOhgXEw8BLQYciooHf5o7EA+kC40qBKkAAAGrpy+wsbKzIiEAOw==" />
<img class="intLink" title="Paste" onclick="formatDoc('paste');" src="data:image/gif;base64,R0lGODlhFgAWAIQUAD04KTRLY2tXQF9vj414WZWIbXmOrpqbmpGjudClFaezxsa0cb/I1+3YitHa7PrkIPHvbuPs+/fvrvv8/f///////////////////////////////////////////////yH5BAEAAB8ALAAAAAAWABYAAAWN4CeOZGmeaKqubGsusPvBSyFJjVDs6nJLB0khR4AkBCmfsCGBQAoCwjF5gwquVykSFbwZE+AwIBV0GhFog2EwIDchjwRiQo9E2Fx4XD5R+B0DDAEnBXBhBhN2DgwDAQFjJYVhCQYRfgoIDGiQJAWTCQMRiwwMfgicnVcAAAMOaK+bLAOrtLUyt7i5uiUhADs=" />
<img class="intLink" title="Clean" onclick="if(validateMode()&&confirm('Are you sure?')){oDoc.innerHTML=sDefTxt};" src="data:image/gif;base64,R0lGODlhFgAWAIQbAD04KTRLYzFRjlldZl9vj1dusY14WYODhpWIbbSVFY6O7IOXw5qbms+wUbCztca0ccS4kdDQjdTLtMrL1O3YitHa7OPcsd/f4PfvrvDv8Pv5xv///////////////////yH5BAEKAB8ALAAAAAAWABYAAAV84CeOZGmeaKqubMteyzK547QoBcFWTm/jgsHq4rhMLoxFIehQQSAWR+Z4IAyaJ0kEgtFoLIzLwRE4oCQWrxoTOTAIhMCZ0tVgMBQKZHAYyFEWEV14eQ8IflhnEHmFDQkAiSkQCI2PDC4QBg+OAJc0ewadNCOgo6anqKkoIQA7" />
<img style="padding:0px 0px 3px 0px;" src="data:image/gif;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAEDmlDQ1BrQ0dDb2xvclNwYWNlR2VuZXJpY1JHQgAAOI2NVV1oHFUUPpu5syskzoPUpqaSDv41lLRsUtGE2uj+ZbNt3CyTbLRBkMns3Z1pJjPj/KRpKT4UQRDBqOCT4P9bwSchaqvtiy2itFCiBIMo+ND6R6HSFwnruTOzu5O4a73L3PnmnO9+595z7t4LkLgsW5beJQIsGq4t5dPis8fmxMQ6dMF90A190C0rjpUqlSYBG+PCv9rt7yDG3tf2t/f/Z+uuUEcBiN2F2Kw4yiLiZQD+FcWyXYAEQfvICddi+AnEO2ycIOISw7UAVxieD/Cyz5mRMohfRSwoqoz+xNuIB+cj9loEB3Pw2448NaitKSLLRck2q5pOI9O9g/t/tkXda8Tbg0+PszB9FN8DuPaXKnKW4YcQn1Xk3HSIry5ps8UQ/2W5aQnxIwBdu7yFcgrxPsRjVXu8HOh0qao30cArp9SZZxDfg3h1wTzKxu5E/LUxX5wKdX5SnAzmDx4A4OIqLbB69yMesE1pKojLjVdoNsfyiPi45hZmAn3uLWdpOtfQOaVmikEs7ovj8hFWpz7EV6mel0L9Xy23FMYlPYZenAx0yDB1/PX6dledmQjikjkXCxqMJS9WtfFCyH9XtSekEF+2dH+P4tzITduTygGfv58a5VCTH5PtXD7EFZiNyUDBhHnsFTBgE0SQIA9pfFtgo6cKGuhooeilaKH41eDs38Ip+f4At1Rq/sjr6NEwQqb/I/DQqsLvaFUjvAx+eWirddAJZnAj1DFJL0mSg/gcIpPkMBkhoyCSJ8lTZIxk0TpKDjXHliJzZPO50dR5ASNSnzeLvIvod0HG/mdkmOC0z8VKnzcQ2M/Yz2vKldduXjp9bleLu0ZWn7vWc+l0JGcaai10yNrUnXLP/8Jf59ewX+c3Wgz+B34Df+vbVrc16zTMVgp9um9bxEfzPU5kPqUtVWxhs6OiWTVW+gIfywB9uXi7CGcGW/zk98k/kmvJ95IfJn/j3uQ+4c5zn3Kfcd+AyF3gLnJfcl9xH3OfR2rUee80a+6vo7EK5mmXUdyfQlrYLTwoZIU9wsPCZEtP6BWGhAlhL3p2N6sTjRdduwbHsG9kq32sgBepc+xurLPW4T9URpYGJ3ym4+8zA05u44QjST8ZIoVtu3qE7fWmdn5LPdqvgcZz8Ww8BWJ8X3w0PhQ/wnCDGd+LvlHs8dRy6bLLDuKMaZ20tZrqisPJ5ONiCq8yKhYM5cCgKOu66Lsc0aYOtZdo5QCwezI4wm9J/v0X23mlZXOfBjj8Jzv3WrY5D+CsA9D7aMs2gGfjve8ArD6mePZSeCfEYt8CONWDw8FXTxrPqx/r9Vt4biXeANh8vV7/+/16ffMD1N8AuKD/A/8leAvFY9bLAAAAOGVYSWZNTQAqAAAACAABh2kABAAAAAEAAAAaAAAAAAACoAIABAAAAAEAAAAQoAMABAAAAAEAAAAQAAAAABedU8gAAAAqSURBVDgRY/iPBxgbG//fvHkzHhX//zMxUAhGDWBgGA2D0TAAZaOBTwcARJI8l3biI98AAAAASUVORK5CYII=" />
<img class="intLink" title="Hyperlink" onclick="addLink();" src="data:image/gif;base64,R0lGODlhFgAWAOMKAB1ChDRLY19vj3mOrpGjuaezxrCztb/I19Ha7Pv8/f///////////////////////yH5BAEKAA8ALAAAAAAWABYAAARY8MlJq7046827/2BYIQVhHg9pEgVGIklyDEUBy/RlE4FQF4dCj2AQXAiJQDCWQCAEBwIioEMQBgSAFhDAGghGi9XgHAhMNoSZgJkJei33UESv2+/4vD4TAQA7" />
<img class="intLink" title="Youtube" onclick="var sLnk=prompt('Write the youtube embed Youtube URL here','http:\/\/');if(sLnk&&sLnk!=''&&sLnk!='http://'){createYoutubeVideo(sLnk);}" style="padding-bottom:3px;" src="data:image/gif;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAB70lEQVR42mNkoBAwUs0AFwkRVl5WFiF2JiYOJgYGdrAkEIDo/0AAJBn+/Wf4+fPvvx+f//x5t+fl299wA9Y7mCcZiQnPAKpkZfj/D6QDisGaGaAMMAUigGK/r338nOF96Ow8sAHn/Z1vCrGxqRGjGQbe/fx1y2jnUXVGCQ52npO+jp+AihiRNbPoaDP8+/iJ4e/DBxiaQWpA3rLde4KPUY2PW3m3m80ddJs53NwYuCIiGb5v28rwff16Bobfv+CawQYAmd6Hzqgw6gnw6W12triI4mwgATKAOyYW7MW/z58zfJo5g+HfndtwzSAQdOScPqOxEL/JOgez08h+BjmP080dbABI7P2B/Qwv581j4P75k4GLlQXukvDjF00Zdfh59bc6WVxADzAOd3cGFlc3hifTpzF8v3CBgQUYo8xMjAzsTMwMnCwsDKAIDjhy3oBRmI1V5KyX/SugLkbk0P4rIcnw/OEjBuZfP+GamRkRmI2Z+b/F3hNiID8y7nQ0v6LOx62FEtpAw778+s3w/fdvDM1MwORz9+u3695HzmuDA8lUiN+tQlulU4ydTZSdiZGdjYmJFWQAUJLx19+/DD///GUEmvH/DzABAfGvtz9/v+66+aD89PtPO5DzAih0WIGYCYqxgX9QJ/6GYipmJnIBAGKXA3ZDuxX3AAAAAElFTkSuQmCC" />
<img class="intLink" title="Remove formatting" style="padding-bottom:3px;" onclick="formatDoc('removeFormat')" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAQAAAC1+jfqAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QA/4ePzL8AAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAHdElNRQfjAx8UBhREcYbCAAAAmElEQVQoz6XRIQoCYRDF8Z8gaLQZjcYvGsUkJj2DRUwewRsYjQsW4x7BM5iseoIFy9bPIOqu7K7B/5SB95iZx/AvrUIf9L7Us/uzmdojyMRSjWmDubWupYmFkZmrA7h9hiWiBJxEm6prXpbgolN98MtSI8PxvaiGVGyy9OXypik7UVpKVKIjE4Wv0AWGtlaFRJlB85d+yG8eryUvOcQW1MAAAAAldEVYdGRhdGU6Y3JlYXRlADIwMTktMDMtMzFUMTg6MDY6MjArMDI6MDCoiNwZAAAAJXRFWHRkYXRlOm1vZGlmeQAyMDE5LTAzLTMxVDE4OjA2OjIwKzAyOjAw2dVkpQAAAABJRU5ErkJggg=="/>
<img class="intLink" title="Hyperlink" onclick="addSelfLink();" src="data:image/gif;base64,R0lGODlhFgAWAOMKAB1ChDRLY19vj3mOrpGjuaezxrCztb/I19Ha7Pv8/f///////////////////////yH5BAEKAA8ALAAAAAAWABYAAARY8MlJq7046827/2BYIQVhHg9pEgVGIklyDEUBy/RlE4FQF4dCj2AQXAiJQDCWQCAEBwIioEMQBgSAFhDAGghGi9XgHAhMNoSZgJkJei33UESv2+/4vD4TAQA7" />
<img style="padding:0px 0px 3px 0px;" src="data:image/gif;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAEDmlDQ1BrQ0dDb2xvclNwYWNlR2VuZXJpY1JHQgAAOI2NVV1oHFUUPpu5syskzoPUpqaSDv41lLRsUtGE2uj+ZbNt3CyTbLRBkMns3Z1pJjPj/KRpKT4UQRDBqOCT4P9bwSchaqvtiy2itFCiBIMo+ND6R6HSFwnruTOzu5O4a73L3PnmnO9+595z7t4LkLgsW5beJQIsGq4t5dPis8fmxMQ6dMF90A190C0rjpUqlSYBG+PCv9rt7yDG3tf2t/f/Z+uuUEcBiN2F2Kw4yiLiZQD+FcWyXYAEQfvICddi+AnEO2ycIOISw7UAVxieD/Cyz5mRMohfRSwoqoz+xNuIB+cj9loEB3Pw2448NaitKSLLRck2q5pOI9O9g/t/tkXda8Tbg0+PszB9FN8DuPaXKnKW4YcQn1Xk3HSIry5ps8UQ/2W5aQnxIwBdu7yFcgrxPsRjVXu8HOh0qao30cArp9SZZxDfg3h1wTzKxu5E/LUxX5wKdX5SnAzmDx4A4OIqLbB69yMesE1pKojLjVdoNsfyiPi45hZmAn3uLWdpOtfQOaVmikEs7ovj8hFWpz7EV6mel0L9Xy23FMYlPYZenAx0yDB1/PX6dledmQjikjkXCxqMJS9WtfFCyH9XtSekEF+2dH+P4tzITduTygGfv58a5VCTH5PtXD7EFZiNyUDBhHnsFTBgE0SQIA9pfFtgo6cKGuhooeilaKH41eDs38Ip+f4At1Rq/sjr6NEwQqb/I/DQqsLvaFUjvAx+eWirddAJZnAj1DFJL0mSg/gcIpPkMBkhoyCSJ8lTZIxk0TpKDjXHliJzZPO50dR5ASNSnzeLvIvod0HG/mdkmOC0z8VKnzcQ2M/Yz2vKldduXjp9bleLu0ZWn7vWc+l0JGcaai10yNrUnXLP/8Jf59ewX+c3Wgz+B34Df+vbVrc16zTMVgp9um9bxEfzPU5kPqUtVWxhs6OiWTVW+gIfywB9uXi7CGcGW/zk98k/kmvJ95IfJn/j3uQ+4c5zn3Kfcd+AyF3gLnJfcl9xH3OfR2rUee80a+6vo7EK5mmXUdyfQlrYLTwoZIU9wsPCZEtP6BWGhAlhL3p2N6sTjRdduwbHsG9kq32sgBepc+xurLPW4T9URpYGJ3ym4+8zA05u44QjST8ZIoVtu3qE7fWmdn5LPdqvgcZz8Ww8BWJ8X3w0PhQ/wnCDGd+LvlHs8dRy6bLLDuKMaZ20tZrqisPJ5ONiCq8yKhYM5cCgKOu66Lsc0aYOtZdo5QCwezI4wm9J/v0X23mlZXOfBjj8Jzv3WrY5D+CsA9D7aMs2gGfjve8ArD6mePZSeCfEYt8CONWDw8FXTxrPqx/r9Vt4biXeANh8vV7/+/16ffMD1N8AuKD/A/8leAvFY9bLAAAAOGVYSWZNTQAqAAAACAABh2kABAAAAAEAAAAaAAAAAAACoAIABAAAAAEAAAAQoAMABAAAAAEAAAAQAAAAABedU8gAAAAqSURBVDgRY/iPBxgbG//fvHkzHhX//zMxUAhGDWBgGA2D0TAAZaOBTwcARJI8l3biI98AAAAASUVORK5CYII=" />
    <img class="intLink" title="Refresh Topic" style="padding-bottom:3px;" (mousedown)="selectImage($event);" (mouseup)="selectTopic($event);" [src]="refreshImageSrc"/>
  </div>

    <div
                                               style="font-size:12px;font-weight:400;color:black;float:right;width:70px;
                                               padding-right:20px;font-family:arial;display:table-cell;vertical-align:middle;">
      <img class="intLink" title="Edit Topic" style="padding:9px 0px 0px 0px;" (click)="edit($event);" [src]="editImageSrc"/> &nbsp;<div style="float:right;padding:10px 0px 20px 0px;">{{action}}</div></div>
</div>


</div>






<div style="display:table;text-align:center;margin:0px;padding:120px 0px 0px 0px;width:100%;" id="pane2">
  <div class="doNotPrint" style="float:left; width:10%;">&nbsp;</div>
  <div class="doNotPrint" style="float:right; width:10%;">&nbsp;</div>
  <div tabindex="1" (blur)='setTopic()' style="border: 1px gainsboro solid; text-align: left; padding:40px 90px 40px 90px; width:80%;margin:0 auto !important; display:inline-block" id="textBox" contenteditable="false"></div>
</div>

  <footer></footer>
