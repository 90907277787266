<admin-header></admin-header>
 <body style="background-color:#ffffff00; height:100%;width:100%; margin:0px;">
  <form method="post">

    <div class="doNotPrint" style="background-color:whitesmoke;position:fixed;margin-top:47px;display:table;width:100%; height:38px; valign:middle;border:0px solid lightgrey;padding:0px 0px 0px 0px;">
      <div  style="text-align:center;margin:0 auto;color:mediumseagreen;padding:0px 5px 0px 12px;font-family:Helvetica,AppleGothic,sans-serif;font-size:1em; font-weight:600;width:90%;vertical-align: middle;">
        <a class="fa fa-arrow-left intLink" title="Done with the deck of '{{topic.hashTag}}'" [routerLink]="['/admin/doc']" [queryParams]="{h:topic.hashTag}" valign="middle" style="color:dodgerblue;;background-color:#ffffff00;font-size:1em;width:22px;height:22px;border:0px; padding:10px;font-weight:300;"></a>
        <a class="fa fa-download intLink"  valign="middle" (click)="onPrint();" style="color:dodgerblue;background-color:#ffffff00;border:0px; padding:10px;font-weight:300;font-size:1em;width:22px;height:22px;" title="Download the deck of '{{topic.hashTag}}'"></a>
        <a class="fa fa-arrow-right intLink" title="Demo the deck of '{{topic.hashTag}}'" (click)="demo()"  valign="middle" style="color:dodgerblue;;background-color:#ffffff00;font-size:1em;width:22px;height:22px;border:0px; padding:10x;font-weight:300;"></a>
      </div>
    </div>
    <div  class="doNotPrint" style="padding:100px 0px 10px 0px;font-family:Helvetica,AppleGothic,sans-serif; width:100%;text-align:center;color:mediumseagreen;font-size:1em;font-weight:500;vertical-align: middle;">Deck</div>
    <div class="block_container" style="background-color:#ffffff00;border-bottom:1px solid gainsboro;padding:20px 0px 0px 0px;font-family:Century Gothic,CenturyGothic,AppleGothic,sans-serif; text-align: center; valign: top; font-size:1em;font-weight:200;"><a href="" ><img src="/assets/isloq.png" align="middle" style="margin-bottom:13px;width:19px; height:19px;" /></a> <span style="color:grey;"></span> <a style="text-decoration:none;" href=""><span
      style="color:dodgerblue;">i</span><span
      style="color:mediumseagreen;">s</span><span
      style="color:orangered;">l</span><span
      style="color:orange;">o</span><span
      style="color:dodgerblue;">q</span><span
      class="sup" style="color:orangered;">&trade;</span><span style="color:dodgerblue;">&nbsp;Publish Instantly!</span> </a>
    </div>
    <div id="doc" style="font-family:Helvetica,AppleGothic,sans-serif;width:92%;overflow:hidden;margin:4px 4% 40px 4%;padding-top:10px;z-index:-1px;">
      <h2 style="vertical-align: middle;padding:0px 0px 0px 5px;margin:0 auto;width:90%;text-align:center;">
        <div  style="font-family: Arial, Helvetica, sans-serif;padding:0px 0px 0px 2px; margin:0px 0px 0px 3px;" >{{model.hashTag}}</div>
      </h2>

<div id="textbox" style="font-family:Helvetica,AppleGothic,sans-serif;width:70%;height:70%;overflow:hidden;margin:0px 0px 50px 15%;padding-top:10px;z-index:-1px;">
<div class = "regular" style="min-height:300px;margin-top:30px;">
    <br>
    <div  style="width:100%;height:100%;vertical-align:top;margin:0px;display:inline-table;padding:5px;" >
      <div style="width:100%;height:100%;vertical-align:top;margin:0px;display:inline-table;" >
        <div  style="text-align;center; pading:20px 0px 0px 0px;margin-bottom:30px;">
          <div  valign="top" style="text-align: center;font-weight:500;font-size:1.5em;color:dodgerblue;padding:0px 1px 2px 0px; height:20px;margin:0 auto;" >Welcome</div>
        </div>
        <ul *ngFor="let slide of subtitles let i = index" style="width:80%; list-style: none;background-color:white;display:table; margin:0 auto; font-family:Helvetica,AppleGothic,sans-serif; inline-block;font-size:1em;font-weight:400;padding: 10px; height:20px;text-align:left;">
        <li>
<div style="font-weight:600;font-size:2em;color:mediumseagreen;
            float:left;display:inline-block;">•</div><div style="padding:10px
                                             0px 0px 20px;color:dark">{{slide}}</div>

      </li>
        </ul>
      </div>
    </div>
    <br>
    <br>
  </div>


  <div  *ngFor="let slide of subtitles; let i = index" (click)="selectSlide(slide, i)" class = "pagebreak" style="min-height:300px;margin-top:30px;">
    <div [class.active]="i === selectedSlideIndex">
  <div  class = "regular" style="min-height:300px;">
    <br>
    <div  style="width:100%;height:100%;vertical-align:top;margin:0px;display:inline-table;padding:5px;" >
      <div style="width:100%;height:100%;vertical-align:top;margin:0px;display:inline-table;" >
        <div  style="text-align;center; pading:20px 0px 0px 0px;margin-bottom:30px;">
          <div  valign="top" style="text-align: center;font-weight:300;font-size:1em;color:dodgerblue;padding:0px 1px 2px 0px; height:20px;margin:0 auto;" >{{slide}}</div>
        </div>
        <ul *ngFor="let tag of slides[slide]; let j = index" (click)="selectTag(tag, j)" style="width:80%; list-style: none;background-color:white;display:table; margin:0 auto; font-family:Helvetica,AppleGothic,sans-serif; inline-block;font-size:1em;font-weight:400;padding: 10px; height:20px;text-align:left;">
          <li style="display:table;">
            <div [class.active]="i === selectedSlideIndex && j === selectedTagIndex">
            <div style="font-weight:250;font-size:.9em;color:mediumseagreen;margin-top;100px; float:left;display:table-cell;vertical-align: middle;">•</div><div style="vertical-align:middle;padding:0px 0px 0px 20px;color:dark;display:table-cell;" [innerHTML]="renderImage(tag.value) | safeHtml"></div>&nbsp;<div style="float:right; width:20px;display:table-cell;vertical-align:top;"><a *ngIf="tag.source!==theUser" href="{{tag.source}}" target="_blank">[{{refIndex(tag.source)}}]</a><a *ngIf="tag.source===theUser" [routerLink]="['/admin/public']" target="_blank">[{{refIndex(tag.source)}}]</a></div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <br>
    <br>
  </div>
  </div>
  </div>

<div class="pagebreak"> </div>

<div class = "regular" style="min-height:300px;margin-top:30px;">
    <br>
    <div  style="width:100%;height:100%;vertical-align:top;margin:0px;display:inline-table;padding:5px;" >
      <div style="width:100%;height:100%;vertical-align:top;margin:0px;display:inline-table;" >
        <div  style="text-align;center; pading:20px 0px 0px 0px;margin-bottom:30px;">
          <div  valign="top" style="text-align: center;font-weight:500;font-size:1.5em;color:dodgerblue;padding:0px 1px 2px 0px; height:20px;margin:0 auto;" >Thank you</div>
        </div>
        <ul *ngFor="let tag of utags let i = index" style="width:80%; list-style: none;background-color:white;display:table; margin:0 auto; font-family:Helvetica,AppleGothic,sans-serif; inline-block;font-size:1em;font-weight:400;padding: 10px; height:20px;text-align:left;">
                    <li *ngIf="tag.length > 0">
                      <div style="font-weight:600;font-size:2em;color:mediumseagreen; float:left;display:inline-block;">•</div><div style="padding:10px 0px 0px 20px;color:dark">
                        <span *ngIf="tag===theUser" style="display: inline-block;" valign="top"><a (click)='profile();'>{{curbemail(tag)}}</a></span>
                        <span *ngIf="tag!==theUser" style="display: inline-block;" valign="top">[{{i+1}}]&nbsp;<a href="{{tag}}" target="_blank">{{tag | truncate : 32}}</a></span>
                      </div>
                    </li>
        </ul>
      </div>
    </div>
    <br>
    <br>
  </div>
	</div>
    <div class="block_container" style="background-color:#ffffff00;border-top:1px solid gainsboro;padding:10px 0px 0px 0px;font-family:Century Gothic,CenturyGothic,AppleGothic,sans-serif; text-align: center; valign: top; font-size:1em;font-weight:200;"><a style="text-decoration:none;" href="http://siloq.com" >
      <span style="color:dodgerblue;">Powered by</span> <img src="assets/isloq.png" align="middle" style="margin-bottom:13px;width:19px; height:19px;" /></a> <span style="color:grey;"></span> <a style="text-decoration:none;" href="http://siloq.com"><span style="color:dodgerblue;">i</span><span
      style="color:mediumseagreen;">s</span><span
      style="color:dodgerblue;">l</span><span
      style="color:orange;">o</span><span
      style="color:dodgerblue;">q</span><span
      class="sup"
      style="color:orangered;">™</span>
    </a>
    </div>
  <br>
  <br>
  <br>
  <br>
  <doc-footer></doc-footer>
