<admin-header></admin-header>
<div style="text-align:center; height:100%; width:100%;padding-top:120px;">
<div class="row expanded align-center" style="text-align: center; margin: auto;">
<h2 class="block_container siloqlogofont" style="min-width:330px;">Direct Feedback to <a href="/"
                                                               target="_self"><span

  style="color:dodgerblue;">{{taxonomy}}</span><span
  class="sup"
  style="color:mediumseagreen;">&trade;</span>
  </a> </h2>
</div>
	<br>
  <admin-alert></admin-alert>
  <br>
	<form>
		<div class="small-12 columns">
      <div maxlength="25" contenteditable="true" class="siloqinput mydisable" [textContent]='sub' style="font-family:arial;min-width:300px;" id="subject" > </div>
		</div>
		<br>
		<div class="small-12 columns">
			<div contenteditable="true" class="siloqinput" data-text="Short Description *"  [textContent]='model.value' style="height:140px;font-family:arial;min-width:300px;min-height:100px;" id="value"> </div>
		</div>
		<br>
		<br>
    <div class="small-12 columns">
          <div style="width:25%;margin:auto;min-width:300px;" class="siloqfont">
	    <button (click)="submitTag()" type="button" class="siloqbutton">Submit</button>
            <button class="siloqbutton" style="color:orangered;"(click)='done()'>Cancel</button>
            <br>
          </div>
     </div>
		<br>
		<br>
		<br>
	</form>
</div>
<admin-footer></admin-footer>
