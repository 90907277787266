import { Injectable } from '@angular/core';
import { Response, Headers, RequestOptions } from '@angular/http';
import {Observable, throwError} from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {Tag} from '../admin/tag/model/tag';
import {Topic} from '../admin/topic/model/topic';
import { config } from '../config';
import {RouterModule} from '@angular/router';
import { SisHeaderComponent } from '../sisHeader/sis-header.component';
import { HomeFooterComponent } from '../homeFooter/home-footer.component';
import { FormsModule } from '@angular/forms';
import { map, pipe, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class TagService {
  constructor(private http: HttpClient) { }

  getTags() : Observable<Tag[]> {
    let headers = new HttpHeaders().append('Content-Type', 'text/plain');
    let options = { headers: headers, withCredentials: true  };
    return this.http.get<Tag[]>(config.tagsUrl, options)
    .pipe(catchError((error:any) => throwError(error.json().error || 'Server error')));
  }

  searchTags(s: string, k: string, d: string) : Observable<Tag[]> {
    return this.http.get<Tag[]>(config.tagsUrl + "SearchTag/?s=" + s + "&k=" + k + "&d=" + d)
    .pipe(catchError((error:any) => throwError(error.json().error || 'Server error')));
  }

  filterTags(h: string, s: string, v: string, k: string, d: string) : Observable<Tag[]> {
    return this.http.get<Tag[]>(config.tagsUrl + "FilterTag/?h=" + h + "&s=" + s + "&v=" + v + "&k=" + k + "&d=" + d)
    .pipe(catchError((error:any) => throwError(error.json().error || 'Server error')));
  }

  getTopicUserTags(h: string, d: string) : Observable<Tag[]> {
    let body = new Tag("", "", "", "", "");
    body.hashTag = h;
    body.email = d;
    let headers = new HttpHeaders().append('Content-Type', 'text/plain');
    let options = { headers: headers, withCredentials: true  };
    let bodyString = JSON.stringify(body); // Stringify payload
    return this.http.post<Tag[]>(config.tagsUrl + "/tags/topic/user", bodyString, options)
    .pipe(catchError((error:any) => throwError(error.json().error || 'Server error')));
  }

  getTopicSourceTags(h: string, s: string) : Observable<Tag[]> {
    let body = new Tag("", "", "", "", "");
    body.hashTag = h;
    body.source = s;
    let headers = new HttpHeaders().append('Content-Type', 'text/plain');
    let options = { headers: headers, withCredentials: true  };
    let bodyString = JSON.stringify(body); // Stringify payload
    return this.http.post<Tag[]>(config.tagsUrl + "/tags/topic/source", bodyString, options)
    .pipe(catchError((error:any) => throwError(error.json().error || 'Server error')));
  }

  addTag (body: Tag): Observable<Tag> {
    let headers = new HttpHeaders().append('Content-Type', 'text/plain');
    let options = { headers: headers, withCredentials: true  };
    let bodyString = JSON.stringify(body); // Stringify payload
    return this.http.post<Tag>(config.tagsUrl, bodyString, options) // ...using post request
    /*
    .pipe(map((res:Response) => res)) // ...and returning data
    .pipe(catchError((error:any) => throwError(error || 'Server error'))); //...errors if any
    */
  }

  addTags (body: Tag[]): Observable<Topic> {
    let headers = new HttpHeaders().append('Content-Type', 'text/plain');
    let options = { headers: headers, withCredentials: true  };
    let bodyString = JSON.stringify(body); // Stringify payload
    return this.http.post<Topic>(config.tagsUrl+"tags", bodyString, options) // ...using post request
    /*
    .pipe(map((res:Response) => res)) // ...and returning data
    .pipe(catchError((error:any) => throwError(error || 'Server error'))); //...errors if any
    */
  }

  updateTag (body: Tag): Observable<Object> {
    let headers = new HttpHeaders().append('Content-Type', 'text/plain');
    let options = { headers: headers, withCredentials: true  };
    let bodyString = JSON.stringify(body); // Stringify payload
    return this.http.put<Object>(config.tagsUrl, bodyString, options) // ...using post request
  }

  updateTagsTopicName(oName, nName): Observable<Object> {
    let headers = new HttpHeaders().append('Content-Type', 'text/plain');
    let options = { headers: headers, withCredentials: true  };
    return this.http.put<Object>(config.tagsUrl+"/"+oName+"/"+nName, "", options) // ...using post request
    .pipe(catchError((error:any) => throwError(error || 'Server error'))); //...errors if any
  }

  deleteTag (id: String): Observable<Object> {
    let headers = new HttpHeaders().append('Content-Type', 'text/plain');
    let options = { headers: headers, withCredentials: true  };
    return this.http.delete<Object>(config.tagsUrl+id, options) // ...using delete request
    .pipe(catchError((error:any) => throwError(error || 'Server error'))); //...errors if any
  }
  rankTag (body: Tag): Observable<Object> {
    let headers = new HttpHeaders().append('Content-Type', 'text/plain');
    let options = { headers: headers, withCredentials: true  };
    let bodyString = JSON.stringify(body); // Stringify payload
    return this.http.put<Object>(config.tagsUrl+"/rank/", bodyString, options) // ...using post request
    .pipe(catchError((error:any) => throwError(error || 'Server error'))); //...errors if any
  }
}
