<header></header>
<div class="content" style=" background-size:cover;
            width:100%;text-align:center;
            display:table;margin-top:10px;border-top:1px solid
            darkgrey;padding-top:80px;">
  <div class="content-inside" style="margin-left:0 auto;
              min-width:300px;width:25%;text-align: center; display:table-cell;
              padding:0px 0px 0px 0px;">
    <div class="small-12 columns"><h3 class="block_container siloqlogofont"
                                      style="text-align:center;margin:
                                      auto;color:navy;min-width:300px;width:25%;">Logout <a href="/" target="_self">
                                                     <span
                                                       style="color:dodgerblue;">{{taxonomy}}</span><span
      class="sup"
      style="color:mediumseagreen;">&trade;</span>
    </a></h3>
    </div>
    <br>
    <br>
    <div class="container2">
      <div class="small-12 columns"><h4 class="block_container siloqlogofont" style="font-size:1em;text-align:center;margin: auto;color:orangered;mineght:600;width:300px;width:25%;">Successfully logged out of <a href="/" target="_self"> <span
          style="color:navy;">{{taxonomy}}</span><span
          class="sup"
          style="color:mediumseagreen;">&trade;</span>

      </a></h4>
      </div>
      <br>
      <div class="small-12 columns">
        <label>
          <div class="siloqlogofont" style="margin:auto;text-align:center;width:300px;color:dodgerblue;padding-top:5px;" >
            Thank you for visiting
          </div>
          <div
              style="margin:auto;text-align:center;width:300px;color:mediumseagreen;padding-top:5px;font-weigt:200;font-size:1em;" class="siloqfont">
            You've securely signed off.
          </div>
        </label>
      </div>
    </div>
      <br>
      <div *ngIf="!userSVC.authUser.email" class="row expanded align-center" style="text-align: center; margin:0 auto;width:25%;">
          <br>
          <button *ngIf="!userSVC.authUser.email" (click)='signin()' class="siloqbutton" style="background-color:dodgerblue;color:white;border:0px;">Sign In</button>
        <!--
          <div style="margin:0 auto;text-align:center;" class="siloqfont">
            <a style="color:dodgerblue;" [routerLink]="['/forgot']">Forgot password?</a>
          </div>
          <hr>
          <button (click)='signup()' class="siloqbutton" style="margin-top:12px;background-color:mediumseagreen;color:white;border:0px;">Sign Up</button>
          -->
          <br>
        </div>

  </div>
</div>
<br>
<br>
<br>
<br>
<footer class="footer"></footer>
