<dummy-header></dummy-header>
  <body style="height:100%;width:100%; margin:0px;">

  <div class="doNotPrint" style="background-color:whitesmoke;position:fixed;margin-top:47px;display:table;width:100%; height:38px; valign:middle;border:0px solid lightgrey;padding:0px 0px 0px 0px;">
    <div  style="text-align:center;margin:0 auto;color:mediumseagreen;padding:0px 5px 0px 12px;cursor:pointer;font-family:Helvetica,AppleGothic,sans-serif;font-size:1em; font-weight:600;width:90%;vertical-align: middle;">
      <a class="fa fa-arrow-left intLink" title="Cancel the re-exam of '{{theTitle}}'" (click)="docTopic(theTitle)" type="button" valign="middle" style="color:dodgerblue;;background-color:#ffffff00;font-size:1em;width:22px;height:22px;border:0px; padding-top:10px;font-weight:300;"></a>
      <a class="fa fa-bullseye intLink" title="Re-exam '{{theTitle}}'" (click)="reexam()" type="button" valign="middle" style="color:dodgerblue;;background-color:#ffffff00;font-size:1em;width:22px;height:22px;border:0px; padding-top:10px;font-weight:300;"></a>
    </div>
  </div>
  <div  class="doNotPrint" style="padding:100px 0px 10px 0px;font-family:Helvetica,AppleGothic,sans-serif; width:100%;text-align:center;color:mediumseagreen;font-size:1em;font-weight:500;vertical-align: middle;">{{taxonomy}}: REEXAM</div>
  <div class="block_container" style="background-color:#ffffff00;border-bottom:1px solid gainsboro;padding:20px 0px 0px 0px;font-family:Century Gothic,CenturyGothic,AppleGothic,sans-serif; text-align: center; valign: top; font-size:1em;font-weight:200;"><a href="" ><img src="/assets/isloq.png" align="middle" style="margin-bottom:13px;width:19px; height:19px;" /></a> <span style="color:grey;"></span> <a style="text-decoration:none;" href=""><span
    style="color:dodgerblue;">i</span><span
    style="color:mediumseagreen;">s</span><span
    style="color:orangered;">l</span><span
    style="color:orange;">o</span><span
    style="color:dodgerblue;">q</span><span
    class="sup" style="color:orangered;">&trade;</span><span style="color:dodgerblue;">&nbsp;Publish Instantly!</span> </a>
  </div>
  <div id="doc" style="font-family:Helvetica,AppleGothic,sans-serif;width:92%;overflow:hidden;margin:4px 4% 40px 4%;padding-top:10px;z-index:-1px;">
    <h2 style="vertical-align: middle;padding:0px 0px 15px 5px;margin:0 auto;width:90%;text-align:center;">
      <div  style="font-family: Arial, Helvetica, sans-serif;padding:0px 0px 0px 2px; margin:0px 0px 0px 3px;" >{{theTitle}}</div>
    </h2>
    <admin-alert></admin-alert>
    <ng-template id="modal" #modalTemplate>
    </ng-template>

 <div style="width:90%; margin:auto; padding-top: 10px;text-align:center;">
  <input type="text" style="width:60%;display:table-cell;" class="siloqinput" placeholder="Topic" [(ngModel)]='model.hashTag'/>
  <!--
   &nbsp;<button type="button" class="siloqbutton" (click)='reexam()' style="color:orangered;display:table-cell;">&nbsp;&nbsp;&nbsp;⏎&nbsp;&nbsp;&nbsp;</button>
   -->
 </div>
    <br>
    <br>
    <div class="block_container" style="background-color:#ffffff00;border-top:1px solid gainsboro;padding:10px 0px 0px 0px;font-family:Century Gothic,CenturyGothic,AppleGothic,sans-serif; text-align: center; valign: top; font-size:1em;font-weight:200;"><a style="text-decoration:none;" href="http://siloq.com" >
      <span style="color:dodgerblue;">Powered by</span> <img src="assets/isloq.png" align="middle" style="margin-bottom:13px;width:19px; height:19px;" /></a> <span style="color:grey;"></span> <a style="text-decoration:none;" href="http://siloq.com"><span style="color:dodgerblue;">i</span><span
      style="color:mediumseagreen;">s</span><span
      style="color:dodgerblue;">l</span><span
      style="color:orange;">o</span><span
      style="color:dodgerblue;">q</span><span
      class="sup"
      style="color:orangered;">™</span>
    </a>
    </div>
 <br>
 <br>
<dummy-footer></dummy-footer>
