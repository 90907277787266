import { Component, OnInit } from '@angular/core';
import {TagService} from '../../services/tag-service.service';
import {Observable, throwError} from 'rxjs';
import {HostListener} from '@angular/core';
import {ActivatedRoute, Router } from '@angular/router';
import {UserService} from '../../services/user.service';
import {Tag} from '../../admin/tag/model/tag';
import {User} from '../../admin/user/model/user';
import {CookieService} from 'ng2-cookies';
import { AdminAlertComponent } from '../adminAlert/admin-alert.component';
import { AdminAlertService } from '../adminAlert/services/admin-alert.service';
import { AlertTypeEnum } from '../adminAlert/types/alertType.enum';
import { config } from '../../config';
import {RouterModule} from '@angular/router';
import { AdminHeaderComponent } from '../adminHeader/admin-header.component';
import { AdminFooterComponent } from '../adminFooter/admin-footer.component';
import { FormsModule } from '@angular/forms';
@HostListener('window:keydown', ['$event'])
@Component({
  host: {
    '(document:keydown)': 'keyDown($event)',
  },
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.css'],
  imports: [AdminAlertComponent, FormsModule, RouterModule, AdminFooterComponent, AdminHeaderComponent],
  standalone: true
})

export class FeedbackComponent implements OnInit {
  public model = new Tag('', '', '', '', '');
  public theUser = "";
 public theGoldiUser = "";
  theHashTag= "";
  sub= "";
   public taxonomy = "";
   public class = "";
   public subclass = "";

  constructor( private alertService: AdminAlertService, private userService: UserService, private tagService: TagService, private activatedRoute: ActivatedRoute, public userSVC: UserService, private router: Router, private _cookieService:CookieService) {}

  getCookie(key: string){
    return this._cookieService.get(key);
  }

  ngOnInit() {
    this.theUser = this.getCookie("email");
  this.theGoldiUser = this.getCookie("gmt");
    this.taxonomy = config.taxonomy;
    this.class = config.class;
    this.subclass = config.subclass;
    this.activatedRoute.queryParams.subscribe(params => {
      this.sub = params['s'] || "";
    });
  }

showAlert(type: AlertTypeEnum, msg: string) {
    this.alertService.setAlert({
      type,
      text: msg,
    });
  }
  submitTag(){
    let commentOperation:Observable<Tag[]>;
    var tb1 = document.getElementById("subject");
    var tb2 = document.getElementById("value");
    if (tb1.innerText === "" || tb2.innerText === "")
    {
      this.showAlert(AlertTypeEnum['warning'], "Both the values can not be empty");
      return;
    }

    this.model.source = this.theUser;
    this.model.email =  "feedback@tagndoc.com";
    this.model.hashTag = "feedback";
    this.model.value = "<subject>" + tb1.innerText + "</subject>" +  tb2.innerText;
    this.tagService.addTag(this.model).subscribe(
      () => {
        tb2.innerText = "";
        this.showAlert(AlertTypeEnum['info'], "Thanks for your feedback.");
      },
       (err: any) => {
        console.log(err);
      });
  }

 public keyDown(event: any) {
    switch (event.keyCode) {
      case 13:
        this.submitTag()
        event.preventDefault();
        break;
    }
    return;
  }

  done() {
    this.router.navigate(['/admin/account']);
  }
}

