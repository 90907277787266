import { Component, OnInit } from '@angular/core';
import {UserService} from '../../services/user.service';
import {ActivatedRoute, Router } from '@angular/router';
import { config } from '../../config';
import {RouterModule} from '@angular/router';
import { FormsModule } from '@angular/forms';

@Component({
 host: {
                '(document:keydown)': 'keyDown($event)',
        },
  templateUrl: './admin-sis-header.component.html',
  styleUrls: ['./admin-sis-header.component.css'],
  selector: 'admin-sis-header',
  imports: [RouterModule],
  standalone: true
})

export class AdminSisHeaderComponent implements OnInit {
  public taxonomy = "";
  public class = "";
  public subclass = "";
  public chromeExtension = "";
  constructor( private activatedRoute: ActivatedRoute, public userSVC: UserService, private router: Router){}

  ngOnInit(){
    this.userSVC.sessionVerifyEx();
    this.class = config.class;
    this.subclass = config.subclass;
    this.chromeExtension = config.chromeExtension;
    this.taxonomy = config.taxonomy;
  }

  public newDoc() {
    this.router.navigate(['/admin/doc'],  { queryParams: { h : "newDoc", d : window.btoa(this.userSVC.authUser.email)}});
  }
}
