import { Component, OnInit } from '@angular/core';
import {UserService} from '../../services/user.service';
import {ActivatedRoute, Router } from '@angular/router';
import { config } from '../../config';
import {RouterModule, RouterOutlet} from '@angular/router';
import { FormsModule } from '@angular/forms';
import {CookieService} from 'ng2-cookies';
import { CommonModule } from '@angular/common';

@Component({
  templateUrl: './dummy-header.component.html',
  styleUrls: ['./dummy-header.component.css'],
  selector: 'dummy-header',
  imports: [CommonModule, RouterModule, RouterOutlet],
  standalone: true
})

export class DummyHeaderComponent implements OnInit {
  public identity = "";
  public taxonomy = "";
  public class = "";
  public subclass = "";
  public chromeExtension = "";
  public theUser = "";
  public theGoldiUser = "";

  constructor( private activatedRoute: ActivatedRoute, public userSVC: UserService, private router: Router, private _cookieService:CookieService){}

  getCookie(key: string){
    return this._cookieService.get(key);
  }

  ngOnInit(){
    this.userSVC.sessionVerifyEx();
    this.theUser = this.getCookie("email");
    this.theGoldiUser = this.getCookie("gmt");
    this.class = config.class;
    this.subclass = config.subclass;
    this.chromeExtension = config.chromeExtension;
    this.identity = config.identity;
    this.taxonomy = config.taxonomy;
  }

  reexams() {
      this.router.navigate(['/admin/exams'],  { queryParams: { t : "Reexam", u : "scheduled.imoqr@siloq.com"}});
  }

  results() {
      this.router.navigate(['/admin/exams'],  { queryParams: { t : "Result", u : "result.imoqr@siloq.com"}});
    }

  goldimembers() {
    if(this.theGoldiUser && this.theGoldiUser !== 'null') {
      this.router.navigate(['/admin/mocks'],  { queryParams: { t : "Goldimembers", u : "gold.imoqr@siloq.com"}});
    } else {
      this.router.navigate(['/admin/goldimembers']);
    }
  }

 public newDoc() {
     this.router.navigate(['/admin/doc'],  { queryParams: { h : "newDoc", d : window.btoa(this.userSVC.authUser.email)}});
   }
}
