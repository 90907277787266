import { Component} from '@angular/core';
import { config } from '../config';
import {RouterModule} from '@angular/router';
import { FormsModule } from '@angular/forms';

@Component({
  templateUrl: './home-footer.component.html',
  styleUrls: ['./home-footer.component.css'],
  selector: 'home-footer',
  standalone: true
 })
export class HomeFooterComponent{
public taxonomy = "";
public class = "";
public subclass = "";

ngOnInit(){
    this.taxonomy = config.taxonomy;
    this.class = config.class;
    this.subclass = config.subclass;
  }
}

