<admin-header></admin-header>
<div class="doNotPrint" style="margin-top:50px;display:table;width:100%; height:18px; valign:middle;border:0px solid lightgrey;padding:5px 0px 0px 5px;">
      <div style="vertical-align: middle;padding-top:20px;margin:0px;width:40%;display:table-cell;">
        <div style="font-family: Arial, Helvetica, sans-serif;float:left;padding:0px 2px 0px 2px; margin:0px 0px 0px 3px;cursor:pointer;" (click)="profile();" >{{curbemail(theUser)}}</div><input class="intLink" type="button" value="⭐️" valign="middle" style="background-color:#ffffff00;height:16px;width:16px;border:0px; padding:0px 0px 5px 0px;font-weight:300;font-size:.75em;"/>
      </div>
      <div style="display:table-cell;font-family:Helvetica,AppleGothic,sans-serif; width:20%;text-align:center;margin:0 auto; color:mediumseagreen;font-size:1em;font-weight:500;vertical-align: middle;padding-top:5px;">Tel</div>
      <div style="display:table-cell;font-family:Helvetica,AppleGothic,sans-serif; float:right;width:40%;margin-right:10px;text-align:right;padding:0px 5px 0px 12px;">
        <a class="fa fa-check intLink" title="Done with Tel" (click)="cancel()"  valign="middle" style="color:orangered;;background-color:#ffffff00;font-size:1em;width:22px;height:22px;border:0px; padding-top:10px;font-weight:300;"></a>
      </div>
    </div>
<div style="text-align:center; width:100%; padding-top:30px;">
    <div class="row expanded align-center" style="width:100%;text-align: center; margin: auto;">
      <h2 class="block_container siloqlogofont" style="text-align:left;min-width:300px;"> Tel <a href="" target="_self"><span

        style="color:dodgerblue;">{{taxonomy}}</span><span
        class="sup"
        style="color:mediumseagreen;">&trade;</span>
        </a> Your Domain</h2>
</div>
<br>
  <admin-alert></admin-alert>
  <br>
<form>
<div class="row align-center">
   <div class="small-12  columns">
        <label>
            <input readonly class="siloqinput" type="text" style="min-width:300px;" placeholder="Tel Domain" id="domain" [(ngModel)]="domain" name="domain">
        </label>
    </div>
     <!--<div  *ngIf="done" style="display: inline-block; margin-left:120px;text-align:left;">
	     Done {{domain}} ...
     </div>-->
    <br>
    <br>
      <div class="small-12  columns">
     <div style="width:25%;margin:auto;min-width:300px;" class="siloqfont">
		<button (click)="tel()" type="button" class="siloqbutton">Tel</button>
		<button (click)="stoptel()" type="button" class="siloqbutton">Stop</button>
    <button (click)="sync()" type="button" class="siloqbutton">Sync</button>
                <br>
                <br>
                <br>
	</div>
                <hr style="width:25%;margin:auto;min-width:300px;">
                <br>
                <br>
     <div style="width:25%;margin:auto;min-width:300px;" class="siloqfont">
		<button (click)="syncimoqr()" type="button" class="siloqbutton">Sync {{taxonomy}}</button>
     </div>
                <br>
                <br>
                <br>
    </div>
</div>
</form>
</div>
<admin-footer></admin-footer>
