<div style="position: fixed;width:100%; background-color:ffffff00"  *ngIf="alert">
  <div style="margin:0 auto; width:60%; padding-bottom:5px; border-bottom:0px solid gainsboro;font-family:Century Gothic,CenturyGothic,AppleGothic,sans-serif; text-align: center; valign: top; font-size:1em;font-weight:200;" class="alert">
    <div style="display:table; width:100%;">
      <div style="display:table-cell; width:10%;">&nbsp;</div>
      <div  style="width:79%; text-align:center;display:inline-block; position:relative;"><a href="" ><img src="/assets/admin-{{taxonomy}}.png" align="middle" style="margin-bottom:13px;width:19px; height:19px;" /></a> <span
          style="color:dodgerblue;">{{taxonomy}}</span><span
          class="sup" style="color:mediumseagreen;">&trade;</span>
        <span style="color:dodgerblue;">&nbsp;Runtime!</span> </div>
      <div style="display:table-cell; width:10%;"><input class="intLink" type="button" value="✕" valign="middle" (click)='alert = undefined;' title="Close" style="vertical-align:middle;color:orangered;background-color:#ffffff00;height:16px;width:16px;border:0px; padding:0px 0px 16px 0px;font-weight:300;font-size:.75em;" /></div>
    </div>
    <!--
    <div style="width:100%;"> {{ alert.text }}</div>
    -->
    <div style="width:100%;" [innerHTML]="alert.text"></div>
  </div>
</div>
