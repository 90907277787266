import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import {TagService} from '../../services/tag-service.service';
import {Tag} from '../tag/model/tag';
import {TopicService} from '../../services/topic.service';
import {Topic} from './model/topic';
import {Taxon} from './model/topic';
import {Observable, throwError} from 'rxjs';
import {HostListener} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {UserService} from '../../services/user.service';
import {Login} from '../../services/user.service';
import {CookieService} from 'ng2-cookies';
import { DataService } from "../../services/data.service";
import { AdminAlertComponent } from '../adminAlert/admin-alert.component';
import { AdminAlertService } from '../adminAlert/services/admin-alert.service';
import { AlertTypeEnum } from '../adminAlert/types/alertType.enum';
import { ModalService } from '../../modal/services/modal.service';
import { ModalComponent } from '../../modal/modal.component';
import { config } from '../../config';
import {RouterModule} from '@angular/router';
import { AdminHeaderComponent } from '../adminHeader/admin-header.component';
import { AdminFooterComponent } from '../adminFooter/admin-footer.component';
import { FormsModule } from '@angular/forms';
import { TruncatePipe } from '../tag/tag.truncate.pipe';
import { SafeHtmlPipe } from '../tag/tag.safehtml.pipe';
import { CommonModule } from '@angular/common';

declare function unescape(s:string): string;
declare function escape(s:string): string;

@Component({
  selector: 'app-topic',
  templateUrl: './topic.component.html',
  styleUrls: ['./topic.component.css'],
  imports: [CommonModule, AdminAlertComponent, FormsModule, RouterModule, AdminFooterComponent, AdminHeaderComponent, TruncatePipe, SafeHtmlPipe],
  standalone: true
})
export class TopicComponent implements OnInit {
  topics: Topic[] = [];
  selectedTopics: Topic[] = [];
  public showAuto: boolean;
  private model: Topic = new Topic('', '', '');
  public unmodel: Topic = new Topic('', '', '');
  private umodel: Topic = new Topic('', '', '');
  private branch: Topic = new Topic('', '', '');
  public selectedTopic;
  selectedIndex: number = -1;
  searchText = "onion";
  currentPage: number = 1;
  pageSize = 9;
  totalCount: number = 0;
  pageCount: number = 0;
  sortKey = "-updatedate";
  selectAll = false;
  public theUser = "";
 public theGoldiUser = "";
  host = window.location.protocol+"//"+window.location.hostname;
  @ViewChild('modalTemplate', {read: TemplateRef}) modalTemplate: TemplateRef<any>;
  public taxonomy = "";
  public pageTitle = "";
  public class = "";
  public subclass = "";
  edit = false;

  constructor(private tagService: TagService, private topicService: TopicService, private activatedRoute: ActivatedRoute, public userSVC: UserService, private router: Router, private loginInfo : Login, private _cookieService:CookieService, private data: DataService, private alertService: AdminAlertService, private modalService: ModalService){
    loginInfo = new Login();
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  showAlert(type: AlertTypeEnum, msg: string) {
    this.alertService.setAlert({
      type,
      text: msg,
    });
  }

  openModal(modalTemplate: TemplateRef<any>, topic: any) {
    this.modalService
      .open(modalTemplate, { size: 'lg', title: 'Archive Activity', message: "Do you want to delete the selected activity '" + unescape(topic.hashTag) + "' ?" })
      .subscribe((action) => {

      topic.hashTag = escape(topic.hashTag);
      this.topicService.archiveTopic(topic).subscribe(
        () => {
          this.model = new Topic('', '', '');
          this.setFirstPage();
          this.showAlert(AlertTypeEnum['info'], "The selected topic '" + unescape(topic.hashTag) + " " + "has been deleted.");
        },
         (err: any) => {
          console.log(err);
        });

      });
  }

  getCookie(key: string){
    return this._cookieService.get(key);
  }

  ngOnInit() {
    this.theUser = this.getCookie("email");
    this.theGoldiUser = this.getCookie("gmt");
    this.host = window.location.protocol+"//"+window.location.hostname;
    this.unmodel = new Topic('', '', '');
    this.class = config.class;
    this.subclass = config.subclass;
    this.taxonomy = config.taxonomy;
    this.activatedRoute.queryParams.subscribe(params => {
       this.searchText = params['q'] || "";
            if(this.searchText) {
              this.searchText = unescape(this.searchText);
            }
      this.currentPage = parseInt(params['p']) || 1;
    });

    this.setCurrentPage(this.currentPage);
  }

  loadTopics(callback: () => any) {
    this.model = new Topic('', '', '');
    this.topicService.searchTopics(escape(this.searchText), this.sortKey, this.theUser, "128")
      .subscribe(
        (topics: any) => {
          this.topics = topics; //Bind to view
          //alert(this.topics.length);
          if(this.topics !== undefined) {
            this.totalCount = this.topics.length;
            this.pageCount = Math.ceil(this.totalCount / this.pageSize);
            callback();
            this.setPageVisible();
          }
        },
         (err: any) => {
          console.log(err);
        });
  }

  archiveTopic(topic: Topic){
      let commentOperation:Observable<Topic[]>;
      this.openModal(this.modalTemplate, topic);
  }

  public selectTopic( topic: any, i: number) {
    topic.rank = i + 1;
    this.selectedIndex = i;
    this.selectedTopic =  topic;
    this.data.changeMessage(this.selectedTopic.hashTag.split(' ').slice(0,6).join(' ') + " " + this.selectedTopic.value.split(' ').slice(0,6).join(' ') + " " + this.curbemail(this.theUser));
  }

 @HostListener('keydown', ['$event'])
 keyDown(event: any) {
    switch (event.keyCode) {
      case 38:
        this.onUp();
        break;
      case 40:
        this.onDown()
        break;
      case 13:
        this.setCurrentPage(1);
        break;
    }
    return;
  }

  public onDown() {
    if(this.selectedIndex < this.topics.length - 1)
    {
      this.selectedTopic = this.topics[++this.selectedIndex];
      this.data.changeMessage(this.selectedTopic.hashTag.split(' ').slice(0,6).join(' ') + " " + this.selectedTopic.value.split(' ').slice(0,6).join(' ') + " " + this.curbemail(this.selectedTopic.email));
    }
  }

  public onUp() {
    if(this.selectedIndex > 0)
    {
      this.selectedTopic = this.topics[--this.selectedIndex];
      this.data.changeMessage(this.selectedTopic.hashTag.split(' ').slice(0,6).join(' ') + " " + this.selectedTopic.value.split(' ').slice(0,6).join(' ') + " " + this.curbemail(this.selectedTopic.email));
    }
  }

  docTopic(topic: any) {
    this.router.navigate(['/admin/loq'],  { queryParams: { h : topic.hashTag, i : topic.id}});
  }

  palyTopic(topic: any) {
    this.router.navigate(['/admin/play'],  { queryParams: { h : topic.hashTag, i : topic.id}});
  }

  editTopic2(topic: any) {
    this.router.navigate(['/admin/edit'],  { queryParams: { h : topic.hashTag, i : topic.id}});
  }

  docBranch(branch: any) {
    this.router.navigate(['/admin/edit'],  { queryParams: { h : branch}});
  }

  manageTopicUsers(topic: any) {
    this.router.navigate(['/admin/user'],  { queryParams: { h : topic.hashTag}});
  }

  invite() {
    this.router.navigate(['/admin/invite']);
  }

  inbox() {
    //TODO:
    //this.router.navigate(['/admin/inbox'], { queryParams: { h : "inbox-"+this.theUser, d : this.theUser}});
    this.router.navigate(['/admin/publish'], { queryParams: { h : "inbox", d : "tagndoc@tagndoc.com"}});
  }

  tagndoc() {
    this.router.navigate(['/admin/tagndoc']);
  }

  editTopic(topic: any) {
    //this.umodel.edit =  !this.umodel.edit;
    this.umodel = new Topic(topic.hashTag, topic.value, topic.userId);
    topic.edit = !topic.edit;
  }

  cancelUpdate(topic: Topic) {
    topic.hashTag = this.umodel.hashTag;
    topic.value = this.umodel.value;
    topic.insertDate = this.umodel.insertDate;
    topic.edit = false;
  }

  //pagination for table
  setCurrentPage(page: number) {
    this.loadTopics(() => {
      this.currentPage = page;
    });
  }

  public setFirstPage() {
    this.loadTopics(() => {
      this.currentPage = 1;
    });
  }

  setPageVisible() {
    this.selectedIndex = (this.currentPage - 1) * this.pageSize;
    var end = this.currentPage * this.pageSize
    this.selectedTopics = [];
    var j = this.selectedIndex;
    for (let i = j; i < this.topics.length; i++) {
      this.topics[i].show = false;
      /*
      if (this.topics[i].hashTag.indexOf("_exam") > 0) {
           continue;
      }
      */
      if(j >= this.selectedIndex && j < end) {
        this.topics[i].hashTag = unescape(this.topics[i].hashTag);
        this.topics[i].show = true;
        this.topics[i].value = "";
        this.selectedTopics.push(this.topics[i]);
        j++;
      }
    }
    this.selectedTopic = this.topics[this.selectedIndex];
    if (this.selectedTopic) {
      this.data.changeMessage(this.selectedTopic.hashTag.split(' ').slice(0,6).join(' ') + " " + this.selectedTopic.value.split(' ').slice(0,6).join(' ') + " " + this.curbemail(this.selectedTopic.email));
      this.selectedTopic.rank = this.selectedIndex + 1;
    }
  }


  setMe(element: any) {
    var s_height = element.scrollHeight;
    element.setAttribute('style','height:'+s_height+'px');
  }

  selectAllTopics() {
    for (let i = 0; i < this.topics.length; i++) {
      if(!this.selectAll) {
        this.topics[i].checked = true;
      }
      else {
        this.topics[i].checked = false;
      }

    }
  }

  removeUserFromTopic(model: Topic, user: string){
    var id = model.users.indexOf(user);
    model.users.splice(id,  1);
    let commentOperation:Observable<Topic[]>;
    this.topicService.updateTopic(model).subscribe(
      () => {
      },
       (err: any) => {
        console.log(err);
      });
  }

  sort(event): void {
    if(event.target) { this.sortKey=this.sortKey.includes('-') ? event.target.value : '-'+event.target.value; }
    this.setFirstPage();
  }

  setArrayFromNumber(i: number) {
  return new Array(i);
}

curbemail(email: string): string {
    if(!email) {
      return email;
    }

    var index = email.indexOf("@");
    if(index > 0) {
      return email.substring(0, index);
    }
    return email;
  }

profile(t: any) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.navigate(['profile'],  { queryParams: {d : window.btoa(t.email)}});
    return;
  }

myProfile(t: any) {
    this.router.navigate(['profile'],  { queryParams: { h : t.hashTag, i : t.id, d : window.btoa(this.theUser)}});
    return;
  }

  renderImage(t: any) {
    var s = t;
    t = t.replace(/<(?:.|\n)*?>/gm, '');
     if (t.startsWith("http://") || t.startsWith("https://")) {
         const img = new Image();
         img.src = t;
         var w = 60;
         var h = (w/img.width)*img.height;
         t = '<img src="' + t + '" style="width:' + w + 'px; height:' + h + 'px;" />'
         return t;
      }
         return s;
    }

    parseTitle(t: string): string {
        return t.replace(/_/g, " ");
    }

    merge() {
       var count = 0;
       for (let i = 0; i < this.topics.length; i++) {
          if(this.topics[i].checked) {
            count++;
            if (count > 1) {
              break;
            }
          }
       }

       if (count > 1) {
         let ht = prompt("New Activity name for the selected Activities: ");
         if(ht === null) {
          return;
         }

         var topics = [];
         if (ht && this.topics.length > 0) {
          //TODO: escape
           for (let i = 0; i < this.topics.length; i++) {
              if(this.topics[i].checked) {
                this.topics[i].checked = false;
                topics.push(this.topics[i].id);
              }
            }

           if(topics.length > 0) {
             var topic: Topic = new Topic("","","")
             topic.taxonomy = [];
             topic.email = this.theUser;
             topic.hashTag = escape(ht);
             //
             var taxon: Taxon = new Taxon();
             taxon.key = "mergeTopics";
             taxon.value = topics;
             //
             topic.taxonomy.push(taxon)
             //
             document.getElementById("loading").style.display = "inline";
             this.mergeTopics(topic, (t: any) => {
                this.router.navigate(['admin/loq'],  { queryParams: { h : t.hashTag, i : t.id}});
                document.getElementById("loading").style.display = "none";
              });
           }

          }
        //TODO: Add Tags to the new Loq
       } else {
           this.showAlert(AlertTypeEnum['info'], "Please select at least 2 activities to merge and create a new Activity.");
       }
   }

   mergeTopics(t: Topic, callback: (t: any) => any) {
    this.topicService.mergeTopics(t).subscribe(
      (data: any) => {
        callback(data);
      },
       (err: any) => {
        console.log(err);
      });
   }

   setState(target: any, topic: Topic) {
      topic.checked=target.checked;
      return;
   }

   setStateAll(event: any) {
      event.target.checked=!event.target.checked;
      this.edit=!this.edit;
   }

    first() {
      if(this.currentPage>1){
        this.currentPage=1;
        this.setPageVisible();
      }
    }

    previous() {
      if(this.currentPage>1){
        this.currentPage-=1;
        this.setPageVisible();
      }
    }

    next() {
      if(this.currentPage<this.pageCount){
        this.currentPage+=1;
        this.setPageVisible();
      }
    }

    last() {
      if(this.currentPage<this.pageCount){
        this.currentPage=this.pageCount;
        this.setPageVisible();
      }
    }
}
