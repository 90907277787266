import { Injectable } from '@angular/core';
import { Response, Headers, RequestOptions } from '@angular/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {Topic} from '../admin/topic/model/topic';
import { config } from '../config';
import {RouterModule} from '@angular/router';
import { SisHeaderComponent } from '../sisHeader/sis-header.component';
import { HomeFooterComponent } from '../homeFooter/home-footer.component';
import { FormsModule } from '@angular/forms';
import { map, pipe, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class QrCodeService {
  constructor(private http: HttpClient) { }

  qrCode(body: Topic) : Observable<string> {
           let headers = new HttpHeaders().append('Content-Type', 'text/plain');
    let options = { headers: headers, withCredentials: true  };
        let bodyString = JSON.stringify(body); // Stringify payload
        return this.http.post<string>(config.topicsUrl + "topics/topic/qrcode", bodyString, options) // ...using post request
      }
}
