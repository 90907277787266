import { Injectable } from '@angular/core';

export class Serializable {
    fromJSON(json: any){
        for (var propName in json)
            this[propName] = json[propName];
        return this;
    }
}

export class Taxon extends Serializable {
  key="";
  value: any;
  constructor(){
		 super();
  }
}

export class User extends Serializable {
  public password="";
  public sessionId: string
  public goldimembersSessionId: string
	public GetEmail(): string { return this.email }
	public GetSessionId(): string { return this.sessionId }
	public GetGoldimembersId(): string { return this.goldimembersSessionId }
	public id="";
	public insertDate="";
  public topics: string[];
  public users: string[];
  public isDisabled: false;
  public passwdHashSalt="";
  public taxonomy: Taxon[];
	//
	public show: boolean = false;
	public edit: boolean = false;
	public checked: boolean = false;

	constructor(
		public email: string,
		public userId: string,
		public phone: string) {
                    super();
	}
}
