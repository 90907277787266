<admin-header></admin-header>
<body style="height:100%;width:100%;">
 <div class="container2" style="margin:80px auto;width:276px; padding: 20px;">
   <div style="width:256px;text-align:center;margin:0 auto;" class="siloqfont">
                <br>
	<h2 class="block_container" style="text-align:center;padding:10px;">Thank you from <a href="/" target="_self"><span style="color:dodgerblue;">{{taxonomy}}</span><span class="sup" style="color:mediumseagreen;">&trade;</span></a></h2>
                <br>
                <br>
      <button class="button" style="background-color:mediumseagrean;margin-bottom:60px;"(click)='done()'>OK</button> </div>
                <br>
                <br>
</div>
 <admin-footer></admin-footer>

