<div class="modal {{ size }}">
<div class="block_container" style="background-color:#ffffff00;border-bottom:1px solid gainsboro;padding:10px 0px 0px 0px;font-family:Century Gothic,CenturyGothic,AppleGothic,sans-serif; text-align: center; valign: top; font-size:.85em;font-weight:200;"><a href="" ><img src="/assets/admin-{{taxonomy}}.png" align="middle" style="margin-bottom:13px;width:19px; height:19px;" /></a> <span
  style="color:grey;"></span> <a style="text-decoration:none;" href=""><span
  style="color:dodgerblue;">{{taxonomy}}</span><span
  class="sup"
  style="color:mediumseagreen;">&trade;</span>
  <span style="color:dodgerblue;">&nbsp;Runtime!</span> </a>
</div>
<br>

  <div class="modal-header">
    {{ title }}
  </div>
  <div class="modal-content" [innerHTML]="message">
  </div>
  <div class="modal-footer">
    <span class="siloqbutton" (click)="submit()">Yes</span>
    <span class="modal-close siloqbutton" style="background-color: orangered;" (click)="close()">No</span>
  </div>
</div>

<div class="modal-backdrop" (click)="close()"></div>
