import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import {TagService} from '../../services/tag-service.service';
import {Tag} from '../tag/model/tag';
import {TopicService} from '../../services/topic.service';
import {Topic} from '../topic/model/topic';
import {Observable, throwError} from 'rxjs';
import {HostListener} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {UserService} from '../../services/user.service';
import {Login} from '../../services/user.service';
import {CookieService} from 'ng2-cookies';
import { DataService } from "../../services/data.service";
import { AdminAlertComponent } from '../adminAlert/admin-alert.component';
import { AdminAlertService } from '../adminAlert/services/admin-alert.service';
import { AlertTypeEnum } from '../adminAlert/types/alertType.enum';
import { ModalService } from '../../modal/services/modal.service';
import { ModalComponent } from '../../modal/modal.component';
import { config } from '../../config';
import {RouterModule} from '@angular/router';
import { AdminHeaderComponent } from '../adminHeader/admin-header.component';
import { AdminFooterComponent } from '../adminFooter/admin-footer.component';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { TruncatePipe } from '../tag/tag.truncate.pipe';

declare function unescape(s:string): string;
declare function escape(s:string): string;

@Component({
  selector: 'app-mocks',
  templateUrl: './mocks.component.html',
  styleUrls: ['./mocks.component.css'],
  imports: [CommonModule, AdminAlertComponent, FormsModule, RouterModule, AdminFooterComponent, AdminHeaderComponent, TruncatePipe],
  standalone: true
})

export class MocksComponent implements OnInit {

  topics: Topic[] = [];
  selectedTopics: Topic[] = [];
  golditopics: Topic[] = [];
  public showAuto: boolean;
  private model: Topic = new Topic('', '', '');
  public unmodel: Topic = new Topic('', '', '');
  private umodel: Topic = new Topic('', '', '');
  private branch: Topic = new Topic('', '', '');
  public selectedTopic;
  selectedIndex: number = -1;
  searchText = "";
  currentPage: number = 1;
  pageSize = 9;
  totalCount: number = 0;
  pageCount: number = 0;
  sortKey = "-updatedate";
  selectAll = false;
  public theUser = "";
  public theGoldiUser = "";
  host = window.location.protocol+"//"+window.location.hostname;
  @ViewChild('modalTemplate', {read: TemplateRef}) modalTemplate: TemplateRef<any>;
  public taxonomy = "";
  public pageTitle = "";
  public class = "";
  public subclass = "";
  user = "mock.imoqr@siloq.com";
  public title = "";

  constructor(private tagService: TagService, private topicService: TopicService, private activatedRoute: ActivatedRoute, public userSVC: UserService, private router: Router, private loginInfo : Login, private _cookieService:CookieService, private data: DataService, private alertService: AdminAlertService, private modalService: ModalService){
    loginInfo = new Login();
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  openModal(modalTemplate: TemplateRef<any>, topic: any) {
      this.modalService
        .open(modalTemplate, { size: 'lg', title: 'Archive Activity', message: "Do you want to delete the selected activity '" + unescape(topic.hashTag) + "' ?" })
        .subscribe((action) => {

        topic.hashTag = escape(topic.hashTag);
        this.topicService.archiveTopic(topic).subscribe(
          () => {
            this.model = new Topic('', '', '');
            this.setFirstPage();
            this.showAlert(AlertTypeEnum['info'], "The selected topic '" + unescape(topic.hashTag) + " " + "has been deleted.");
          },
           (err: any) => {
            console.log(err);
          });

        });
    }

    archiveTopic(topic: Topic){
        let commentOperation:Observable<Topic[]>;
        this.openModal(this.modalTemplate, topic);
    }

  showAlert(type: AlertTypeEnum, msg: string) {
    this.alertService.setAlert({
      type,
      text: msg,
    });
  }

  getCookie(key: string){
    return this._cookieService.get(key);
  }

  ngOnInit() {
    this.theUser = this.getCookie("email");
    this.theGoldiUser = this.getCookie("gmt");
    this.host = window.location.protocol+"//"+window.location.hostname;
    this.unmodel = new Topic('', '', '');
    this.class = config.class;
    this.subclass = config.subclass;
    this.taxonomy = config.taxonomy;
    this.pageTitle = "MOCK";

    this.activatedRoute.queryParams.subscribe(params => {
       this.searchText = params['q'] || "";
            if(this.searchText) {
              this.searchText = unescape(this.searchText);
            }
      this.currentPage = parseInt(params['p']) || 1;
      this.user = params['u'] || "mock.imoqr@siloq.com";
      this.title = params['t'] || "MOCK";
      this.pageTitle = this.title
    });

    if(this.title === "GOLD") {
      this.searchGoldimembersMocks();
    } else if(this.title === "REMOCK") {
      this.searchMyMocks();
    } else {
      this.setCurrentPage(this.currentPage);
    }
  }

  loadTopicsEx(callback: () => any) {
    this.model = new Topic('', '', '');
    this.topicService.examSearchTopics(escape(this.searchText), this.sortKey, this.user, this.theUser, "128")
      .subscribe(
        (topics: any) => {
          this.topics = topics; //Bind to view
          if(this.topics !== undefined) {
            this.totalCount = this.topics.length;
            this.pageCount = Math.ceil(this.totalCount / this.pageSize);
            callback();
          }
        },
         (err: any) => {
          console.log(err);
        });
  }

  loadTopics(callback: () => any) {
  //TODO: Load for Goldimembers
    this.model = new Topic('', '', '');
    this.topicService.mockSearchTopics(escape(this.searchText), this.sortKey, "128")
      .subscribe(
        (topics: any) => {
          this.topics = topics; //Bind to view
          if(this.topics !== undefined) {
            this.totalCount = this.topics.length;
            this.pageCount = Math.ceil(this.totalCount / this.pageSize);
            callback();
          }
        },
         (err: any) => {
          console.log(err);
        });
  }

  public selectTopic( topic: any, i: number) {
    topic.rank = i + 1;
    this.selectedIndex = i;
    this.selectedTopic =  topic;
    this.data.changeMessage(this.selectedTopic.hashTag.split(' ').slice(0,6).join(' ') + " " + this.selectedTopic.value.split(' ').slice(0,6).join(' ') + " " + this.curbemail(this.theUser));
  }

 @HostListener('keydown', ['$event'])
 keyDown(event: any) {
    switch (event.keyCode) {
      case 38:
        this.onUp();
        break;
      case 40:
        this.onDown()
        break;
      case 13:
        this.setCurrentPage(1);
        break;
    }
    return;
  }

  public onDown() {
    if(this.selectedIndex < this.topics.length - 1)
    {
      this.selectedTopic = this.topics[++this.selectedIndex];
      this.data.changeMessage(this.selectedTopic.hashTag.split(' ').slice(0,6).join(' ') + " " + this.selectedTopic.value.split(' ').slice(0,6).join(' ') + " " + this.curbemail(this.selectedTopic.email));
    }
  }

  public onUp() {
    if(this.selectedIndex > 0)
    {
      this.selectedTopic = this.topics[--this.selectedIndex];
      this.data.changeMessage(this.selectedTopic.hashTag.split(' ').slice(0,6).join(' ') + " " + this.selectedTopic.value.split(' ').slice(0,6).join(' ') + " " + this.curbemail(this.selectedTopic.email));
    }
  }

  docTopic(topic: any) {
    if(this.isGoldimembers(topic.taxonomy) && this.theGoldiUser && this.theGoldiUser !== 'null') {
      this.router.navigate(['/admin/mock'],  { queryParams: { h : topic.hashTag, i : topic.id}});
    }

    if(this.isGoldimembers(topic.taxonomy) && (!this.theGoldiUser || this.theGoldiUser === 'null')) {
      this.router.navigate(['/admin/goldimembers']);
    }

    if(!this.isGoldimembers(topic.taxonomy)) {
      this.router.navigate(['/admin/mock'],  { queryParams: { h : topic.hashTag, i : topic.id}});
    }
  }

  mockExamRequest(topic: any) {
          this.showAlert(AlertTypeEnum['info'], "Please hold on while scheduling the Mock Exam Request for you");
          document.getElementById("loading").style.display = "inline";
          topic.hashTag = escape(topic.hashTag);
             topic.email = this.theUser;
             var u = topic.users[0];
             topic.users = [];
             topic.users.push(u);
             topic.users.push('mock.imoqr@siloq.com');
             topic.users.push(this.theUser);
          this.mockRequest(topic, (t: any) => {
            document.getElementById("loading").style.display = "none";
            this.router.navigate(['/admin/exam'], { queryParams: {h : t.hashTag, i : t.id, t : "Request", u: t.users[1]}});
            //TODO: Email notification
            //Timed anytime offline exams
            //document.getElementById("loading").style.display = "none";
            //this.router.navigate(['/admin/exam'], { queryParams: { h : t.hashTag}});
          });
  }
  mockRequest(t: Topic, callback: (t: any) => any) {
        this.topicService.mockRequest(t).subscribe(
          (data: any) => {
            callback(data);
          },
           (err: any) => {
            console.log(err);
          });
      }

  playTopic(topic: any) {
    this.router.navigate(['/admin/play'],  { queryParams: { h : topic.hashTag, i : topic.id}});
  }

  editTopic2(topic: any) {
    this.router.navigate(['/admin/edit'],  { queryParams: { h : topic.hashTag, i : topic.id}});
  }

  docBranch(branch: any) {
    this.router.navigate(['/admin/edit'],  { queryParams: { h : branch}});
  }

  manageTopicUsers(topic: any) {
    this.router.navigate(['/admin/user'],  { queryParams: { h : topic.hashTag}});
  }

  invite() {
    this.router.navigate(['/admin/invite']);
  }

  inbox() {
    //TODO:
    //this.router.navigate(['/admin/inbox'], { queryParams: { h : "inbox-"+this.theUser, d : this.theUser}});
    this.router.navigate(['/admin/publish'], { queryParams: { h : "inbox", d : "tagndoc@tagndoc.com"}});
  }

  tagndoc() {
    this.router.navigate(['/admin/tagndoc']);
  }

  editTopic(topic: any) {
    //this.umodel.edit =  !this.umodel.edit;
    this.umodel = new Topic(topic.hashTag, topic.value, topic.userId);
    topic.edit = !topic.edit;
  }

  cancelUpdate(topic: Topic) {
    topic.hashTag = this.umodel.hashTag;
    topic.value = this.umodel.value;
    topic.insertDate = this.umodel.insertDate;
    topic.edit = false;
  }

  //pagination for table
  setCurrentPage(page: number) {
    this.loadTopics(() => {
      this.setPageVisible();
      this.currentPage = page;
    });
  }

  public setFirstPage() {
    this.loadTopics(() => {
      this.setPageVisible();
      this.currentPage = 1;
    });
  }

  setGoldimembersPageVisible() {
    this.selectedIndex = (this.currentPage - 1) * this.pageSize;
    var end = this.currentPage * this.pageSize
    this.selectedTopics = [];
    this.golditopics = [];
    var j = this.selectedIndex;
    for (let i = 0; i < this.topics.length; i++) {
      if(this.isGoldimembers(this.topics[i].taxonomy)) {
        this.golditopics.push(this.topics[i]);
      }
    }
    this.totalCount = this.golditopics.length;
    this.pageCount = Math.ceil(this.totalCount / this.pageSize);

    for (let i = 0; i < this.golditopics.length; i++) {
      this.golditopics[i].show = false;
      if(i >= this.selectedIndex && i < end ) {
          this.golditopics[i].show = true;
          this.golditopics[i].hashTag = unescape(this.golditopics[i].hashTag);
          this.selectedTopics.push(this.golditopics[i]);
      }
    }
    this.selectedTopic = this.topics[this.selectedIndex];
    if (this.selectedTopic) {
      this.data.changeMessage(this.selectedTopic.hashTag.split(' ').slice(0,6).join(' ') + " " + this.selectedTopic.value.split(' ').slice(0,6).join(' ') + " " + this.curbemail(this.selectedTopic.email));
      this.selectedTopic.rank = this.selectedIndex + 1;
    }
  }

  setPageVisible() {
    this.selectedIndex = (this.currentPage - 1) * this.pageSize;
    var end = this.currentPage * this.pageSize
    this.selectedTopics = [];
    for (let i = 0; i < this.topics.length; i++) {
      this.topics[i].show = false;
      if(i >= this.selectedIndex && i < end) {
        this.topics[i].show = true;
        this.topics[i].hashTag = unescape(this.topics[i].hashTag);
        this.selectedTopics.push(this.topics[i]);
      }
    }
    this.selectedTopic = this.topics[this.selectedIndex];
    if (this.selectedTopic) {
      this.data.changeMessage(this.selectedTopic.hashTag.split(' ').slice(0,6).join(' ') + " " + this.selectedTopic.value.split(' ').slice(0,6).join(' ') + " " + this.curbemail(this.selectedTopic.email));
      this.selectedTopic.rank = this.selectedIndex + 1;
    }
  }


  setMe(element: any) {
    var s_height = element.scrollHeight;
    element.setAttribute('style','height:'+s_height+'px');
  }

  selectAllTopics() {
    for (let i = 0; i < this.topics.length; i++) {
      if(!this.selectAll) {
        this.topics[i].checked = true;
      }
      else {
        this.topics[i].checked = false;
      }

    }
  }

  removeUserFromTopic(model: Topic, user: string){
    var id = model.users.indexOf(user);
    model.users.splice(id,  1);
    let commentOperation:Observable<Topic[]>;
    this.topicService.updateTopic(model).subscribe(
      () => {
      },
       (err: any) => {
        console.log(err);
      });
  }

  sort(event): void {
    if(event.target) { this.sortKey=this.sortKey.includes('-') ? event.target.value : '-'+event.target.value; }
    this.setFirstPage();
  }

  setArrayFromNumber(i: number) {
  return new Array(i);
}

curbemail(email: string): string {
    if(!email) {
      return email;
    }

    var index = email.indexOf("@");
    if(index > 0) {
      return email.substring(0, index);
    }
    return email;
  }

profile(t: any) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.navigate(['profile'],  { queryParams: {d : window.btoa(t.email)}});
    return;
  }

myProfile(t: any) {
    this.router.navigate(['profile'],  { queryParams: { h : t.hashTag, i : t.id, d : window.btoa(this.theUser)}});
    return;
  }

examTopics(list: string) {
    this.router.navigate(['/admin/exams'],  { queryParams: { t : "MOCK " + list.toUpperCase(), u : list.toLowerCase()+".imoqr@siloq.com", cbid : "mock"}});
}

  renderImage(t: any) {
    var s = t;
    t = t.replace(/<(?:.|\n)*?>/gm, '');
     if (t.startsWith("http://") || t.startsWith("https://")) {
         const img = new Image();
         img.src = t;
         var w = 60;
         var h = (w/img.width)*img.height;
         t = '<img src="' + t + '" style="width:' + w + 'px; height:' + h + 'px;" />'
         return t;
      }
         return s;
    }

    parseTitle(t: string): string {
        return t.replace(/_/g, " ");
    }

    searchGoldimembersMocks() {
            this.pageTitle = this.title;
            this.loadTopics(() => {
              this.currentPage = 1;
              this.setGoldimembersPageVisible();
            });

            return;
    }

    searchMyMocks() {
            this.pageTitle = this.title;
            this.loadTopicsEx(() => {
              this.currentPage = 1;
              this.setPageVisible();
            });

            return;
    }

    searchTopics(list: string) {
            this.title = list;
            this.pageTitle = list.toUpperCase();
            this.user = list.toLowerCase()+".imoqr@siloq.com"
            this.loadTopicsEx(() => {
              this.currentPage = 1;
              this.setPageVisible();
            });

            return;
    }

  results() {
      this.router.navigate(['/admin/exams'],  { queryParams: { t : "Result", u : "result.imoqr@siloq.com"}});
    }

    setPage() {
        if(this.title === "GOLD") {
            this.setGoldimembersPageVisible();
        } else {
          this.setPageVisible();
        }
    }

    first() {
      if(this.currentPage>1){
        this.currentPage=1;
        this.setPage();
      }
    }

    previous() {
      if(this.currentPage>1){
        this.currentPage-=1;
        this.setPage();
      }
    }

    next() {
      if(this.currentPage<this.pageCount){
        this.currentPage+=1;
        this.setPage();
      }
    }

    last() {
      if(this.currentPage<this.pageCount){
        this.currentPage=this.pageCount;
        this.setPage();
      }
    }

    isGoldimembers(tax): boolean {
     if(tax) {
         for (let i = 0; i < tax.length; i++) {
           if(tax[i].key === "goldimembersEnabled") {
             return (tax[i].value[0] === "true");
           }
         }
     }
     return false;
  }

}
