import { Component } from '@angular/core';
import {TelService} from '../../services/tel.service';
import {UserService} from '../../services/user.service';
import {ActivatedRoute, Router } from '@angular/router';
import {HostListener} from '@angular/core';
import {CookieService} from 'ng2-cookies';
import { AdminAlertComponent } from '../adminAlert/admin-alert.component';
import { AdminAlertService } from '../adminAlert/services/admin-alert.service';
import { AlertTypeEnum } from '../adminAlert/types/alertType.enum';
import { config } from '../../config';
import {RouterModule} from '@angular/router';
import { AdminHeaderComponent } from '../adminHeader/admin-header.component';
import { AdminFooterComponent } from '../adminFooter/admin-footer.component';
import { FormsModule } from '@angular/forms';
@HostListener('window:keydown', ['$event'])
@Component({
  host: {
    '(document:keydown)': 'keyDown($event)',
  },
  templateUrl: './tel.component.html',
  styleUrls: ['./tel.component.css'],
  imports: [AdminAlertComponent, FormsModule, RouterModule, AdminFooterComponent, AdminHeaderComponent],
  standalone: true
})

export class TelComponent {
  domain = "isloq.com";
  done = false;
  public theUser = "";
 public theGoldiUser = "";
  searchText= "";
 public taxonomy = "";
 public class = "";
 public subclass = "";

  constructor( private alertService: AdminAlertService, private activatedRoute: ActivatedRoute, private TelService: TelService, public userSVC: UserService, private router: Router, private _cookieService:CookieService){ }

  showAlert(type: AlertTypeEnum, msg: string) {
    this.alertService.setAlert({
      type,
      text: msg,
    });
  }

  getCookie(key: string){
    return this._cookieService.get(key);
  }

  ngOnInit() {
    this.theUser = this.getCookie("email");
  this.theGoldiUser = this.getCookie("gmt");
    this.taxonomy = config.taxonomy;
    this.class = config.class;
    this.subclass = config.subclass;
    if(this.theUser!=="tpalanisamy@yahoo.com") {
      this.router.navigate(['/error']);
    }

    this.activatedRoute.queryParams.subscribe(params => {
      this.searchText = params['q'] || "";
    });
  }

  tel() {
    if (this.domain.length > 3) {
      this.TelService.tel(this.domain);
      this.done = true;
      this.sync();
    }
  }

  stoptel() {
    if (this.domain.length > 3) {
      //TODO:
      /*
      this.TelService.tel(this.domain);
      this.done = true;
      this.sync();
      */
    }
  }

  sync() {
    if (this.domain.length > 3) {
      this.TelService.syncAuto(this.domain);
      this.TelService.syncSearch(this.domain);
    }
  }

  syncimoqr() {
    if (this.domain.length > 3) {
      this.TelService.syncAuto("imoqr.com");
      this.TelService.syncSearch("imoqr.com");
      this.showAlert(AlertTypeEnum['warning'], "Your topics are indexed and ready to use!");
    }
  }

 public keyDown(event: any) {
    switch (event.keyCode) {
      case 13:
        this.tel()
        event.preventDefault();
        break;
    }
    return;
  }

  cancel() {
    this.router.navigate(['/admin/account']);
  }

  curbemail(email: string): string {
    if(!email) {
      return email;
    }

    var index = email.indexOf("@");
    if(index > 0) {
      return email.substring(0, index);
    }

    return email;
  }

  profile() {
    this.router.navigate(['profile'],  { queryParams: {d : window.btoa(this.theUser)}});
    return;
  }

}
