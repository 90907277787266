<admin-header></admin-header>
<body style="background-color:#ffffff00; height:100%;width:100%; margin:0px;">
  <div class="doNotPrint" style="margin-top:50px;display:table;width:100%; height:18px; valign:middle;border:0px solid lightgrey;padding:20px 0px 0px 5px;">
      <div style="vertical-align: middle;padding:0px;margin:0px;width:40%;display:table-cell;">
      </div>
      <div style="display:table-cell;font-family:Helvetica,AppleGothic,sans-serif; width:20%;text-align:center;margin:0;color:mediumseagreen;font-size:1em;font-weight:200;vertical-align: middle;">Settings</div>
      <div style="display:table-cell;font-family:Helvetica,AppleGothic,sans-serif; display:table-cell;float:right;width:40%;margin-right:10px;text-align:right;padding:0px 5px 0px 12px;">
      </div>
    </div>
    <div class="block_container" style="background-color:#ffffff00;border-bottom:1px solid gainsboro;padding:10px 0px 0px 0px;font-family:Century Gothic,CenturyGothic,AppleGothic,sans-serif; text-align: center; valign: top; font-size:1em;font-weight:200;"><a href="" ><img src="/assets/isloq.png" align="middle" style="margin-bottom:13px;width:19px; height:19px;" /></a> <span style="color:grey;"></span> <a style="text-decoration:none;" href=""><span
        style="color:dodgerblue;">{{taxonomy}}</span><span
        class="sup"
        style="color:mediumseagreen;">&trade;</span><span
        style="color:dodgerblue;">&nbsp;Runtime!</span> </a>
    </div>
    <br>
     <ng-template id="modal" #modalTemplate>
    </ng-template>
<div style="padding:0px; text-align:center; width:10%; margin:0 auto;min-width:300px;">

  <div style="margin-top:20px; width:25%;text-align:center;height:60px;min-width:300px;text-align:left;" >
    <h2 class="block_container siloqlogofont" style="min-width:300px;font-size:1.2em;font-weight:600px; color:dodgerblue;">User Name</h2>
    <h3 class="block_container siloqlogofont" style="padding:10px 0px 10px 0px;font-size:1.2em; color:navy;min-width:300px;">{{theUser}}</h3>
    <a  style="font-family:Helvetica,AppleGothic,sans-serif; width:100px;background-color:orange;color:#383838;border:0px; border-radius: 30px; -moz-border-radius: 30px;height:20px;"(click)='logout()' class="siloqbutton">Sign Out</a>
    </div>
  <!--
  <div (click)="logout()" style="cursor:pointer;margin-top:20px;height:60px;min-width:30%;text-align:left;" class="active">
    <h3 class="block_container siloqlogofont" style="padding-top:10px;font-size:1.2em; color:orangered;text-align:left;;width:100%">Sign&nbsp;Out</h3>
  </div>
  -->
  <br>
  <br>
  <br>
  <br>
  <div (click)="profile();" style="cursor:pointer;margin-top:20px;min-width:30%;text-align:left;" class="regular" >
    <h2 class="block_container siloqlogofont" style="font-size:1.2em;font-weight:600px; color:dodgerblue;text-align:left;width:100%">Profile</h2>
    <h3 class="block_container siloqlogofont" style="cursor:pointer;padding-top:10px;font-size:1.2em; color:grey;text-align:left;;width:100%">{{curbemail(theUser)}}<input class="intLink" type="button" value="⭐️" valign="middle" style="background-color:#ffffff00;height:16px;width:16px;border:0px; padding:0px 0px 5px 0px;font-weight:300;font-size:.75em;"/></h3>
    </div>

  <div (click)='resetPwd()' style="cursor:pointer;margin-top:20px;min-width:30%;height:60px;text-align:left;" class="regular" >
      <h3 class="block_container siloqlogofont"  style="padding-top:10px;font-size:1.2em; color:mediumseagreen;text-align:left;;width:100%">Reset&nbsp;Password</h3>
  </div>

  <div (click)="invite()"  style="cursor:pointer;margin-top:20px;height:60px;min-width:30%;text-align:left;"  class="regular" >
        <h3 class="block_container siloqlogofont"  style="padding-top:10px;font-size:1.2em; color:mediumseagreen;text-align:left;;width:100%">Invite&nbsp;Friends</h3>
  </div>

  <div (click)="career()" style="cursor:pointer;margin-top:20px;height:60px;min-width:30%;text-align:left;" class="regular">
    <h3 class="block_container siloqlogofont" style="padding-top:10px;font-size:1.2em; color:mediumseagreen;text-align:left;;width:100%">Upload&nbsp;Resume</h3>
  </div>

  <div *ngIf="theUser==='tpalanisamy@yahoo.com'" >
    <div (click)="tiny()"  style="cursor:pointer;margin-top:20px;height:60px;min-width:30%;text-align:left;"  class="regular" >
      <h3 class="block_container siloqlogofont" style="padding-top:10px;font-size:1.2em; color:mediumseagreen;min-width:300px;text-align:left;;width:100%">Tiny/Referral&nbsp;Url</h3>
    </div>

    <div (click)="qrcode()"  style="cursor:pointer;margin-top:20px;height:60px;min-width:30%;text-align:left;"  class="regular" >
      <h3 class="block_container siloqlogofont" style="padding-top:10px;font-size:1.2em; color:mediumseagreen;text-align:left;;width:100%">QR&nbsp;Code</h3>
    </div>

    <div (click)="tel()" style="cursor:pointer;margin-top:20px;height:60px;min-width:30%;text-align:left;text-align:left;" class="regular">
      <h3 class="block_container siloqlogofont" style="padding-top:10px;font-size:1.2em; color:mediumseagreen;text-align:left;;width:100%">Tel&nbsp;Domains</h3>
    </div>

    <div (click)="upload()" style="cursor:pointer;margin-top:20px;height:60px;min-width:30%;text-align:left;" class="regular">
      <h3 class="block_container siloqlogofont" style="padding-top:10px;font-size:1.2em; color:mediumseagreen;text-align:left;;width:100%">Upload&nbsp;Content</h3>
    </div>
  </div>
<!--
  TODO: Caching Reload Button?!
  <div (click)="logout()" style="cursor:pointer;margin-top:20px;height:60px;min-width:30%;text-align:left;" class="active">
        <h3 class="block_container siloqlogofont" style="padding-top:10px;font-size:1.2em; color:orangered;text-align:left;;width:100%">Sign&nbsp;Out</h3>
    </div>
  -->
    <br>
  <hr style="width:25%;min-width:300px;padding-left:0px;">
  <div class="row align-center siloqfont" style="margin-bottom:50px;font-weight:400;text-align:center;">
    <a title="Please send us your requests" style="text-decoration:none;color:dodgerblue;" class="intLink" [routerLink]="['/admin/feedback']" [queryParams]="{s:'Support'}">Support&nbsp;&nbsp;</a>
    <a title="Please tell us what you want more better" style="text-decoration:none;color:mediumseagreen;" class="intLink" [routerLink]="['/admin/feedback']"[queryParams]="{s:'Feedback'}">Feedback&nbsp;&nbsp;</a>
    <a *ngIf="this.theGoldiUser && this.theGoldiUser != 'null'" title="Unsubscribe {{memberType}}" class="intLink" [routerLink]="['/admin/feedback']" [queryParams]="{s:'Unsubscribe ' + memberType}"> <span style="color:orange;">Unsubscribe&nbsp;&nbsp;</span></a>
    <a title="Close {{taxonomy}} Account" class="intLink" [routerLink]="['/admin/feedback']" [queryParams]="{s:'Close Account'}"> <span style="color:orangered;">Close Account</span></a>&nbsp;&nbsp;
  </div>
  </div>
  <br>
    <admin-footer></admin-footer>
