<header></header>
<div style="text-align:center; height:100%; width:100%;padding-top:70px;">
  <br>
<div class="row expanded align-center" style="text-align: center; margin: auto;">
<h2 class="block_container siloqlogofont" style="min-width:320px;">Direct Message to <a href="/" target="_self"> <span
   style="color:dodgerblue;">{{taxonomy}}</span><span
  class="sup"
  style="color:mediumseagreen;">&trade;</span>

  </a> </h2>
  <alert></alert>
  <br>
</div>
	<br>
	<form>
		<div class="small-12 columns">
			<div contenteditable="true" class="siloqinput" style="min-width:300px;" data-text="Your registered email*" id="source" [textContent]='model.source' name="source" > </div>
		</div>
		<br>
		<div class="small-12 columns">
			<div contenteditable="true" style="height:140px;min-height:100px;min-width:300px;" [textContent]='sub' class="siloqinput" data-text="Short Description *" id="value">{{sub}} </div>
    </div>
		<br>
		<br>
    <div class="small-12 columns">
       <button (click)="submitTag()" type="button" class="siloqbutton" style="color:dodgerblue;">Submit</button>
     </div>
     <br>
	</form>
</div>
<footer></footer>
