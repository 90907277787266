import { Component, OnInit } from '@angular/core';
import { config } from '../../config';
import {RouterModule} from '@angular/router';
import { FormsModule } from '@angular/forms';
@Component({
  templateUrl: './dummy-footer.component.html',
  styleUrls: ['./dummy-footer.component.css'],
  selector: 'dummy-footer',
  standalone: true
 })

export class DummyFooterComponent{
public taxonomy = "";
public class = "";
public subclass = "";

ngOnInit(){
    this.taxonomy = config.taxonomy;
    this.class = config.class;
    this.subclass = config.subclass;
  }
}
