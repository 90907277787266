<admin-sis-header></admin-sis-header>
<div class="doNotPrint" style="display:table;width:100%; height:18px; margin-top:60px; valign:middle;">
  <div style="vertical-align: middle;padding:0px;margin:0px;width:40%;display:table-cell;">
    <a class="intLink" style="font-family: Arial, Helvetica, sans-serif;float:left;padding:0px 2px 0px 2px; margin:0px 0px 0px 3px;cursor:pointer;" title="{{curbemail(theUser)}}"><img src="assets/user.png" align="middle" title="uiloq™: Timeline Logo" title="{{userSVC.authUser.email}}"alt="uiloq™: Timeline Logo"style="margin-bottom:10px;width:18px; height:18px;" /></a>
    <input class="intLink" type="button" value="⭐️" valign="middle" style="background-color:#ffffff00;height:16px;width:16px;border:0px; padding:0px 0px 5px 0px;font-weight:300;font-size:.75em;"/>
  </div>
  <div style="display:table-cell;font-family:Helvetica,AppleGothic,sans-serif; width:20%;text-align:center;margin:0;color:mediumseagreen;font-size:1em;font-weight:200;vertical-align: middle;">Trends</div>
  <div style="display:table-cell;font-family:Helvetica,AppleGothic,sans-serif;float:right;width:40%;margin:0px 0px 0px 20px;text-align:right;padding:0px 5px 0px 0px;font-size:1em;font-weight:200;">
    <select name="tags" id="tags"  style="cursor:pointer;color:orangered;background-color:#ffffff00;height:18px;border:0px; padding:0px 0px 5px 0px;font-weight:300;font-size:.8em;"> <option value="email">User</option> <option value="hashtag">Topic</option> <option value="updatedate">Date</option> </select>
  </div>
</div>
<br>
<div class="block_container" style="padding-top:0px;background-color:white;width:100%;">
  <div style="background-color:whites;width:100%;padding:3px 0px 21px 0px;">
    <div style="display:table; white-space:nowrap;padding-left:5%;width:100%;float:left;border-top:0px gainsboro solid;">
       <div style="width:75%;padding-top:0px;margin-left:0%;display:flex;justify-content:left;align-items:left;">
         <a href="/admin/graph" target="_self" style="text-decoration:none;" > <img title="🔍 Sisloq™" alt="🔍 Sisloq™" src="assets/uiloq-logo.png" style="width:32px; height:32px;padding:17px 2px 14px 0px;" /> </a> &nbsp;<input class="siloqinput" type="search" placeholder="🔍 Siloq&reg; Secure Search" name="siloqSearch" #siloqSearch (input)="auto()" [(ngModel)]='searchText' autofocus="autofocus" style="color: grey;padding:7px;height: 32px; width:70%;min-width:160px;margin-top:17px;"/>&nbsp;<img title="🔍 Siloq®" alt="🔍 Siloq®" (click)="setFirstPage()" src="assets/siloq-logo.png" style="width:32px; height:32px;padding:17px 0px 0px 2px; cursor:pointer;" /><span style="font-family:Century Gothic,CenturyGothic,AppleGothic,sans-serif;font-size:2.3em;font-weight:400;padding:12px 0px 0px 0px;height:36px;"></span>
         <div  class="intLink" valign="middle"  style="display:table-cell;width:5%;color:mediumseagreen;background-color:#ffffff00;border:0px; padding:19px 0px 0px 5px;font-weight:300;font-size:1.5em;height:22px;width:22px;cursor:pointer;" title="Copy Tiny Url of Siloq® Search page"> 🔗 </div>
       </div>

       <div *ngIf="!showAuto" class="block_container" style="background-color:#ffffff00;border-top:px solid gainsboro;width:55%;padding:0px 0px 0px 0px;font-family:Century Gothic,Cen   turyGothic,AppleGothic,sans-serif; text-align: center; valign: top; font-size:1em;font-weight:200;"><a href="" ><img src="/assets/logo.png" align="middle" style="margin-bottom   :13px;width:19px; height:19px;" /></a> <span style="color:grey;"></span> <a style="text-decoration:none;" href=""><span style="color:dodgerblue;">S</span><span style="color:me   diumseagreen;">i</span><span style="color:orangered;">l</span><span style="color:dodgerblue;">o</span><span style="color:orange;">q</span><span class="sup" style="color:orange   red;">&trade;</span><span style="color:dodgerblue;">&nbsp;Sustainable Learning!</span></a> </div>
       <div style="margin:0%;width:75%;padding:0px;text-align:left;">
         <ul *ngIf="showAuto" style="list-style: none;display:table; margin:0px auto; font-family:Helvetica,AppleGothic,sans-serif; -moz-border-radius: 15px; border-radius: 15px;inline-block;font-size:1em;border:3px lightsteelblue solid;padding: 5px 5px 5px 5px; height:20px; width:100%;">
          <li *ngFor="let auto of autos; let i = index" style="-moz-border-radius: 15px; border-radius: 15px;" (click)="selectAuto(auto, i)" class="regular" [class.active]="i === selectedIndex">
            <div style="cursor:pointer;-moz-border-radius: 15px; border-radius: 15px;font-size:1em;inline-block;text-align: left;padding:5px;">{{auto.Name}}</div>
          </li>
        </ul>
      </div>
    </div>
    <br>
    <br>
    <br>
  <div *ngIf="!showAuto" style="margin-top:50px;padding:10px 0px 1px 3px;font-family:Century Gothic,CenturyGothic,AppleGothic,sans-serif; font-size:.75em;font-weight:400; background-color:whitesmoke; color: navy; height:23px;text-align:left;">
    <div *ngIf="searchs.Total >= searchs.MaxSize" style="display: inline-block;
    margin-left:5%;">
      Page {{currentPage | number:'1.0-0'}} of more than {{totalCount | number:'1.0-0'}},000,000 results ({{searchs.Time}} seconds)
    </div>
    <div *ngIf="searchs.Total > 0 && searchs.Total < searchs.MaxSize" style="display: inline-block; margin-left:5%;">
      Page {{currentPage | number:'1.0-0'}} of {{totalCount | number:'1.0-0'}} results ({{searchs.Time}} seconds)
    </div>
    <div  *ngIf="working && searchs.Results === undefined" style="display: inline-block; margin-left:5%;text-align:left;">
      Working...
    </div>
    <div  *ngIf="!working && searchs.Results === undefined" style="display: inline-block; margin-left:5%;">
      No results
    </div>
  </div>

   <admin-alert></admin-alert>

  <div style="width: 100%; dispaly:table;">

  <div style="width: 5%;min-height:600px; dispaly:table-cell;float:left;background-color:#ffffff00;cursor:pointer;" (click)="setPreviousPage();">
    &nbsp;
  </div>

  <div style="height:100%; dispaly:table-cell;width:55%;background-color:#ffffff00;float:left;">
  <div *ngIf="!showAuto && searchs.Total > 0" style="align:left; font-family:Helvetica,AppleGothic,sans-serif; padding: 4px; min-width:300px; width:100%;">
    <div  *ngFor="let search of searchs.Results; let i = index;" (click)="selectItem(search, i);" (mouseenter)="browseIndex=i;">
      <div class="regular" [class.active]="i === selectedItemIndex" [class.browse]="i === browseIndex">
          <div  valign="top" nowrap style="display:table;text-align:left; font-size:1.1em;font-weight:400;"><a href="{{search.Url}}" (click)="rankUrl()" style="text-decoration:none; color:navy;">{{search.Title | truncate : 64}}</a>&nbsp;&nbsp;<span class="doNotPrint intLink" title="Copy Loq Url of '{{search.Title}}'" valign="top" style="font-weight:100;font-size:.9em;display:table-cell;padding:5px 1px 2px 0px; height:20px;text-align;left;cursor:pointer" (click)="copyLoqUrlToClipboard(i);">🔗</span></div>
          <div  valign="top" nowrap style="padding-top:3px;text-align:left; font-size:.9em;font-weight:400;">
          <a href="{{search.Url}}" (click)="rankUrl()" style="text-decoration:none; color:mediumseagreen;">{{search.Url | truncate : 64}}</a>
        </div>
        <div  valign="top" style="line-height: 1.5;padding-top:4px;text-align:left; font-size:.8em;font-weight:400;color:dark;" [innerHTML]="search.Text | truncate : 320">
        </div>
      </div>
      <br>
    </div>
  </div>
    &nbsp;
  </div>

  <div style="width: 35%; min-height:600px; dispaly:table-cell;background-color:#ffffff00;float:left;cursor:pointer;" (click)="setNextPage();">
    &nbsp;
  </div>

  </div>



  <br>
  <div  *ngIf="!showAuto  && searchs.Relateds !== undefined &&
  searchs.Relateds.length > 0" style="margin-left:5%; align:left;
  font-family:Helvetica,AppleGothic,sans-serif; padding: 3px; width:55%;
  min-width:300px;">
    <div valign="top" nowrap style="text-align:left; font-size:1.2em;font-weight:400;color:dark;">Searches related to {{searchText | truncate : 64}}</div>
    <ul style="align:left; font-family:Helvetica,AppleGothic,sans-serif;
    padding: 3px 3px 3px 0px; width:100%;">
      <li *ngFor="let item of searchs.Relateds; let i = index"
        [attr.data-index]="i" class="siloqinput" style="-moz-border-radius: 20px; border-radius:
        20px; background-color:#F0F0F0;width: 95%; text-align:left;
        float:left;  line-height: 2; padding:5px 5px 5px 1%;
        margin:5px 5px 5px 0px;font-size:1em;font-weight:400;color:dark;border-width:1px;">
        <div style="display:table">
        <div style="width:5%;display:table-cell">&nbsp;&nbsp;🔍&nbsp;&nbsp;</div>
        <div style="display:table-cell"><a href="/admin/search?q={{item}}&s=0" style="text-decoration:none; color:navy;">&nbsp;&nbsp;{{item | truncate : 32}}</a>
        </div>
        </div>
      </li>
    </ul>
  </div>
  <br>
  <div *ngIf="!showAuto && searchs.Total > 0" style="width:55%;height:60px;text-align:center;margin-left:5%;">
    <div style="background-color: Transparent;padding:0px;border:none;font-family:Century Gothic,CenturyGothic,AppleGothic,sans-serif;font-size:2em;font-weight:700;height:60px;text-align:center;display:table;margin:auto;width:40%;">
      <div style="height:23px;text-align:center;width:100px;margin:auto;display:table-row;">
        <div style="height:23px;text-align:center;display:table-cell;">
          <div style="height:23px;text-align:right;">
            <button type="button" class="btn btn-primary" (click)="setPreviousPage()"
                                  style="background-color: Transparent;padding:0px
                                  0px 40px 0px;border:none;font-family:Century
                                  Gothic,CenturyGothic,AppleGothic,sans-serif;font-size:.5em;font-weight:700;">
              <span style="cursor:pointer;color:dodgerblue;"><&nbsp;&nbsp;</span>
            </button>
          </div>
        </div>
        <div class="page-number" (click)="setPage(0)">
          <div style="color:dodgerblue;">S</div>
        </div>
        <div class="page-number" (click)="setPage(1)">
          <div style="color:grey;">.&nbsp;</div>
        </div>
        <div class="page-number" (click)="setPage(2)">
          <div style="color:orangered;">i</div>
        </div>
        <div class="page-number" (click)="setPage(3)">
          <div style="color:grey;">.&nbsp;</div>
        </div>
        <div class="page-number" (click)="setPage(4)">
          <div style="color:mediumseagreen;">l&nbsp;</div>
        </div>
        <div class="page-number" (click)="setPage(5)">
          <div style="color:grey;">.&nbsp;</div>
        </div>
        <div class="page-number" (click)="setPage(6)">
          <div style="color:orange;">o&nbsp;</div>
        </div>
        <div class="page-number" (click)="setPage(7)">
          <div style="color:grey;">.&nbsp;</div>
        </div>
        <div class="page-number" (click)="setPage(8)">
          <div style="color:dodgerblue;">q&nbsp;</div>
        </div>
        <div style="height:23px;text-align:center;display:table-cell;">
          <div style="height:23px;text-align:left;">
            <button  type="button" class="btn btn-primary" (click)="setNextPage()"
                                   style="background-color:
                                   Transparent;padding:0px 0px 40px 0px; border:none;font-family:Century
                                   Gothic,CenturyGothic,AppleGothic,sans-serif;font-size:.5em;font-weight:700;">
              <span style="cursor:pointer;color:dodgerblue;">></span>
            </button>
          </div>
        </div>
      </div>

<div style="height:23px;text-align:center;width:30%;margin:auto;display:table-row;">
  <div style="height:23px;text-align:center;display:table-cell;">
    <div style="height:23px;text-align:left; width:80px;">
      <button type="button" class="btn btn-primary" (click)="setPreviousPage()" style="background-color: Transparent;padding:0px 0px 40px 0px;border:none;font-family:Century Gothic,CenturyGothic,AppleGothic,sans-serif;font-size:.4em;font-weight:300;">
        <span style="cursor:pointer;color:dodgerblue;">Previous</span>
      </button>
    </div>
  </div>
  <div *ngFor="let i of [0,1,2,3,4,5,6,7,8]" style="padding-left:6px;width:100px;display:table-cell;" class="page-number" (click)="setPage(i)" [class.selected]="i+1 === currentPage">
    <div style="font-size:.4em;font-weight:300;width:1px;">{{i+1}}</div>
  </div>
  <div style="height:23px;text-align:center;display:table-cell;">
    <div style="height:23px;text-align:right;">
      <button  type="button" class="btn btn-primary" (click)="setNextPage()" style="background-color: Transparent;padding:0px 0px 40px 0px; border:none;font-family:Century Gothic,CenturyGothic,AppleGothic,sans-serif;font-size:.4em;font-weight:300;">
        <span style="cursor:pointer;color:dodgerblue;">Next</span>
      </button>
    </div>
  </div>
</div>

    </div>
    <br>
    <div class="block_container" style="background-color:#ffffff00;border-top:1px solid gainsboro;padding:10px 0px 0px 0px;font-family:Century Gothic,CenturyGothic,AppleGothic,sans-serif; text-align: center; valign: top; font-size:1em;font-weight:200;"><a style="text-decoration:none;" href="http://siloq.com" >
      <span style="color:dodgerblue;">Powered by</span> <img src="assets/siloq-logo.png" align="middle" style="margin-bottom:13px;width:19px; height:19px;" /></a> <span style="color:grey;"></span> <a style="text-decoration:none;" href="http://siloq.com"><span style="color:dodgerblue;">S</span><span
      style="color:mediumseagreen;">i</span><span
      style="color:dodgerblue;">l</span><span
      style="color:orange;">o</span><span
      style="color:dodgerblue;">q</span><span
      class="sup"
      style="color:orangered;">®</span>
    </a>
    </div>
  </div>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
<admin-footer *ngIf="!showAuto"></admin-footer>
