import { Component, OnInit } from '@angular/core';
import {Title} from '@angular/platform-browser';
import {TopicService} from '../services/topic.service';
import {Topic} from '../admin/topic/model/topic';
import {ActivatedRoute, Router } from '@angular/router';
import { AlertComponent } from '../alert/alert.component';
import { AlertService } from '../alert/services/alert.service';
import { AlertTypeEnum } from '../alert/types/alertType.enum';
import { DomSanitizer } from '@angular/platform-browser';
import { config } from '../config';
import {RouterModule} from '@angular/router';
import { HeaderComponent } from '../header/header.component';
import { FooterComponent } from '../footer/footer.component';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

declare function escape(s:string): string;
declare function unescape(s:string): string;
@Component({
  selector: 'app-publish',
  templateUrl: './publish.component.html',
  styleUrls: ['./publish.component.css'],
  imports: [AlertComponent, FormsModule, RouterModule, FooterComponent, HeaderComponent, CommonModule],
  standalone: true
})
export class PublishComponent implements OnInit {
  topics: Topic[];
  public topic = new Topic('', '', '');
  public theUser = "";
 public theGoldiUser = "";

  constructor(private titleService: Title, private topicService: TopicService, private activatedRoute: ActivatedRoute)
  { }

  ngOnInit() {
    this.theUser = "contact@siloq.com";

    this.activatedRoute.queryParams.subscribe(params => {

      this.topic.hashTag = params['h'] || "";

      if(this.topic.hashTag != "") {
        this.titleService.setTitle(this.topic.hashTag);
        this.getTopic(this.topic.hashTag, this.theUser, (t: any)=>{
          this.topic = t;
          var tb = document.getElementById("textBox");
          tb.innerHTML = unescape(this.topic.value);
        });
      }
    });
  }

  getTopic(h: string, e: string, callback: (t: any) => any) {
    this.topicService.getTopic(h, e).subscribe(
      (data: any) => {
        callback(data);
      },
       (err: any) => {
        console.log(err);
      });
  }

}
