import { Component, OnInit} from '@angular/core';
import {UserService} from '../services/user.service';
import {CookieService} from 'ng2-cookies';
import { ActivatedRoute, Router } from '@angular/router';
import { config } from '../config';
import {RouterModule} from '@angular/router';
import { HeaderComponent } from '../header/header.component';
import { FooterComponent } from '../footer/footer.component';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

@Component({
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.css'],
  imports: [FormsModule, RouterModule, FooterComponent, HeaderComponent, CommonModule],
  standalone: true
})
export class LogoutComponent implements OnInit {
  public taxonomy = "";
  public class = "";
  public subclass = "";

  constructor(private activatedRoute: ActivatedRoute, public userSVC: UserService, private router: Router, private _cookieService:CookieService) { }

  ngOnInit() {
    this.taxonomy = config.taxonomy;
    this.class = config.class;
    this.subclass = config.subclass;
    this.logout();
  }

  setCookie(key: string, value: string){
    return this._cookieService.set(key, value);
  }

  getCookie(key: string){
    return this._cookieService.get(key);
  }

  logout(){
    let gwt = this.getCookie("gwt");
    this.setCookie("gwt", "");
    this.setCookie("gmt", "");
    this.setCookie("uid", "");
    this.setCookie("email", "");
    this.userSVC.logoutGwt(gwt);
  }

  signup(){
    this.router.navigate(['signup']);
  }

  signin(){
    this.router.navigate(['login']);
  }

  forgot(){
    this.router.navigate(['forgot']);
  }

}
