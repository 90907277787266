import { Injectable } from '@angular/core';

export class Serializable {
    fromJSON(json: any){
        for (var propName in json)
            this[propName] = json[propName];
        return this;
    }
}

export class Taxon extends Serializable {
  key="";
  value: string[];
  constructor(){
		 super();
  }
}

export class Topic extends Serializable {
	public id="";
  public key="";
  public name="";
	public insertDate="";
	public email="";
  public users: string[];
  public topics: string[];
  public taxonomy: Taxon[];
	public isDisabled: boolean = false;
	//
	public show: boolean = false;
	public edit: boolean = false;
	public checked: boolean = false;

	constructor(
		public hashTag: string,
		public userId: string,
		public value: string,) {
		 super();
	}
}

