import { Component } from '@angular/core';
import {UserService} from '../../services/user.service';
import {Login} from '../../services/user.service';
import { Observable, Subscription } from 'rxjs';
import {ActivatedRoute, Router } from '@angular/router';
import {CookieService} from 'ng2-cookies';

@Component({
  templateUrl: './redirect.component.html',
  styleUrls: ['./redirect.component.css']
 })

export class RedirectComponent {
searchText = "";
page = "";
hashTag = "";
friend = "";
gwt = "";

  constructor(private activatedRoute: ActivatedRoute,public userSVC: UserService, private router: Router, private loginInfo : Login, private _cookieService:CookieService){loginInfo = new Login();}

  ngOnInit() {

    this.activatedRoute.queryParams.subscribe(params => {
      this.gwt = params['g'] || "";
      this.searchText = params['q'] || "";
      this.page = params['u'] || "";
      this.hashTag = params['h'] || "";
      this.friend = params['f'] || "";
    });

    if (this.gwt) {
      this.userSVC.sessionVerifyGwt(this.gwt);
    } else {
      this.userSVC.sessionVerifyExt();
      this.gwt = this.userSVC.authUser.sessionId;
    }

    setTimeout(()=>{
      if(this.userSVC.authUser.email) {
        this.router.navigate(['/admin/'+this.page], { queryParams: {u: this.page,  g : this.gwt, d: this.userSVC.authUser.email,  h : this.hashTag, q : this.searchText, f : this.friend}});
      } else {
        this.router.navigate(['/login/'], { queryParams: { u: this.page, g : this.gwt,  d: this.userSVC.authUser.email,  h : this.hashTag, q : this.searchText, f : this.friend}});
      }
    }, 1000);
  }
}
