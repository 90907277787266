import { Injectable } from '@angular/core';
import { Response, Headers, RequestOptions } from '@angular/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {Tiny} from '../admin/tiny/model/tiny';
import { config } from '../config';
import {RouterModule} from '@angular/router';
import { SisHeaderComponent } from '../sisHeader/sis-header.component';
import { HomeFooterComponent } from '../homeFooter/home-footer.component';
import { FormsModule } from '@angular/forms';
import { map, pipe, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class TinyService {
  constructor(private http: HttpClient) { }

  tinyUrl(body: Tiny): Observable<Object> {
    let headers = new HttpHeaders().append('Content-Type', 'text/plain');
    let options = { headers: headers, withCredentials: true  };
    let bodyString = JSON.stringify(body); // Stringify payload
    return this.http.post<Object>(config.slqioApiUrl, bodyString, options) // ...using post request
    .pipe(map((res:Response) => res)) // ...and returning data
    .pipe(catchError((error:any) => throwError(error || 'Server error'))); //...errors if any
  }

  tiny() {
    return this.http.get(config.tinUrl + "tiny/srch")
  }
}
