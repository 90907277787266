import { Injectable } from '@angular/core';
import { Response, Headers, RequestOptions } from '@angular/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {Topic} from '../admin/topic/model/topic';
import {Tag} from '../admin/tag/model/tag';
import { config } from '../config';
import {RouterModule} from '@angular/router';
import { SisHeaderComponent } from '../sisHeader/sis-header.component';
import { HomeFooterComponent } from '../homeFooter/home-footer.component';
import { FormsModule } from '@angular/forms';
import { map, pipe, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class TopicService {
constructor(private http: HttpClient) { }

  qrCode(body: Topic) : Observable<string> {
           let headers = new HttpHeaders().append('Content-Type', 'text/plain');
    let options = { headers: headers, withCredentials: true  };
        let bodyString = JSON.stringify(body); // Stringify payload
        return this.http.post<string>(config.topicsUrl + "topics/topic/qrcode", bodyString, options) // ...using post request
      }

  getTopic(h: string, e: string) : Observable<Topic> {
    let headers = new HttpHeaders().append('Content-Type', 'text/plain');
    let options = { headers: headers, withCredentials: true};
    return this.http.post<Topic>(config.topicsUrl + "topics/topic/user", {"email": e, "hashTag": h}, options); // ...using post request
  }

  getTopicById(id: string) : Observable<Topic> {
    let headers = new HttpHeaders().append('Content-Type', 'text/plain');
    let options = { headers: headers, withCredentials: true };
    return this.http.post<Topic>(config.topicsUrl + "topics/topic", JSON.stringify(id), options)
  }

  publishTopic(h: string, e: string) : Observable<boolean> {
        let headers = new HttpHeaders().append('Content-Type', 'text/plain');
        let options = { headers: headers, withCredentials: true  };
        return this.http.post<boolean>(config.topicsUrl + "topics/topic/publish", {"email": e, "hashTag": h}, options) // ...using post request
  }

  pressTopic(h: string, e: string) : Observable<boolean> {
        let headers = new HttpHeaders().append('Content-Type', 'text/plain');
        let options = { headers: headers, withCredentials: true  };
        return this.http.post<boolean>(config.topicsUrl + "topics/topic/press", {"email": e, "hashTag": h}, options) // ...using post request
      }

  mockRequest(body: Topic) : Observable<boolean> {
      let headers = new HttpHeaders().append('Content-Type', 'text/plain');
    let options = { headers: headers, withCredentials: true  };
      let bodyString = JSON.stringify(body); // Stringify payload
      return this.http.post<boolean>(config.topicsUrl + "topics/topic/mock/request", bodyString, options) // ...using post request
    }

  examRequest(body: Topic) : Observable<boolean> {
      let headers = new HttpHeaders().append('Content-Type', 'text/plain');
    let options = { headers: headers, withCredentials: true  };
      let bodyString = JSON.stringify(body); // Stringify payload
      return this.http.post<boolean>(config.topicsUrl + "topics/topic/exam/request", bodyString, options) // ...using post request
    }

    examResponse(body: Topic) : Observable<boolean> {
    let headers = new HttpHeaders().append('Content-Type', 'text/plain');
    let options = { headers: headers, withCredentials: true  };
    let bodyString = JSON.stringify(body); // Stringify payload
    return this.http.post<boolean>(config.topicsUrl + "topics/topic/exam/response", bodyString, options) // ...using post request
  }

    examFinalize(body: Topic) : Observable<boolean> {
    let headers = new HttpHeaders().append('Content-Type', 'text/plain');
    let options = { headers: headers, withCredentials: true  };
    let bodyString = JSON.stringify(body); // Stringify payload
    return this.http.post<boolean>(config.topicsUrl + "topics/topic/exam/finalize", bodyString, options) // ...using post request
  }
    examResult(body: Topic) : Observable<boolean> {
    let headers = new HttpHeaders().append('Content-Type', 'text/plain');
    let options = { headers: headers, withCredentials: true  };
    let bodyString = JSON.stringify(body); // Stringify payload
    return this.http.post<boolean>(config.topicsUrl + "topics/topic/exam/result", bodyString, options) // ...using post request
  }

  examScores(body: Topic) : Observable<Topic[]> {
    let headers = new HttpHeaders().append('Content-Type', 'text/plain');
    let options = { headers: headers, withCredentials: true  };
    let bodyString = JSON.stringify(body); // Stringify payload
    return this.http.post<Topic[]>(config.topicsUrl + "topics/topic/exam/scores", bodyString, options) // ...using post request
  }

  examResults(body: Topic) : Observable<Topic[]> {
    let headers = new HttpHeaders().append('Content-Type', 'text/plain');
    let options = { headers: headers, withCredentials: true  };
    let bodyString = JSON.stringify(body); // Stringify payload
    return this.http.post<Topic[]>(config.topicsUrl + "topics/topic/exam/results", bodyString, options) // ...using post request
  }

  examReexam(body: Topic) : Observable<Topic[]> {
      let headers = new HttpHeaders().append('Content-Type', 'text/plain');
    let options = { headers: headers, withCredentials: true  };
      let bodyString = JSON.stringify(body); // Stringify payload
      return this.http.post<Topic[]>(config.topicsUrl + "topics/topic/exam/reexam", bodyString, options) // ...using post request
    }

  examMock(body: Topic) : Observable<Topic> {
      let headers = new HttpHeaders().append('Content-Type', 'text/plain');
    let options = { headers: headers, withCredentials: true  };
      let bodyString = JSON.stringify(body); // Stringify payload
      return this.http.post<Topic>(config.topicsUrl + "topics/topic/exam/mock", bodyString, options) // ...using post request
    }

     getTopics() : Observable<Topic[]> {
      let headers = new HttpHeaders().append('Content-Type', 'text/plain');
    let options = { headers: headers, withCredentials: true  };
        return this.http.get<Topic[]>(config.topicsUrl, options)
            .pipe(catchError((error:any) => throwError(error.json().error || 'Server error')));
     }

     searchTopics(s: string, k: string, d: string, n: string) : Observable<Topic[]> {
         return this.http.get<Topic[]>(config.topicsUrl + "SearchTopic/?s=" + s + "&k=" + k + "&d=" + d + "&n=" + n)
            .pipe(catchError((error:any) => throwError(error.json().error || 'Server error')));
     }

     publicSearchTopics(s: string, k: string, n: string) : Observable<Topic[]> {
         return this.http.get<Topic[]>(config.topicsUrl + "PublicSearchTopic/?s=" + s + "&k=" + k + "&n=" + n)
            .pipe(catchError((error:any) => throwError(error.json().error || 'Server error')));
     }

    pressSearchTopics(s: string, k: string, n: string) : Observable<Topic[]> {
         return this.http.get<Topic[]>(config.topicsUrl + "PressSearchTopic/?s=" + s + "&k=" + k + "&n=" + n)
            .pipe(catchError((error:any) => throwError(error.json().error || 'Server error')));
     }

    examSearchTopics(s: string, k: string, e: string, d: string, n: string) : Observable<Topic[]> {
         return this.http.get<Topic[]>(config.topicsUrl + "ExamSearchTopic/?s=" + s + "&k=" + k + "&e=" + e + "&d=" + d + "&n=" + n)
            .pipe(catchError((error:any) => throwError(error.json().error || 'Server error')));
     }

    mockSearchTopics(s: string, k: string, n: string) : Observable<Topic[]> {
         return this.http.get<Topic[]>(config.topicsUrl + "MockSearchTopic/?s=" + s + "&k=" + k + "&n=" + n)
            .pipe(catchError((error:any) => throwError(error.json().error || 'Server error')));
     }

     filterTopics(h: string, s: string, v: string, k: string, d: string) : Observable<Topic[]> {
         return this.http.get<Topic[]>(config.topicsUrl + "FilterTopic/?h=" + h + "&s=" + s + "&v=" + v + "&k=" + k + "&d=" + d)
            .pipe(catchError((error:any) => throwError(error.json().error || 'Server error')));
     }

    addTopic (body: Topic): Observable<Object> {
           let headers = new HttpHeaders().append('Content-Type', 'text/plain');
    let options = { headers: headers, withCredentials: true  };
        let bodyString = JSON.stringify(body); // Stringify payload
        return this.http.post<Object>(config.topicsUrl + "topics/", bodyString, options) // ...using post request
                         .pipe(catchError((error:any) => throwError(error || 'Server error'))); //...errors if any
    }

    mergeTopics (body: Topic): Observable<Object> {
           let headers = new HttpHeaders().append('Content-Type', 'text/plain');
    let options = { headers: headers, withCredentials: true  };
        let bodyString = JSON.stringify(body); // Stringify payload
        return this.http.post<Object>(config.topicsUrl + "topics/merge", bodyString, options) // ...using post request
    }

    updateTopic (body: Topic): Observable<Topic> {
           let headers = new HttpHeaders().append('Content-Type', 'text/plain');
    let options = { headers: headers, withCredentials: true  };
        let bodyString = JSON.stringify(body); // Stringify payload
        return this.http.put<Topic>(config.topicsUrl + "topics/" + body.id, bodyString, options) // ...using post request
               }

    cloneTopic (body: Topic): Observable<Object> {
           let headers = new HttpHeaders().append('Content-Type', 'text/plain');
    let options = { headers: headers, withCredentials: true  };
        let bodyString = JSON.stringify(body); // Stringify payload
        return this.http.put(config.topicsUrl + "/topics/clone/topic/from/to/new", JSON.stringify(body), options) // ...using post request
       }

       cloneTopicToUser (body: Topic): Observable<Object> {
    let headers = new HttpHeaders().append('Content-Type', 'text/plain');
    let options = { headers: headers, withCredentials: true  };
       	let bodyString = JSON.stringify(body); // Stringify payload
             return this.http.put(config.topicsUrl + "/topics/clone/topic/to/user", JSON.stringify(body), options) // ...using post request
    }

    renameTopic (body: Topic): Observable<Object> {
           let headers = new HttpHeaders().append('Content-Type', 'text/plain');
    let options = { headers: headers, withCredentials: true  };
	let bodyString = JSON.stringify(body); // Stringify payload
      return this.http.put<Response>(config.topicsUrl + "/topics/rename/topic/from/to", bodyString, options) // ...using post request
                         .pipe(catchError((error:any) => throwError(error || 'Server error'))); //...errors if any
    }

    updateTopicValue (body: Topic): Observable<Object> {
     let headers = new HttpHeaders().append('Content-Type', 'text/plain');
    let options = { headers: headers, withCredentials: true  };
      let bodyString = JSON.stringify(body); // Stringify payload
      return this.http.put(config.topicsUrl + "topics/" + body.id + "/value", bodyString, options) // ...using post request
    }

    archiveTopic (body: Topic): Observable<Object> {
    let headers = new HttpHeaders().append('Content-Type', 'text/plain');
    let options = { headers: headers, withCredentials: true  };
	let bodyString = JSON.stringify(body); // Stringify payload
      return this.http.put<Response>(config.topicsUrl + "topics/archive/disable/" + body.id, bodyString, options) // ...using post request
                         .pipe(catchError((error:any) => throwError(error || 'Server error'))); //...errors if any
    }

  deleteTopic (id: String): Observable<Object> {
    let headers = new HttpHeaders().append('Content-Type', 'text/plain');
    let options = { headers: headers, withCredentials: true  };
    return this.http.delete<Object>(config.topicsUrl+"topics/" + id, options) // ...using delete request
                         .pipe(catchError((error:any) => throwError(error || 'Server error'))); //...errors if any
    }

  getTopicTags(body: Topic) : Observable<Tag[]> {
    let headers = new HttpHeaders().append('Content-Type', 'text/plain');
    let options = { headers: headers, withCredentials: true  };
    let bodyString = JSON.stringify(body); // Stringify payload
    return this.http.post<Tag[]>(config.topicsUrl + "/topics/topic/tags", {"id": body.id}, options)
    .pipe(catchError((error:any) => throwError(error.json().error || 'Server error')));
  }
}
