export class Search  {
        public Results: Result[];
        public Relateds: string[];
	public Start: number;
        public Total: number;
        public PageSize: number;
        public MaxSize: number;
	public Time: string = "0.27";
}

export class Result  {
        public Title= "";
        public Url= "";
        public Text="";
	public Rank: number;
}
