import { Injectable } from '@angular/core';
import {User} from '../admin/user/model/user';
import {Topic} from '../admin/topic/model/topic';
import { Response, Headers, RequestOptions } from '@angular/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {CookieService} from 'ng2-cookies';
import { config } from '../config';
import {RouterModule} from '@angular/router';
import { SisHeaderComponent } from '../sisHeader/sis-header.component';
import { HomeFooterComponent } from '../homeFooter/home-footer.component';
import { FormsModule } from '@angular/forms';
import { map, pipe, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import {
  CanActivate,
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from '@angular/router';

export class Serializable {
  fromJSON(json: any){
    for (var propName in json)
      this[propName] = json[propName];
    return this;
  }
}

export class Login extends Serializable {
  email="";
  passwd="";
  userId="";
  sessionId="";
  goldimembersSessionId="";

  constructor(){super();}
}

export class Contact {
  email="";
  description="";

  constructor(){}
}

@Injectable()
export class UserService implements CanActivate {
  userLoggedIn: boolean = false;
  loggedInUser = new Login();
  authUser = new Login();

  constructor( private http: HttpClient, private router: Router, private _cookieService:CookieService  ) { }

  setCookie(key: string, value: string){
    return this._cookieService.set(key, value);
  }

  getCookie(key: string){
    return this._cookieService.get(key);
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    let url: string = state.url;
    return this.verifyLogin(url);
  }

  verifyLogin(url: string): boolean {
    if (this.userLoggedIn) { return true; }

    this.router.navigate(['/login']);
    return false;
  }

  signUp(body: Login) {
    let headers = new HttpHeaders().append('Content-Type', 'text/plain');
    let options = { headers: headers, withCredentials: true  };
    this.authUser.email = "";
    this.authUser.userId = "";
    this.authUser.sessionId = "";
    this.authUser.goldimembersSessionId = "";
    let bodyString = JSON.stringify(body);
    let endpoint = config.tagsUrl + 'SignUp/';
    let login = new Login();
    this.http.post(endpoint, bodyString, options)
      .pipe(catchError((error:any) => throwError(error || alert(`${error.message} Unable to SignUp. Please, try again!`))))
      .subscribe((json: Object) => {
        login.fromJSON(json);
        this.authUser.email = login.email;
        this.authUser.sessionId = login.sessionId;
        var s = login.userId.replace(/0/g,"");
        if(s != "") {
          this.authUser.userId = login.userId;
          this.loggedInUser.userId = login.userId;
        }
        //
        this.loggedInUser.email = login.email;
        this.loggedInUser.sessionId = login.sessionId;
        s = login.goldimembersSessionId.replace(/0/g,"");
        if(s != "") {
          this.authUser.goldimembersSessionId = login.goldimembersSessionId;
          this.loggedInUser.goldimembersSessionId = login.goldimembersSessionId;
        }
      })
  }

login(body: Login) : Observable<any> {
    let headers = new HttpHeaders().append('Content-Type', 'text/plain');
    let options = { headers: headers, withCredentials: true  };
    this.authUser.email = "";
    this.authUser.userId = "";
    this.authUser.sessionId = "";
    this.loggedInUser.email = "";
    this.loggedInUser.sessionId = "";
    let bodyString = JSON.stringify(body);
    let endpoint = config.tagsUrl + 'Login/';
    return this.http.post<any>(endpoint, bodyString, options)
  }

  logoutGwt(gwt: string){
    let headers = new HttpHeaders().append('Content-Type', 'text/plain');
    let options = { headers: headers, withCredentials: true  };
    this.userLoggedIn = false;
    let bodyString = JSON.stringify(this.authUser);
    let endpoint = config.tagsUrl + 'LogoutGwt/?g='+gwt;
    this.authUser.email = "";
    this.authUser.userId = "";
    this.authUser.sessionId = "";
    this.authUser.goldimembersSessionId = "";
    //
    this.loggedInUser.email = "";
    this.loggedInUser.userId = "";
    this.loggedInUser.sessionId = "";
    this.loggedInUser.goldimembersSessionId = "";
    return this.http.post(endpoint, bodyString, options)
      .pipe(catchError((error:any) => throwError(error || alert(`${error.message} Unable to logout. Please, try again!`))))
      .subscribe((json: Object) => {
      })
  }

  logout(){
    let headers = new HttpHeaders().append('Content-Type', 'text/plain');
    let options = { headers: headers, withCredentials: true  };
    this.userLoggedIn = false;
    let bodyString = JSON.stringify(this.authUser);
    let endpoint = config.tagsUrl + 'Logout/';
    this.authUser.email = "";
    this.authUser.userId = "";
    this.authUser.sessionId = "";
    this.authUser.goldimembersSessionId = "";
    //
    this.loggedInUser.email = "";
    this.loggedInUser.userId = "";
    this.loggedInUser.sessionId = "";
    this.loggedInUser.goldimembersSessionId = "";
    return this.http.post(endpoint, bodyString, options)
      .pipe(catchError((error:any) => throwError(error || alert(`${error.message} Unable to logout. Please, try again!`))))
      .subscribe();
  }

  reset(body: Login) {
    let headers = new HttpHeaders().append('Content-Type', 'text/plain');
    let options = { headers: headers, withCredentials: true  };
    let bodyString = JSON.stringify(body);
    let endpoint = config.tagsUrl + 'Reset/';
    return this.http.post(endpoint, bodyString, options)
      .pipe(catchError((error:any) => throwError(error || 'Server error')))
      .subscribe()
  }

  forgot(body: Login) {
    let headers = new HttpHeaders().append('Content-Type', 'text/plain');
    let options = { headers: headers, withCredentials: true  };
    let bodyString = JSON.stringify(body);
    let endpoint = config.tagsUrl + 'Forgot/';
    return this.http.post(endpoint, bodyString, options)
      .pipe(catchError((error:any) => throwError(error || 'Server error')))
      .subscribe();
  }

  invite(uemail: string, femail: string) {
    let headers = new HttpHeaders().append('Content-Type', 'text/plain');
    let options = { headers: headers, withCredentials: true  };
    let endpoint = config.tagsUrl + 'users/' + uemail + '/invite/' + femail;
    return this.http.post(endpoint, '', options)
      .pipe(catchError((error:any) => throwError(error || 'Server error')))
      .subscribe();
  }

  sendEmail(body: Topic, femail: string) {
    let headers = new HttpHeaders().append('Content-Type', 'text/plain');
    let options = { headers: headers, withCredentials: true  };
    let bodyString = JSON.stringify(body);
    let endpoint = config.tagsUrl + 'SendEmail/' + femail;
    return this.http.post(endpoint, bodyString, options)
      .pipe(catchError((error:any) => throwError(error || 'Server error')))
      .subscribe();
  }

  accepted(uemail: string, femail: string) {
    let headers = new HttpHeaders().append('Content-Type', 'text/plain');
    let options = { headers: headers, withCredentials: true  };
    let endpoint = config.tagsUrl + 'users/' + uemail + '/accepted/' + femail;
    return this.http.post(endpoint, '', options)
      .pipe(catchError((error:any) => throwError(error || 'Server error')))
      .subscribe();
  }

  removed(uemail: string, femail: string) {
    let headers = new HttpHeaders().append('Content-Type', 'text/plain');
    let options = { headers: headers, withCredentials: true  };
    let endpoint = config.tagsUrl + 'users/' + uemail + '/removed/' + femail;
    return this.http.post(endpoint, '', options)
      .pipe(catchError((error:any) => throwError(error || 'Server error')))
      .subscribe();
  }

  notify(body: Topic) {
    let headers = new HttpHeaders().append('Content-Type', 'text/plain');
    let options = { headers: headers, withCredentials: true  };
    let bodyString = JSON.stringify(body);
    let endpoint = config.tagsUrl + 'Notify/';
    return this.http.post(endpoint, bodyString, options)
      .pipe(catchError((error:any) => throwError(error || 'Server error')))
      .subscribe();
  }

  allUsers() {
    return this.http.get(config.tagsUrl + "AllUsers/")
      .pipe(catchError((error:any) => throwError(error.json().error || 'Server error')));
  }

  ////// session ///////////

  sessionVerifyExt()  : string{
    let email = "";
    this.authUser.email = email;
    this.loggedInUser.email = email;
    let headers = new HttpHeaders().append('Content-Type', 'text/plain');
    let options = { headers: headers, withCredentials: true  };
    let endpoint = config.usersUrl + "SessionVerifyExt/";
    this.http.get<string>(endpoint, options)
      .subscribe((email: string) => {
        this.authUser.email = email;
        this.loggedInUser.email = email;
        return email;
      });

    return email;
  }

  sessionVerifyGwt(gwt: any)  : string{
    let email = "";
    this.authUser.email = email;
    this.loggedInUser.email = email;
    let headers = new HttpHeaders().append('Content-Type', 'text/plain');
    let options = { headers: headers, withCredentials: true  };
    let endpoint = config.usersUrl + "SessionVerifyGwt/?g="+gwt;
    this.http.get<string>(endpoint, options)
      .subscribe((email: string) => {
        this.authUser.email = email;
        this.loggedInUser.email = email;
        this.setCookie("email", this.authUser.email)
        return email;
      });

    return email;
  }

  sessionVerifyEx()  : string{
    let email = "";
    this.authUser.email = email;
    this.loggedInUser.email = email;
    let headers = new HttpHeaders().append('Content-Type', 'text/plain');
    let options = {  headers: headers,
                     responseType: 'text' as 'text', //important
                     withCredentials: true
                   };
    let gwt = this.getCookie("gwt");
    if (!gwt) {
      return "";
    }
    let endpoint = config.usersUrl + "SessionVerifyGwt/?g="+gwt;
    this.http.get(endpoint, options)
      .subscribe({ next: (email: any) => {
                      this.authUser.email = email;
                      this.loggedInUser.email = email;
                      this.setCookie("email", this.authUser.email)
                      return email;
                     },
                     error: (error: any) => {
                      console.log(error);
                     }
                   });
    return email;
  }
  /*
.subscribe((email: string) => {
      alert(email);
        this.authUser.email = email;
        this.loggedInUser.email = email;
        this.setCookie("email", this.authUser.email)
        return email;
      });
*/
  ////// user ///////////

  getUser(h: string) : Observable<User> {
    let headers = new HttpHeaders().append('Content-Type', 'text/plain');
    let options = { headers: headers, withCredentials: true  };
    return this.http.get<User>(config.usersUrl + "GetUser/?h=" + h, options)
    .pipe(catchError((error:any) => throwError(error.json().error || 'Server error')));
  }

  getUsers() : Observable<User[]> {
    let headers = new HttpHeaders().append('Content-Type', 'text/plain');
    let options = { headers: headers, withCredentials: true  };

    return this.http.get<User[]>(config.usersUrl, options)
    .pipe(catchError((error:any) => throwError(error.json().error || 'Server error')));
  }

  searchUsers(s: string, k: string, d: string) : Observable<User[]> {
    return this.http.get<User[]>(config.usersUrl + "SearchUser/?s=" + s + "&k=" + k + "&d=" + d)
    .pipe(catchError((error:any) => throwError(error.json().error || 'Server error')));
  }

  filterUsers(h: string, s: string, v: string, k: string, d: string) : Observable<User[]> {
    return this.http.get<User[]>(config.usersUrl + "FilterUser/?h=" + h + "&s=" + s + "&v=" + v + "&k=" + k + "&d=" + d)
    .pipe(catchError((error:any) => throwError(error.json().error || 'Server error')));
  }

  getUserByEmail(d: string) : Observable<User> {
    return this.http.get<User>(config.usersUrl + "UserByEmail/" + d)
    .pipe(catchError((error:any) => throwError(error.json().error || 'Server error')));
  }

  addUser (body: User): Observable<Object> {
    const headers = new HttpHeaders().append('Content-Type', 'text/plain');
    let bodyString = JSON.stringify(body); // Stringify payload
    return this.http.post<Object>(config.usersUrl + "users/", bodyString, {headers}) // ...using post request
    .pipe(map((res:Response) => res)) // ...and returning data
    .pipe(catchError((error:any) => throwError(error || 'Server error'))); //...errors if any
  }

  updateUser (body: User): Observable<Object> {
    const headers = new HttpHeaders().append('Content-Type', 'text/plain');
    let bodyString = JSON.stringify(body); // Stringify payload
    return this.http.put<Object>(config.usersUrl + "users/" + body.id, bodyString, {headers}) // ...using post request
  }

  deleteUser(id: String): Observable<User> {
      return this.http.delete<User>(config.usersUrl+"users/" + id)
  }

  contact(body: Contact) {

  }
}
