import { Component, OnInit } from '@angular/core';
import {AutoService} from '../services/auto.service';
import {SearchService} from '../services/search.service';
import {Auto} from '../admin/auto/model/auto';
import {Search} from './model/search';
import {Observable, throwError} from 'rxjs';
import {HostListener} from '@angular/core';
import {ActivatedRoute, Router } from '@angular/router';
import {TinyService} from '../services/tiny.service';
import { DataService } from "../services/data.service";
import { Subscription } from 'rxjs';
import {IdService} from '../services/id.service';
import {Tiny} from '../admin/tiny/model/tiny';
import { AlertComponent } from '../alert/alert.component';
import { AlertService } from '../alert/services/alert.service';
import { AlertTypeEnum } from '../alert/types/alertType.enum';
import { config } from '../config';
import {RouterModule} from '@angular/router';
import { SisHeaderComponent } from '../sisHeader/sis-header.component';
import { HomeFooterComponent } from '../homeFooter/home-footer.component';
import { FormsModule } from '@angular/forms';
import {CommonModule} from '@angular/common';

@HostListener('window:keydown', ['$event'])
@Component({
  host: {
    '(document:keydown)': 'keyDown($event)',
  },
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css'],
  imports: [CommonModule, FormsModule, AlertComponent, RouterModule, HomeFooterComponent, SisHeaderComponent],
  standalone: true
})
export class SearchComponent implements OnInit {
  autos: Auto[];
  public searchs = new Search();
  public psearchs = new Search();
  public selectedAuto;
  searchText = "";
  subscription: Subscription;
  oldSearchData = "";
  selectedIndex = 0;
  show = true;
  showAuto = false;
  currentPage = 0;
  pageSize = 8;
  pageDirection = -1;
  totalCount = 0;
  pageCount = 0;
  working = true;
  counter = 0;
  start = 0;
  tin = ""
  searchData = ""
  selectedItemIndex = -1;
  selectedItem;
  tinUrl = config.slqioUrl;
  public theGoldiUser = "";
  public taxonomy = "";
  public pageTitle = "";
  public class = "";
  public subclass = "";

  constructor(private autoService: AutoService, private tinyService: TinyService, private searchService: SearchService, private activatedRoute: ActivatedRoute, private router: Router, private data: DataService, private idService: IdService, private alertService: AlertService) { }

  public showAlert(type: AlertTypeEnum, msg: string) {
    this.alertService.setAlert({
      type,
      text: msg,
    });
  }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
    this.class = config.class;
    this.subclass = config.subclass;
    this.taxonomy = config.taxonomy;
      this.searchText = params['q'] || "";
      var s = params['s'];
      this.start = + s || 0;
      //this.tin = params['l'] || ""
      if (this.start == 0) {
        //this.tiny();
      }

      if (this.searchText != "") {
        this.data.changeMessage(this.searchText);
      }

      this.subscription = this.data.currentMessage.subscribe(message => this.searchData = message)

      this.search();
    });
  }

  auto() {
    //Use a queue for managing in-flight requests

    var res = this.searchText.trim().split(" ");
    var txt = res[res.length - 1];
    txt = txt.trim()
    var head  = this.searchText.substring(0,this.searchText.indexOf(txt)).trim();

    this.counter++;
    var thisCounter = this.counter;
    var timeoutId = setTimeout(()=> {
      this.showAuto = false;
      if (txt.length > 2) {
        this.autos = new Array();
        //TODO: [0] current auto textbox value
        //	let t = new Auto();
        //	 t.Name = this.searchText;
        //	 t.Rank = 0;
        //	 this.autos = new Array()
        //	 this.autos[0] = t;

        this.autoService.auto(txt)
          .subscribe(
            (data: any) => {
              //				this.autos = this.autos.concat(data); //Bind to view
              if (this.counter == thisCounter) {
                this.autos = data; //Bind to view
                if (this.autos.length > 0) {
                  this.showAuto = true;
                } else {
                  this.showAuto = false;
                }
                var i;
                for (i = 0; i < this.autos.length; i++) {
                  this.autos[i].Name = head  + " " + this.autos[i].Name;
                }
              }
            },
             (err: any) => {
              console.log(err);
            });
      } else {
        this.autos = new Array();
        this.showAuto = false;
      }
    }, 1000/60);
    //	clearTimeout(timeoutId);
  }

  search() {
    this.showAuto = false;
    this.start = this.start % this.pageSize == 0 ? this.start : this.start - (this.start % this.pageSize);
    if (this.searchData != "" && this.searchData != this.oldSearchData) {
      this.searchs = new Search();
      this.totalCount = 0;
      this.pageCount = 0;
      this.show = false;
      this.working = true;

      this.searchService.search(this.searchData, this.start)
        .subscribe(
          (data: any) => {
            this.searchs = data; //Bind to view
            if(this.searchs.Results !== undefined) {
              this.totalCount = this.searchs.Total;
              this.pageSize = this.searchs.PageSize
              this.oldSearchData = this.searchData;
              this.pageCount = Math.ceil(this.totalCount / this.pageSize);
              this.start = this.searchs.Start;
              this.currentPage = (this.start + this.pageSize)/this.pageSize;
              this.working = false;
            }
          },
           (err: any) => {
            console.log(err);
            this.working = false;
          });
    }
  }


  /*
   TODO: Public search only!
  search() {
    this.showAuto = false;
    this.start = this.start % this.pageSize == 0 ? this.start : this.start - (this.start % this.pageSize);
    if (this.searchData != "" && this.searchData != this.oldSearchData) {
      this.searchs = new Search();
      this.totalCount = 0;
      this.pageCount = 0;
      this.show = false;
      this.working = true;

      this.searchService.search(this.searchData, this.start)
        .subscribe(
          (data: any) => {
            this.psearchs = data; //Bind to view
            if(this.psearchs.Results !== undefined) {
              for (let i = 0; i < this.psearchs.Results.length; i++) {
                if(this.psearchs.Results[i].Title.endsWith("_public")) {
                  this.searchs.Results.push(this.psearchs.Results[i]);
                }
              }
              this.searchs.Start = this.psearchs.Start;
              this.searchs.PageSize = this.psearchs.PageSize;
              this.oldSearchData = this.searchData;
              this.working = false;
              this.setPageVisible();
            }
          },
           (err: any) => {
            console.log(err);
            this.working = false;
          });
    }
  }

  setPageVisible() {
    this.totalCount = this.searchs.Results.length;
    this.pageSize = this.searchs.PageSize
    this.pageCount = Math.ceil(this.totalCount / this.pageSize);
    this.start = this.searchs.Start;
    this.currentPage = (this.start + this.pageSize)/this.pageSize;

    this.selectedItemIndex = (this.currentPage - 1) * this.pageSize;

     // var end = this.currentPage * this.pageSize
      //for (let i = 0; i < this.searchs.Results.length; i++) {
      //  if(i >= this.selectedItemIndex && i < end) {
       //   this.searchs.Results[i].show = true;
       // }
      //}

    this.selectedItem = this.searchs.Results[this.selectedItemIndex];
    this.data.changeMessage(this.selectedItem.Title);
    if (this.selectedItem.Title) {
      this.selectedItem.rank = this.selectedItemIndex + 1;
    }
  }
*/

  public selectAuto( auto: any, i: number) {
    this.selectedIndex = i;
    this.selectedAuto =  auto;
    this.searchText = this.selectedAuto.Name;
    this.setFirstPage()
    this.showAuto = false;
  }

 public keyDown(event: any) {
    switch (event.keyCode) {
      case 37:
        this.setPreviousPage();
        break;
      case 39:
        this.setNextPage()
        break;
      case 38:
        this.onUp();
        break;
      case 40:
        this.onDown()
        break;
      case 75:
        this.onItemUp();
        break;
      case 76:
        this.onItemDown()
        break;
      case 13:
        this.setFirstPage()
      case 27:
        this.selectedItemIndex = -1
        this.setFirstPage()
        break;
    }
    return;
  }

public onDown() {
    if(this.selectedIndex < this.autos.length - 1)
    {
      this.selectedAuto = this.autos[++this.selectedIndex];
      this.searchText = this.selectedAuto.Name;
    }
  }

  public onUp() {
    if(this.selectedIndex > 0)
    {
      this.selectedAuto = this.autos[--this.selectedIndex];
      this.searchText = this.selectedAuto.Name;
    }
  }

  tiny() {
    this.tin = "";
    this.tinyService.tiny()
      .subscribe(
        (data: any) => {
          this.tin = data.text().slice(1, -1);
        });
  }

  //pagination
  public setFirstPage() {
    if(this.searchText.length > 0) {
      window.location.href='/search?q=' + this.searchText + '&l=' + this.tin;
      event.preventDefault();
    }
  }

  setPreviousPage() {
    if (this.currentPage > 1) {
      window.location.href='/search?q=' + this.searchText + '&s=' + (this.start - this.pageSize) + '&l=' + this.tin;
    }
  }

  setNextPage() {
    if (this.currentPage < this.pageCount) {
      window.location.href='/search?q=' + this.searchText + '&s=' + (this.start + this.pageSize) + '&l=' + this.tin;
    }
  }

  setPage(page: number) {
    if (page >= 0 && page < this.pageCount) {
      window.location.href='/search?q=' + this.searchText + '&s=' + (page * this.pageSize) + '&l=' + this.tin;
    }
  }

  id(callback: (id: string) => any) {
    this.idService.id()
      .subscribe(
        (id: any) => {
          if(id!=undefined){
            callback(id);
          }
        },
         (err: any) => {
          console.log(err);
        });
  }

  tinyUrl(tiny: Tiny) {
    this.tinyService.tinyUrl(tiny)
      .subscribe(
        (tiny: any) => {
          if(tiny!=undefined){
            ;
          }
        },
         (err: any) => {
          console.log(err);
        });
  }

  public copyTinyUrlToClipboard() {
    this.id((id: string) => {
    let t = new Tiny('', '', '');
    t.hashTag = id.replace(/^"(.*)"$/, '$1');
    t.email = "search@siloq.com";
    t.value = window.location.href;
    this.tinyUrl(t);

    var dummy = document.createElement('input');
    document.body.appendChild(dummy);
    dummy.value = this.tinUrl+t.hashTag;
    dummy.select();
    document.execCommand('copy');
    document.body.removeChild(dummy);

    this.showAlert(AlertTypeEnum['success'], "TinyURL is copied to the clipboerd, Paste anyware!");
    });
}

  copyLoqUrlToClipboard(i: any) {
    this.selectedItemIndex = i;
    this.id((id: string) => {
    let t = new Tiny('', '', '');
    t.hashTag = id.replace(/^"(.*)"$/, '$1');
    t.email = "search@siloq.com";
    t.value = this.searchs.Results[i].Url+"#"+i;
    this.tinyUrl(t);

    var dummy = document.createElement('input');
    document.body.appendChild(dummy);

    dummy.value = this.searchs.Results[i].Title + "... read more here >> " + this.tinUrl+t.hashTag;
    if (this.searchs.Results[i].Title.length > 64) {
      dummy.value = this.searchs.Results[i].Title.substring(0, 64) + "... read more here >> " + this.tinUrl+t.hashTag;
    }

    dummy.select();
    document.execCommand('copy');
    document.body.removeChild(dummy);

    this.showAlert(AlertTypeEnum['success'], "LoqURL is copied to the clipboerd, Paste anyware!");
    });
}

public selectItem( item: any, i: number) {
    this.selectedItemIndex = i;
    this.selectedItem =  item;
  }

  public onItemDown() {
    if(this.selectedItemIndex < this.searchs.Results.length - 1)
    {
      this.selectedItem = this.searchs.Results[++this.selectedItemIndex];
    }
  }

  public onItemUp() {
    if(this.selectedItemIndex > 0)
    {
      this.selectedItem = this.searchs.Results[--this.selectedItemIndex];
    }
  }

}
