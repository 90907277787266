import { Component, OnInit } from '@angular/core';
import {TagService} from '../services/tag-service.service';
import {Observable, throwError} from 'rxjs';
import {HostListener} from '@angular/core';
import {ActivatedRoute, Router } from '@angular/router';
import {Tag} from '../admin/tag/model/tag';
import { AlertComponent } from '../alert/alert.component';
import { AlertService } from '../alert/services/alert.service';
import { AlertTypeEnum } from '../alert/types/alertType.enum';
import { config } from '../config';
import {RouterModule} from '@angular/router';
import { HeaderComponent } from '../header/header.component';
import { FooterComponent } from '../footer/footer.component';
import { FormsModule } from '@angular/forms';

@HostListener('window:keydown', ['$event'])
@Component({
	host: {
    '(document:keydown)': 'keyDown($event)',
	},
	selector: 'app-contact',
	templateUrl: './contact.component.html',
	styleUrls: ['./contact.component.css'],
  imports: [AlertComponent, FormsModule, RouterModule, FooterComponent, HeaderComponent],
  standalone: true
})

  export class ContactComponent implements OnInit {
	public model = new Tag('', '', '', '', '');
	public theUser = "";
 public theGoldiUser = "";
  sub= "";
  public taxonomy = "";
  public class = "";
  public subclass = "";

	constructor( private alertService: AlertService, private tagService: TagService, private activatedRoute: ActivatedRoute, private router: Router) { }

	ngOnInit() {
		this.activatedRoute.queryParams.subscribe(params => {
    this.sub = params['s'] || "";
		});

    this.theUser = "support@tagndoc.com";
		this.model.email = this.theUser;
		this.model.hashTag = "contact";
    this.taxonomy = config.taxonomy;
    this.class = config.class;
    this.subclass = config.subclass;
	}

showAlert(type: AlertTypeEnum, msg: string) {
    this.alertService.setAlert({
      type,
      text: msg,
    });
  }

  validateEmail(email: any) {
     const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
     return re.test(email);
   }

 submitTag(){
     let commentOperation:Observable<Tag[]>;
     var tb1 = document.getElementById("source");
     this.model.source = tb1.innerText;
     var tb2 = document.getElementById("value");
     this.model.value = tb2.innerText;
     if (this.model.source === "" || this.model.value === "")
     {
       this.showAlert(AlertTypeEnum['warning'], "Both the values can not be empty");
       return;
     } else if (!this.validateEmail(this.model.source)) {
       this.showAlert(AlertTypeEnum['warning'], "Email is not valid. Please re-enter and try again!");
       return;
     }

     this.model.email = this.theUser;
     this.tagService.addTag(this.model).subscribe(
       () => {
        tb1.innerText = tb2.innerText = "";
        this.showAlert(AlertTypeEnum['info'], "Thanks for contacting us. We'll get back to you in 72 hours.");
       },
        (err: any) => {
         console.log(err);
       });
   }

   getStarted(){
     this.router.navigate(['/publish'], { queryParams: {h : 'Help'}});
   }

   public keyDown(event: any) {
     switch (event.keyCode) {
       case 13:
       this.submitTag()
         event.preventDefault();
         break;
     }
     return;
   }
}

