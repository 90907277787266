<dummy-header></dummy-header>
  <body style="height:100%;width:100%; margin:0px;">

  <div class="doNotPrint" style="background-color:whitesmoke;position:fixed;margin-top:47px;display:table;width:100%; height:38px; valign:middle;border:0px solid lightgrey;padding:0px 0px 0px 0px;">
    <div  style="text-align:center;margin:0 auto;color:mediumseagreen;padding:0px 5px 0px 12px;font-family:Helvetica,AppleGothic,sans-serif;font-size:1em; font-weight:600;width:90%;vertical-align: middle;">
      <a class="fa fa-arrow-left intLink" title="Done with the uploading of '{{model.hashTag}}'" (click)="docTopic(model.hashTag)" type="button" valign="middle" style="color:dodgerblue;;background-color:#ffffff00;font-size:1em;width:22px;height:22px;border:0px; padding:10px 0px 0px 0px;font-weight:300;"></a>
      <a class="fa fa-upload intLink" title="Upload to '{{model.hashTag}}'" (click)="once=true; submitSqb()" type="button" valign="middle" style="color:dodgerblue;background-color:#ffffff00;font-size:1em;width:22px;height:22px;border:0px; padding:10px 0px 0px 0px;font-weight:300;"></a>
      <a class="fa fa-send-o intLink" title="Upload and redirect To '{{model.hashTag}}' to exam" (click)="once=false; submitSqb()" type="button" valign="middle" style="color:dodgerblue;background-color:#ffffff00;font-size:1em;width:22px;height:22px;border:0px; padding:10px 0px 0px 0px;font-weight:300;"></a>
    </div>
  </div>
  <div  class="doNotPrint" style="padding:90px 0px 5px 0px;font-family:Helvetica,AppleGothic,sans-serif; width:100%;text-align:center;color:mediumseagreen;font-size:1em;font-weight:500;vertical-align: middle;">SQS</div>
  <div class="block_container" style="background-color:#ffffff00;border-bottom:1px solid gainsboro;padding:10px 0px 0px 0px;font-family:Century Gothic,CenturyGothic,AppleGothic,sans-serif; text-align: center; valign: top; font-size:1em;font-weight:200;"><a href="" ><img src="/assets/isloq.png" align="middle" style="margin-bottom:13px;width:19px; height:19px;" /></a> <span style="color:grey;"></span> <a style="text-decoration:none;" href=""><span
    style="color:dodgerblue;">i</span><span
    style="color:mediumseagreen;">s</span><span
    style="color:orangered;">l</span><span
    style="color:orange;">o</span><span
    style="color:dodgerblue;">q</span><span
    class="sup" style="color:orangered;">&trade;</span><span style="color:dodgerblue;">&nbsp;Publish Instantly!</span> </a>
  </div>
  <div id="doc" style="font-family:Helvetica,AppleGothic,sans-serif;width:92%;overflow:hidden;margin:4px 4% 40px 4%;padding-top:10px;z-index:-1px;">
    <!--
    <h2 style="vertical-align: middle;padding:0px 0px 15px 5px;margin:0 auto;width:90%;text-align:center;">
      <div  style="font-family: Arial, Helvetica, sans-serif;padding:0px 0px 0px 2px; margin:0px 0px 0px 3px;" >{{model.hashTag}}</div>
    </h2>
    -->
    <admin-alert></admin-alert>
    <ng-template id="modal" #modalTemplate>
    </ng-template>

 <div style="width:90%; margin:0 auto; padding-top: 0px;vertical-align: middle; text-align:center;">
   <!--
   <h3 style="font-family:Century Gothic,CenturyGothic,AppleGothic,sans-serif;">Upload SQBs </h3>
   -->
   <input style="padding-left:80px" type="file" (change)="fileChangeEventToo($event)">
   <!--
   TODO: Upload only *.slq format
   -->
   <h4 style="vertical-align: middle;padding:5px 0px 15px 5px;margin:0 auto;width:90%;text-align:center;">Answer Finalized<input class="siloqinput" type="checkbox" title="#Options per Question" [(ngModel)]='answerFinalized' name="options" style="color:mediumseagreen;vertical-align: middle;height:20px; width:20px;font-weight:400;"  valign="middle"/> </h4>
   <h4 *ngIf="!answerFinalized" style="vertical-align: middle;padding:5px 0px 15px 5px;margin:0 auto;width:90%;text-align:center;">#Options <input class="siloqinput" type="text" title="#Options per Question" [(ngModel)]='options' name="options" style="color:mediumseagreen;vertical-align: middle;height:20px; width:40px;font-weight:400;"  valign="middle"/> </h4>
   <!--
   TODO: If necessary
   <div (blur)="model.value = $event.target.innerHTML;" class="editTag" [contentEditable]="true" tabindex="0" valign="top" style="font-family:Century Gothic,CenturyGothic,AppleGothic,sans-serif;border:1px solid grey; display:table-cell;padding:0px 1px 2px 0px;text-align: justify; text-justify: inter-word;display: inline-block; margin: 0 auto !important; inter-word;font-weight:500;font-size:12px;min-width:60%;min-height:100px;padding:15px;"[innerHTML]="model.value | safeHtml"></div>
   -->
   <!--
   TODO: Upload only *.slq format
   -->
   <textarea  [(ngModel)]='model.value' name="message" id="sqb" rows="24" cols="60" style="width:90%" placeholder="Please paste SQB here!"></textarea>
   <!--
   <br>
   <input (click)='submitSqb()'  title="Enter to add text" class="siloqbutton" type="button" value="⏎" style="margin: 0 auto !important;color:orangered;min-width:50px;width:5%;"/>
   -->
   </div>
        <br>
    <div class="block_container" style="background-color:#ffffff00;border-top:1px solid gainsboro;padding:10px 0px 0px 0px;font-family:Century Gothic,CenturyGothic,AppleGothic,sans-serif; text-align: center; valign: top; font-size:1em;font-weight:200;"><a style="text-decoration:none;" href="http://siloq.com" >
      <span style="color:dodgerblue;">Powered by</span> <img src="assets/isloq.png" align="middle" style="margin-bottom:13px;width:19px; height:19px;" /></a> <span style="color:grey;"></span> <a style="text-decoration:none;" href="http://siloq.com"><span style="color:dodgerblue;">i</span><span
      style="color:mediumseagreen;">s</span><span
      style="color:dodgerblue;">l</span><span
      style="color:orange;">o</span><span
      style="color:dodgerblue;">q</span><span
      class="sup"
      style="color:orangered;">™</span>
    </a>
    </div>
 <br>
 <br>
<dummy-footer></dummy-footer>
