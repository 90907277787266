<admin-header></admin-header>
<body onload="initDoc();" style="margin: 0px 0px 0px 0px;">
  <form name="compForm">
<input type="hidden" name="myDoc">

<div style="position:fixed;margin-top:39px; border-width:1px; display: inline;background-color:white;width:100%" class="doNotPrint">

  <div style="float:left;padding:0px 5px 0px 0px;">
<img (click)="home()" src="/assets/isloq.png" style="width:40px; height:40px;" />
  </div>

<div style="float:right; margin: 3px; 10px 35px 0px; width: 125px;padding-bottom:35px;">
  <button style="background-color:dodgerblue;" (click)='chat()'
          class="button">Chat</button><button
          style="background-color:orangered;" class="button"
                                              (click)='editDoc()'>Done</button></div>
<div style="width:100%;">

  <div style="display: inline block; padding:0px;margin:0px;width:100%">

    <div contenteditable="true" style="display: inline-block; font-family: Arial, Helvetica, sans-serif;float:left;padding:0px 5px 0px 5px; margin:0px;" (input)="topic.hashTag = $event.target.innerText">{{topic.hashTag}}
    </div>

    <div> <img style="margin:0px;padding:0px;width:10px; height:10px;"class="intLink" title="Redo" onclick="formatDoc('redo');" src="data:image/gif;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAACxUlEQVR42pWRa0iTURjH/+fdnJu7JJZpqTm1NC8VYkmhFSiWgWVXVh8yL1RUfqiUAkFEsvrQzSD9EGjLiEL7kBVGYmEiQ3OWlJq5jLlVaGvqNufu79txhCRq1AuH85z3PM/v+T//Q/CXr+/m2T7whJ74wktrF8ohC128OZ+dEVeY1wy7Fv23VenJV+pf/Regt7JEG3/MFI7JD+hVRg6vOaeU/zOgq3hvUnh2qjowsQ1wOzCikkCvEicmVyh7/groOJ0S6rZag4VBG+4mlUXEcaMvQEgA4EOgvkH6ncZvR3h+zOjGSpV+BvD+gqKa5fmv9AtPkEvD4uU+EomPOHQCQnIZnGMR4KJJvEnY7GmYsqbBZTM7LbqPWpt+QEdcRg0ZrCnlVuVnUpaaJnfSZQacLrBWHggnADwMHcMFIqBiCT1zFCpaASzZBk3tE5Dukn37g1LHGkJ2sGDHRYCdT2F8Kt2XFtPQQwtZurvp7hijM9sBXweGmzwYM4Qd8HrQcSb3VEia/VZolhMw+oH1WADbJC0wAlPfaWwCzA4wnJcNdRXwRRdcqHg7UjVjYnfpiaKAWN1VeboaHqMJnM0OhjbjaHfiglcNwycYfCRCa4Oz+PgP97U5r3BfHtCoeDmxi1hZcM7fsqdNpDEzPYoDqD0pflygt+yZ9xmfpsW2ZNWPpHv047SQAWE5b3fORhPdjNfIZxWLW3a2jmbMC2jPjxpKKR+KdBsI+CLO2927qALWwANDfW2vlmo2N05EzwEol/FjkosiBmKPDlHDWFjf8dBZLaTagU2HHBBFUBM4gv4HEnQ1s9G5nyc1swA1UkHm7qaQ5/5Lh9F9UYCBVsGdHJ05f/quLlJWu3q9Ky9J4cK4XoTG69heoLM0zwI8TBBuCVwne235ZLtn/smU52hNQ3+OVxcji5KJuTLpcvaw4Suz9WCPpW36/y8kUh1DpensuQAAAABJRU5ErkJggg==" />
    </div>

  </div>
<div id="toolBar1" class="doNotPrint" style="padding:0px; 0px 0px 0px;marging:0px;">
  <div class="navbar">
  <div class="dropdown">
    <button class="dropbtn">File</button>
    <div class="dropdown-content">
      <a href="#">Share</a>
      <a href="#">Open</a>
      <a href="#">Save</a>
      <a href="#">Save As</a>
      <a href="#">Export</a>
      <a href="#">Print</a>
    </div>
  </div>
  <div class="dropdown">
    <button class="dropbtn">Edit</button>
    <div class="dropdown-content">
      <a href="#">Title 1 &lt;h1&gt;</a>
      <a href="#">Title 2 &lt;h2&gt;</a>
      <a href="#">Title 3 &lt;h3&gt;</a>
      <a href="#">Title 4 &lt;h4&gt;</a>
      <a href="#">Title 5 &lt;h5&gt;</a>
      <a href="#">Subtitle &lt;h6&gt;</a>
      <a href="#">Paragraph &lt;p&gt;</a>
      <a href="#">Preformatted &lt;pre&gt;</a>
      <a href="#">Select all</a>
      <a href="#">Find and replace</a>
    </div>
  </div>
  <div class="dropdown">
    <button class="dropbtn">Color</button>
    <div class="dropdown-content">
      <button style="border:0px;" onclick="formatDoc('forecolor', this.innerText);">Red</button>
      <a onclick="alert();">Green</a>
      <a href="#">Blue</a>
      <a href="#">Black</a>
      <a href="#">OrangeRed</a>
      <a href="#">MediumSeaGreen</a>
      <a href="#">DodgerBlue</a>
      <a href="#">Orange</a>
      <a href="#">Olive</a>
      <a href="#">Teal</a>
      <a href="#">Violet</a>
      <a href="#">Pink</a>
      <a href="#">White</a>
      <a href="#">YellowGreen</a>
      <a href="#">Gold</a>
      <a href="#">GoldenRed</a>
      <a href="#">FireBrick</a>
      <a href="#">Purple</a>
      <a href="#">Magenta</a>
      <a href="#">Indigo</a>
      <a href="#">SlateGrey</a>
      <a href="#">Show Html</a>
      <a href="#">Full Screen</a>
    </div>
    </div>
  <div class="dropdown">
    <button class="dropbtn">Insert</button>
    <div class="dropdown-content">
      <a href="#">Image</a>
      <a href="#">Video</a>
      <a href="#">Youtube</a>
      <a href="#">Link</a>
      <a href="#">Table</a>
      <a href="#">Headers</a>
    </div>
  </div>
  <div class="dropdown">
    <button class="dropbtn">Font</button>
    <div class="dropdown-content">
      <a href="#">Arial</a>
      <a href="#">Arial Black</a>
      <a href="#">Courier New</a>
      <a href="#">Times New Roman</a>
      <a href="#">Helvetica</a>
      <a href="#">Verdana</a>
      <a href="#">Monaco</a>
      <a href="#">Brush Script MT</a>
      <a href="#">Copperplate</a>
      <a href="#">Clear Formating</a>
    </div>
  </div>
  <div class="dropdown">
    <button class="dropbtn">Back-color</button>
    <div class="dropdown-content">
      <a href="#">Red</a>
      <a href="#">Green</a>
      <a href="#">Blue</a>
      <a href="#">Black</a>
      <a href="#">OrangeRed</a>
      <a href="#">MediumSeaGreen</a>
      <a href="#">DodgerBlue</a>
      <a href="#">Orange</a>
      <a href="#">Olive</a>
      <a href="#">Teal</a>
      <a href="#">Violet</a>
      <a href="#">Pink</a>
      <a href="#">White</a>
      <a href="#">YellowGreen</a>
      <a href="#">Gold</a>
      <a href="#">GoldenRed</a>
      <a href="#">FireBrick</a>
      <a href="#">Purple</a>
      <a href="#">Magenta</a>
      <a href="#">Indigo</a>
      <a href="#">SlateGrey</a>
      <a href="#">Show Html</a>
      <a href="#">Full Screen</a>
    </div>
  </div>
  <div class="dropdown">
    <button class="dropbtn">Size</button>
    <div class="dropdown-content">
      <a href="#">Very small</a>
      <a href="#">A bit small</a>
      <a href="#">Normal</a>
      <a href="#">Medium Large</a>
      <a href="#">Big</a>
      <a href="#">Very Big</a>
      <a href="#">Maximum</a>
      <a href="#">Get Add-ons</a>
    </div>
  </div>
  <div class="dropdown">
    <button class="dropbtn">Help</button>
    <div class="dropdown-content">
      <a href="#">About</a>
      <a href="#">Settings</a>
      <a href="#">Feedback</a>
    </div>
  </div>
</div>
</div>
</div>
<div id="toolBar2" class="doNotPrint" style="background-color:white; position:fixed;display:table;width:100%;padding: 5px 5px 0px 5px; margin:10px 0px 10px 0px; border: 1px gainsboro solid;">
  <div style="width:60%;display:table-cell;">
<img style="padding-bottom: 3px;" class="intLink" title="Save" (click)="save()" src="data:image/gif;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAC/klEQVR42pWSX2hbZRiHny85SZum a5tsWZvEdq0tq2mnVKG7mkyKrSjeiMKEDS8cbAjeiGwwL2SDjaIou5DJYOCNogwd7GIrq1q0+Gdb u7pNzZbUpmyznbT5t8SYnJw05/VrusFuZO6FwzkHznm+5/29rzr0c0qUYVDBwFst0Fhfh+2QPLY0 oRQKcDoNXIYLRMhm7nAh5+LPpaJ/+vVNWfXujynZMeDnm4zi2X8S9D8W1B+u/qZL9OXwkJi/wXRi icHuVtx1bj63QkxcWiSbLofUge+X5dHOJqZyTl74O8Zzfa3kq47ayVVbCG5s4cDRL7jetY2R9BQ9 /RG+W/8EPrvI+KU0at+3SxLpamY672Ikd5VnegMUbEMDRHchtPsbGT11kfTwMP0/jBEKBBjzD9JZ bzETy6DeHl+Uni5fDTCcmWFoswbIXYA2aNcG+4+dJtY7wtCtcSJbIpzzbyXkMtcAe07OyeMDIaay GpA6z/N9AfLirgHEhnXrvEz8epPk3CwtHZ3U1Rt83TxIWAN+ua5b2PtlQnr6gkxXPDwZm2SovZ6i WgPcy9Hb4MHtBKNS5sxsjmjfdtqsAr9Fl1BvfDUvbZvD/PHTBYyFOPZKBXE4uL/k7t1hayWnC3dn L3UDW4lfvYXa9WlMfN1t7PWl9Ai7+T8VjSU4eNvPclwbvPZZXFwdrbwVSBHwtTB2dgLRFkWzSKlk YpVKlKwyFdMkmUwy+sEoyewd9t3wkYsvonZ+ck1kUyvvhLI1Az251QV8oMGbc00UYrdRr574XUxt cLgj91AGu6ONWPEF1MsfX5FCR4gPu/OEg+tJ61136hBFq4iStbXWz0rnWimvsGGDn7+Ws+y47IVZ HeJLx2YkGQxzPFKoGYyfm2SlYlEy9cnFIuVVE7OMVTbJpNMcee9wzeDF8x5cCQ14+qOolHtCvN88 z/anHqGUzuNwrvWq7o1wNRj9Vq1WafD7mLyywCs32/FcvoYK758UW2taxQo5a+W+qP47SbfhwKs0 rLmBfwEejXGgqAgtxQAAAABJRU5ErkJggg==" />
<img class="intLink" title="Print" onclick="printWindow('textBox', 'clsb', 'clsa');" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAWCAYAAADEtGw7AAAABGdBTUEAALGPC/xhBQAAAAZiS0dEAP8A/wD/oL2nkwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAAd0SU1FB9oEBxcZFmGboiwAAAAIdEVYdENvbW1lbnQA9syWvwAAAuFJREFUOMvtlUtsjFEUx//n3nn0YdpBh1abRpt4LFqtqkc3jRKkNEIsiIRIBBEhJJpKlIVo4m1RRMKKjQiRMJRUqUdKPT71qpIpiRKPaqdF55tv5vvusZjQTjOlseUkd3Xu/3dPzusC/22wtu2wRn+jG5So/OCDh8ycMJDflehMlkJkVK7KUYN+ufzA/RttH76zaVocDptRxzQtNi3mRWuPc+6cKtlXZ/sddP2uu9uXlmYXZ6Qm8v4Tz8lhF1H+zDQXt7S8oLMXtbF4e8QaFHjj3kbP2MzkktHpiTjp9VH6iHiA+whtAsX5brpwueMGdONdf/2A4M7ukDs1JW662+XkqTkeUoqjKtOjm2h53YFL15pSJ04Zc94wdtibr26fXlC2mzRvBccEbz2kiRFD414tKMlEZbVGT33+qCoHgha81SWYsew0r1uzfNylmtpx80pngQQ91LwVk2JGvGnfvZG6YcYRAT16GFtW5kKKfo1EQLtfh5Q2etT0BIWF+aitq4fDbk+ImYo1OxvGF03waFJQvBCkvDffRyEtxQiFFYgAZTHS0zwAGD7fG5TNnYNTp8/FzvGwJOfmgG7GOx0SAKKgQgDMgKBI0NJGMEImpGDk5+WACEwEd0ywblhGUZ4Hw5OdUekRBLT7DTgdEgxACsIznx8zpmWh7k4rkpJcuHDxCul6MDsmmBXDlWCH2+XozSgBnzsNCEE4euYV4pwCpsWYPW0UHDYBKSWu1NYjENDReqtKjwn2+zvtTc1vMSTB/mvev/WEYSlASsLimcOhOBJxw+N3aP/SjefNL5GePZmpu4kG7OPr1+tOfPyUu3BecWYKcwQcDFmwFKAUo90fhKDInBCAmvqnyMgqUEagQwCoHBDc1rjv9pIlD8IbVkz6qYViIBQGTJPx4k0XpIgEZoRN1Da0cij4VfR0ta3WvBXH/rjdCufv6R2zPgPH/e4pxSBCpeatqPrjNiso203/5s/zA171Mv8+w1LOAAAAAElFTkSuQmCC">
<img class="intLink" title="Undo" onclick="formatDoc('undo');" src="data:image/gif;base64,R0lGODlhFgAWAOMKADljwliE33mOrpGjuYKl8aezxqPD+7/I19DV3NHa7P///////////////////////yH5BAEKAA8ALAAAAAAWABYAAARR8MlJq7046807TkaYeJJBnES4EeUJvIGapWYAC0CsocQ7SDlWJkAkCA6ToMYWIARGQF3mRQVIEjkkSVLIbSfEwhdRIH4fh/DZMICe3/C4nBQBADs=" />
<img class="intLink" title="Redo" onclick="formatDoc('redo');" src="data:image/gif;base64,R0lGODlhFgAWAMIHAB1ChDljwl9vj1iE34Kl8aPD+7/I1////yH5BAEKAAcALAAAAAAWABYAAANKeLrc/jDKSesyphi7SiEgsVXZEATDICqBVJjpqWZt9NaEDNbQK1wCQsxlYnxMAImhyDoFAElJasRRvAZVRqqQXUy7Cgx4TC6bswkAOw==" />
<img class="intLink" title="Bold" onclick="formatDoc('bold');" src="data:image/gif;base64,R0lGODlhFgAWAID/AMDAwAAAACH5BAEAAAAALAAAAAAWABYAQAInhI+pa+H9mJy0LhdgtrxzDG5WGFVk6aXqyk6Y9kXvKKNuLbb6zgMFADs=" />
<img class="intLink" title="Italic" onclick="formatDoc('italic');" src="data:image/gif;base64,R0lGODlhFgAWAKEDAAAAAF9vj5WIbf///yH5BAEAAAMALAAAAAAWABYAAAIjnI+py+0Po5x0gXvruEKHrF2BB1YiCWgbMFIYpsbyTNd2UwAAOw==" />
<img class="intLink" title="Underline" onclick="formatDoc('underline');" src="data:image/gif;base64,R0lGODlhFgAWAKECAAAAAF9vj////////yH5BAEAAAIALAAAAAAWABYAAAIrlI+py+0Po5zUgAsEzvEeL4Ea15EiJJ5PSqJmuwKBEKgxVuXWtun+DwxCCgA7" />
<img class="intLink" title="Left align" onclick="formatDoc('justifyleft');" src="data:image/gif;base64,R0lGODlhFgAWAID/AMDAwAAAACH5BAEAAAAALAAAAAAWABYAQAIghI+py+0Po5y02ouz3jL4D4JMGELkGYxo+qzl4nKyXAAAOw==" />
<img class="intLink" title="Center align" onclick="formatDoc('justifycenter');" src="data:image/gif;base64,R0lGODlhFgAWAID/AMDAwAAAACH5BAEAAAAALAAAAAAWABYAQAIfhI+py+0Po5y02ouz3jL4D4JOGI7kaZ5Bqn4sycVbAQA7" />
<img class="intLink" title="Right align" onclick="formatDoc('justifyright');" src="data:image/gif;base64,R0lGODlhFgAWAID/AMDAwAAAACH5BAEAAAAALAAAAAAWABYAQAIghI+py+0Po5y02ouz3jL4D4JQGDLkGYxouqzl43JyVgAAOw==" />
<img class="intLink" title="Numbered list" onclick="formatDoc('insertorderedlist');" src="data:image/gif;base64,R0lGODlhFgAWAMIGAAAAADljwliE35GjuaezxtHa7P///////yH5BAEAAAcALAAAAAAWABYAAAM2eLrc/jDKSespwjoRFvggCBUBoTFBeq6QIAysQnRHaEOzyaZ07Lu9lUBnC0UGQU1K52s6n5oEADs=" />
<img class="intLink" title="Dotted list" onclick="formatDoc('insertunorderedlist');" src="data:image/gif;base64,R0lGODlhFgAWAMIGAAAAAB1ChF9vj1iE33mOrqezxv///////yH5BAEAAAcALAAAAAAWABYAAAMyeLrc/jDKSesppNhGRlBAKIZRERBbqm6YtnbfMY7lud64UwiuKnigGQliQuWOyKQykgAAOw==" />
<img class="intLink" title="Quote" onclick="formatDoc('formatblock','blockquote');" src="data:image/gif;base64,R0lGODlhFgAWAIQXAC1NqjFRjkBgmT9nqUJnsk9xrFJ7u2R9qmKBt1iGzHmOrm6Sz4OXw3Odz4Cl2ZSnw6KxyqO306K63bG70bTB0rDI3bvI4P///////////////////////////////////yH5BAEKAB8ALAAAAAAWABYAAAVP4CeOZGmeaKqubEs2CekkErvEI1zZuOgYFlakECEZFi0GgTGKEBATFmJAVXweVOoKEQgABB9IQDCmrLpjETrQQlhHjINrTq/b7/i8fp8PAQA7" />
<img class="intLink" title="Delete indentation" onclick="formatDoc('outdent');" src="data:image/gif;base64,R0lGODlhFgAWAMIHAAAAADljwliE35GjuaezxtDV3NHa7P///yH5BAEAAAcALAAAAAAWABYAAAM2eLrc/jDKCQG9F2i7u8agQgyK1z2EIBil+TWqEMxhMczsYVJ3e4ahk+sFnAgtxSQDqWw6n5cEADs=" />
<img class="intLink" title="Add indentation" onclick="formatDoc('indent');" src="data:image/gif;base64,R0lGODlhFgAWAOMIAAAAADljwl9vj1iE35GjuaezxtDV3NHa7P///////////////////////////////yH5BAEAAAgALAAAAAAWABYAAAQ7EMlJq704650B/x8gemMpgugwHJNZXodKsO5oqUOgo5KhBwWESyMQsCRDHu9VOyk5TM9zSpFSr9gsJwIAOw==" />
<img class="intLink" title="Cut" onclick="formatDoc('cut');" src="data:image/gif;base64,R0lGODlhFgAWAIQSAB1ChBFNsRJTySJYwjljwkxwl19vj1dusYODhl6MnHmOrpqbmpGjuaezxrCztcDCxL/I18rL1P///////////////////////////////////////////////////////yH5BAEAAB8ALAAAAAAWABYAAAVu4CeOZGmeaKqubDs6TNnEbGNApNG0kbGMi5trwcA9GArXh+FAfBAw5UexUDAQESkRsfhJPwaH4YsEGAAJGisRGAQY7UCC9ZAXBB+74LGCRxIEHwAHdWooDgGJcwpxDisQBQRjIgkDCVlfmZqbmiEAOw==" />
<img class="intLink" title="Copy" onclick="formatDoc('copy');" src="data:image/gif;base64,R0lGODlhFgAWAIQcAB1ChBFNsTRLYyJYwjljwl9vj1iE31iGzF6MnHWX9HOdz5GjuYCl2YKl8ZOt4qezxqK63aK/9KPD+7DI3b/I17LM/MrL1MLY9NHa7OPs++bx/Pv8/f///////////////yH5BAEAAB8ALAAAAAAWABYAAAWG4CeOZGmeaKqubOum1SQ/kPVOW749BeVSus2CgrCxHptLBbOQxCSNCCaF1GUqwQbBd0JGJAyGJJiobE+LnCaDcXAaEoxhQACgNw0FQx9kP+wmaRgYFBQNeAoGihCAJQsCkJAKOhgXEw8BLQYciooHf5o7EA+kC40qBKkAAAGrpy+wsbKzIiEAOw==" />
<img class="intLink" title="Paste" onclick="formatDoc('paste');" src="data:image/gif;base64,R0lGODlhFgAWAIQUAD04KTRLY2tXQF9vj414WZWIbXmOrpqbmpGjudClFaezxsa0cb/I1+3YitHa7PrkIPHvbuPs+/fvrvv8/f///////////////////////////////////////////////yH5BAEAAB8ALAAAAAAWABYAAAWN4CeOZGmeaKqubGsusPvBSyFJjVDs6nJLB0khR4AkBCmfsCGBQAoCwjF5gwquVykSFbwZE+AwIBV0GhFog2EwIDchjwRiQo9E2Fx4XD5R+B0DDAEnBXBhBhN2DgwDAQFjJYVhCQYRfgoIDGiQJAWTCQMRiwwMfgicnVcAAAMOaK+bLAOrtLUyt7i5uiUhADs=" />
<img class="intLink" title="Clean" onclick="if(validateMode()&&confirm('Are you sure?')){oDoc.innerHTML=sDefTxt};" src="data:image/gif;base64,R0lGODlhFgAWAIQbAD04KTRLYzFRjlldZl9vj1dusY14WYODhpWIbbSVFY6O7IOXw5qbms+wUbCztca0ccS4kdDQjdTLtMrL1O3YitHa7OPcsd/f4PfvrvDv8Pv5xv///////////////////yH5BAEKAB8ALAAAAAAWABYAAAV84CeOZGmeaKqubMteyzK547QoBcFWTm/jgsHq4rhMLoxFIehQQSAWR+Z4IAyaJ0kEgtFoLIzLwRE4oCQWrxoTOTAIhMCZ0tVgMBQKZHAYyFEWEV14eQ8IflhnEHmFDQkAiSkQCI2PDC4QBg+OAJc0ewadNCOgo6anqKkoIQA7" />
<img class="intLink" title="Hyperlink" onclick="var sLnk=prompt('Write the URL here','http:\/\/');if(sLnk&&sLnk!=''&&sLnk!='http://'){formatDoc('createlink',sLnk)}" src="data:image/gif;base64,R0lGODlhFgAWAOMKAB1ChDRLY19vj3mOrpGjuaezxrCztb/I19Ha7Pv8/f///////////////////////yH5BAEKAA8ALAAAAAAWABYAAARY8MlJq7046827/2BYIQVhHg9pEgVGIklyDEUBy/RlE4FQF4dCj2AQXAiJQDCWQCAEBwIioEMQBgSAFhDAGghGi9XgHAhMNoSZgJkJei33UESv2+/4vD4TAQA7" />
<!--
<img class="intLink" title="Image" style="padding-bottom:3px;" onclick="var sLnk=prompt('Write the image URL here','http:\/\/');if(sLnk&&sLnk!=''&&sLnk!='http://'){createImage(sLnk);}" src="data:image/gif;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAACd0lEQVR42n2TbUhTURjH/+fc6ead7mZZSlK2UKRQyISavTCdFuK0N60GRRCan/oYLkIiLGL6oYK+1Cr60JebplZuEyJXhuYUCamkhCCDCiSwRNuLes86u8r0EuwPD+eey/P8zn3Oc/8EyxIEocVmszWKoqhHAgWDwYjf73crinI5tic8NvGobGtrc5+tPweFsUT1ECjFwwf30NTUtJdv38YATmN5o6vOcQpr12VokucWokjJXMOPIUvFfKk3J2Py/Qiq7XYHf/VYBeQ2d7ssRQUoWJ8Cg47iT3gR0yEFryZnEDGJoHpDHHqz2Aj2eRh2e9UKIPOKx1VUsB1ZqUm8haiauMjXge9zMEkGEF1SHHBrl4T58UFUVa0CmMocrtozDUjJ2ozArwUQQQChAqhOgFGfxJ9pHHB1p4SZsTcagJHHaY/Xeyez2IobH2dXLmG599W6UGjC1OhrDSCmkz6fT86x2HB/YhaJ1JCfhm8B//8Ar9cn5+8rx5OvwYSAOrOIiYE+zSWqAN6CvMN6EC9/hOLJetaLxSiFKdqOv6QcilCBsuwcjPW/0IxRBfT0eGRL2VZ8+NkHhWSAMC9mQyOIzWSBLaUxpKI09zZG+6dRU1OtBTx99ly2WM34NBlAlH4BI/xLCFPLGJnnY51DUJmCZUsLB/zGkcOHtIDOrm552/4NcL87Dx3RI02XDSl5IwrTK5AnFce8oka6JMHj9aH22FEtQG7vkA/UlOLR+F2wVX4wp+XBmlUJyv+FWBhFER2dXXCcOK4B7Ll4qXnQ6XTyUygSSVEYWltb4bp+LW4mVZQKLbtLShoNBkNCO4fD4cjw0JCbsSU7/wOylOIR65zv2QAAAABJRU5ErkJggg==" />
<img class="intLink" title="Video" style="padding-bottom:3px;" onclick="var sLnk=prompt('Write the video URL here','http:\/\/');if(sLnk&&sLnk!=''&&sLnk!='http://'){createVideo(sLnk);}" src="data:image/gif;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAACVElEQVR42s1T204TURRdZ+6FQsHY2NaUmpi2mKiQcIkPErRIjKhg4ME7fIH6Eeo/yJsvfoJQ5NFgwBhBLtIStBjLvYRip3Qu7dQ9B+IP+KCT7JzMZa291t5rGP7yYv8HQUtLa3e9zxeRZbkqyxIkSYIgiFQC/8hxHKoKyuUybNstmzkVZ3Vq6v0HThCLx9fizeciiqxAVRXIdAqSAIlIGGOoVJxjsAXTNGHZNjLfv31ZXFho5QTRaGy/KRJpUFQViqIgFo0h3BRGKpWCrutEcNTdMi0YpkGnia3NzR9LS4tnGAHU8xcu7gcCAY+maZzgRl8fhh89xM/sOpITE1icn0eBiAwCmoYBgyqXyxU+zkzXs5qaGn9HR+eGz+eTVALLpGJw8A6GBof+DCqdXsGr0VHs7uzAsCyu4OAgb61lMj7m9XrPXu7qWtVUjbzLRCDj3t37uH3rJge7nt9NTmJs7C0KBZ3boCHisHhYXU59DTDq3JZI9HwSRZHLd+cw8ngYV652Y46kJ5MT2MhmuXwX6HY3LYsPdG52NsZOBYPXexKJpLs6GgI0zYP29jY+/UwmwxU4TpWDTcvk/t2yLRszM9OXWDgcftDfP/BGVmSQHXhra6F6PFCIkFEOXCI3B+7qzFKJtlKEXtRRpvvx8fE+dq23d+T5i5evD/L5424OOa9CYEdgHrUqhalKzwUGkYmUFRUNjY149vTJgPtaOh0KdUbjzbFQKBj0n/SfqKur89JKVUGkJBGcUueUjJJR+FXQd3O7e+vZ9c30Snp5e2v787//mX4DaREavCsU11AAAAAASUVORK5CYII=" />
-->
<img class="intLink" title="Youtube" onclick="var sLnk=prompt('Write the youtube embed Youtube URL here','http:\/\/');if(sLnk&&sLnk!=''&&sLnk!='http://'){createYoutubeVideo(sLnk);}" style="padding-bottom:3px;" src="data:image/gif;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAB70lEQVR42mNkoBAwUs0AFwkRVl5WFiF2JiYOJgYGdrAkEIDo/0AAJBn+/Wf4+fPvvx+f//x5t+fl299wA9Y7mCcZiQnPAKpkZfj/D6QDisGaGaAMMAUigGK/r338nOF96Ow8sAHn/Z1vCrGxqRGjGQbe/fx1y2jnUXVGCQ52npO+jp+AihiRNbPoaDP8+/iJ4e/DBxiaQWpA3rLde4KPUY2PW3m3m80ddJs53NwYuCIiGb5v28rwff16Bobfv+CawQYAmd6Hzqgw6gnw6W12triI4mwgATKAOyYW7MW/z58zfJo5g+HfndtwzSAQdOScPqOxEL/JOgez08h+BjmP080dbABI7P2B/Qwv581j4P75k4GLlQXukvDjF00Zdfh59bc6WVxADzAOd3cGFlc3hifTpzF8v3CBgQUYo8xMjAzsTMwMnCwsDKAIDjhy3oBRmI1V5KyX/SugLkbk0P4rIcnw/OEjBuZfP+GamRkRmI2Z+b/F3hNiID8y7nQ0v6LOx62FEtpAw778+s3w/fdvDM1MwORz9+u3695HzmuDA8lUiN+tQlulU4ydTZSdiZGdjYmJFWQAUJLx19+/DD///GUEmvH/DzABAfGvtz9/v+66+aD89PtPO5DzAih0WIGYCYqxgX9QJ/6GYipmJnIBAGKXA3ZDuxX3AAAAAElFTkSuQmCC" />
<!--
<img class="intLink" title="Viw Html" style="padding-bottom:3px;" onclick="setDocMode();" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAACS0lEQVR4nI2TX0hTYRjGf8tz5kxFg0xDorS8DlTKLiJIQ0rMEc4SKQoqygJrRBRGQ/RCxJbQH7wwimJRsyIt1OgPeGEXLrH/NRoUx8ZQxJSFc+6c83UhO2vuQh94eT94eZ/vfZ73+0wATle/UPUIaCDJMouhCR1dFchyEiCe2+v2VBpFh9ttdrr6xVKYDYXEtRs3hc8/Lm4/G5yI9q+IHoSIj9qLrymo7Can1E3t5SFkyUKyLCFjorp0S9YVV98IgBQlUDVhTGW19/L1yxQdLRVUlKxBMsGukz1csBXg/fYJ//gUJtIK4yYIeDzI+6sJqxoDj4f4NfoB695OJqfnAPB+91O8Po+SjquU5W0w5BsEY/YGfp46g6aCUOfxjjhwduxj7cZLNN8aJTgdhOxcAkeO422oN6Y1CHJa2nhYf4x5TbB5ezHDHwO0Xn8JhHA03iV9VTpj7zw8OH+W/OZWg8DwYPXWbRx6+56IpuFyVmE7/YiZyT8krUwlKy+XgTsHyUyTOTr8mVSzBL2v4gk0IYioC0amWiT6ug4kvIdoXZNjhse2oMPviSBz4fmExsXIX5eZSHC4tor2TjdtTY1MBPxxDe2dbs6dqDFyl+tJIoHP58NaWoglxcK9niFs5UV0vxjBVl6EtaxwQYIm+OHzoasxcmMLiqJwv8/D39kQEVVHURQjRyOi6owpCpv+k2ByuN3mjEh62LpzB+PTS+sHyM408/TNIDNyMFlqqqmZd7r6iegaQg0zuwwTQykZsQlg4Tsv6+pFsNftNv0DT+gjMY6AUD8AAAAASUVORK5CYII=">
-->
<img class="intLink" title="Remove formatting" style="padding-bottom:3px;" onclick="formatDoc('removeFormat')" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAQAAAC1+jfqAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QA/4ePzL8AAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAHdElNRQfjAx8UBhREcYbCAAAAmElEQVQoz6XRIQoCYRDF8Z8gaLQZjcYvGsUkJj2DRUwewRsYjQsW4x7BM5iseoIFy9bPIOqu7K7B/5SB95iZx/AvrUIf9L7Us/uzmdojyMRSjWmDubWupYmFkZmrA7h9hiWiBJxEm6prXpbgolN98MtSI8PxvaiGVGyy9OXypik7UVpKVKIjE4Wv0AWGtlaFRJlB85d+yG8eryUvOcQW1MAAAAAldEVYdGRhdGU6Y3JlYXRlADIwMTktMDMtMzFUMTg6MDY6MjArMDI6MDCoiNwZAAAAJXRFWHRkYXRlOm1vZGlmeQAyMDE5LTAzLTMxVDE4OjA2OjIwKzAyOjAw2dVkpQAAAABJRU5ErkJggg==">
</div>
<div style="font-size:14px;font-weight:600;color:orangered;float:right;
width:220px;font-family:arial;display:table-cell;">
 <input style="margin: 2px 3px 3px 4px; border:0px; vertical-align: top;background-color:mediumseagreen;padding:4px;"  class="intLink" type="checkbox" (change)='edit($event)'/>&nbsp;Editable
  <select style="font-size: 14px; border: 1px gainsboro solid; -webkit-appearance:listbox; width: 120px;"
          onchange="topicvsclones('backcolor',this[this.selectedIndex].value);this.selectedIndex=0;">
 <option value="clone">Doc Clones</option>
 <option value="tag">Member Tags</option>
 <option class="doc" selected>Other Docs</option>
 </select>
 <!--
 <input style="margin: 2px 3px 3px 4px; border:0px; vertical-align: top;background-color:mediumseagreen;padding:4px;"  class="intLink" type="checkbox" (change)='topicvsclones($event);'/>&nbsp;Any to Any
 -->
</div>
 </div>
</div>
<div style="display:table;width:100%;">
<div style="display:table-cell;text-align:center;vertical-align: top;padding:160px 0px 0px 0px;width:50%;" id="pane1">
  <!--<div class="doNotPrint" style="float:left; width:10%;">&nbsp;</div>-->
  <div class="divh doNotPrint">
  <div class="doNotPrint" style="float:right;margin-right:21px;
  width:21px;font-size:.75em;
  height:20px; border:1px gainsboro solid; padding:5px 0px 0px 5px"
                          (click)='fullDocLeft()'><img class="intLink"
                                                             title="Viw Html"
                                                             style="padding-bottom:3px;"
                                                             src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAADTUlEQVR42oVTW2hTdxz+/if30yQSm5rUE9vYskZKyRpn0dbeLDhEgrCCbvowNrZ1uj0IYxcG0vm24R7cCgMxFB0+bTL2sLGNqswNa+zNNL0lac3aXJqT0ybHJidNkzQnZ1mfWinse/5d+G7kwl8822hQ6MSSJBGKgrBZ0k5Ec5xcRlUPOY0Synj11wRVFEuxIxb1PlpBreeLEjRyQnyJgkCuPk3n2g9qVas5EVmxhD9mhAgniB1/nzWFsA1ddzlGT5ORM3YDo5EBaiWFBwuZPPn8CS801eq0y1kJcWEDvz1Zu+O/WPsmdsHx76Put7uMx/gShQoF8DggZMgnj/lUjaVCH0ltos6gwngwg7FZ/qb3g/r3ty933g75rzjNNlaUYXRpHVaTGlPBTJq884CTQvE8G08WWOcJ82FmrxrjgefwTPODU5dt7259di16+88w9jBkuOdNYnL6+a1as+a0fo/SRJoH/KwkyRzeyy/FX/52wdvdbrIfqKIxPsfDPRofYCpVrf1nrS1BUYGhiRXMz/Eu36eNfc3fBGokSCPkRZ6NX835O08wNnMlDY8/CWcDjaymAr+Pcgj6Eq6FK/a+7fNkN7HqvvAMH+2saTOVNdksWzvvS+LZFHdt8csjn704u+sBx9Xx4ZOnDrYtQwlaSRCcSeAfD3tt6Xrr/x+w94/5z79uswVyMgSnV6A1qGG06BGeWkHYE3UtDnTspFD30SNWzBcdoe+6400fD3vPv2W3e7JyPBuLIDEZHZBrFK31PQ0te8xaxLxxxD0R19KN7j7m4sMaJS0fIc7BgBSLpNkNTmAvXHrlsDstR6i8LMxEBsODJ7dstH740Lu/q8GuNeuR8C4j+ih4q7nFdFqhrzCRnhu+1IbZqLcoRKRoDRbcEZTmozdDt0/tCFLte/f9hs5DNmWVFtkAh/Y2M/68F06To19PChv11do1QQSVFFBwz96J3e3dNcpVb/zi3tvTdAw6NfJSWb7ZaIY4rk/n1qz7Vbl0HnIRKE74I6XVVAf342s7ylTZ+zNT12gcoY8fYmZiBdA6FUTPUp4cuDTE5nQ6nfRfR5VyoCBqqRjHSRSpXv2hd6vOxnM/URJIzOqw7Avx4rpUKIIo5AR8SvgXjK19sfGajWYAAAAASUVORK5CYII=">
</div>
  <div style="border: 1px gainsboro solid; text-align: left; float:right;font-family:arial; font-weight:500;padding:0px; width:89%;margin:0 auto !important; display:inline-block;height:25px;">

    <select [(ngModel)]="selectedLeft" name="topicLeft" placeholder="Select topic" (ngModelChange)="selectTopicLeft($event)" style="height:25px;margin:0px;padding:0px; -webkit-appearance:listbox; width:100%;">
      <!--
        <ng-container *ngIf="!anytoany">
          <ng-container *ngFor="let topic of ltopics">
            <option *ngIf="(topic.topics?.length > 0)" [ngValue]="topic" style="height:25px;margin:0px;padding:0px;">{{topic.hashTag}}</option>
        </ng-container>
        </ng-container>
        <ng-container *ngIf="anytoany">
        </ng-container>
      -->
        <option *ngFor="let topic of ltopics" [ngValue]="topic" style="height:25px;margin:0px;padding:0px;">{{topic.hashTag}}</option>
    </select>

  </div>
  </div>
  <div tabindex="1" (focus)='setFocusLeft()' (blur)='setTopicLeft()' style="border: 1px gainsboro solid; text-align: left; padding:45px; width:85%;margin:0 auto !important; display:inline-block" id="textBox"> </div>
</div>
<div style="border:0px;display:table-cell;text-align:center;padding:160px 0px 0px 0px;width:50%;" id="pane2">
  <!--<div class="doNotPrint" style="float:left; width:5%;">&nbsp;</div>-->
  <!--<div class="doNotPrint" style="float:right; width:10%;">&nbsp;</div> (click)='fullDocLeft($event)' -->
  <div class="divh doNotPrint" >
  <div class="doNotPrint" style="float:right;margin-right:21px;
  width:21px;font-size:.75em;
  height:20px; border:1px gainsboro solid; padding:5px 0px 0px 5px"
                          (click)='fullDocRight()'><img class="intLink"
                                                              title="Viw Html"
                                                              style="padding-bottom:3px;"
                                                              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAADTUlEQVR42oVTW2hTdxz+/if30yQSm5rUE9vYskZKyRpn0dbeLDhEgrCCbvowNrZ1uj0IYxcG0vm24R7cCgMxFB0+bTL2sLGNqswNa+zNNL0lac3aXJqT0ybHJidNkzQnZ1mfWinse/5d+G7kwl8822hQ6MSSJBGKgrBZ0k5Ec5xcRlUPOY0Synj11wRVFEuxIxb1PlpBreeLEjRyQnyJgkCuPk3n2g9qVas5EVmxhD9mhAgniB1/nzWFsA1ddzlGT5ORM3YDo5EBaiWFBwuZPPn8CS801eq0y1kJcWEDvz1Zu+O/WPsmdsHx76Put7uMx/gShQoF8DggZMgnj/lUjaVCH0ltos6gwngwg7FZ/qb3g/r3ty933g75rzjNNlaUYXRpHVaTGlPBTJq884CTQvE8G08WWOcJ82FmrxrjgefwTPODU5dt7259di16+88w9jBkuOdNYnL6+a1as+a0fo/SRJoH/KwkyRzeyy/FX/52wdvdbrIfqKIxPsfDPRofYCpVrf1nrS1BUYGhiRXMz/Eu36eNfc3fBGokSCPkRZ6NX835O08wNnMlDY8/CWcDjaymAr+Pcgj6Eq6FK/a+7fNkN7HqvvAMH+2saTOVNdksWzvvS+LZFHdt8csjn704u+sBx9Xx4ZOnDrYtQwlaSRCcSeAfD3tt6Xrr/x+w94/5z79uswVyMgSnV6A1qGG06BGeWkHYE3UtDnTspFD30SNWzBcdoe+6400fD3vPv2W3e7JyPBuLIDEZHZBrFK31PQ0te8xaxLxxxD0R19KN7j7m4sMaJS0fIc7BgBSLpNkNTmAvXHrlsDstR6i8LMxEBsODJ7dstH740Lu/q8GuNeuR8C4j+ih4q7nFdFqhrzCRnhu+1IbZqLcoRKRoDRbcEZTmozdDt0/tCFLte/f9hs5DNmWVFtkAh/Y2M/68F06To19PChv11do1QQSVFFBwz96J3e3dNcpVb/zi3tvTdAw6NfJSWb7ZaIY4rk/n1qz7Vbl0HnIRKE74I6XVVAf342s7ylTZ+zNT12gcoY8fYmZiBdA6FUTPUp4cuDTE5nQ6nfRfR5VyoCBqqRjHSRSpXv2hd6vOxnM/URJIzOqw7Avx4rpUKIIo5AR8SvgXjK19sfGajWYAAAAASUVORK5CYII=">
</div>
  <div style="border: 1px gainsboro solid; text-align: left; float:right;font-family:arial; font-weight:500;padding:0px; width:89%;margin:0 auto !important; display:inline-block;height:25px;">

    <select [(ngModel)]="selectedRight" name="topicRight" placeholder="Select topic" (ngModelChange)="selectTopicRight($event)" style="width:100%;height:25px;margin:0px;padding:0px;-webkit-appearance:listbox;">
      <!--
        <ng-container *ngIf="!anytoany">
        <option *ngFor="let topic of selectedLeft.topics" [ngValue]="topic" style="height:25px;margin:0px;padding:0px;">{{topic}}</option>
        </ng-container>
        <ng-container *ngIf="anytoany">
        </ng-container>
      -->
        <option *ngFor="let topic of rtopics" [ngValue]="topic" style="height:25px;margin:0px;padding:0px;">{{topic.hashTag}}</option>
    </select>

  </div>
  </div>
  <div tabindex="2" (focus)='setFocusRight()' (blur)='setTopicRight()' style="border: 1px gainsboro solid; text-align: left; padding:45px; width:85%;margin:0 auto !important; display:inline-block" id="textBox2"> </div>
</div>
</div>
<br>
<hr>
<doc-footer></doc-footer>
  <!--
<p id="editMode"><input type="checkbox" name="switchMode" id="switchBox" onchange="setDocMode(this.checked);" /> <label for="switchBox">Show HTML</label></p>
<p><input type="submit" value="Save" /></p>
  -->
