<header></header>
<div  style="text-align:center; width:100%; padding-top:70px;">
<br>
<div class="row expanded align-center" style="text-align: center; margin: auto;">
	<h2 class="block_container siloqlogofont" style="min-width:300px;text-align:center;">
    <a href="/" target="_self"><span style="color:dodgerblue;">{{taxonomy}}</span><span class="sup" style="color:mediumseagreen;">&trade;</span> </a> Goldimembers</h2>
</div>
<br>
  <alert></alert>
  <br>
<form>
<div class="container2" style="width:90%;text-align:center;margin:0 auto;">
   <div class="small-12  columns" style="text-align:center;">
         <div style="font-family:sans-serif;font-size:2em;font-weight:400; color: dodgerblue;">
           New members, aim Goldimembers
         </div>
     <br>
         <div style="font-family:sans-serif;font-size:2em;font-weight:500; color: mediumseagreen;">
            In addition to free mocks,
         </div>
     <br>
     <div style="font-family:sans-serif;font-size:1.2em;font-weight:600; color: navy;">
       Access goldimembers mocks, award-winning exams, exclusive deals, and more
     </div>
     <br>
     <div style="font-family:sans-serif;font-size:1em;font-weight:200; color: navy;">
       Only <b>${{goldimembersMonthlyPrice}}</b>/month. Cancel anytime.
     </div>
   </div>
</div>
  <br>
<div class="small-12  columns" style="text-align:left;">
    <div style="width:300px;text-align:center;margin:auto;" class="siloqfont">
      <button (click)="signin()" type="button" class="siloqbutton" style="background-color:orange;color:#383838;">Start your perfect practice</button>
    </div>
  </div>
</form>
</div>
<footer></footer>

