<admin-header></admin-header>
<div  style="text-align:center; width:100%; padding-top:70px;">
<div class="row expanded align-center" style="text-align: center; margin: auto;">
	<h2 class="block_container siloqlogofont" style="min-width:300px;text-align:center;font-family:sans-serif;font-size:1.4em;font-weight:600;Width:90%;">
    <a href="/" target="_self"><span style="color:dodgerblue;">{{taxonomy}}</span><span class="sup" style="color:mediumseagreen;">&trade;</span> </a> Zone Goldimembers</h2>
</div>
<br>
  <admin-alert></admin-alert>
<form>
  <div class="container2" style="padding:0px;width:90%;text-align:center;margin:0 auto;">
    <div class="small-12  columns" style="width:300px;text-align:center;margin:10px  auto;">
              <div style="font-family:sans-serif;font-size:2em;font-weight:400; color: dodgerblue;">
              New members, aim Goldimembers
            </div>
     <br>
          <div style="font-family:sans-serif;font-size:1em;font-weight:500; color: mediumseagreen;">
            In addition to free mocks,
          </div>
     <br>
     <div style="font-family:sans-serif;font-size:1em;font-weight:600; color: navy;margin:0 auto;width:80%;">
       Access goldimembers mocks, award-winning exams, exclusive deals, and more
     </div>

     <br>
     <div style="font-family:sans-serif;font-size:1.4em;font-weight:500; color: navy;">
       Prepaid options
       <br>
     </div>
     <div style="font-family:sans-serif;font-size:1em;font-weight:200; color: navy;">
       Only <b>${{price}}</b>/{{ttl}} Days. Cancel anytime.
       </div>
   </div>
  <br>
  <div *ngIf="otherOptions && (goldimembersMonthlyPrice || goldimembersAnnualPrice || goldimembersDailyPrice)" style="width:300px;text-align:center;margin:auto;" class="siloqfont">
    <div *ngIf="goldimembersDailyPrice"  (click)="subscribeDaily($event);" style="height:5%; min-width:300px; color:#383838; border:1px solid grey;border-radius: 8px; -moz-border-radius: 8px; ">
      <a  style="padding:2px;height:18px;background-color:#383838;" *ngIf="checkedDaily" class="siloqbutton">&nbsp;o&nbsp;</a>
      <a  style="padding:2px;height:18px;background-color:whitesmoke;" *ngIf="!checkedDaily" class="siloqbutton">&nbsp;o&nbsp;</a>
      <div style=" font-weight:600;">Goldimembers Daily ${{goldimembersDailyPrice}}/day</div>
      <br>
    </div>
    <div *ngIf="goldimembersDailyPrice" style="height:5%; min-height:20px;">
    </div>
    <div *ngIf="goldimembersMonthlyPrice"  (click)="subscribeMonthly($event);"  style="height:5%; min-width:300px; color:#383838;  border:1px solid grey;border-radius: 8px; -moz-border-radius: 8px; ">
      <a style="padding:2px;height:18px;background-color:#383838;" *ngIf="checkedMonthly" class="siloqbutton">&nbsp;o&nbsp;</a>
      <a  style="padding:2px;height:18px;background-color:;" *ngIf="!checkedMonthly" class="siloqbutton">&nbsp;o&nbsp;</a>
    <div style=" font-weight:600;;">Goldimembers Monthly ${{goldimembersMonthlyPrice}}/mo</div>
      <br>
    </div>
    <div *ngIf="goldimembersMonthlyPrice" style="height:5%; min-height:20px;">
      </div>
    <div *ngIf="goldimembersAnnualPrice"  (click)="subscribeAnnual($event);" style="height:5%; min-width:300px; color:#383838; border:1px solid grey;border-radius: 8px; -moz-border-radius: 8px; ">
      <a  style="padding:2px;height:18px;background-color:#383838;" *ngIf="checkedAnnual" class="siloqbutton">&nbsp;o&nbsp;</a>
      <a  style="padding:2px;height:18px;background-color:whitesmoke;" *ngIf="!checkedAnnual" class="siloqbutton">&nbsp;o&nbsp;</a>
    <div style=" font-weight:600;">Goldimembers Annual ${{goldimembersAnnualPrice}}/yr</div>
      <br>
      <br>
    </div>
  </div>
    <br *ngIf="otherOptions">
      <div class="small-12  columns" style="width:300px;text-align:center;margin:10px  auto;">
	<div style="text-align:center;margin:auto;" class="siloqfont">
		<button (click)="subscribe()" type="button" class="siloqbutton" style="width:300px;background-color:whitesmoke;border:4px solid gold;padding:2px;"><div class="ms-text-center" style="width:300px;color:navy; font-weight:600;font-size:1.4em;"><img src="assets/sweat.png" align="middle" title="Not Logged In" alt="imockr™: Timeline Logo" style="margin-bottom:16px;width:24px; height:24px;" /><span style="color:dodgerblue;">S</span><span style="color:mediumseagreen;">i</span><span style="color:orangered;">l</span><span style="color:orange;">o</span><span style="color:dodgerblue;">q</span><span  style="color:navy;">pay</span><span class="sup" style="color:orangered;">&reg;</span></div> Siloqpay and start your perfect practice</button>
            <br *ngIf="!otherOptions">
            <br *ngIf="!otherOptions">
	</div>
    </div>
    <br *ngIf="!otherOptions">
  <div *ngIf="!otherOptions" (click)="otherOptions=!otherOptions" style="font-family:sans-serif;border-top:1px solid grey;padding-top:10px; cursor:pointer;color:dodgerblue; font-weight:600;width:300px;margin:0 auto;">Other Options</div>
    <br *ngIf="!otherOptions">
  <!--
  <br *ngIf="!otherOptions" class="siloqfont">
  <div style="width:300px;text-align:center;margin:auto;" class="siloqfont">
    <siloqpay></siloqpay>
    <br>
  </div>
  -->

  <!--
    <div style="font-family:sans-serif;font-size:1.2em;font-weight:600; color: navy;">
      Join Goldimembers for free - Limited time offer!
    </div>
    <br>
    <div class="small-12  columns" style="text-align:left;">
      <div style="width:300px;text-align:center;margin:auto;" class="siloqfont">
        <button (click)="addMeToGoldimembers()" type="button" class="siloqbutton" style="background-color:orange;color:#383838;">Add me to Goldimembers</button>
        <br>
        <br>
      </div>
    </div>
    -->

</div>
  <br>
  <br>
</form>
</div>
<admin-footer></admin-footer>

