import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import {TagService} from '../../services/tag-service.service';
import {Tag} from '../tag/model/tag';
import {TopicService} from '../../services/topic.service';
import {Topic} from '../topic/model/topic';
import {Observable, throwError} from 'rxjs';
import {HostListener} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {UserService} from '../../services/user.service';
import {Login} from '../../services/user.service';
import {CookieService} from 'ng2-cookies';
import { DataService } from "../../services/data.service";
import { AdminAlertComponent } from '../adminAlert/admin-alert.component';
import { AdminAlertService } from '../adminAlert/services/admin-alert.service';
import { AlertTypeEnum } from '../adminAlert/types/alertType.enum';
import { ModalService } from '../../modal/services/modal.service';
import { ModalComponent } from '../../modal/modal.component';
import { config } from '../../config';
import {RouterModule} from '@angular/router';
import { AdminHeaderComponent } from '../adminHeader/admin-header.component';
import { AdminFooterComponent } from '../adminFooter/admin-footer.component';
import { FormsModule } from '@angular/forms';
import { TruncatePipe } from '../tag/tag.truncate.pipe';

declare function unescape(s:string): string;
declare function escape(s:string): string;

@HostListener('window:keydown', ['$event'])
@Component({
  host: {
    '(document:keydown)': 'keyDown($event)',
  },
  selector: 'app-results',
  templateUrl: './results.component.html',
  styleUrls: ['./results.component.css'],
  imports: [AdminAlertComponent, FormsModule, RouterModule, AdminFooterComponent, AdminHeaderComponent, TruncatePipe],
  standalone: true
})

export class ResultsComponent implements OnInit {

  topics: Topic[] = [];
  selectedTopics: Topic[] = [];
  public showAuto: boolean;
  public topic: Topic = new Topic('', '', '');
  public model: Topic = new Topic('', '', '');
  public branch: Topic = new Topic('', '', '');
  public selectedTopic;
  selectedIndex: number = -1;
  searchText = "_exam";
  currentPage: number = 1;
  pageSize = 9;
  totalCount: number = 0;
  pageCount: number = 0;
  sortKey = "-updatedate";
  selectAll = false;
  public theUser = "";
  host = window.location.protocol+"//"+window.location.hostname;
  @ViewChild('modalTemplate', {read: TemplateRef}) modalTemplate: TemplateRef<any>;
  public theGoldiUser = "";
  public taxonomy = "";
  public pageTitle = "";
  public class = "";
  public subclass = "";

  constructor(private tagService: TagService, private topicService: TopicService, private activatedRoute: ActivatedRoute, public userSVC: UserService, private router: Router, private loginInfo : Login, private _cookieService:CookieService, private data: DataService, private alertService: AdminAlertService, private modalService: ModalService){
    loginInfo = new Login();
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  showAlert(type: AlertTypeEnum, msg: string) {
    this.alertService.setAlert({
      type,
      text: msg,
    });
  }

  openModal(modalTemplate: TemplateRef<any>, topic: any) {
    this.modalService
      .open(modalTemplate, { size: 'lg', title: 'Archive Topic', message: "Do you want to delete the selected topic '" + topic.hashTag + "' ?" })
      .subscribe((action) => {

      this.topicService.archiveTopic(topic).subscribe(
        () => {
          this.model = new Topic('', '', '');
          this.setFirstPage();
          this.showAlert(AlertTypeEnum['info'], "The selected topic '" + topic.hashTag + " " + "has been deleted.");
        },
         (err: any) => {
          console.log(err);
        });

      });
  }

  getCookie(key: string){
    return this._cookieService.get(key);
  }

  ngOnInit() {
    this.theUser = this.getCookie("email");
  this.theGoldiUser = this.getCookie("gmt");
    this.topic.email = this.theUser;
    this.host = window.location.protocol+"//"+window.location.hostname;
    this.class = config.class;
    this.subclass = config.subclass;
    this.taxonomy = config.taxonomy;
    this.activatedRoute.queryParams.subscribe(params => {
      this.searchText = params['q'] || "_result";
      this.topic.hashTag = params['h'] || "";
      this.currentPage = parseInt(params['p']) || 1;
    });


    this.setCurrentPage(this.currentPage);
  }

  loadTopics(callback: () => any) {
    this.topic.hashTag = escape(this.topic.hashTag);
    this.topicService.examResults(this.topic)
      .subscribe(
        (topics: any) => {
            var ttopics = topics; //Bind to view
            if(ttopics !== undefined) {
            for (let i = 0; i < ttopics.length; i++) {
             if(ttopics[i].value.trim().length > 0 && ttopics[i].value.trim().length <= 6) {
               this.topics.push(ttopics[i]);
              }
            }
            this.totalCount = this.topics.length;
            this.pageCount = Math.ceil(this.totalCount / this.pageSize);
            callback();
            this.setPageVisible();
          }
        },
         (err: any) => {
          console.log(err);
        });
         this.model = this.topic;
            this.model.hashTag = unescape(this.topic.hashTag)
  }

  archiveTopic(topic: Topic){
      let commentOperation:Observable<Topic[]>;
      this.openModal(this.modalTemplate, topic);
  }

  public selectTopic( topic: any, i: number) {
    topic.rank = i + 1;
    this.selectedIndex = i;
    this.selectedTopic =  topic;
    this.data.changeMessage(this.selectedTopic.hashTag.split(' ').slice(0,6).join(' ') + " " + this.selectedTopic.value.split(' ').slice(0,6).join(' ') + " " + this.curbemail(this.theUser));
  }

  public keyDown(event: any) {
    switch (event.keyCode) {
      case 38:
        this.onUp();
        break;
      case 40:
        this.onDown()
        break;
      case 13:
        this.setCurrentPage(1);
        break;
    }
    return;
  }

  public onDown() {
    if(this.selectedIndex < this.topics.length - 1)
    {
      this.selectedTopic = this.topics[++this.selectedIndex];
      this.data.changeMessage(this.selectedTopic.hashTag.split(' ').slice(0,6).join(' ') + " " + this.selectedTopic.value.split(' ').slice(0,6).join(' ') + " " + this.curbemail(this.selectedTopic.email));
    }
  }

  public onUp() {
    if(this.selectedIndex > 0)
    {
      this.selectedTopic = this.topics[--this.selectedIndex];
      this.data.changeMessage(this.selectedTopic.hashTag.split(' ').slice(0,6).join(' ') + " " + this.selectedTopic.value.split(' ').slice(0,6).join(' ') + " " + this.curbemail(this.selectedTopic.email));
    }
  }

  docTopic(topic: any) {
    this.router.navigate(['/admin/exam'],  { queryParams: { h : topic.hashTag, i : topic.ID}});
  }

  playTopic(topic: any) {
    this.router.navigate(['/admin/play'],  { queryParams: { h : topic.hashTag, i : topic.ID}});
  }

  editTopic2(topic: any) {
    this.router.navigate(['/admin/edit'],  { queryParams: { h : topic.hashTag, i : topic.ID}});
  }

  docBranch(branch: any) {
    this.router.navigate(['/admin/edit'],  { queryParams: { h : branch}});
  }

  manageTopicUsers(topic: any) {
    this.router.navigate(['/admin/user'],  { queryParams: { h : topic.hashTag}});
  }

  invite() {
    this.router.navigate(['/admin/invite']);
  }

  inbox() {
    //TODO:
    //this.router.navigate(['/admin/inbox'], { queryParams: { h : "inbox-"+this.theUser, d : this.theUser}});
    this.router.navigate(['/admin/publish'], { queryParams: { h : "inbox", d : "tagndoc@tagndoc.com"}});
  }

  tagndoc() {
    this.router.navigate(['/admin/tagndoc']);
  }

  //pagination for table
  setCurrentPage(page: number) {
    this.loadTopics(() => {
      this.currentPage = page;
    });
  }

  public setFirstPage() {
    this.loadTopics(() => {
      this.currentPage = 1;
    });
  }

  setPageVisible() {
    this.selectedIndex = (this.currentPage - 1) * this.pageSize;
    var end = this.currentPage * this.pageSize
    this.selectedTopics = [];
    for (let i = 0; i < this.topics.length; i++) {
      this.topics[i].show = false;
      if(i >= this.selectedIndex && i < end) {
        this.topics[i].show = true;
        this.topics[i].hashTag = unescape(this.topics[i].hashTag);
        this.topics[i].value = unescape(this.topics[i].value);
        this.selectedTopics.push(this.topics[i]);
      }
    }
    this.selectedTopic = this.topics[this.selectedIndex];
    if (this.selectedTopic) {
      this.data.changeMessage(this.selectedTopic.hashTag.split(' ').slice(0,6).join(' ') + " " + this.selectedTopic.value.split(' ').slice(0,6).join(' ') + " " + this.curbemail(this.selectedTopic.email));
      this.selectedTopic.rank = this.selectedIndex + 1;
    }
  }


  setMe(element: any) {
    var s_height = element.scrollHeight;
    element.setAttribute('style','height:'+s_height+'px');
  }

  selectAllTopics() {
    for (let i = 0; i < this.topics.length; i++) {
      if(!this.selectAll) {
        this.topics[i].checked = true;
      }
      else {
        this.topics[i].checked = false;
      }

    }
  }

  removeUserFromTopic(model: Topic, user: string){
    var id = model.users.indexOf(user);
    model.users.splice(id,  1);
    let commentOperation:Observable<Topic[]>;
    this.topicService.updateTopic(model).subscribe(
      () => {
      },
       (err: any) => {
        console.log(err);
      });
  }

  getTopicValue (topic: any) {
    this.tagService.getTopicUserTags(topic.hashTag, this.theUser)
      .subscribe((tags: any) => {
          let ts = tags;
          if(ts !== undefined && ts.length > 0) {
            for (let i = 0; i < ts.length; i++) {
              if(ts[i].value) {
                topic.value = unescape(ts[i].name) + " : " +unescape(ts[i].value);
                break;
              }
            }
          }
        },
         (err: any) => {
          console.log(err);
        });
    topic.hashTag = unescape(topic.hashTag);
  }

  sort(event: any): void {
    if (event.target instanceof EventTarget) {
      this.sortKey=this.sortKey.includes('-') ? event.target.value : '-'+event.target.value;
    }
    this.setFirstPage();
  }

  setArrayFromNumber(i: number) {
  return new Array(i);
}

curbemail(email: string): string {
    if(!email) {
      return email;
    }

    var index = email.indexOf("@");
    if(index > 0) {
      return email.substring(0, index);
    }
    return email;
  }

profile(t: Topic) {
    this.router.navigate(['profile'],  { queryParams: { h : t.hashTag, d : window.btoa(t.email)}});
    return;
  }

myProfile(t: Topic) {
    this.router.navigate(['profile'],  { queryParams: { h : t.hashTag, d : window.btoa(this.theUser)}});
    return;
  }

  renderImage(t: string) {
    var s = t;
    t = t.replace(/<(?:.|\n)*?>/gm, '');
     if (t.startsWith("http://") || t.startsWith("https://")) {
         const img = new Image();
         img.src = t;
         var w = 60;
         var h = (w/img.width)*img.height;
         t = '<img src="' + t + '" style="width:' + w + 'px; height:' + h + 'px;" />'
         return t;
      }
         return s;
    }

    first() {
      if(this.currentPage>1){
        this.currentPage=1;
        this.setPageVisible();
      }
    }

    previous() {
      if(this.currentPage>1){
        this.currentPage-=1;
        this.setPageVisible();
      }
    }

    next() {
      if(this.currentPage<this.pageCount){
        this.currentPage+=1;
        this.setPageVisible();
      }
    }

    last() {
      if(this.currentPage<this.pageCount){
        this.currentPage=this.pageCount;
        this.setPageVisible();
      }
    }
}
