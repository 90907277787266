import { Injectable } from '@angular/core';
import { Response, Headers, RequestOptions } from '@angular/http';
import { HttpClient } from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {Auto} from '../admin/auto/model/auto';
import { config } from '../config';
import {RouterModule} from '@angular/router';
import { SisHeaderComponent } from '../sisHeader/sis-header.component';
import { HomeFooterComponent } from '../homeFooter/home-footer.component';
import { FormsModule } from '@angular/forms';
import { map, pipe, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class AutoService {
constructor(private http: HttpClient) { }

    auto(s: string) : Observable<Auto[]> {
         return this.http.get<Auto[]>(config.autoUrl + "auto/" + s)
         .pipe(catchError((error:any) => throwError(error.json().error || 'Server error')));
     }

     complete(s: string) : Observable<Auto[]> {
         return this.http.get<Auto[]>(config.autoUrl + "complete/" + s)
	       .pipe(catchError((error:any) => throwError(error.json().error || 'Server error')));
     }
}
