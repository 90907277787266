import { Injectable } from '@angular/core';
import { Response, Headers, RequestOptions } from '@angular/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import { config } from '../config';
import {RouterModule} from '@angular/router';
import { SisHeaderComponent } from '../sisHeader/sis-header.component';
import { HomeFooterComponent } from '../homeFooter/home-footer.component';
import { FormsModule } from '@angular/forms';
import { map, pipe, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class TelService {
constructor(private http: HttpClient) { }

 tel(d: string) {
	 return this.http.get(config.telUrl + "crawl/?d=" + d )
	 .pipe(map( (res:Response) => {
	       res.text();
	      }))
	      .subscribe((data: any) => {console.log(data)});
     }

 syncSearch(d: string) {
 return this.http.get(config.syncUrl + "syncdb/search/" + d )
	 .pipe(map( (res:Response) => {
	       res.text();
	      }))
	      .subscribe((data: any) => {console.log(data)});
     }

 syncAuto(d: string) {
 return this.http.get(config.syncUrl + "syncdb/auto/" + d )
	 .pipe(map( (res:Response) => {
	       res.text();
	      }))
	      .subscribe((data: any) => {console.log(data)});
     }
}
