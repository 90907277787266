import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import {TopicService} from '../../services/topic.service';
import {Topic} from '../topic/model/topic';
import {Observable, throwError} from 'rxjs';
import {HostListener} from '@angular/core';
import {ActivatedRoute, Router } from '@angular/router';
import {UserService} from '../../services/user.service';
import {Login} from '../../services/user.service';
import {CookieService} from 'ng2-cookies';
import { AdminAlertComponent } from '../adminAlert/admin-alert.component';
import { AdminAlertService } from '../adminAlert/services/admin-alert.service';
import { AlertTypeEnum } from '../adminAlert/types/alertType.enum';
import { ModalService } from '../../modal/services/modal.service';
import { ModalComponent } from '../../modal/modal.component';
import { config } from '../../config';
import {RouterModule} from '@angular/router';
import { DummyHeaderComponent } from '../dummyHeader/dummy-header.component';
import { DummyFooterComponent } from '../dummyFooter/dummy-footer.component';
import { FormsModule } from '@angular/forms';
declare function escape(s:string): string;
declare function unescape(s:string): string;

@HostListener('window:keydown', ['$event'])
@Component({
  host: {
    '(document:keydown)': 'keyDown($event)',
  },
  selector: 'app-reexam',
  templateUrl: './reexam.component.html',
  styleUrls: ['./reexam.component.css'],
  imports: [AdminAlertComponent, FormsModule, RouterModule, DummyFooterComponent, DummyHeaderComponent],
  standalone: true
})

export class ReexamComponent implements OnInit {

  topics: Topic[];
  public model = new Topic('', '', '');
  public topic = new Topic('', '', '');
  public umodel = new Topic('', '', '');
  private branch = new Topic('', '', '');
  public selectedTopic;
  selectedIndex = 0;
  selectAll = false;
  public theUser = "";
 public theGoldiUser = "";
  theTitle = "";
  @ViewChild('modalTemplate', {read: TemplateRef}) modalTemplate: TemplateRef<any>;
  public taxonomy = "";
  public pageTitle = "";
  public class = "";
  public subclass = "";

  constructor( private alertService: AdminAlertService, private modalService: ModalService, private topicService: TopicService, private activatedRoute: ActivatedRoute, public userSVC: UserService, private router: Router, private _cookieService:CookieService){}

  getCookie(key: string){
    return this._cookieService.get(key);
  }

  ngOnInit() {
    this.theUser = this.getCookie("email");
  this.theGoldiUser = this.getCookie("gmt");
    this.class = config.class;
    this.subclass = config.subclass;
    this.taxonomy = config.taxonomy;
    this.activatedRoute.queryParams.subscribe(params => {
      this.model.hashTag = unescape(params['h']) || "";
      this.theTitle = this.model.hashTag;
    });

    this.setFirstPage();
  }

showAlert(type: AlertTypeEnum, msg: string) {
    this.alertService.setAlert({
      type,
      text: msg,
    });
  }

  openModal(modalTemplate: TemplateRef<any>, size: string, title: string, message: string, callback: () => any) {
    this.modalService.open(modalTemplate, { size: size, title: title, message: message })
      .subscribe((action) => { callback(); });
  }

  getTopic(h: string, e: string, callback: (t: any) => any) {
    this.topicService.getTopic(escape(h), e).subscribe(
      (data: any) => {
        callback(data);
      },
       (err: any) => {
        console.log(err);
      });
  }

  public selectTopic( topic: any, i: number) {
    topic.rank = i + 1;
    this.selectedIndex = i;
    this.selectedTopic =  topic;
  }

 public keyDown(event: any) {
    switch (event.keyCode) {
      case 38:
        this.onUp();
        event.preventDefault();
        break;
      case 40:
        this.onDown()
        event.preventDefault();
        break;
      case 13:
        this.reexam();
        event.preventDefault();
        break;
    }
    return;
  }

  public onDown() {
    if(this.selectedIndex < this.topics.length - 1)
    {
      this.selectedTopic = this.topics[++this.selectedIndex];
    }
  }

  public onUp() {
    if(this.selectedIndex > 0)
    {
      this.selectedTopic = this.topics[--this.selectedIndex];
    }
  }

  docTopic(t: any) {
    this.router.navigate(['/admin/exam'],  { queryParams: { h : t}});
  }

  //pagination for table
  public setFirstPage() {
    this.getTopic(this.model.hashTag, this.theUser, (t: any) => {
      t.hashTag = unescape(t.hashTag);
      this.topic = t;
      this.topic.hashTag = unescape(this.topic.hashTag);
      //
      this.showAlert(AlertTypeEnum['success'], "Rename and conduct the exam again!");
      this.model.hashTag = this.model.hashTag.replace("_exam_scheduled", "")
    });
  }

examReexam(t: Topic, callback: (t: any) => any){
       this.topicService.examReexam(t).subscribe(
        (data: any) => {
               callback(data);
             },
          (err: any) => {
           console.log(err);
         });
     }

  reexam(){
   this.openModal(this.modalTemplate, "md", "Exam: Reexam", "Do you want to reexam " + this.topic.hashTag + "?", () => {
    this.showAlert(AlertTypeEnum['info'], "Reexam: Ready!");
    document.getElementById("loading").style.display = "inline";
      if (this.topic.hashTag === undefined) {
        return;
      }

      if (this.model.hashTag == "" || this.model.hashTag == this.topic.hashTag) {
        return;
      }

      this.topic.hashTag = escape(this.model.hashTag);
      this.examReexam(this.topic, (t: any) => {
        document.getElementById("loading").style.display = "none";
        this.router.navigate(['/admin/exam'], { queryParams: { h : t.hashTag, t : 'Conduct', u: "conduct.imoqr@siloq.com"}});
      });
    });
  }

  jsunescape(t: string) {
    return unescape(t)
  }
}
