<body style="height:100%;width:100%; margin:0px;">
  <div class="doNotPrint Row" style="height:38px; border-bottom:1px solid  #ffffff00;left:0; top:0;padding-top:0px; position:fixed;background-color:#f0f0f0;">
    <div style="vertical-align: middle;padding:0px 0px 0px 10px;margin:0px 0px 0px 10px;width:33%;display:table-cell;font-family:arial,sans-serif; font-size:.75em;font-weight:200;text-align:left;" class="Column">
      <a [routerLink]="['/home']" ><img src="assets/{{taxonomy}}.png" align="middle" style="margin-bottom:10px;width:36px; height:36px;" title="{{taxonomy}} Home" alt="{{taxonomy}} Home"/></a>
      <!--
      <a style="border-width:2px;background-color:whitesmoke;color:#383838;" title="Start a new Topic" (click)="newDoc();" class="button siloqinput">&nbsp;+&nbsp;</a>
      -->
    </div>
      <div  autofocus class="Column" style="vertical-align:middle; width:33%;min-width:300px;text-align:center;font-size:.75em;font-weight:200;">
        <a style="text-decoration:none;" title="{{taxonomy}} Mocks" [routerLink]="['/admin/mocks']"><img src="assets/mock.png"  style="margin-bottom:10px;width:28px; height:28px;"/></a>
      <!--
        <a style="text-decoration:none;" title="{{taxonomy}} Reexam" (click)="reexams(); "alt="{{taxonomy}} Recent Mocks"><img src="assets/reexam-too.png"  style="margin-bottom:10px;width:28px; height:28px;"/></a>
        <a style="text-decoration:none;" title="{{taxonomy}} Exams" (click)="exams(); "alt="{{taxonomy}} Recent Mocks"><img src="assets/bullseye-too.png"  style="margin-bottom:10px;width:28px; height:28px;"/></a>
      -->
        <a style="text-decoration:none;" title="{{taxonomy}} Result" ><img src="assets/result.png"  style="margin-bottom:10px;width:28px; height:28px;"/></a>
      </div>
    <div style="width:33%;float:right;text-align:right;padding:0px 10px 0px 0px; 10px;width:460px;font-family:arial,sans-serif; font-size:.75em;font-weight:200;" class="Column">
      <a [routerLink]="['/admin/topic']" ><img src="assets/{{taxonomy}}.png" align="middle" style="visibility:hidden;cursor:pointer;margin-bottom:10px;width:30px; height:30px;" title="isloq™ Recent Loqs" alt="isloq™ Recent Loqs"/></a>
      <a *ngIf="userSVC.authUser.email"style="cursor:pointer;border-width:4px;padding:3px;text-decoration:none;background-color:whitesmoke;color:#383838;" title="{{userSVC.authUser.email}}" [routerLink]="['/admin/account']" class="siloqinput">&nbsp;{{userSVC.authUser.email.toUpperCase().charAt(0)}}&nbsp;</a>
    </div>
  </div>
