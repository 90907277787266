<admin-header></admin-header>
<body style="background-color:#ffffff00; height:100%;width:100%; margin:0px;">
  <form method="post">
    <div class="doNotPrint" style="background-color:whitesmoke;position:fixed;margin-top:50px;display:table;width:100%; height:38px; valign:middle;border:0px solid lightgrey;padding:0px 0px 0px 0px;text-align:center;">
      <div  style="vertical-align: middle;padding:0px;margin:0px;width:20%;display:table-cell;">
        <a class="intLink" style="font-family: Arial, Helvetica, sans-serif;float:left;padding:0px 2px 0px 2px; margin:0px 0px 0px 3px;cursor:pointer;" (click)="mocks();" title="{{curbemail(theUser)}}"><img src="assets/user.png" align="middle" title="uiloq™: Timeline Logo" title="{{userSVC.authUser.email}}"alt="uiloq™: Timeline Logo"style="margin-bottom:10px;width:18px; height:18px;" /></a>
      </div>
      <div *ngIf="topic.users" style="display:table-cell;text-align:center;margin: auto;color:mediumseagreen;padding-top:6px;font-family:Helvetica,AppleGothic,sans-serif;font-size:1em; font-weight:600;width:60%;vertical-align: middle;">
        <a *ngIf="theUser === topic.email && topic.users.indexOf('exam.imoqr@siloq.com') >= 0" title="Answer Exam '{{topic.hashTag}}'" class="fa fa-file-text-o intLink" type="button" valign="middle" (click)="branchTopic('exam');"style="color:dodgerblue;background-color:#ffffff00;border:0px; padding:0px 5px 0px 0px;font-weight:300;font-size:1em;width:22px;height:22px;"></a>

        <a *ngIf="theUser === topic.email && topic.users.indexOf('answer.imoqr@siloq.com') >= 0" title="Finalize and Conduct Exam Answer '{{topic.hashTag}}'" class="fa fa-magic intLink" type="button" valign="middle" (click)="branchTopic('answer');"style="color:dodgerblue;background-color:#ffffff00;border:0px; padding:0px 5px 0px 0px;font-weight:300;font-size:1em;width:22px;height:22px;"></a>

        <a *ngIf="theUser === topic.email && topic.users.indexOf('conduct.imoqr@siloq.com') >= 0" title="Publish Mock '{{topic.hashTag}}'" class="fa fa-dot-circle-o intLink" type="button" valign="middle" (click)="mockExam();" style="color:dodgerblue;background-color:#ffffff00;border:0px; padding:0px 5px 0px 0px;font-weight:300;font-size:1em;width:22px;height:22px;"></a>
        <a *ngIf="taxonomy==='iexamr' && theUser === topic.email && topic.users.indexOf('conduct.imoqr@siloq.com') >= 0" title="Conduct Exam '{{topic.hashTag}}'" class="fa fa-bullseye intLink" type="button" valign="middle" (click)="conductExam();" style="color:dodgerblue;background-color:#ffffff00;border:0px; padding:0px 5px 0px 0px;font-weight:300;font-size:1em;width:22px;height:22px;"></a>

        <a *ngIf="theUser === topic.email && topic.users.indexOf('scheduled.imoqr@siloq.com') >= 0" title="Exams '{{topic.hashTag}}'" class="fa fa-arrow-left intLink" type="button" valign="middle" href="branchTopic('exams')" style="color:dodgerblue;background-color:#ffffff00;border:0px; padding:0px 5px 0px 0px;font-weight:300;font-size:1em;width:22px;height:22px;"></a>
        <a *ngIf="theUser === topic.email && topic.users.indexOf('scheduled.imoqr@siloq.com') >= 0" title="Exam Scores '{{topic.hashTag}}'" class="fa fa-star intLink" type="button" valign="middle" (click)="branchTopic('scores');"style="color:dodgerblue;background-color:#ffffff00;border:0px; padding:0px 5px 0px 0px;font-weight:300;font-size:1em;width:22px;height:22px;"></a>
        <a *ngIf="theUser === topic.email && topic.users.indexOf('scheduled.imoqr@siloq.com') >= 0" title="Re-exam '{{topic.hashTag}}'" class="fa fa-circle-o intLink" type="button" valign="middle" (click)="branchTopic('reexam');"style="color:dodgerblue;background-color:#ffffff00;border:0px; padding:0px 5px 0px 0px;font-weight:300;font-size:1em;width:22px;height:22px;"></a>
        <a *ngIf="theUser === topic.email && topic.users.indexOf('scheduled.imoqr@siloq.com') >= 0" title="Exam Finalized '{{topic.hashTag}}'" class="fa fa-flag-checkered intLink" type="button" valign="middle" (click)="branchTopic('scheduled');"style="color:dodgerblue;background-color:#ffffff00;border:0px; padding:0px 5px 0px 0px;font-weight:300;font-size:1em;width:22px;height:22px;"></a>

        <a *ngIf="theUser === topic.email && topic.users.indexOf('finalized.imoqr@siloq.com') >= 0" title="Exam Scheduled '{{topic.hashTag}}'" class="fa fa-arrow-left intLink" type="button" valign="middle" (click)="branchTopic('finalized');"style="color:dodgerblue;background-color:#ffffff00;border:0px; padding:0px 5px 0px 0px;font-weight:300;font-size:1em;width:22px;height:22px;"></a>
        <a *ngIf="theUser === topic.email && topic.users.indexOf('finalized.imoqr@siloq.com') >= 0" title="Exam Download Finalized '{{topic.hashTag}}'" class="fa fa-cloud-download intLink" type="button" valign="middle" (click)="branchTopic('download');"style="color:dodgerblue;background-color:#ffffff00;border:0px; padding:0px 5px 0px 0px;font-weight:300;font-size:1em;width:22px;height:22px;"></a>
        <a *ngIf="theUser === topic.email && topic.users.indexOf('finalized.imoqr@siloq.com') >= 0" title="Exam Upload Finalized '{{topic.hashTag}}'" class="fa fa-cloud-upload intLink" type="button" valign="middle" (click)="branchTopic('upload');"style="color:dodgerblue;background-color:#ffffff00;border:0px; padding:0px 5px 0px 0px;font-weight:300;font-size:1em;width:22px;height:22px;"></a>

        <a *ngIf="topic.users.indexOf(theUser) >= 0 && topic.users.indexOf('request.imoqr@siloq.com') >= 0" title="Respond to Exam" class="fa fa-edit intLink" type="button" valign="middle" (click)="branchTopic('request');" style="color:dodgerblue;background-color:#ffffff00;border:0px; padding:0px 5px 0px 0px;font-weight:300;font-size:1em;width:22px;height:22px;"></a>

        <a *ngIf="theUser === topic.email && topic.users.indexOf('response.imoqr@siloq.com') >= 0" title="Save with answer(s) to the exam '{{topic.hashTag}}'" class="fa fa-stop intLink" type="button" valign="middle" (click)="updateTopicValue(topic);clearSpeech();mocks();" style="color:dodgerblue;background-color:#ffffff00;border:0px; padding:0px 5px 0px 0px;font-weight:300;font-size:1em;width:22px;height:22px;"></a>
        <a *ngIf="theUser === topic.email && topic.users.indexOf('response.imoqr@siloq.com') >= 0" title="Submit with answer(s) to the exam '{{topic.hashTag}}'" class="fa fa-star intLink" type="button" valign="middle" (click)="branchTopic('response');" style="color:dodgerblue;background-color:#ffffff00;border:0px; padding:0px 5px 0px 0px;font-weight:300;font-size:1em;width:22px;height:22px;"></a>

        <a *ngIf="theUser === topic.email && topic.users.indexOf('result.imoqr@siloq.com') >= 0" title="Exams" class="fa fa-arrow-left intLink" type="button" valign="middle" href="/admin/mocks" style="color:dodgerblue;background-color:#ffffff00;border:0px; padding:0px 5px 0px 0px;font-weight:300;font-size:1em;width:22px;height:22px;"></a>
        <a *ngIf="theUser === topic.email && topic.users.indexOf('result.imoqr@siloq.com') >= 0" title="Exam Results '{{topic.hashTag}}'" class="fa fa-star intLink" type="button" valign="middle" (click)="branchTopic('results');"style="color:dodgerblue;background-color:#ffffff00;border:0px; padding:0px 5px 0px 0px;font-weight:300;font-size:1em;width:22px;height:22px;"></a>
      </div>
      <div style="display:table-cell;font-family:Helvetica,AppleGothic,sans-serif;float:right;width:40%;margin:0px 0px 0px 20px;text-align:right;padding:0px 5px 0px 0px;font-size:1em;font-weight:200;">
      <div *ngIf="topic.hashTag.indexOf('_exam_result') > 0 || topic.hashTag.indexOf('_mock_result') > 0" style="padding-top:6px;display:table-cell;text-align:right;color:dodgerblue;width:20%;">{{parseFloat64(topic.value) | number : '1.0-0'}}%&nbsp;&nbsp;</div>
      <div *ngIf="topic.hashTag.indexOf('_exam_response') > 0 || topic.hashTag.indexOf('_mock_response') > 0" (click)="updateTopicValue(topic);mocks();" style="padding-top:6px;display:table-cell;text-align:right;color:orangered;width:20%;">{{timer}}&nbsp;&nbsp;</div>
        </div>
    </div>
    <div  class="doNotPrint" style="padding:100px 0px 0px 0px;font-family:Helvetica,AppleGothic,sans-serif; width:100%;text-align:center;color:mediumseagreen;font-size:1em;font-weight:500;vertical-align: middle;">{{taxonomy}}</div>
    <div class="block_container" style="background-color:#ffffff00;border-bottom:1px solid gainsboro;padding:10px 0px 0px 0px;font-family:Century Gothic,CenturyGothic,AppleGothic,sans-serif; text-align: center; valign: top; font-size:1em;font-weight:200;"><a href="" ><img src="/assets/isloq.png" align="middle" style="margin-bottom:13px;width:19px; height:19px;" /></a> <span style="color:grey;"></span> <a style="text-decoration:none;" href=""><span
                                                  style="color:dodgerblue;">i</span><span
                                                     style="color:mediumseagreen;">s</span><span
                                                     style="color:orangered;">l</span><span
                                                     style="color:orange;">o</span><span
                                                     style="color:dodgerblue;">q</span><span
                                                     class="sup" style="color:orangered;">&trade;</span><span style="color:dodgerblue;">&nbsp;Exam Instantly Runtime!</span> </a>
    </div>


    <admin-alert></admin-alert>
       <ng-template #modalTemplate>
    </ng-template>


    <div *ngIf="!model.hashTag" style="font-family:Helvetica,AppleGothic,sans-serif;width:92%;overflow:hidden;margin:4px 4% 0px 4%;padding-top:10px;z-index:-1px;">
      <h3 style="text-align:center;">No Activity Available</h3>
    </div>
      <div *ngIf="topic.users" id="doc" style="text-align:center;font-family:Helvetica,AppleGothic,sans-serif;width:92%;overflow:hidden;margin:4px 4% 40px 4%;padding-top:10px;z-index:-1px;">
      <!--
      <h2  *ngIf="topic.hashTag.indexOf('_exam_result') > 0 || topic.hashTag.indexOf('_mock_result') > 0" style="text-align:center;color:dodgerblue">{{parseFloat64(topic.value) | number : '1.0-0'}}%</h2>
      <h2  *ngIf="topic.hashTag.indexOf('_exam_response') > 0 || topic.hashTag.indexOf('_mock_response') > 0" style="text-align:center;color:orangered">{{timer}}</h2>
      -->
        <h2  *ngIf="topic.users.indexOf('conduct.imoqr@siloq.com') >= 0 || topic.users.indexOf('request.imoqr@siloq.com') >= 0" style="text-align:center;color:navy">EXAM HALL</h2>
        <h3  *ngIf="topic.users.indexOf('conduct.imoqr@siloq.com') >= 0 || topic.users.indexOf('request.imoqr@siloq.com') >= 0" style="text-align:center;color:mediumseagreen">Please start whenever you're ready!</h3>
        <h3 style="text-align:center;">"{{model.hashTag}}"</h3>
        <div *ngIf="topic.users.indexOf('conduct.imoqr@siloq.com') >= 0" style="text-align:left;min-width:300px;width:10%;margin:0 auto;">
          <h4  *ngIf="!editable" style="color:dodgerblue">Number Of Random Questions <span style="color:dark;"> <br>{{random}}</span></h4>
          <h4  *ngIf="!editable" style="pointer-events:none;user-select:none;color:navy">Time Duration in Minutes<span style="color:dark;"> <br>{{timeDuration}}</span></h4>
          <h4  *ngIf="taxonomy==='iexamr' && !editable" style="pointer-events:none;user-select:none;color:navy">Start Time<span style="color:dark;"> <br>{{startTime}} </span></h4>
          <h4  *ngIf="!editable" style="pointer-events:none;user-select:none;color:navy">Page Size<span style="color:dark;"> <br>{{pageSize}}</span></h4>
          <h4  *ngIf="!editable" style="color:dodgerblue">Right Question Reward<span style="color:dark;"> <br>{{rightQuestionReward}}</span></h4>
          <h4  *ngIf="!editable" style="pointer-events:none;user-select:none;color:navy">Wrong Question Penalty<span style="color:dark;"> <br>{{wrongQuestionPenalty}}</span></h4>
          <h4  *ngIf="!editable" style="pointer-events:none;user-select:none;color:navy">Audio Enabled<span style="color:dark;"> <br>{{audioEnabled}} </span></h4>
          <h4  *ngIf="!editable" style="pointer-events:none;user-select:none;color:navy">Goldimembers Enabled<span style="color:dark;"> <br>{{GoldimembersEnabled}}</span></h4>
          <h4  *ngIf="theUser==='tpalanisamy@yahoo.com' && !editable" style="pointer-events:none;user-select:none;color:navy" >Rent Price<span style="color:dark;"> <br>{{rentPrice}}</span></h4>
          <h4  *ngIf="theUser==='tpalanisamy@yahoo.com' && !editable" style="pointer-events:none;user-select:none;color:navy" >Buy Price<span style="color:dark;"> <br>{{buyPrice}} </span></h4>
          <h4  *ngIf="editable" style="color:navy">Number Of Random Questions <br><input class="siloqinput" type="text" title="#Random Questions" [(ngModel)]='random' name="random" style="color:dark;vertical-align: middle;height:20px; width:50px;font-weight:400;"  valign="middle"/> </h4>
          <h4  *ngIf="editable" style="color:navy">Time Duration in Minutes<br><input class="siloqinput" type="text" title="Time Duration" [(ngModel)]='timeDuration' name="timeDuration" style="color:dark;vertical-align: middle;height:20px; width:50px;font-weight:400;"  valign="middle"/> </h4>
          <h4  *ngIf="taxonomy==='iexamr' && editable" style="color:navy">Start Time <br><input class="siloqinput" type="text" title="Start Time" [(ngModel)]='startTime' name="startTime" style="color:dark;vertical-align: middle;height:20px; width:150px;font-weight:400;"  valign="middle"/> </h4>
          <h4  *ngIf="editable" style="color:navy">Page Size <br><input class="siloqinput" type="text" title="Page Size" [(ngModel)]='pageSize' name="pageSize" style="color:dark;vertical-align: middle;height:20px; width:50px;font-weight:400;"  valign="middle"/> </h4>
          <h4  *ngIf="editable" style="color:navy">Right Question Reward <br><input class="siloqinput" type="text" title="Right Question Reward" [(ngModel)]='rightQuestionReward' name="startTime" style="color:dark;vertical-align: middle;height:20px; width:150px;font-weight:400;"  valign="middle"/> </h4>
          <h4  *ngIf="editable" style="color:navy">Wrong Question Penalty<br><input class="siloqinput" type="text" title="Wrong Question Penalty" [(ngModel)]='wrongQuestionPenalty' name="pageSize" style="color:dark;vertical-align: middle;height:20px; width:50px;font-weight:400;"  valign="middle"/> </h4>
          <h4  *ngIf="editable" style="color:navy">Audio Enabled<br><input class="siloqinput" type="checkbox" title="Wrong Question Penalty" [(ngModel)]='audioEnabled' name="audioEnabled" style="color:dark;vertical-align: middle;height:20px; width:50px;font-weight:400;margin-left:-15px;"  valign="middle"/> </h4>
          <h4  *ngIf="editable" style="color:navy">Goldimembers Enabled<br><input class="siloqinput" type="checkbox" title="Goldimembers Enabled" [(ngModel)]='goldimembersEnabled' name="goldimembers" style="color:dark;vertical-align: middle;height:20px; width:50px;font-weight:400;margin-left:-15px;"  valign="middle"/> </h4>
          <h4  *ngIf="theUser==='tpalanisamy@yahoo.com' && editable" style="color:navy">Rent Price<br><input class="siloqinput" type="text" title="Rent Price" [(ngModel)]='rentPrice' name="rentPrice" style="color:dark;vertical-align: middle;height:20px; width:150px;font-weight:400;"  valign="middle"/> </h4>
          <h4  *ngIf="theUser==='tpalanisamy@yahoo.com' && editable" style="color:navy">Buy Price<br><input class="siloqinput" type="text" title="Selling Price" [(ngModel)]='buyPrice' name="sellPrice" style="color:dark;vertical-align: middle;height:20px; width:50px;font-weight:400;"  valign="middle"/> </h4>
        </div>
      <div  *ngIf="topic.users.indexOf('result.imoqr@siloq.com') >= 0" style="width:100%; text-align:center;">
         <div  class="fa" style="text-align:center; border: 4px solid orangered;width:170px;margin:0 auto;">Wanted To Revisit</div>
        <br>
        <div style="text-align: center;width:200px;margin:0 auto;">
          <div  style="text-align: left;width:100%;">⦿ - No Answer Exists</div>
          <br>
          <div  style="text-align: left;width:100%;">⦾ - Not Answered</div>
          <br>
          <div  style="text-align: left;width:100%;">✔ - Correct</div>
          <br>
          <div  style="text-align: left;width:100%;">✘ - Good Luck Next Time</div>
        </div>
      </div>
      <div  *ngIf="topic.users.indexOf('response.imoqr@siloq.com') >= 0" style="width:100%; text-align:center;padding-bottom:0px;">
        <div class="fa" style="text-align:center; border: 4px solid orangered;width:25%;">Click Question To Revisit</div>
        <div *ngIf="audioEnabled" style="width:100%;text-align:center;margin:0 auto;padding-top:10px;">
          <!--
          <a (click)="stop();" style="font-size:1em;padding-top:5px;" class="fa fa-stop"></a>
          -->
          <a (click)="fastBackward();" style="font-size:1em;padding-top:5px;" class="fa fa-fast-backward"></a>
          <a (click)="backward();" style="font-size:1em;padding-top:5px;" class="fa fa-backward"></a>
          <a *ngIf="!playing" (click)="play();" style="font-size:1em;padding-top:5px;" class="fa fa-play"></a>
          <a *ngIf="playing" (click)="pause();" style="font-size:1em;padding-top:5px;" class="fa fa-pause"></a>
          <a (click)="forward();" style="font-size:1em;padding-top:5px;" class="fa fa-forward"></a>
          <a (click)="fastForward();" style="font-size:1em;padding-top:5px;" class="fa fa-fast-forward"></a>
        </div>
        <div *ngIf="!audioEnabled" style="width:25%;text-align:center;margin:0 auto;">Double Click Option To Answer</div>
      </div>
      <h2  *ngIf="scheduled" style="text-align:center;color:orangered">This exam may have been scheduled or not available!</h2>
      <br class="doNotPrint">
        <br *ngIf="!audioEnabled" class="doNotPrint">
      <div  *ngIf="subtitles && subtitles.length" class="col-md-12" style="margin:auto; width:100%;font-family:Century Gothic,CenturyGothic,AppleGothic,sans-serif; padding-top:4px;padding-bottom:1px;background-color:white; color: navy; height:23px;text-align:center;width:90%;">
          <div style="display: inline-block;"> <a *ngIf='currentPage>1' (click)="first();" style="cursor:pointer;color:dodgerblue;"><<</a>&nbsp;&nbsp;<a *ngIf='currentPage>1' (click)="previous();" style="cursor:pointer;color:dodgerblue;"><</a>&nbsp;&nbsp;Page <input type="text"  class="siloqinput" style="font-weight:300;font-size:1em;text-align:center;width:30px;height:20px;" name="currPage" placeholder="1" [(ngModel)]='currentPage' (blur)="goto();"/> of {{pageCount | number:0}}&nbsp;&nbsp;<a *ngIf='currentPage<pageCount' (click)="next();" style="color:dodgerblue;cursor:pointer;">></a>&nbsp;&nbsp;<a *ngIf='currentPage<pageCount' (click)="last();" style="color:dodgerblue;cursor:pointer;">>></a>
        </div>
      </div>
      <br class="doNotPrint">
        <br class="doNotPrint">
        <!--
        <div *ngIf="(!(topic.users.indexOf('conduct.imoqr@siloq.com') >= 0 || topic.users.indexOf('request.imoqr@siloq.com') >= 0))" style="min-width:300px; width:60%;border:1px solid grey;text-align:center; margin:0 auto;">
        -->
        <div  style="min-width:300px; width:60%;border:1px solid grey;text-align:center; margin:0 auto;">
          <div  *ngFor="let selectedSlide of selectedSubtitles; let i = index" (click)="selectSlide(selectedSlide, i)" (mouseenter)="browseSlideIndex = i" style="width:98%;margin:0px;padding:2px">
            <div *ngFor="let tag of slides[selectedSlide]; let j = index" (click)="selectTag(tag, j)" (mouseenter)="browseTagIndex = j" style="width:98%; list-style: none;background-color:white;display:table; margin:0; font-family:Helvetica,AppleGothic,sans-serif; inline-block;padding: 2px; height:20px;text-align:left;">
                <div *ngIf="(topic.users.indexOf('answer.imoqr@siloq.com') >= 0 || topic.users.indexOf('response.imoqr@siloq.com') >= 0 || topic.users.indexOf('result.imoqr@siloq.com') >= 0)" class = "regular" [class.browse]="i === browseSlideIndex && j === browseTagIndex" [class.active]="i === selectedSlideIndex && j === selectedTagIndex" style="vertical-align:top;display:inline-block; text-align:left;margin:0px; ">
                  <div *ngIf= "j === 0 && topic.users.indexOf('result.imoqr@siloq.com') < 0" class="Row" style="display: grid; grid-template-columns: max-content auto;">
                    <span *ngIf="!isNumber(+tag.name[0])" style="display: table-cell;padding-top:6px;">&nbsp;{{(this.currentPage - 1) * this.pageSize+i+1}}.&nbsp;SLQID:&nbsp;</span>
                    <span *ngIf="isNumber(+tag.name[0])" style="display: table-cell;padding-top:6px;">&nbsp;{{(this.currentPage - 1) * this.pageSize+i+1}}.</span>
                    <span *ngIf="slides[selectedSlide][j+1].tags">
                      <span class="fa" *ngIf="isNumber(+tag.name[0]) && slides[selectedSlide][j+1].tags.indexOf('self-note') < 0" style="text-align:left;  width: 100%;display:table-cell; border: 4px solid #ffffff00;padding:0px 1px 2px 0px; font-weight:600;font-size:14px;" id="{{i}}" [innerHTML]="(renderImage(tag.name.split('. ').slice(1).join('. ')) || renderImage(tag.name.split(') ').slice(1).join(') '))) | safeHtml" (click)="selfNote($event, tag)"></span>
                      <span class="fa" *ngIf="isNumber(+tag.name[0]) && slides[selectedSlide][j+1].tags.indexOf('self-note') >= 0" style=" text-align:left;width: 100%; display:table-cell; border: 4px solid orangered; padding:0px 1px 2px 0px; font-weight:600;font-size:14px;" id="{{i}}" [innerHTML]="(renderImage(tag.name.split('. ').slice(1).join('. ')) || renderImage(tag.name.split(') ').slice(1).join(') '))) | safeHtml" (click)="selfNote($event, tag)"></span>
                      <span class="fa" *ngIf="!isNumber(+tag.name[0]) && slides[selectedSlide][j+1].tags.indexOf('self-note') < 0" style=" text-align:left; width: 100%;display:table-cell; border: 4px solid #ffffff00;padding:0px 1px 2px 0px; font-weight:600;font-size:14px;" id="{{i}}" [innerHTML]="renderImage(tag.name) | safeHtml" (click)="selfNote($event, tag)"></span>
                      <span class="fa" *ngIf="!isNumber(+tag.name[0]) && slides[selectedSlide][j+1].tags.indexOf('self-note') >= 0" style=" text-align:left; width: 100%;display:table-cell; border: 4px solid orangered; padding:0px 1px 2px 0px; font-weight:600;font-size:14px;" id="{{i}}" [innerHTML]="renderImage(tag.name) | safeHtml" (click)="selfNote($event, tag)"></span><span *ngIf="audioEnabled" (click)="speak(tag.name);" style="display:table-cell;font-size:1em;padding-top:5px;" class="fa fa-volume-up"></span>
                    </span>
                    <span *ngIf="!slides[selectedSlide][j+1].tags">
                      <span class="fa" *ngIf="isNumber(+tag.name[0])" style="text-align:left;  width: 100%;display:table-cell; border: 4px solid #ffffff00;padding:0px 1px 2px 0px; font-weight:600;font-size:14px;" id="{{i}}" [innerHTML]="(renderImage(tag.name.split('. ').slice(1).join('. ')) || renderImage(tag.name.split(') ').slice(1).join(') '))) | safeHtml" (click)="selfNote($event, tag)"></span>
                      <span class="fa" *ngIf="!isNumber(+tag.name[0])" style=" text-align:left; width: 100%;display:table-cell; border: 4px solid #ffffff00;padding:0px 1px 2px 0px; font-weight:600;font-size:14px;" id="{{i}}" [innerHTML]="renderImage(tag.name) | safeHtml" (click)="selfNote($event, tag)"></span>
                    </span>
                  </div>
                  <div *ngIf= "j === 0 && topic.users.indexOf('result.imoqr@siloq.com') >= 0" class="Row" style="display: grid; grid-template-columns: max-content auto;">
                    <span *ngIf="!isNumber(+tag.name[0])" style="display: table-cell;padding-top:6px;">&nbsp;{{(this.currentPage - 1) * this.pageSize+i+1}}.&nbsp;SLQID:&nbsp;</span>
                    <span *ngIf="isNumber(+tag.name[0])" style="display: table-cell;padding-top:6px;">&nbsp;{{(this.currentPage - 1) * this.pageSize+i+1}}.</span>
                    <span *ngIf="slides[selectedSlide][j+1].tags">
                      <span class="fa" *ngIf="isNumber(+tag.name[0]) && slides[selectedSlide][j+1].tags.indexOf('self-note') < 0" style="text-align:left;  width: 100%;display:table-cell; border: 4px solid #ffffff00;padding:0px 1px 2px 0px; font-weight:600;font-size:14px;" id="{{i}}" [innerHTML]="(renderImage(tag.name.split('. ').slice(1).join('. ')) || renderImage(tag.name.split(') ').slice(1).join(') '))) | safeHtml"></span>
                      <span class="fa" *ngIf="isNumber(+tag.name[0]) && slides[selectedSlide][j+1].tags.indexOf('self-note') >= 0" style=" text-align:left;width: 100%; display:table-cell; border: 4px solid orangered; padding:0px 1px 2px 0px; font-weight:600;font-size:14px;" id="{{i}}" [innerHTML]="(renderImage(tag.name.split('. ').slice(1).join('. ')) || renderImage(tag.name.split(') ').slice(1).join(') '))) | safeHtml"></span>
                      <span class="fa" *ngIf="!isNumber(+tag.name[0]) && slides[selectedSlide][j+1].tags.indexOf('self-note') < 0" style=" text-align:left; width: 100%;display:table-cell; border: 4px solid #ffffff00;padding:0px 1px 2px 0px; font-weight:600;font-size:14px;" id="{{i}}" [innerHTML]="renderImage(tag.name) | safeHtml"></span>
                      <span class="fa" *ngIf="!isNumber(+tag.name[0]) && slides[selectedSlide][j+1].tags.indexOf('self-note') >= 0" style=" text-align:left; width: 100%;display:table-cell; border: 4px solid orangered; padding:0px 1px 2px 0px; font-weight:600;font-size:14px;" id="{{i}}" [innerHTML]="renderImage(tag.name) | safeHtml"></span>
                    </span>
                    <span *ngIf="!slides[selectedSlide][j+1].tags">
                      <span class="fa" *ngIf="isNumber(+tag.name[0])" style="text-align:left;  width: 100%;display:table-cell; border: 4px solid #ffffff00;padding:0px 1px 2px 0px; font-weight:600;font-size:14px;" id="{{i}}" [innerHTML]="(renderImage(tag.name.split('. ').slice(1).join('. ')) || renderImage(tag.name.split(') ').slice(1).join(') '))) | safeHtml"></span>
                      <span class="fa" *ngIf="!isNumber(+tag.name[0])" style=" text-align:left; width: 100%;display:table-cell; border: 4px solid #ffffff00;padding:0px 1px 2px 0px; font-weight:600;font-size:14px;" id="{{i}}" [innerHTML]="renderImage(tag.name) | safeHtml"></span>
                    </span>
                  </div>
                  <div *ngIf="audioEnabled && j > 0" class="Row" >
                    <div  class="editTag" tabindex="{{i+j+1}}"  style="cursor:pointer;display:table-cell;padding:0px 1px 2px 0px;text-align: justify; text-justify: inter-word;display: inline-block; margin: 0 auto !important; inter-word;font-weight:500;font-size:12px;"[innerHTML]="renderImage(tag.value) | safeHtml" (ngModelChange)="tag.value = $event"></div><a (click)="speak(tag.value);" style="font-size:1em;padding-top:5px;" class="fa fa-volume-up"></a>
                  </div>
                  <div *ngIf= "!audioEnabled && j > 0" class="Row" >
                    <div  class="editTag" tabindex="{{i+j+1}}"  style="cursor:pointer;display:table-cell;padding:0px 1px 2px 0px;text-align: justify; text-justify: inter-word;display: inline-block; margin: 0 auto !important; inter-word;font-weight:500;font-size:12px;"[innerHTML]="renderImage(tag.value) | safeHtml" (ngModelChange)="tag.value = $event" (dblclick)="addAnswer($event, tag)"></div>
                  </div>
                </div>

                <div *ngIf="!(topic.users.indexOf('answer.imoqr@siloq.com') >= 0 || topic.users.indexOf('response.imoqr@siloq.com') >= 0 || topic.users.indexOf('result.imoqr@siloq.com') >= 0)" class = "regular" [class.browse]="i === browseSlideIndex && j === browseTagIndex" [class.active]="i === selectedSlideIndex && j === selectedTagIndex" style="vertical-align:top;display:inline-block; text-align:left;margin:0px; ">
                  <div *ngIf= "j === 0" class="Row" style="display: grid; grid-template-columns: max-content auto;">
                    <span *ngIf="isNumber(+tag.name[0])" style="display: table-cell;padding-top:3px;">&nbsp;{{(this.currentPage - 1) * this.pageSize+i+1}}.&nbsp;SLQID:</span>
                    <span *ngIf="!isNumber(+tag.name[0])" style="display: table-cell;padding-top:3px;">&nbsp;{{(this.currentPage - 1) * this.pageSize+i+1}}.</span>
                    <span class="fa" *ngIf="isNumber(+tag.name[0])" style="text-align:left;  width: 100%;display:table-cell; border: 1px solid #ffffff00;padding:0px 1px 2px 0px; font-weight:600;font-size:14px;" id="{{i}}" [innerHTML]="renderImage(tag.name.split('.')[1]) || renderImage(tag.name.split('. ')[1]) || renderImage(tag.name.split(')')[1]) || renderImage(tag.name.split(') ')[1]) | safeHtml" ></span>
                    <span class="fa" *ngIf="!isNumber(+tag.name[0])" style=" text-align:left; width: 100%;display:table-cell; border: 1px solid #ffffff00;padding:0px 1px 2px 0px; font-weight:600;font-size:14px;" id="{{i}}" [innerHTML]="renderImage(tag.name) | safeHtml"></span>
                  </div>
                  <div *ngIf= "j > 0" class="Row" >
                    <div *ngIf="j > 0" class="editTag" tabindex="{{i+j+1}}"  style="cursor:pointer;display:table-cell;padding:0px 1px 2px 0px;text-align: justify; text-justify: inter-word;display: inline-block; margin: 0 auto !important; inter-word;font-weight:500;font-size:12px;"[innerHTML]="renderImage(tag.value) | safeHtml" (ngModelChange)="tag.value = $event"></div>
                  </div>
                </div>

                <!--
                <div *ngIf="i >= 0 && i < subtitles.length && tag.name !== tag.hashTag"  class = "regular" [class.browse]="i === browseSlideIndex && j === browseTagIndex" [class.active]="i === selectedSlideIndex && j === selectedTagIndex" style="vertical-align:top;display:table; text-align:left;margin:0px; ">
                    <div *ngIf="j === 0"> <span><span>{{i+1}}.&nbsp;</span><span *ngIf="+tag.name[0] >= 1 && +tag.name[0] <= 9 ">SQBID:&nbsp;</span><span class="editTag" tabindex="{{i}}"  style="padding:0px 1px 2px 0px; font-weight:600;font-size:14px;" id="{{i}}" [innerHTML]="tag.name | safeHtml" ></span></span> </div>
                    <div *ngIf="j > 0" class="editTag" tabindex="{{i+j+1}}"  style="cursor:pointer;display:table-cell;padding:0px 1px 2px 0px;text-align: justify; text-justify: inter-word;display: inline-block; margin: 0 auto !important; inter-word;font-weight:500;font-size:12px;"[innerHTML]="renderImage(tag.value) | safeHtml" (ngModelChange)="tag.value = $event" (dblclick)="addAnswer($event, tag)"></div>
                </div>
  -->
            </div>
        </div>
      </div>
    </div>
    <!--
    <br class="doNotPrint">
    -->
    <div  *ngIf="subtitles && subtitles.length" class="col-md-12" style="margin:auto; width:100%;font-family:Century Gothic,CenturyGothic,AppleGothic,sans-serif; padding-top:0px;padding-bottom:1px;background-color:white; color: navy; height:23px;text-align:center;width:90%;">
      <!--
      <div style="display: inline-block;"> <a *ngIf='currentPage>1' href="admin/exam?p=1&h={{model.hashTag}}" style="cursor:pointer;color:dodgerblue;"><<</a>&nbsp;&nbsp;<a *ngIf='currentPage>1' href="admin/exam?p={{currentPage-1}}&h={{model.hashTag}}" style="cursor:pointer;color:dodgerblue;"><</a>&nbsp;&nbsp;Page {{currentPage}} of {{pageCount | number:0}}&nbsp;&nbsp;<a *ngIf='currentPage<pageCount' href="admin/exam?&p={{currentPage+1}}&h={{model.hashTag}}" style="color:dodgerblue;cursor:pointer;">></a>&nbsp;&nbsp;<a *ngIf='currentPage<pageCount' href="admin/exam?&&p={{pageCount}}&h={{model.hashTag}}" style="color:dodgerblue;cursor:pointer;">>></a>
      -->
        <div style="display: inline-block;"> <a *ngIf='currentPage>1' (click)="first();" style="cursor:pointer;color:dodgerblue;"><<</a>&nbsp;&nbsp;<a *ngIf='currentPage>1' (click)="previous();" style="cursor:pointer;color:dodgerblue;"><</a>&nbsp;&nbsp;Page <input type="text"  class="siloqinput" style="font-weight:300;font-size:1em;text-align:center;width:30px;height:20px;" name="currPage" placeholder="1" [(ngModel)]='currentPage' (blur)="goto();"/> of {{pageCount | number:0}}&nbsp;&nbsp;<a *ngIf='currentPage<pageCount' (click)="next();" style="color:dodgerblue;cursor:pointer;">></a>&nbsp;&nbsp;<a *ngIf='currentPage<pageCount' (click)="last();" style="color:dodgerblue;cursor:pointer;">>></a>
      </div>
    </div>
    <br>
    <br>
    <div class="block_container" style="background-color:#ffffff00;border-top:1px solid gainsboro;padding:10px 0px 0px 0px;font-family:Century Gothic,CenturyGothic,AppleGothic,sans-serif; text-align: center; valign: top; font-size:1em;font-weight:200;"><a style="text-decoration:none;" href="http://siloq.com" >
      <span style="color:dodgerblue;">Powered by</span> <img src="assets/isloq.png" align="middle" style="margin-bottom:13px;width:19px; height:19px;" /></a> <span style="color:grey;"></span> <a style="text-decoration:none;" href="http://siloq.com"><span style="color:dodgerblue;">i</span><span
      style="color:mediumseagreen;">s</span><span
      style="color:dodgerblue;">l</span><span
      style="color:orange;">o</span><span
      style="color:dodgerblue;">q</span><span
      class="sup"
      style="color:orangered;">™</span>
    </a>
    </div>
<doc-footer></doc-footer>
