import { Component } from '@angular/core';
import {UserService} from '../services/user.service';
import {Login} from '../services/user.service';
import { Router } from '@angular/router';
import { AlertComponent } from '../alert/alert.component';
import { AlertService } from '../alert/services/alert.service';
import { AlertTypeEnum } from '../alert/types/alertType.enum';
import { config } from '../config';
import {RouterModule} from '@angular/router';
import { HeaderComponent } from '../header/header.component';
import { FooterComponent } from '../footer/footer.component';
import { FormsModule } from '@angular/forms';

@Component({
host: {
      '(document:keydown)': 'keyDown($event)',
      },

  templateUrl: './forgot.component.html',
  styleUrls: ['./forgot.component.css'],
  imports: [AlertComponent, FormsModule, RouterModule, FooterComponent, HeaderComponent],
  standalone: true
})

export class ForgotComponent {
  password2= "";
  passwordFail: boolean = false;
  public taxonomy = "";
  public class = "";
  public subclass = "";

  constructor( private alertService: AlertService, public userSVC: UserService, private router: Router, public loginInfo: Login){
  	loginInfo = new Login();
  }

  ngOnInit() {
    this.taxonomy = config.taxonomy;
    this.class = config.class;
    this.subclass = config.subclass;
	}
   validateEmail(email: any) {
     const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
     return re.test(email);
   }

  showAlert(type: AlertTypeEnum, msg: string) {
      this.alertService.setAlert({
        type,
        text: msg,
      });
    }

  forgot() {
    if (!this.validateEmail(this.loginInfo.email)) {
       this.showAlert(AlertTypeEnum['warning'], "Email is not valid. Please re-enter and try again!");
       return;
    }

      this.userSVC.forgot(this.loginInfo);
      this.router.navigate(['/login']);
    }

    public keyDown(event: any) {
      switch (event.keyCode) {
        case 13:
        this.forgot()
          event.preventDefault();
          break;
      }
      return;
    }
 }
