<admin-header></admin-header>
  <body style="height:100%;width:100%; margin:0px;">
    <div class="doNotPrint" style="margin-top:50px;display:table;width:100%; height:18px; valign:middle;border:0px solid lightgrey;padding:20px 0px 0px 5px;">
      <admin-alert></admin-alert>
      <div style="vertical-align: middle;padding:0px;margin:0px;width:40%;display:table-cell;">
        <div style="font-family: Arial, Helvetica, sans-serif;float:left;padding:0px 2px 0px 2px; margin:0px 0px 0px 3px;cursor:pointer;" (click)="profile();" >{{curbemail(theUser)}}</div><input class="intLink" type="button" value="⭐️" valign="middle" style="background-color:#ffffff00;height:16px;width:16px;border:0px; padding:0px 0px 5px 0px;font-weight:300;font-size:.75em;"/>
      </div>
      <div style="display:table-cell;font-family:Helvetica,AppleGothic,sans-serif; width:20%;text-align:center;margin:0 auto; color:mediumseagreen;font-size:1em;font-weight:500;vertical-align:
                 middle;">Careers</div>
      <div style="display:table-cell;font-family:Helvetica,AppleGothic,sans-serif; float:right;width:40%;margin-right:10px;text-align:right;padding:0px 5px 0px 12px;">
        <a class="fa fa-check intLink" title="Done with the sharing of '{{model.hashTag}}'" (click)="accTopic(topic)"  valign="middle" style="color:orangered;;background-color:#ffffff00;font-size:1em;width:22px;height:22px;border:0px; padding-top:10px;font-weight:300;"></a>
      </div>
    </div>
    <div class="block_container" style="background-color:#ffffff00;border-bottom:1px solid gainsboro;padding:10px 0px 0px 0px;font-family:Century Gothic,CenturyGothic,AppleGothic,sans-serif; text-align: center; valign: top; font-size:1em;font-weight:200;"><a href="" ><img src="/assets/isloq.png" align="middle" style="margin-bottom:13px;width:19px; height:19px;" /></a> <span style="color:grey;"></span> <a style="text-decoration:none;" href=""><span

      style="color:dodgerblue;">{{taxonomy}}</span><span
      class="sup"
      style="color:mediumseagreen;">&trade;</span>
        Publish Instantly! </a>
    </div>
 <div style="width:90%; margin:auto; padding-top: 10px;">
   <h1 style="font-family:Century Gothic,CenturyGothic,AppleGothic,sans-serif;">Welcome And Be True To Yourself&nbsp;&nbsp;</h1>
   <h2 style="font-family:Century Gothic,CenturyGothic,AppleGothic,sans-serif;">Upload Resume&nbsp;&nbsp;</h2>
   <input type="file" (change)="FileChangeEvent($event)">
        <br>
        <br>
  <div *ngIf="CurrentFile"  class="siloqbutton" style="padding: 2px; font-family:Helvetica,AppleGothic,sans-serif; color:cornsilk; display:table; width:100%;" >
        <br>
        <img [src]="ImageSource">
        <br>
        <br>
        </div>
        <br>
   <button *ngIf="CurrentFile" type="button" class="siloqbutton" (click)="notify();" style="color:dodgerblue;display:table-cell;">Upload</button>
 </div>
 <br>
 <br>
<admin-footer></admin-footer>
