<dummy-header></dummy-header>
<body style="height:100%;width:100%; margin:0px;">
  <div style="text-align:center; width:100%; padding-top:120px;">
    <admin-alert></admin-alert>
    <br>
    <div class="row expanded align-center" style="width:100%;text-align: center; margin: auto;">
      <admin-alert></admin-alert>
      <h2 class="block_container siloqlogofont" style="text-align:left;min-width:300px;">New <a href="/" target="_self"><span style="color:dodgerblue;">{{taxonomy}}</span><span class="sup" style="color:orange;">&trade;</span>
        </a>Password</h2>
    </div>
    <br>
    <form>
      <div class="row align-center">
        <div class="small-12 columns">
          <label>
            <input autocomlete="new-password" type="password" placeholder="Enter new password"  class="siloqinput" id="password1"  style="min-width:300px;" [(ngModel)]="passwd" name="password1" required #pwd1="ngModel">
            <div [hidden]="pwd1.valid || pwd1.pristine" class="siloqfont" style="text-align:left;min-width:300px;">
              Password required
            </div>
          </label>
        </div>
        <br>
        <div class="small-12  columns">
          <label>
            <input autocomlete="new-password" type="password" placeholder="Confirm new password" id="password2"  class="siloqinput" style="min-width:300px;" [(ngModel)]="password2" name="password2" required #pwd2="ngModel">
            <div [hidden]="pwd2.valid || pwd2.pristine" class="siloqfont" style="text-align:left;min-width:300px;">
              Confirm Password
            </div>
          </label>
        </div>
        <div class="small-12  columns" style="text-align:left;">
          <div *ngIf="passwordFail" class="siloqfont" style="text-align:left;min-width:300px;">
            Passwords Do Not Match
          </div>
        </div>
        <br>
        <br>
        <div class="small-12  columns">
          <div style="width:25%;margin:auto;min-width:300px;" class="siloqfont">
            <button (click)="reset()" type="button" class="siloqbutton">Save</button>
            <button class="siloqbutton" style="color:orangered;"(click)='done()'>Cancel</button>
            <br>
          </div>
        </div>
      </div>
    </form>
  </div>
  <br>
  <br>
<dummy-footer></dummy-footer>
