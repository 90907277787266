<admin-header></admin-header>
<body style="padding-top:5px;background-color:white; height:100%; width:100%; margin:0px; padding: 0px 0% 0px 0%;">
  <form method="post">

    <div class="doNotPrint" style="background-color:whitesmoke;position:fixed;margin-top:-10px;display:table;width:100%; height:38px; valign:middle;border:0px solid lightgrey;padding:0px 0px 0px 0px;">
      <div  style="text-align:center;margin:0 auto;color:mediumseagreen;padding:0px 5px 0px 0px;font-family:Helvetica,AppleGothic,sans-serif;font-size:1em; font-weight:600;width:90%;vertical-align: middle;">
        <!--
        <a title="Merge Activities" class="fa fa-code-fork intLink" type="button" valign="middle" (click)="merge();" style="color:orangered;background-color:#ffffff00;border:0px; padding:0px 5px 0px 0px;font-weight:300;font-size:1em;width:60px;height:22px;">&nbsp;Merge</a>
        -->
        <a title="Merge Activities" style="font-family:Helvetica,AppleGothic,sans-serif; background-color:orange;color:#383838;border:0px; border-radius: 30px; -moz-border-radius: 30px;height:20px;" class="siloqbutton" (click)="merge();" >Merge Acts</a>
      </div>
    </div>
    <div class="doNotPrint" style="display:table;width:100%; height:18px; margin-top:60px; valign:middle;padding-top:50px;">
      <div style="vertical-align: middle;padding:0px;margin:0px;width:40%;display:table-cell;">
        <a class="intLink" style="font-family: Arial, Helvetica, sans-serif;float:left;padding:0px 2px 0px 2px; margin:0px 0px 0px 3px;cursor:pointer;"(click)="myProfile(selectedTopic);" title="{{curbemail(theUser)}}"><img src="assets/user.png" align="middle" title="uiloq™: Timeline Logo" title="{{userSVC.authUser.email}}"alt="uiloq™: Timeline Logo"style="margin-bottom:10px;width:18px; height:18px;" /></a>
        <input class="intLink" type="button" value="⭐️" valign="middle" style="background-color:#ffffff00;height:16px;width:16px;border:0px; padding:0px 0px 5px 0px;font-weight:300;font-size:.75em;"/>
      </div>
      <div style="padding-bottom:10px;display:table-cell;font-family:Helvetica,AppleGothic,sans-serif; width:20%;text-align:center;margin:0;color:mediumseagreen;font-size:1em;font-weight:200;vertical-align: middle;">ACTS</div>
      <div title="Sort By" style="display:table-cell;font-family:Helvetica,AppleGothic,sans-serif;float:right;width:40%;margin:0px 0px 0px 20px;text-align:right;padding:0px 5px 0px 0px;font-size:1em;font-weight:200;">
        <select name="tags" id="tags" (change)="sort($event);"
                            style="cursor:pointer;color:orangered;background-color:#ffffff00;height:18px;border:0px; padding:0px 0px 5px 0px;font-weight:300;font-size:.8em;"> <option value="email">User</option> <option value="hashtag">Topic</option> <option value="updatedate">Date</option> </select>
      </div>
    </div>
    <div class="block_container" style="font-family:Helvetica,AppleGothic,sans-serif; width:100%;margin:0 auto;color:mediumseagreen;font-size:1em;font-weight:200;vertical-align: middle;"><input name="siloqSearch" #siloqSearch  class="siloqinput" type="search" placeholder="🔍 Siloq&reg; Secure Local Loq Search"  [(ngModel)]='searchText' style="color: grey;padding:7px;height: 32px; width:42%;min-width:160px;margin: auto;"/></div>
    <div class="block_container" style="background-color:#ffffff00;border-bottom:1px solid gainsboro;padding:10px 0px 0px 0px;font-family:Century Gothic,CenturyGothic,AppleGothic,sans-serif; text-align: center; valign: top; font-size:1em;font-weight:200;"><a href="http://isloq.com" ><img src="/assets/isloq.png" align="middle" style="margin-bottom:13px;width:19px; height:19px;" /></a> <span style="color:grey;"></span> <a style="text-decoration:none;" href="http://isloq.com"><span style="color:dodgerblue;">i</span><span style="color:mediumseagreen;">s</span><span style="color:orangered;">l</span><span style="color:dodgerblue;">o</span><span style="color:orange;">q</span><span class="sup" style="color:orangered;">&trade;</span><span style="color:dodgerblue;">&nbsp;Publish Instantly Runtime!</span></a>
</div>

<admin-alert></admin-alert>
       <ng-template #modalTemplate>
    </ng-template>


<div (dblclick)="edit=!edit;" id="textbox" style="width:90%; margin:0 auto; padding: 0px 4% 0px 4%;">
  <br class="doNotPrint">
  <div  *ngIf="topics && topics.length" class="col-md-12" style="margin:auto; font-family:Century Gothic,CenturyGothic,AppleGothic,sans-serif; padding-top:4px;padding-bottom:1px;background-color:white; color: navy; height:23px;text-align:center;width:85%;">
    <div style="display: inline-block;">
      <a *ngIf='currentPage>1' (click)="first();" style="cursor:pointer;color:dodgerblue;"><<</a>&nbsp;&nbsp;<a *ngIf='currentPage>1' (click)="previous();" style="cursor:pointer;color:dodgerblue;"><</a>&nbsp;&nbsp;Page {{currentPage}} of {{pageCount | number:0}}&nbsp;&nbsp;<a *ngIf='currentPage<pageCount' (click)="next();" style="color:dodgerblue;cursor:pointer;">></a>&nbsp;&nbsp;<a *ngIf='currentPage<pageCount' (click)="last();" style="color:dodgerblue;cursor:pointer;">>></a>
    </div>
  </div>
<div class="container" style="width:100%;color:#696969;border:0px solid #696969;
border-color:gainsboro;margin-top:0px;">
	<div style="width:100%;overflow:hidden;">
		<table border = "0" cellpadding = "0" cellspacing = "0" style="font-family:arial,sans-serif; font-size:1em;font-weight:200;width:100%;">

			<tr class="block_container" style="color:grey;padding:40px;">
        <th style="width:100%;" valign="top">
          <div  style="cursor:pointer;font-weight:500;">
            <div *ngIf="!showAuto" style="width:80%;overflow:hidden;padding-top:0px;" class="doNotPrint">
              <div *ngIf="!showAuto" style="padding:15px 0px 1px 10px;font-family:Century Gothic,CenturyGothic,AppleGothic,sans-serif; font-size:.75em;font-weight:400; color: navy; height:23px;text-align:left;" >
                <div (click)="sortKey=sortKey.includes('-') ? 'hashtag' : '-hashtag'; setFirstPage();" *ngIf="topics && topics.length >= 64" style="display: inline-block;">
                  Page {{currentPage | number:'1.0-0'}} > {{totalCount | number:'1.0-0'}},000,000 Loq(s)
                </div>
                <div (click)="sortKey=sortKey.includes('-') ? 'hashtag' : '-hashtag'; setFirstPage();" *ngIf="topics && topics.length > 0 && topics.length < 64" style="display: inline-block;">
                  Page {{currentPage | number:'1.0-0'}} of {{totalCount | number:'1.0-0'}} Loq(s)
                </div>
                <div  *ngIf="topics === undefined" style="display: inline-block; text-align:left;">
                  Working...
                </div>
                <div  *ngIf="(topics && topics.length === 0)" style="display: inline-block;">
                  No LOQ
                </div>
                <input  *ngIf="(topics && topics.length > 1)" class="doNotPrint" type="checkbox" style="float:left;width: 13px; height: 13px; vertical-align: top; position: relative; top: -0px; *overflow: hidden;padding:0px;font-weight:300;font-size:.75em;padding:10px 0px 0px 0px; margin-right:10px;" (click)="setStateAll($event)"/>
              </div>
            </div>
          </div>
        </th>
      </tr>

      <tr>
        <td>
          <div class="container2 siloqfont" style="font-family:Helvetica,AppleGothic,sans-serif; font-size:1em; font-weight: 200;width:90%;">
   		<div *ngFor="let topic of selectedTopics; let i = index" (click)="selectTopic(topic, i)" (mouseenter)="browseIndex=i;">
			<div *ngIf="(!topic.edit || topic !== selectedTopic) && topic.show"  valign="top">
        <div  class = "regular" [class.active]="i === selectedIndex" [class.browse]="i === browseIndex" style="padding:10px;">
          <div style="background-color:whitesmoke;width:100%;min-height:20px;height:auto;margin:0px;font-family:Helvetica,AppleGothic,sans-serif;padding:0px 0px 10px 0px;float:center;-moz-border-radius: 0px;border-radius: 0px;border-bottom:1px solid lightgrey;">
            <div *ngIf="edit===true" style="text-align:right;min-width:60px;">
              <input  class="doNotPrint" type="checkbox" style="float:left;width: 13px; height: 13px; vertical-align: top; position: relative; top: -2px; *overflow: hidden;padding:0px;font-weight:300;font-size:.75em;padding:10px 0px 0px 0px; margin-right:10px;" (click)="setState($event.target, topic);"/>
              </div>
            <div  style="float:left; text-align:left;width:80%;">
              <div contenteditable="false"  style="text-align:left;display:table-cell;height:auto;color:navy;font-weight:500;font-size:.85em;padding:0px; margin:0px;" class="intLink" title="View Topic {{topic.hashTag}}" (click)='docTopic(topic)'[innerHTML]="parseTitle(topic.hashTag) | truncate : 64"></div>
            </div>
          <div style="text-align:right;min-width:60px;"> <input class="intLink" type="button" value="❐" valign="middle" (click)='docTopic(topic)' title="View Topic {{topic.hashTag}}" style="vertical-align:middle;color:mediumseagreen;background-color:#ffffff00;height:16px;width:16px;border:0px; padding:0px 0px 16px 0px;font-weight:300;font-size:.75em;"/> <input *ngIf="theUser===topic.email" class="intLink" type="button" value="✕" valign="middle" (click)='archiveTopic(topic)' title="Archive {{topic.hashTag}}" style="vertical-align:middle;color:orangered;background-color:#ffffff00;height:16px;width:16px;border:0px; padding:0px 0px 16px 0px;font-weight:300;font-size:.75em;"/></div>
       </div>

    <div style="vertical-align:top;margin:0px;text-align:left;padding:20px 5px 5px 5px;" >
      <div style="display:inline-block;width:100%;">
        <div (click)='docTopic(topic)' style="display:table-cell;cursor:pointer;font-family:Century Gothic,CenturyGothic,AppleGothic,sans-serif; text-align: left; valign: top; font-size:.85em;font-weight:300;color:black" [innerHTML]="renderImage(topic.value) | truncate : 128 | safeHtml"> </div>
      <span class="doNotPrint" (click)='docTopic(topic) 'style="cursor:pointer;text-align:center; border:0px; background-color:#ffffff00;height:16px;border:0px;padding:0px 0px 5px 0px;font-weight:200;font-size:.75em;color:navy;">{{topic.updateDate | date: 'M/d/yy, h:mm a'}}&nbsp;&nbsp;</span><span (click)="profile(topic);" title="View Profile {{curbemail(topic.email)}}" class="doNotPrint" style="cursor:pointer;background-color:#ffffff00;height:16px;border:0px;padding:0px 0px 5px 0px;font-weight:300;font-size:.75em;color:grey;">{{curbemail(topic.email)}}</span>

      </div>
    </div>
    </div>
      </div>
    </div>
          </div>
        </td>
      </tr>
	</table>
	</div>

</div>
<br>
<div  *ngIf="topics && topics.length" class="col-md-12" style="margin:auto; font-family:Century Gothic,CenturyGothic,AppleGothic,sans-serif; padding-top:4px;padding-bottom:1px;background-color:white; color: navy; height:23px;text-align:center;width:85%;">
  <div style="display: inline-block;">
    <!--
			<a *ngIf='currentPage>1' href="admin/topic?p=1&q={{searchText}}" style="cursor:pointer;color:dodgerblue;"><<</a>&nbsp;&nbsp;<a *ngIf='currentPage>1' href="admin/topic?&p={{currentPage-1}}&q={{searchText}}" style="cursor:pointer;color:dodgerblue;"><</a>&nbsp;&nbsp;Page {{currentPage}} of {{pageCount | number:0}}&nbsp;&nbsp;<a *ngIf='currentPage<pageCount' href="admin/topic?&p={{currentPage+1}}&q={{searchText}}" style="color:dodgerblue;cursor:pointer;">></a>&nbsp;&nbsp;<a *ngIf='currentPage<pageCount' href="admin/topic?&&p={{pageCount}}&q={{searchText}}" style="color:dodgerblue;cursor:pointer;">>></a>
    -->
    <a *ngIf='currentPage>1' (click)="first();" style="cursor:pointer;color:dodgerblue;"><<</a>&nbsp;&nbsp;<a *ngIf='currentPage>1' (click)="previous();" style="cursor:pointer;color:dodgerblue;"><</a>&nbsp;&nbsp;Page {{currentPage}} of {{pageCount | number:0}}&nbsp;&nbsp;<a *ngIf='currentPage<pageCount' (click)="next();" style="color:dodgerblue;cursor:pointer;">></a>&nbsp;&nbsp;<a *ngIf='currentPage<pageCount' (click)="last();" style="color:dodgerblue;cursor:pointer;">>></a>
		</div>
	</div>
  <div class="block_container" style="background-color:#ffffff00;border-top:1px solid gainsboro;padding:10px 0px 0px 0px;font-family:Century Gothic,CenturyGothic,AppleGothic,sans-serif; text-align: center; valign: top; font-size:1em;font-weight:200;"><a style="text-decoration:none;" href="http://siloq.com" >
    <span style="color:dodgerblue;">Powered by</span> <img src="assets/isloq.png" valign="middle" style="margin-bottom:13px;width:19px; height:19px;" /></a> <span style="color:grey;"></span> <a style="text-decoration:none;" href="http://siloq.com"><span style="color:dodgerblue;">i</span><span
    style="color:mediumseagreen;">s</span><span
    style="color:dodgerblue;">l</span><span
    style="color:orange;">o</span><span
    style="color:dodgerblue;">q</span><span
    class="sup"
    style="color:orangered;">™</span>
  </a>
  </div>
</div>
<br>
<br>
<br>
<br>
<admin-footer></admin-footer>
