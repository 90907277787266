import { Injectable } from '@angular/core';
import { Response, Headers, RequestOptions } from '@angular/http';
import { HttpClient } from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {Search} from '../search/model/search';
import { config } from '../config';
import {RouterModule} from '@angular/router';
import { SisHeaderComponent } from '../sisHeader/sis-header.component';
import { HomeFooterComponent } from '../homeFooter/home-footer.component';
import { FormsModule } from '@angular/forms';
import { map, pipe, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class SearchService {
constructor(private http: HttpClient) { }

     search(q: string, s: number) : Observable<Search> {
         return this.http.get<Search>(config.searchUrl + "search/?q=" + q + "&s=" + s)
            .pipe(catchError((error:any) => throwError(error.json().error || 'Server error')));
     }

     //search(s: string) : Observable<Search> {
	     //    return this.http.get(this.tagsUrl + "search/" + s)
	     // .pipe(map((res:Response) => res.json()))
	     //.pipe(catchError((error:any) => throwError(error.json().error || 'Server error')));
	     //}
}
