import { Component, OnInit, NgZone } from '@angular/core';
import {Title} from '@angular/platform-browser';
import {TagService} from '../services/tag-service.service';
import {TopicService} from '../services/topic.service';
import {Tag} from '../admin/tag/model/tag';
import {Topic} from '../admin/topic/model/topic';
import {User} from '../admin/user/model/user';
import {Observable, throwError} from 'rxjs';
import {HostListener} from '@angular/core';
import {ActivatedRoute, Router } from '@angular/router';
import {UserService} from '../services/user.service';
import {Login} from '../services/user.service';
import {CookieService} from 'ng2-cookies';
import {DataService } from "../services/data.service";
import { config } from '../config';
import {RouterModule} from '@angular/router';
import { HeaderComponent } from '../header/header.component';
import { HomeFooterComponent } from '../homeFooter/home-footer.component';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

declare function escape(s:string): string;
declare function unescape(s:string): string;
@HostListener('window:keydown', ['$event'])
@Component({
  host: {
    '(document:keydown)': 'keyDown($event)',
  },
  selector: 'app-press',
  templateUrl: './press.component.html',
  styleUrls: ['./press.component.css'],
  imports: [FormsModule, RouterModule, HomeFooterComponent, HeaderComponent, CommonModule],
  standalone: true
})
export class PressComponent implements OnInit {

  tags: Tag[];
  ctags: Tag[];
  utags: any;
  public theUser = "";
 public theGoldiUser = "";
  loggedinUser= "";
  user = new User("", "", "");
  topics: Topic[] = [];
  ptopics: Topic[] = [];
  public showAuto: boolean;
  public unmodel: Topic = new Topic('', '', '');
  private umodel: Topic = new Topic('', '', '');
  private branch: Topic = new Topic('', '', '');
  public selectedTopic;
  selectedIndex: number = 0;
  searchText = "";
  currentPage: number = 1;
  pageSize = 8;
  totalCount: number = 0;
  pageCount: number = 0;
  sortKey = "-updatedate";
  selectAll = false;
  host = window.location.protocol+"//"+window.location.hostname;

  constructor(private titleService: Title, private ngZone: NgZone, private tagService: TagService, private topicService: TopicService, private activatedRoute: ActivatedRoute, public userSVC: UserService, private router: Router, private _cookieService:CookieService, private data: DataService){
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  getCookie(key: string){
    return this._cookieService.get(key);
  }

  ngOnInit() {
    this.loggedinUser = this.getCookie("email");
    this.host = window.location.protocol+"//"+window.location.hostname;
    this.unmodel = new Topic('', '', '');
    this.activatedRoute.queryParams.subscribe(params => {
      this.currentPage = parseInt(params['p']) || 1;
     this.searchText = params['q'] || "";
          if(this.searchText) {
            this.searchText = unescape(this.searchText);
          }
      this.theUser = params['d'] || "";
    });

    if(this.theUser) {
      this.theUser = window.atob(this.theUser) || "";
      if(!this.loggedinUser) {
            this.router.navigate(['login']);
      }
      this.titleService.setTitle(this.curbemail(this.theUser));
      this.loadTopics();
    } else {
      this.loadPressTopics();
    }
  }

  loadPressTopics() {
    this.topicService.pressSearchTopics(this.searchText, this.sortKey, "128")
      .subscribe(
        ptopics => {
          this.ptopics = ptopics; //Bind to view
          if(this.ptopics !== undefined) {
          this.topics = [];
            for (let i = 0; i < this.ptopics.length; i++) {
                if(this.ptopics[i].hashTag.endsWith("_press")) {
                  this.topics.push(this.ptopics[i]);
               }
            }
            this.setPageVisible();
          }
        },
         (err: any) => {
          console.log(err);
        });
  }

  loadTopics() {
    this.topicService.searchTopics(escape(this.searchText), this.sortKey, this.theUser, "128")
      .subscribe(
        ptopics => {
          this.ptopics = ptopics; //Bind to view
          if(this.ptopics !== undefined) {
          this.topics = [];
            for (let i = 0; i < this.ptopics.length; i++) {
                if(this.ptopics[i].hashTag.endsWith("_press")) {
                  this.topics.push(this.ptopics[i]);
               }
            }
            this.setPageVisible();
          }
        },
         (err: any) => {
          console.log(err);
        });
  }

  setPageVisible() {
    this.totalCount = this.topics.length;
    this.pageCount = Math.ceil(this.totalCount / this.pageSize);
    this.selectedIndex = (this.currentPage - 1) * this.pageSize;
    var end = this.currentPage * this.pageSize
    var j = this.selectedIndex;
    for (let i = 0; i < this.topics.length; i++) {
      this.topics[i].show = false;
      if(j >= this.selectedIndex && j < end) {
        this.getTopicValue(this.topics[i]);
        j++;
      }
    }
    this.selectedTopic = this.topics[this.selectedIndex];
    this.data.changeMessage(this.curbemail(this.theUser));
    if (this.selectedTopic) {
      this.selectedTopic.rank = this.selectedIndex + 1;
    }
  }

getTopicValue (topic: any) {
    this.tagService.getTopicUserTags(escape(unescape(topic.hashTag)), topic.email)
      .subscribe(
       (tags: any) => {
          let ts  = tags;
          if(ts !== undefined && ts.length > 0) {
            for (let i = 0; i < ts.length; i++) {
              if(ts[i].value) {
                topic.value = unescape(ts[i].name) + " : " +unescape(ts[i].value);
                topic.show = true;
                break;
              }
            }
          }
        },
         (err: any) => {
          console.log(err);
        });
    topic.hashTag = unescape(topic.hashTag);
  }

keyDown(event: any) {
    switch (event.keyCode) {
      case 38:
        this.onUp();
        break;
      case 40:
        this.onDown()
        break;
      case 13:
      if(this.theUser) {
            this.loadTopics();
          } else {
            this.loadPressTopics();
          }
        break;
    }
    return;
  }

  public onDown() {
    if(this.selectedIndex < this.topics.length - 1)
    {
      this.selectedTopic = this.topics[++this.selectedIndex];
      this.data.changeMessage(this.selectedTopic.hashTag);
    }
  }

  public onUp() {
    if(this.selectedIndex > 0)
    {
      this.selectedTopic = this.topics[--this.selectedIndex];
      this.data.changeMessage(this.selectedTopic.hashTag);
    }
  }

  public selectTopic( topic: any, i: number) {
    topic.rank = i + 1;
    this.selectedIndex = i;
    this.selectedTopic =  topic;
    this.data.changeMessage(this.selectedTopic.hashTag);
  }

  releaseTopic(topic: any) {
    var lx = topic.hashTag.lastIndexOf("_press");
    this.router.navigate(['/release'],  { queryParams: { h : topic.hashTag.substring(0, lx), d : window.btoa(topic.email)}});
  }

  profile(p: any) {
    this.router.navigate(['/profile'],  { queryParams: {d : window.btoa(this.theUser), p : p}});
  }

  myTopic(h: any) {
    this.router.navigate(['/admin/topic'],  { queryParams: {d : window.btoa(this.loggedinUser), h : h}});
  }

  getParentTopic(topic: any): string {
    var lx = topic.hashTag.lastIndexOf("_press");
    return topic.hashTag.substring(0, lx);
  }

  getUserByEmail() {
    this.userSVC.getUserByEmail(this.theUser)
      .subscribe(
        (user: any) => {
          if(user!=undefined){
            this.user = user;
          }
        },
         (err: any) => {
          console.log(err);
        });
  }

  convertToId(source: string): string {
    return source.replace(/ /g, "-");
  }

  cutUntil(tag: Tag): string {
    var index = tag.source.indexOf("#");
    if(index > 1) {
      var url = tag.source.substring(0, index);
      return url+"#:~:text="+encodeURIComponent(tag.value).replace(/-/g, "%2D");
    } else {
      return tag.source+"#:~:text="+encodeURIComponent(tag.value).replace(/-/g, "%2D");
    }
  }

  curbemail(email: string): string {
    var index = email.indexOf("@");
    if(index > 0) {
      return email.substring(0, index);
    } else {
      return email;
    }
    return email;
  }

    renderImage(t: any) {
      var s = t;
      t = t.replace(/<(?:.|\n)*?>/gm, '');
       if (t.startsWith("http://") || t.startsWith("https://")) {
           const img = new Image();
           img.src = t;
           var w = 60;
           var h = (w/img.width)*img.height;
           t = '<img src="' + t + '" style="width:' + w + 'px; height:' + h + 'px;" />'
           return t;
        }
           return s;
      }

  parseTitle(t: string): string {
          return t.replace(/_/g, " ");
      }

  public sort(event): void {
    if(event.target) { this.sortKey=this.sortKey.includes('-') ? event.target.value : '-'+event.target.value; }
    this.setFirstPage();
  }

  public setFirstPage() {
    this.loadTopics();
  }
}

