import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import {UserService} from './user.service';
import {CookieService} from 'ng2-cookies';


@Injectable()
export class AuthGuardService implements CanActivate {

    constructor(public userSVC: UserService, private router: Router, private _cookieService:CookieService){}

     canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
       var success = false;
       var gwt = this.getCookie("gwt");
       if(gwt) {
        success = true;
       } else {
        alert('You are not allowed to view this page. You are redirected to login Page');
         this.router.navigate(['login'], { queryParams: { returnUrl: state.url }});
       }

       return success;
     }

	getCookie(key: string){
	     return this._cookieService.get(key);
	   }
}
